import PostsService from "../PostsService";

export async function loadResults(search_params, type = "detail") {
  let fields = [
    { key: "row_num", label: " # " },
    { key: "selected", label: "" },
    {
      key: "name",
      label: "Name",
      cellType: "name_link",
      helpLink: `${process.env.VUE_APP_OLD_TAIR_URL}/servlets/TairObject?type=definitions&id=897`,
    },
    {
      key: "alias",
      label: "Aliases",
      cellType: "name_link",
      helpLink: `${process.env.VUE_APP_OLD_TAIR_URL}/servlets/TairObject?type=definitions&id=92`,
    },
    {
      key: "type",
      label: "Type",
      cellType: "name_link",
      helpLink: `${process.env.VUE_APP_OLD_TAIR_URL}/servlets/TairObject?type=definitions&id=898`,
    },
    {
      key: "poly_site",
      label: "Polymorphism Site",
      cellType: "name_link",
    },
    {
      key: "genes",
      label: "Genes",
      cellType: "name_list",
    },
    {
      key: "chrom",
      label: "Chrom",
      cellType: "name_link",
    },
  ];
  let items = [];
  try {
    let response = await PostsService.fetchPolyDetailSearch(search_params);
    if (response.data.error) {
      return { error: true, items: [], fields: [] };
    }
    let docs = response.data.docs;
    let totalMatch = response.data.total;

    for (const d of docs) {
      let genes = [];
      d.gene_names &&
        d.gene_names.forEach((g) => {
          genes.push({
            name: g,
          });
        });
      let alias = "";
      if (d.aliases && d.aliases.length > 0) {
        alias = d.aliases.join(";");
      }
      items.push({
        id: d.id,
        selected: false,
        name: {
          name: d.name[0],
          link: `/polyallele?key=${d.polyId}`,
        },
        alias: {
          name: alias,
        },
        type: {
          name: d.polymorphism_type,
        },
        poly_site: {
          name: d.polymorphism_site ? d.polymorphism_site : "unknown",
        },
        chrom: {
          name: d.chromosome,
        },
        genes: genes,
        html: d.description,
        _showDetails: d.description ? true : false,
        originalRow: d,
      });
    }

    search_params.matchCount = totalMatch;
    let queryStatement = "";
    if (type === "general") {
      queryStatement = `Your query for polymorphisms where name OR GenBank accession contains the term <span class="highlight-term">${search_params.searchText}</span> resulted in <span style="color: green">${totalMatch}</span> matches`;
    } else {
      queryStatement = setQueryStatement(search_params);
    }
    return {
      error: false,
      items: items,
      fields: fields,
      queryStatement: queryStatement,
    };
  } catch (error) {
    //   this.loading = false;
    return { error: true, items: [], fields: [] };
  }
}
function setQueryStatement(params) {
  try {
    let queryStatement = `Your query for polymorphisms/alleles where `;
    let queryParts = [];
    let searchParts = [];
    params.searchTerms.forEach((search, index) => {
      if (search.search_input) {
        searchParts.push(
          `${search.search_type} ${search.search_method} <span class="highlight-term">${search.search_input}</span>`
        );
      }
    });
    if (searchParts.length > 0) {
      queryParts.push(searchParts.join(" and "));
    }

    let restrictParts = [];
    params.restrictByFeatures &&
      params.restrictByFeatures.forEach((feat) => {
        for (let key in feat) {
          if (key == "allele_poly_radio") {
            if (feat[key] == "allele") {
              restrictParts.push(
                `<span class="highlight-term">is ONLY alleles</span>,`
              );
            } else {
              restrictParts.push(
                `<span class="highlight-term">is ONLY polymorphisms</span>,`
              );
            }
          } else if (key == "is_genetic_marker") {
            restrictParts.push(
              `<span class="highlight-term">has genetic markers</span>,`
            );
          } else {
            restrictParts.push(
              `${key} is <span class="highlight-term">${feat[key]}</span>`
            );
          }
        }
      });
    if (restrictParts.length > 0) {
      let restricPartsStr = queryParts.push(restrictParts.join(" and "));
    }

    let mapParts = [];
    params.mapLocation &&
      params.mapLocation.forEach((map) => {
        if (map.chromosome) {
          mapParts.push(
            `chromosome is <span class="highlight-term">${map.chromosome}</span>`
          );
        }
        if (map.high_range && map.low_range) {
          mapParts.push(
            `AGI map location is between <span class="highlight-term">${map.low_range}</span> kb and <span class="highlight-term">${map.high_range}</span> kb`
          );
        }
      });

    if (mapParts.length > 0) {
      queryParts.push(mapParts.join(mapParts.length > 1 ? " and " : ""));
    }

    queryStatement += queryParts.join(queryParts.length > 1 ? " and " : " ");
    queryStatement += ` resulted in <span style="color: green">${params.matchCount}</span> matches`;
    return queryStatement;
  } catch (error) {
    console.log(error);
    return "";
  }
}
