<template>
  <div>
    <div v-if="!error" class="detail-wrapper">
      <div class="side-wrapper">
        <ul class="list-group list-group-flush">
          <a
            v-for="(col, i) in side_cols"
            :key="i"
            href="#"
            class="list-group-item list-group-item-action flex-column align-items-start side-item"
            v-bind:class="{ selected: i == colIdxSelected }"
            @click="sideColClicked(i)"
          >
            {{ col }}
          </a>
        </ul>
      </div>
      <div class="list-wrapper" v-on:scroll.passive="handleScroll">
        <div
          v-if="loading"
          class="d-flex justify-content-center align-items-center"
          style="height: 100%"
        >
          <b-spinner label="Loading..."></b-spinner>
        </div>
        <div
          v-if="json_data"
          class="text-left m-2 font-weight-bold fixed-title"
        >
          <div class="d-flex align-items-center">
            <h4>Gene: {{ json_data.name }} <span v-if="json_data.isObsolete" style="color: red">(obsolete)</span></h4>
            <span class="ml-2"
              >[<a :href="helpLink" target="_blank">Help</a>]</span
            >
          </div>
        </div>
        <BaseEntry
          v-for="(e, i) in entries"
          :selectedColIdx="colIdxSelected"
          :currIdx="i"
          :key="i"
          :content="e"
          :ref="`entry` + i"
        ></BaseEntry>
      </div>
    </div>
    <div v-else>
      <PageNotFound />
    </div>
  </div>
</template>
<script>
//http://localhost:8082/gene?key=1000650258
import BaseEntry from "@/components/detail/BaseEntry";
import PostsService from "@/services/PostsService";
import PageNotFound from "@/components/common/PageNotFound";
import { getSlimViewUrl, getFullViewUrl } from "@/services/JBrowseService";

export default {
  name: "GeneDetail",
  components: {
    BaseEntry,
    PageNotFound,
  },
  data() {
    return {
      loading: false,
      error: false,
      base_url: process.env.VUE_APP_S3_PATH + "gene/",
      json_data: null,
      side_cols: [
        "Summary",
        "Maps and Mapping Data",
        "Sequences",
        "Protein Data",
        "Expression",
        "Gene Ontology",
        "Germplasms",
        "Polymorphisms",
        "Publications",
        "External Links",
      ],
      colIdxSelected: 0,
      entries: [],
      entryPosys: [],
      helpTexts: {
        name: `<b>name</b> Corresponds to the published or preferred symbolic name of a gene or the systematic, chromosome based name of the open reading frame (orf) given by the Arabidopsis Genome Initiative (AGI) annotators.`,
        nameType: `<b>name_type:</b> A classification of gene names used in TAIR.

<ul>
  <li>
    <b>full_name:</b> The full descriptive name of a gene. For example; Agamous, Aspartate aminotransferase deficient 3
  </li>
  <li>
    <b>gene_product:</b> For protein coding genes, this refers to the name of the protein if different than the gene name. For genes that do not have a symbolic or full name, they may be referred to as hypothetical or expressed proteins.
  </li>
  <li>
    <b>orf:</b> Systematic name assigned to an open reading frame (orf). In the past, orf names were assigned based on the name of the BAC they were located on. For example, T7C6.11 or T7C6_11. The AGI naming convention used for the completed genome sequence uses the format At(chromosome number or letter M,C)G(for gene) and a numeric accession. Each gene model from a locus is designated with an additional numeric suffix.
  </li>
  <li>
    <b>symbol:</b> This is the mnemonic naming used for gene names by researchers. Examples include AG (Agamous) and QRT1 (Quartet1). A symbol for a gene is designated when a gene has been published or if the name and symbol have been registered at Oklahoma State, currently maintained by David Meinke's group or in GenBank.
  </li>
</ul>
`,
        geneModelType: `<b>gene model type:</b> Types of gene models used in TAIR.
<ul>
  <li>
    <b>protein coding:</b> A gene model whose product is a transcript which is subsequently translated into a protein.
  </li>
  <li>
    <b>pseudogene:</b> A gene model that represents a sequence of DNA that is very similar to a normal gene but that has been altered slightly so it is not expressed. Such genes were probably once functional but over time acquired one or more mutations that rendered them incapable of producing a protein product.
  </li>
  <li>
    <b>unknown:</b> Used when the type of gene model is unknown (e.g. for genes only known by mutation) or when the information is not yet available.
  </li>
  <li>
    <b>pre trna:</b> A gene model in which the primary product of transcription codes for transfer RNAs (t-RNA). The pre-tRNA of prokaryotes and eukaryotes has extra nucleotides at the 5' and 3' extremities and in some eukaryotic pre-tRNAs introns are also present. Maturation of tRNA precursors is a multistep enzymatic process consisting of nucleolytic size reducing reactions and of nucleotide modifications.
  </li>
  <li>
    <b>ribosomal rna:</b> The transcribed product of ribosomal DNA, also known as rRNA. These rRNA's are part of the ribosome.
  </li>
  <li>
    <b>small nuclear rna:</b> Also known as snRNA. Small nuclear RNA (snRNA) is the name used to refer to a number of small RNA molecules found in the nucleus. These RNA molecules are important in a number of processes including RNA splicing (removal of the introns from hnRNA) and maintenance of the telomeres, or chromosome ends. They are always found associated with specific proteins and the complexes are referred to as small nuclear ribonucleoproteins (SNRNP) or sometimes as snurps.
  </li>
  <li>
    <b>small nucleolar rna:</b> Small nuclear RNAs (snoRNA) that are involved in the processing of pre-ribosomal RNA in the nucleolus. Box C/D containing snoRNAs (U14, U15, U16, U20, U21 and U24-U63) direct site-specific methylation of various ribose moieties. Box H/ACA containing snoRNAs (E2, E3, U19, U23, and U64-U72) direct the conversion of specific uridines to pseudouridine. Site-specific cleavages resulting in the mature ribosomal RNAs are directed by snoRNAs U3, U8, U14, U22 and the snoRNA components of RNase MRP and RNase P.
  </li>
</ul>`,
        locus: `<b>Locus</b> In TAIR a locus is a mapped element that corresponds to a transcribed region in Arabidopsis genome, or a genetic locus that segregates as a single genetic locus or quantitative trait. Loci are mapped based upon sequence match, or by recombination frequencies. A locus can have one or more associated gene models such as alternatively spliced variants.`,
        sequence: `<b>NucleotideSequence</b> One or more nucleotide sequences associated to a sequenced object such as a gene, clone, marker or polymorphism.`,
        sourceType: `<b>type</b> Source types of nucleotide sequences in TAIR.
<ul>
  <li>
    <b>genomic:</b> Sequences obtained using genomic DNA as a template.
  </li>
  <li>
    <b>mrna:</b> Sequences obtained from complementary DNA sequences (cDNAs) representing messenger RNAs (mRNAs).
  </li>
</ul>`,
        mapLocation: `<b>Map Locations</b> Displays the location of the object (e.g. gene, clone, polymorphism) on all maps for which the data is available.`,
        mapLinks: `<b>Map Links</b> Map links are displayed for any type of data that has been placed on a map. Selecting the SeqViewer pages take you to a close up or nucleotide sequence view centered on the object (e.g. a gene, clone or polymorphism). Links to the MapViewer will display the object on all of the maps (genetic, physical and sequence) where it has been located.`,
        mapTypeInfo: `
<b>map_type:</b> Types of maps in TAIR.
<ul>
  <li>
    <b>null:</b> Two or more overlapping BAC, cosmid or similarly large clone.
  </li>
  <li>
    <b>genetic:</b> A linkage map of a chromosome based upon recombination frequencies. Sets of markers are mapped by crossing parents with two different forms of the marker (in the case of many classical markers, these are the wild-type and the mutant) and scoring how many recombination events separate the marker from other markers on the same map.
  </li>
  <li>
    <b>nuc_sequence:</b> A linkage map based on the ordered assembly nucleotide sequences of BACS obtained from Arabidopsis Genome Initiative (AGI). BAC clone sequences used to generate the sequence map are termed Annotation Units which may differ from the original BAC clone sequence due to trimming of sequences at the ends of the clones. The order of sequences is based upon the tiling path provided by the AGI.
  </li>
  <li>
    <b>physical_framework:</b> Physical maps are collections of overlapping clones that have been arranged into a tiling path based on either fingerprinting (digestion of clones with restriction enzymes and comparison of the fragment sizes) or hybridization. The framework designates the physical map used in AtDB as the reference for scaling multiple maps for display.
  </li>
</ul>
`,
        geneFeatures: `<b>sequence features:</b> Types of gene or genome features that can be mapped on the genome or a gene. These may be structural features of a gene, or characteristic sequence features of a genome (such as repeats or duplicated regions).
<ul>
  <li>
    <b>3'splice site:</b> During RNA processing, when the introns are removed from the transcript the 3' splice site defines the 3' boundary of the intron. Typically the dinucleotide combination AG defines the 3' boundary.
  </li>
  <li>
    <b>3'UTR:</b> An untranslated region (UTR) located at the 3' end of the transcript starting at the end of the last (termination) codon. Typically this region contains the polyadenylation site and may contain sequences required for post-transcriptional regulation.
  </li>
  <li>
    <b>5'splice site:</b> During RNA processing, when the introns are removed from the transcript the 5' splice site defines the 5' boundary of the intron. Typically the dinucleotide combination GT defines the 5' boundary.
  </li>
  <li>
    <b>5'UTR:</b> An untranslated region (UTR) located at the 5' end of a transcript that extends up to but not including the first codon.
  </li>
  <li>
    <b>attenuator:</b> An attenuator is the terminator sequence at which attenuation occurs. Attenuation describes the regulation of transcription that is involved in controlling the expression of bacterial operons.
  </li>
  <li>
    <b>binding site:</b> A DNA sequence to which a protein, such as a transcription factor, binds.
  </li>
  <li>
    <b>clone seq:</b> Clones that are mapped onto the genome sequence include annotation units (e.g. BACS used to assemble the genome sequence), full-length cDNAs and expressed sequence tags. The clone sequence is matched to the genome sequence according to a set of parameters defined for each clone type.
  </li>
  <li>
    <b>coding region:</b> The DNA sequence of a gene that is translated into a protein.
  </li>
  <li>
    <b>exon:</b> A segment of an interrupted gene that is represented in the mature RNA product.
  </li>
  <li>
    <b>genic:</b> The parts of the genome that contain genes. The current definition of a genic region in TAIR does not include upstream of any 5' UTR's or downstream of any 3' UTRs. The presence of UTR sequences is determined by comparison to full-length cDNA sequences and is not inferred.
  </li>
  <li>
    <b>intergenic:</b> The portions of a genome that are not considered to lie within a defined gene. Intergenic regions may overlap with genic regions on the complement strand. Intergenic region includes sequences upstream and downstream of experimentally determined 5' and 3' UTRs. If no UTR sequences are determined, upstream and downstream intergenic sequences are derived from the position of the most 5' and 3' base pairs of the gene model.
  </li>
  <li>
    <b>intron:</b> A segment of DNA in a gene that is transcribed but removed from the transcript by splicing together exon sequences from either side of the intron.
  </li>
  <li>
    <b>match segment:</b> Regions of the genome upon which a segment of similar sequence is mapped. For example sequences flanking polymorphisms such as T-DNA insertion flanks or sequences at the 5' and 3' flanks of a substitution are all sequence matched segments.
  </li>
  <li>
    <b>ORF:</b> An open reading frame (ORF) corresponds to any nucleotide sequence that can potentially encode a protein. In TAIR ORFs include those that are experimentally verified by comparison to cDNA sequence as well as ORFs predicted by computational methods. Predicted ORFs include those encoding hypothetical proteins (e.g. there is no evidence that a transcript is generated that corresponds to the open reading frame).
  </li>
  <li>
    <b>origin of replication:</b> A sequence of DNA at which replication is initiated.
  </li>
  <li>
    <b>poly A site:</b> The polyadenylation site defines the place in the gene where addition of a sequence of polyadenylic acid to the 3' end of an RNA after transcription will occur.
  </li>
  <li>
    <b>promoter:</b> The part of a gene required for its transcriptional regulation. This includes specific regions for initiation of transcription and regions required for temporal, spatial and quantitative regulation.
  </li>
  <li>
    <b>splice junction:</b> The sequences immediately surrounding the exon-intron boundaries.
  </li>
  <li>
    <b>terminator:</b> A sequence of DNA found at the end of a transcript, that causes RNA polymerase to terminate transcription.
  </li>
</ul>`,
        mapLinks: `<b>Map Links</b> Map links are displayed for any type of data that has been placed on a map. Selecting the SeqViewer pages take you to a close up or nucleotide sequence view centered on the object (e.g. a gene, clone or polymorphism). Links to the MapViewer will display the object on all of the maps (genetic, physical and sequence) where it has been located.`,
        polymorphism: `<b>Polymorphism</b> Any genetic or epigenetic variation between two ecotypes or within one ecotype that can be assessed experimentally by sequencing, PCR amplification, restriction digest analysis or phenotypic inspection.`,
        polyName: `<b>name</b> The name of a polymorphism such as an allele name, or an alphanumeric identifying code (e.g. for SALK T-DNA insertions or TILLing lines) or the name of the marker used to detect the polymorphism.`,
        polyType: `<b>type:</b> Types of polymorphisms in TAIR.
<ul>
  <li>
    <b>compound:</b> Refers to polymorphisms between two lines where an insertion and a substitution or a deletion and a substitution occur at the same genomic location.
  </li>
  <li>
    <b>deletion:</b> A variation where a segment of DNA is absent in one variant with respect to a reference polymorphism. In TAIR, the reference polymorphism corresponds to the wild type Columbia ecotype, but other reference ecotypes may be used if the Columbia variant is not known.
  </li>
  <li>
    <b>digest_pattern:</b> The characteristic pattern of products of a restriction enzyme digestion. Also refers to a type of polymorphism where the pattern of restriction enzyme fragments in one variant differs from that of another variant.
  </li>
  <li>
    <b>INDEL:</b> Insertion/deletions (INDELS) are sequence variants where the Columbia (reference) polymorphism is an insertion relative to one ecotype and a deletion relative to another, different ecotype.
  </li>
  <li>
    <b>insertion:</b> A variant in which a segment of DNA is present in one genotype and absent in another. In TAIR, insertions are defined with respect to a reference polymorphism. The reference polymorphism typically corresponds to the wild type Columbia variant, but other reference ecotypes may be used if the Columbia variant is not known.
  </li>
  <li>
    <b>PCR_product_length:</b> Refers to polymorphisms that are represented by different PCR product lengths. The polymorphisms are linked to the genetic markers (SSLP, AFLP) that can be used to detect them.
  </li>
  <li>
    <b>substitution:</b> Replacement of one nucleotide sequence by another nucleotide or one amino acid in a protein by another amino acid. Also known as a single nucleotide polymorphism (SNP).
  </li>
  <li>
    <b>visible:</b> Allelic variations in a visible or biochemical phenotype at one or more loci that can be detected by quantitative or qualitative methods.
  </li>
</ul>
`,
        alleleType: `<b>allele type:</b> A classification of alleles based upon the phenotype and genotype of alleles.
<ul>
  <li>
    <b>antimorphic:</b> A type of mutation in which the mutated gene product has an altered function that acts antagonistically to the wild type allele. Antimorphic mutants are usually dominant or semi-dominant.
  </li>
  <li>
    <b>gain of function:</b> A type of mutation in which the altered gene product possesses a new function or a new pattern of gene expression. Gain of function mutants are usually dominant or semi-dominant.
  </li>
  <li>
    <b>haplo-insufficient:</b> A description applied to a gene that produces a mutant phenotype when present in a diploid individual heterozygous for an amorphic allele.
  </li>
  <li>
    <b>hypermorphic:</b> A type of mutation in which the altered gene product possesses an increased level of activity, or in which the wild-type gene product is expressed at an increased level.
  </li>
  <li>
    <b>hypomorphic:</b> A type of mutation in which the altered gene product possesses an increased level of activity, or in which the wild-type gene product is expressed at an increased level.
  </li>
  <li>
    <b>loss of function:</b> A type of mutation in which the altered gene product lacks the function of the wild-type gene. Also called amorphic or null mutation.
  </li>
  <li>
    <b>unknown:</b> Used when the type of the allele is not known or the information is not yet available.
  </li>
</ul>
`,
        germplasm: `<b>Germplasm</b> In TAIR, germplasms correspond to individual strains having unique genotypes, pooled strains and sets of pools and strains.`,
        phenotype: `<b>phenotype</b> Any detectable manifestation of the genotype of an organism.`,
        comments: `<b>Comments</b> Comments displayed include comments contributed by TAIR curators or other members of the community. Comments are associated to particular data on the data detail pages. Registered users can submit comments on any detail page. The comments are then displayed along with the name of the contributor. Comments are reviewed by curators who may perform updates or corrections to data based upon the contributors comments.`,
        updateHistory: `<b>UpdateHistory</b> History of this record in the database. The types of histories tracked in the database include obsoletion, merging (obsoleted and merged with another object), replacement (a newer version of an object replacing an older version) and splits (an object giving rise to two objects).`,
        dateLastModified: `<b>date last modified</b> Date when the record was last modified in the database.`,
        tairAccession: `<b>TAIR Accession</b> The unique identifier for a record in TAIR. The accession can be used to create external links to TAIR detail pages from other databases or websites. For information on how to create links using the accessions see our page on Hyperlinking to TAIR under the About TAIR section of the website.`,
      },
      helpLink: `${process.env.VUE_APP_OLD_TAIR_URL}/help/helppages/genedeta.jsp`,
    };
  },
  async mounted() {
    if (this.$route.query.key || this.$route.query.name) {
      try {
        this.loading = true;
        let geneId = null;
        if (this.$route.query.name) {
          let response = await PostsService.getGeneIdByName(
            this.$route.query.name.toUpperCase()
          );
          geneId = response.data;
          if (geneId == null) {
            this.error = true;
          }
        } else {
          geneId = this.$route.query.key;
        }
        this.json_data = (await this.getGeneDetail(geneId)).data;
        this.calculateUTRsAndIntrons(this.json_data.geneFeatures);
        this.processEntries();
        this.loading = false;
        setTimeout(() => {
          document.title = this.json_data.name;
          this.entries.forEach((e, i) => {
            let entryPagePosY = this.$refs["entry" + i][0].$el.offsetTop;

            this.entryPosys.push(entryPagePosY);
          });
        }, 1000);
      } catch (error) {
        console.error("Error fetching gene details: ", error);
        this.loading = false;
        this.error = true;
      }
    }
  },
  methods: {
    calculateUTRsAndIntrons(geneFeatures) {
      const orf = geneFeatures.find(
        (feature) => feature.assignmentFeatureType === "ORF"
      );
      const exons = geneFeatures.filter(
        (feature) => feature.assignmentFeatureType === "exon"
      );

      if (!orf) {
        return;
      }

      if (!exons) {
        return;
      }

      let utr_5 = [];
      let utr_3 = [];
      let introns = [];

      // Calculate 5' and 3' UTRs
      exons.forEach((exon) => {
        if (exon.endPosition < orf.startPosition) {
          // Complete exon before ORF start is 5' UTR
          utr_5.push({
            assignmentFeatureType: "5' UTR",
            startPosition: exon.startPosition,
            endPosition: exon.endPosition,
          });
        } else if (
          exon.startPosition < orf.startPosition &&
          orf.startPosition <= exon.endPosition
        ) {
          // Exon overlaps ORF start
          utr_5.push({
            assignmentFeatureType: "5' UTR",
            startPosition: exon.startPosition,
            endPosition: orf.startPosition - 1,
          });
        }

        if (exon.startPosition > orf.endPosition) {
          // Complete exon after ORF end is 3' UTR
          utr_3.push({
            assignmentFeatureType: "3' UTR",
            startPosition: exon.startPosition,
            endPosition: exon.endPosition,
          });
        } else if (
          exon.startPosition <= orf.endPosition &&
          orf.endPosition < exon.endPosition
        ) {
          // Exon overlaps ORF end
          utr_3.push({
            assignmentFeatureType: "3' UTR",
            startPosition: orf.endPosition + 1,
            endPosition: exon.endPosition,
          });
        }
      });

      // Calculate Introns
      const sortedExons = exons.sort(
        (a, b) => a.startPosition - b.startPosition
      );
      for (let i = 0; i < sortedExons.length - 1; i++) {
        if (sortedExons[i].endPosition < sortedExons[i + 1].startPosition - 1) {
          introns.push({
            assignmentFeatureType: "intron",
            startPosition: sortedExons[i].endPosition + 1,
            endPosition: sortedExons[i + 1].startPosition - 1,
          });
        }
      }

      // Append calculated features to the geneFeatures array
      geneFeatures.push(...introns, ...utr_3, ...utr_5);
    },
    scrollToElement(colIdx) {
      const entryRef = this.$refs["entry" + colIdx];
      const el = entryRef[0].$el;
      if (el) {
        var top = el.offsetTop - 210;
        el.parentElement.scrollTo(0, top);
      }
    },
    handleScroll(e) {
      let scrollTop = e.target.scrollTop + 215;
      let currTopEntry = 0;
      this.entryPosys.forEach((e, i) => {
        if (scrollTop > e) {
          currTopEntry = i;
        }
      });
      this.colIdxSelected = currTopEntry;
    },
    sideColClicked(colIdx) {
      this.scrollToElement(colIdx);
      this.colIdxSelected = colIdx;
    },
    async getGeneDetail(id) {
      return PostsService.getGeneDetail({ key: id });
    },
    processEntries() {
      this.side_cols.forEach((title) => {
        let entry = {
          title: title,
          name: title.replace(/\s/g, ""),
        };
        switch (title) {
          case "Summary":
            entry.type = "bands";
            entry.bands = this.fillSummary();
            break;
          case "Maps and Mapping Data":
            entry.type = "bands";
            entry.bands = this.fillMappingData();
            break;
          case "Sequences":
            entry.type = "bands";
            entry.bands = this.fillSequences();
            break;
          case "Protein Data":
            entry.type = "bands";
            entry.bands = this.fillProteinData();
            break;
          case "Expression":
            entry.type = "bands";
            entry.bands = this.fillExpression();
            break;
          case "Polymorphisms":
            entry.type = "bands";
            entry.bands = this.fillPolymorphisms();
            break;
          case "Germplasms":
            entry.type = "bands";
            entry.bands = this.fillGermplasms();
            break;
          case "Gene Ontology":
            entry.type = "bands";
            entry.bands = this.fillGeneOntology();
            break;
          case "Publications":
            entry.type = "bands";
            entry.bands = this.fillPublications();
            break;
          case "External Links":
            entry.type = "bands";
            entry.bands = this.fillExternalLinks();
            break;
          default:
            entry.type = "bands";
            entry.bands = [];
            break;
        }
        this.entries.push(entry);
      });
    },
    fillSummary() {
      let bands = [];
      bands.push({
        key: "Name",
        text: this.json_data.name,
        helpTxt: this.helpTexts.name,
      });
      bands.push({
        key: "Name Type",
        text: this.json_data.nameType,
        helpTxt: this.helpTexts.nameType,
      });
      bands.push({
        key: "Gene Model Type",
        text: this.json_data.geneModelType,
        helpTxt: this.helpTexts.geneModelType,
      });
      bands.push(this.getSymbols());
      bands.push({
        key: "Description",
        text: this.json_data.description,
      });
      bands.push({
        key: "Locus",
        type: "link",
        text: this.json_data.locusName
          ? this.json_data.locusName
          : "No Locus Available",
        link: this.json_data.locusId
          ? `/locus?key=${this.json_data.locusId}`
          : null,
        helpTxt: this.helpTexts.locus,
      });
      bands.push({
        key: "Date last modified",
        text: this.getDateModified(),
        helpTxt: this.helpTexts.dateLastModified,
      });
      bands.push({
        key: "TAIR Accession",
        text: this.json_data.tairAccession,
        helpTxt: this.helpTexts.tairAccession,
      });
      return bands;
    },
    fillMappingData() {
      let bands = [];
      bands.push(this.getMapDetails());
      bands.push(this.getMapLocations());
      bands.push(this.getGeneFeatures());
      return bands;
    },
    fillSequences() {
      let bands = [];
      bands.push(this.getSequences());
      return bands;
    },
    fillGeneOntology() {
      let bands = [];
      bands.push(this.getGO());
      return bands;
    },
    fillProteinData() {
      let bands = [];
      bands.push(this.getProteinData());
      return bands;
    },
    fillExpression() {
      let bands = [];
      bands.push(this.getPlantOntologyAnnotations());
      return bands;
    },
    fillGermplasms() {
      let bands = [];
      bands.push(this.getGermplasms());

      return bands;
    },
    fillPolymorphisms() {
      let bands = [];
      bands.push(this.getPolymorphisms());

      return bands;
    },
    fillPublications() {
      let bands = [];
      bands.push(this.getPublications());
      return bands;
    },
    fillExternalLinks() {
      let bands = [];
      bands.push(this.getExternalLinks());
      return bands;
    },
    getDateModified() {
      let date = new Date(this.json_data.dateLastModified);
      return this.dateToYMD(date);
    },
    dateToYMD(date) {
      var d = date.getDate();
      var m = date.getMonth() + 1; //Month from 0 to 11
      var y = date.getFullYear();
      return (
        "" + y + "-" + (m <= 9 ? "0" + m : m) + "-" + (d <= 9 ? "0" + d : d)
      );
    },
    getSymbols() {
      let entry = {
        key: "Symbols",
        type: "table",
        items: [],
        fields: [],
      };
      if (this.json_data.symbols) {
        entry.fields.push({
          key: "symbol",
          label: "Symbol",
          cellType: "name_link",
        });
        entry.fields.push({
          key: "fullname",
          label: "Full Name",
          cellType: "name_link",
        });
        this.json_data.symbols.forEach((s) => {
          entry.items.push({
            symbol: { name: s.symbol },
            fullname: { name: s.fullName },
          });
        });
      } else {
        entry.type = "";
        entry.text = "No Symbols available";
        return entry;
      }

      entry.count = entry.items.length;
      return entry;
    },
    getMapDetails() {
      // add jbrowse url
      if (!this.json_data.mapLocation) {
        return {
          key: "Map Detail Image",
          text: "No Map Detail Image available",
        };
      }
      let jbrowseChromosome = this.json_data.mapLocation.chromosome;
      let spanStartPosition = this.json_data.mapLocation.startPosition;
      let spanEndPosition = this.json_data.mapLocation.endPosition;
      let geneModelType = this.json_data.geneModelType;
      let jbrowseUrl = getSlimViewUrl(
        jbrowseChromosome,
        spanStartPosition,
        spanEndPosition,
        geneModelType
      );
      let fullScreenUrl = getFullViewUrl(
        jbrowseChromosome,
        spanStartPosition,
        spanEndPosition,
        geneModelType
      );
      let entry = {
        key: "Map Detail Image",
        // text: "value",
        type: "iframe",
        name: this.json_data.name,
        jbrowseUrl: jbrowseUrl,
        fullScreenUrl: fullScreenUrl,
      };
      return entry;
    },
    getMapLocations() {
      let entry = {
        key: "Map Locations (Global Assignments)",
        type: "table",
        items: [],
        fields: [],
        helpTxt: this.helpTexts.mapLocation,
      };
      entry.fields.push({
        key: "chrom",
        label: "Chrom",
        cellType: "name_link",
      });
      entry.fields.push({
        key: "map",
        label: "Map",
        cellType: "name_link",
      });
      entry.fields.push({
        key: "map_type",
        label: "Map Type",
        cellType: "name_link",
      });
      entry.fields.push({
        key: "coordinates",
        label: "Coordinates",
        cellType: "name_link",
      });
      entry.fields.push({
        key: "orientation",
        label: "Orientation",
        cellType: "name_link",
      });
      entry.fields.push({
        key: "attrib",
        label: "Attrib",
        cellType: "name_link",
      });
      //Data
      if (this.json_data.mapLocation) {
        let m = this.json_data.mapLocation;
        let item = {
          chrom: { name: m.chromosome },
          map: {
            name: m.mapName,
            link: `${process.env.VUE_APP_OLD_TAIR_URL}/servlets/TairObject?type=map&id=${m.mapId}`,
          },
          map_type: { name: m.mapType },
          coordinates: {
            name: `${m.startPosition} - ${m.endPosition} ${m.units}`,
          },
          // orientation: { name: m.orientation },
          attrib: {
            name: "details",
            link: `${process.env.VUE_APP_OLD_TAIR_URL}/servlets/TairObject?id=${m.globalAssignmentId}&type=global_assignment`,
          },
        };
        entry.items.push(item);
      } else {
        entry.type = "";
        entry.text = "No Map Locations available";
        return entry;
      }
      //Count
      entry.count = entry.items.length;
      return entry;
    },
    getSequences() {
      let entry = {
        key: "Sequences",
        type: "table",
        items: [],
        fields: [],
        helpTxt: this.helpTexts.sequence,
      };
      //Fields
      entry.fields.push({
        key: "bio_src",
        label: "Bio Source",
        cellType: "name_link",
      });
      entry.fields.push({
        key: "src",
        label: "Source",
        cellType: "name_link",
      });
      entry.fields.push({
        key: "date",
        label: "Date",
        cellType: "name_link",
      });
      entry.fields.push({
        key: "genbank_acc",
        label: "GenBank Accession",
        cellType: "name_link",
      });
      //Data
      if (
        this.json_data.sequence &&
        this.json_data.sequence.nucleotideSequences &&
        this.json_data.sequence.nucleotideSequences.length > 0
      ) {
        let ncs = this.json_data.sequence.nucleotideSequences;
        ncs.forEach((nc) => {
          let item = {
            bio_src: { name: nc.nucleotideSequenceType },
            src: { name: "Unknown Source" },
            date: { name: "2017-07-27 10:09:59.0" },
            genbank_acc: {
              name: nc.icAccession,
              link: `https://www.ncbi.nlm.nih.gov/nucleotide/${nc.icAccession}`,
            },
          };
          entry.items.push(item);
        });
      } else {
        entry.type = "";
        entry.text = "No Sequences available";
        return entry;
      }
      //Count
      entry.count = entry.items.length;
      return entry;
    },
    getGeneFeatures() {
      let entry = {
        key: "Gene Features",
        type: "table",
        items: [],
        fields: [],
        helpTxt: this.helpTexts.geneFeatures,
      };
      //Fields
      entry.fields.push({
        key: "type",
        label: "Type",
        cellType: "name_link",
      });
      entry.fields.push({
        key: "coordinates",
        label: "Coordinates",
        cellType: "name_link",
      });
      entry.fields.push({
        key: "annot_src",
        label: "Annotation Source",
        cellType: "name_link",
      });
      entry.fields.push({
        key: "date",
        label: "Date",
        cellType: "name_link",
      });
      //Data
      if (
        this.json_data.geneFeatures &&
        this.json_data.geneFeatures.length > 0
      ) {
        let allNull = true; // Flag to determine if all entries are null
        this.json_data.geneFeatures.forEach((gf) => {
          if (gf.assignmentFeatureType || gf.startPosition || gf.endPosition) {
            allNull = false; // Set flag to false if any value is not null
            entry.items.push({
              type: { name: gf.assignmentFeatureType || "Unknown" },
              coordinates: {
                name: `${gf.startPosition || "Unknown"}-${
                  gf.endPosition || "Unknown"
                }`,
              },
            });
          }
        });

        if (allNull) {
          entry.type = "";
          entry.text = "No Gene Features available";
          return entry;
        }
      } else {
        entry.type = "";
        entry.text = "No Gene Features available";
        return entry;
      }
      //Count
      entry.count = entry.items.length;
      return entry;
    },
    //Protein Data
    getProteinData() {
      let entry = {
        key: "Protein Data",
        type: "table",
        items: [],
        fields: [],
      };
      //Fields
      entry.fields.push({
        key: "name",
        label: "Name",
        cellType: "name_link",
      });
      entry.fields.push({
        key: "length",
        label: "Length (aa)",
        cellType: "name_link",
      });
      entry.fields.push({
        key: "molecule_wt",
        label: "Molecular Weight",
        cellType: "name_link",
      });
      entry.fields.push({
        key: "isoelectric_point",
        label: "Isoelectric Point",
        cellType: "name_link",
      });
      entry.fields.push({
        key: "link_list",
        label: "INTERPRO",
        cellType: "link_list",
      });
      //Data
      if (this.json_data.proteinData && this.json_data.proteinData.length > 0) {
        this.json_data.proteinData.forEach((pd) => {
          let item = {
            name: {
              name: pd.name,
              link: `/protein?key=${pd.tairObjectId}`,
            },
            length: { name: pd.length },
            molecule_wt: { name: pd.calcMw },
            isoelectric_point: { name: pd.calcPi },
          };

          let link_list = [];
          pd.domains.forEach((ip) => {
            if (ip.interproData.accession) {
              link_list.push({
                name: `${ip.interproData.name}:${ip.interproData.accession}`,
                link: `https://www.ebi.ac.uk/interpro/entry/${ip.interproData.accession}`,
              });
            }
          });
          item.link_list = { link_list: link_list };
          entry.items.push(item);
        });
      } else {
        entry.type = "";
        entry.text = "No Protein Data available";
      }
      //Count
      entry.count = entry.items.length;
      return entry;
    },
    getPlantOntologyAnnotations() {
      let entry = {
        key: "Plant Ontology Annotations",
        type: "table",
        items: [],
        fields: [],
        buttons: [],
      };
      entry.fields.push({
        key: "category",
        label: "Category",
        cellType: "name_link",
      });
      entry.fields.push({
        key: "relationship_type",
        label: "Relationship Type",
        cellType: "name_link",
      });
      entry.fields.push({
        key: "link_list",
        label: "Keywords",
        cellType: "link_list",
      });
      if (this.json_data.plantOntologyAnnotation) {
        let annots = this.json_data.plantOntologyAnnotation.data;
        annots = annots.filter((a) => {
          return (
            a.category == "Growth and Developmental Stages" ||
            a.category == "Plant Structure"
          );
        });
        if (annots.length == 0) {
          entry.type = "";
          entry.text = "No Plant Ontology Annotations available";
          return entry;
        }
        annots.forEach((s) => {
          let item = {
            category: { name: s.category },
            relationship_type: { name: s.relationshipType },
            keywords: [],
          };
          let link_list = [];
          s.keywords.forEach((k) => {
            link_list.push({
              name: k.keyword,
              link: `/keyword?key=${k.keywordId}`,
            });
          });
          item.link_list = { link_list: link_list };
          entry.items.push(item);
        });
      } else {
        entry.type = "";
        entry.text = "No Plant Ontology Annotations available";
        return entry;
      }
      entry.count = entry.items.length;
      let searchParams = {
        keyword_types: ["grow", "stru"],
        searchTerms: [
          {
            search_type: "gene_name",
            search_method: "exactly",
            search_input: name,
          },
        ],
      };
      let key = "searchParams_po";
      localStorage.setItem(key, JSON.stringify(searchParams));
      let link = `/results?mainType=detail&category=annotations&key=${key}`;
      entry.buttons.push({
        name: "View Annotation Details",
        url: link,
      });
      return entry;
    },
    getGO() {
      let entry = {
        key: "Gene Ontology Annotations",
        type: "table",
        items: [],
        fields: [],
        buttons: [],
      };
      entry.fields.push({
        key: "category",
        label: "Category",
        cellType: "name_link",
      });
      entry.fields.push({
        key: "relationship_type",
        label: "Relationship Type",
        cellType: "name_link",
      });
      entry.fields.push({
        key: "link_list",
        label: "Keywords",
        cellType: "link_list",
      });
      if (this.json_data.plantOntologyAnnotation) {
        let annots = this.json_data.plantOntologyAnnotation.data;
        annots = annots.filter((a) => {
          return (
            a.category == "GO Biological Process" ||
            a.category == "GO Molecular Function" ||
            a.category == "GO Cellular Component"
          );
        });
        if (annots.length == 0) {
          entry.type = "";
          entry.text = "No Gene Ontology Annotations available";
          return entry;
        }
        annots.forEach((s) => {
          let item = {
            category: { name: s.category },
            relationship_type: { name: s.relationshipType },
            keywords: [],
          };
          let link_list = [];
          s.keywords.forEach((k) => {
            link_list.push({
              name: k.keyword,
              link: `/keyword?key=${k.keywordId}`,
            });
          });
          item.link_list = { link_list: link_list };
          entry.items.push(item);
        });
      } else {
        entry.type = "";
        entry.text = "No Gene Ontology Annotations available";
        return entry;
      }

      entry.count = entry.items.length;
      let name = this.json_data.name.replace(/\.\d+$/, '');
      let searchParams = {
        keyword_types: ["comp", "func", "proc"],
        searchTerms: [
          {
            search_type: "gene_name",
            search_method: "exactly",
            search_input: name,
          },
        ],
      };
      let key = "searchParams_go";
      localStorage.setItem(key, JSON.stringify(searchParams));
      let link = `/results?mainType=detail&category=annotations&key=${key}`;

      entry.buttons.push({
        name: "View Annotation Details",
        url: link
      });
      return entry;
    },
    getGermplasms() {
      let entry = {
        key: "Germplasms",
        type: "multiline",
        items: [],
        fields: [],
        buttons: [],
        helpTxt: this.helpTexts.germplasm,
      };
      let germplasms = this.json_data.germplasm;
      if (!germplasms || germplasms.length == 0) {
        return {
          key: "Germplasms",
          text: "No Germplasms found",
        };
      }
      if (germplasms && germplasms.length > 0) {
        entry.fields.push({
          key: "name_link",
          label: "Name",
          cellType: "name_link",
        });
        entry.fields.push({
          key: "poly",
          label: "Polymorphisms",
          cellType: "link_list",
        });
        entry.fields.push({
          key: "name_link2",
          label: "Stock Name",
          cellType: "name_link",
        });
        entry.fields.push({
          key: "img_list",
          label: "Images",
          cellType: "image_list",
          width: "100%",
        });
        entry.fields.push({
          key: "phenotypes",
          label: "Phenotypes",
          helpTxt: "Phenotypes Help",
          cellType: "bullet_list",
          width: "100%",
        });
        germplasms.forEach((g) => {
          let item = {};
          if (g.names && g.names.length > 0) {
            let mainLink = `/germplasm?key=${g.names[0].tairObjectId}`;
            item.name_link = { name: g.names[0].name, link: mainLink };
          }
          let polymorphisms = [];
          if (g.polymorphisms) {
            g.polymorphisms.forEach((p) => {
              polymorphisms.push({
                name: p.polyOriginalName,
                link: `/polyallele?key=${p.polymorphismId}`,
              });
            });
          }
          item.poly = polymorphisms;
          if (g.stockNames && g.stockNames.length > 0) {
            let url =
              "https://abrc.osu.edu/stocks/number/" + g.stockNames[0].stockId;
            let name = g.stockNames[0].name ? g.stockNames[0].name : "";
            item.name_link2 = { name: name, link: url };
          }
          let phenotypes = [];
          if (g.phenotypes) {
            g.phenotypes.forEach((p) => {
              let bulletObj = {};
              bulletObj.title = "";
              bulletObj.points = [];

              if (p.phenotype) {
                let author = "Phenotype curated by ABRC.";
                if (p.label) {
                  author = `<a href="/publication?key=${p.referenceId}">${p.label} et al.</a>`;
                }
                let point_html = "<span>" + p.phenotype + "</span>";
                point_html +=
                  "<span class='text-muted'> (" + author + ")</span>";
                bulletObj.points.push(point_html);
                phenotypes.push(bulletObj);
              }
            });
          }
          item.phenotypes = phenotypes;
          if (g.images) {
            let images = [];
            g.images.forEach((gi) => {
              images.push({
                img_url: `${process.env.VUE_APP_OLD_TAIR_URL}/servlets/images/thumb_${gi.imageId}.jpg`,
                full_url: `${process.env.VUE_APP_OLD_TAIR_URL}/servlets/images/${gi.imageId}.jpg`,
              });
            });
            item.img_list = images;
          } else {
            item.img_list = [];
          }
          entry.items.push(item);
        });
        entry.count = germplasms.length;
      }
      return entry;
    },
    //Polymorphisms
    getPolymorphTypes() {
      let polymorphismTypes = [];
      polymorphismTypes.push({
        label: "compound",
        desc: "Refers to polymorphisms between two lines where an insertion and a substitution or a deletion and a substitution occur at the same genomic location.",
      });
      polymorphismTypes.push({
        label: "deletion",
        desc: "A variation where a segment of DNA is absent in one variant with respect to a reference polymorphism. In TAIR the reference polymorphism corresponds to the wild type Columbia ecotype but other reference ecotypes may be used if the Columbia variant is not known.",
      });
      polymorphismTypes.push({
        label: "digest_pattern",
        desc: "The characteristic pattern of products of a restriction enzyme digestion. Also refers to a type of polymorphism where the pattern of restriction enzyme fragments in one variant differs from that of another variant.",
      });
      polymorphismTypes.push({
        label: "INDEL",
        desc: "Insertion/deletions (INDELS) are sequence variants where the Columbia (reference) polymorphism is an insertion relative to one ecotype and a deletion relative to another, different ecotype.",
      });
      polymorphismTypes.push({
        label: "insertion",
        desc: "A variant in which a segment of DNA is present in one genotype and absent in another. In TAIR, insertions are defined with respect to a reference polymorphism. The reference polymorphism typically corresponds to the wild type Columbia variant but other reference ecotypes may be used if the Columbia variant is not known.",
      });
      polymorphismTypes.push({
        label: "PCR_product_length",
        desc: "Refers to polymorphisms that are represented by different PCR product lengths. The polymorphisms are linked to the genetic markers (SSLP, AFLP) that can be used to detect them",
      });
      polymorphismTypes.push({
        label: "substitution",
        desc: "Replacement of one nucleotide sequence by another nucleotide or one amino acid in a protein by another amino acid. Also known as a single nucleotide polymorphism (SNP).",
      });
      polymorphismTypes.push({
        label: "visible",
        desc: "Allelic variations in a visible or biochemical phenotype at one or more loci that can be detected by quantitative or qualitative methods.",
      });
      return polymorphismTypes;
    },
    getAlleleTypes() {
      let alleleTypes = [];
      alleleTypes.push({
        label: "antimorphic",
        desc: "A type of mutation in which the mutated gene product has an altered function that acts antagonistically to the wild type allele. Antimorphic mutants are usually dominant or semi-dominant.",
      });
      alleleTypes.push({
        label: "gain of function",
        desc: "A type of mutation in which the altered gene product possesses a new function or a new pattern of gene expression. Gain of function mutants are usually dominant or semidominant.",
      });
      alleleTypes.push({
        label: "haplo-insufficient",
        desc: "A description applied to a gene that produces a mutant phenotype when present in a diploid individual heterozygous for an amorphic allele.",
      });
      alleleTypes.push({
        label: "hypermorphic",
        desc: "A type of mutation in which the altered gene product possesses an increased level of activity, or in which the wild-type gene product is expressed at a increased level.",
      });
      alleleTypes.push({
        label: "hypomorphic",
        desc: "A type of mutation in which the altered gene product possesses an increased level of activity, or in which the wild-type gene product is expressed at a increased level.",
      });
      alleleTypes.push({
        label: "loss of function",
        desc: "A type of mutation in which the altered gene product lacks the function of the wild-type gene. Also called amorphic or null mutation.",
      });
      alleleTypes.push({
        label: "unknown",
        desc: "Used when the type of the allele is not known or the information is not yet available.",
      });
      return alleleTypes;
    },
    getPolymorphisms() {
      let entry = {
        key: "Polymorphism",
        type: "table",
        items: [],
        fields: [],
        helpTxt: this.helpTexts.polymorphism,
      };
      let polymorphismInfos = this.json_data.polymorphismInfos;
      if (polymorphismInfos && polymorphismInfos.length > 0) {
        let nameHelpTxt = `<div class="d-flex flex-column p-2" style="height: 200px; overflow-y: auto;">
          <p class="w-100 text-left"><b>name:</b>The name of a polymorphism such as an allele name, or an alphanumeric identifying code (e.g. for SALK T-DNA insertions or TILLing lines) or the name of the marker used to detect the polymorphism.</p>`;
        entry.fields.push({
          key: "name",
          label: "Name",
          cellType: "name_link",
          helpTxt: nameHelpTxt,
        });
        let polymorphismTypes = this.getPolymorphTypes();
        let polyHelpTxt = `<div class="d-flex flex-column p-2" style="height: 200px; overflow-y: auto;">
          <p class="w-100 text-left"><b>type:</b>Types of polymorphisms in TAIR.</p>`;
        polyHelpTxt += `<ul class="w-100 text-left">`;
        polymorphismTypes.forEach((p) => {
          polyHelpTxt += `<li><b>${p.label}</b>: ${p.desc}</li>`;
        });
        polyHelpTxt += `</ul></div>`;
        entry.fields.push({
          key: "poly_type",
          label: "Type",
          cellType: "name_link",
          helpTxt: polyHelpTxt,
        });
        entry.fields.push({
          key: "polymorphismSite",
          label: "Polymorphism Site",
          cellType: "name_link",
        });
        let alleleHelpTxt = `<div class="d-flex flex-column p-2" style="height: 200px; overflow-y: auto;">
          <p class="w-100 text-left"><b>allele type:</b> A classification of alleles based upon the phenotype and genotype of alleles. </p>`;
        alleleHelpTxt += `<ul class="w-100 text-left">`;
        let alleleTypes = this.getAlleleTypes();
        alleleTypes.forEach((p) => {
          alleleHelpTxt += `<li><b>${p.label}</b>: ${p.desc}</li>`;
        });
        alleleHelpTxt += `</ul></div>`;
        entry.fields.push({
          key: "allele_type",
          label: "Allele Type",
          cellType: "name_link",
          helpTxt: alleleHelpTxt,
        });
        polymorphismInfos.forEach((c) => {
          let item = {};
          item.name = {
            name: c.originalName,
            link: `/polyallele?key=${c.polymorphismId}`,
          };
          item.poly_type = { name: c.polymorphismType };
          item.polymorphismSite = { name: c.geneFeatureSite };
          item.allele_type = { name: c.alleleMode ? c.alleleMode : "unknown" };
          entry.items.push(item);
        });
        entry.count = entry.items.length;
      }
      if (entry.items.length == 0) {
        entry.type = "";
        entry.text = "No polymorphisms available";
      }
      return entry;
    },
    //Publications
    getPublications() {
      let entry = {
        key: "Publications",
        type: "table",
        items: [],
        fields: [],
      };
      let pubs = this.json_data.publications;
      if (pubs && pubs.length > 0) {
        entry.fields.push({
          key: "publication",
          label: "Author(s)/Title",
          cellType: "name_link",
        });
        entry.fields.push({
          key: "source",
          label: "Source",
          cellType: "name_link",
        });
        entry.fields.push({
          key: "date",
          label: "Publication Year",
          cellType: "text",
        });
        entry.count = pubs.length;
        pubs.forEach((p) => {
          let item = {};
          let pub_link = `/publication?key=${p.publicationId}`;
          let link_html = `${p.authors} / ${p.title}`;
          item.publication = {
            name: link_html,
            link: pub_link,
          };
          item.source = { name: p.pubSource.name };
          item.date = p.publicationYear;
          entry.items.push(item);
        });
      } else {
        entry.type = ""; // Ensuring this is set to 'text' to handle no data scenarios.
        entry.text = "No publications available";
      }
      return entry;
    },
    //External Links
    getExternalLinks() {
      let entry = {
        key: "External Link",
        type: "links",
        items: [],
      };
      let links = this.json_data.externalLinks;
      if (links == null || links.length == 0) {
        entry.type = "";
        entry.text = "No external links available";
        return entry;
      }
      let count = 0;
      links.forEach((l) => {
        count += 1;
        let url = l.baseUrl + l.urlVariable;
        let link = {
          name: l.webSiteName,
          link: url,
        };
        entry.items.push({ title: l.webSiteName, links: [link] });
      });
      entry.count = count;
      if (links.length == 0) {
        entry.type = "";
        entry.text = "No external links available";
      }
      return entry;
    },
  },
};
</script>

<style scoped lang="scss">
.fixed-title {
  position: sticky;
  top: 0;
  background-color: #f1efec;
  z-index: 10;
  padding-left: 10px;
  padding-top: 10px;
}

.my-custom-scrollbar {
  position: relative;
  height: 200px;
  overflow: auto;
}
.table-wrapper-scroll-y {
  display: block;
}
</style>
