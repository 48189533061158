const CSVDownloadManager = {
  items: [],
  query: {},

  downloadCsv(category, type) {
    const downloadFunctions = {
      annotations: this.downloadAnnotationCSVData,
      descriptions: this.downloadDescriptionCSVData,
      locus_history: this.downloadLocusHistoryCSVData,
      genes: this.downloadGeneCSVData,
      germplasm: this.downloadGermplasmCSVData,
      keyword: this.downloadKeywordCSVData,
      protein: this.downloadProteinCSVData,
      polyallele: this.downloadPolymorphismCSVData,
      publication: this.downloadPublicationCSVData,
      dna: this.downloadDnaCSVData,
      markers: this.downloadMarkerCsvData,
      protocol: this.downloadProtocolCsvData,
      community: this.downloadCommunityCsvData,
      transposon: this.downloadTransposonCsvData,
      ecotype: this.downloadEcotypeCsvData,
      microarray: this.downloadMicroarrayCSVData,
    };

    const downloadFunction = downloadFunctions[category];
    if (downloadFunction) {
      downloadFunction.call(this, type);
    } else {
      console.error(`Unknown category: ${category}`);
    }
  },
  downloadGeneCSVData(type) {
    let csv =
      "TAIR Accession\tLocus\tGene Type\tDescription\tOther Name(Type)\tKeywords\n";
    let selectedItems = this.getSelectedItems(type);

    selectedItems.forEach((row) => {
      let doc = row.originalDoc;
      csv += "Locus:" + (doc.locus_tairObjectId || "N/A") + "\t";
      csv += (doc.gene_name ? doc.gene_name[0] : "N/A") + "\t";
      csv += (doc.gene_model_type ? doc.gene_model_type[0] : "N/A") + "\t";
      csv +=
        (doc.description ? doc.description[0].replace(",", ";") : "N/A") + "\t";
      csv += (doc.other_names ? doc.other_names.join(";") : "N/A") + "\t";
      csv += (doc.keywords ? doc.keywords.join(";") : "N/A") + ",\n";
    });

    this.downloadCsvFile(csv, "gene_results.tsv");
  },
  downloadKeywordCSVData(type) {
    let csv =
      "Keyword\tcategory\tannotation_count\tpublication_count\tloci_count\n";
    let selected_items = [];
    if (type == "all") {
      selected_items = this.items;
    } else {
      selected_items = this.items.filter((s) => s.selected);
    }
    selected_items.forEach((row) => {
      let doc = row.originalDoc;
      csv += (doc.kwName ? doc.kwName[0] : "N/A") + "\t";
      csv += (doc.kwCategory ? doc.kwCategory[0] : "N/A") + "\t";
      csv += doc.annotCount + "\t";
      csv += doc.pubCount + "\t";
      csv += doc.lociCount + "\n";
    });
    this.downloadCsvFile(csv, "keyword_results.tsv");
  },
  downloadGermplasmCSVData(type) {
    let csv =
      "Name\tPolymorphism\tLocus\tBackground\tDonors\tStock Number\tStock Type\tPhenotypes\n";
    let selected_items = [];
    if (type == "all") {
      selected_items = this.items;
    } else {
      selected_items = this.items.filter((s) => s.selected);
    }
    selected_items.forEach((row) => {
      csv += row.originalDoc.name[0].trim() + "\t";
      let polyList = row.originalDoc.polyList;
      if (polyList && polyList.length > 0) {
        polyList = polyList.map((a) => a.split(";")[0]);
        polyList = polyList.join(";");
        csv += polyList.trim() + "\t";
      } else {
        csv += ",";
      }

      let locusList = row.originalDoc.locusList;
      if (locusList && locusList.length > 0) {
        locusList = locusList.map((l) => l.split(";")[0]).join(";");
        csv += locusList + "\t";
      } else {
        csv += ",";
      }

      let backgrounds = row.originalDoc.bgEcotypes;
      if (backgrounds && backgrounds.length > 0) {
        let uniqueEcotypes = [...new Set(backgrounds)];
        uniqueEcotypes = uniqueEcotypes.map((e) => e.split(";")[0]);
        csv += uniqueEcotypes.join(";") + "\t";
      } else {
        csv += ",";
      }

      let donors = row.originalDoc.donors;
      if (donors && donors.length > 0) {
        donors = donors.map((d) => d.split(";")[0]).join(";");
        csv += donors.trim() + "\t";
      } else {
        csv += ",";
      }

      csv += row.originalDoc.stockNo + "\t";
      csv += row.originalDoc.germplasmType + "\t";
      csv += row.originalDoc.phenotypes
        ? row.originalDoc.phenotypes.join(";").replace(/,/g, ";")
        : "";
      csv += "\n";
    });
    this.downloadCsvFile(csv, "germplasm_results.tsv");
  },
  downloadProteinCSVData(type) {
    let csv =
      "TAIR Accession\tName\tSource\tLast Updated\tCalc MW\tCalc pI\tLength\tGene\tDescription\tLocus\n";

    let selected_items = [];
    if (type == "all") {
      selected_items = this.items;
    } else {
      selected_items = this.items.filter((s) => s.selected);
    }
    selected_items.forEach((row) => {
      csv += row.id + "\t";
      csv += row.name + "\t";
      csv += "unknown" + "\t";
      csv += "unknown" + "\t";
      csv += row.calcmw + "\t";
      csv += row.calcPi + "\t";
      csv += row.length + "\t";
      csv += row.symbol + "\t";
      csv += row.description + "\t";
      csv += row.protein_models
        ? row.protein_models.locusName
        : "unknown" + "\t";
      csv += "\n";
    });
    this.downloadCsvFile(csv, "protein_results.tsv");
  },
  downloadPolymorphismCSVData(type) {
    let csv =
      "Name\tAliases\tType\tPolymorphism Site\tGenes\tChromosome\tDescription\n";

    let selected_items = [];
    if (type == "all") {
      selected_items = this.items;
    } else {
      selected_items = this.items.filter((s) => s.selected);
      if (selected_items.length == 0) {
        alert("Nothing selected");
        return;
      }
    }
    selected_items.forEach((row) => {
      let orig = row.originalRow;
      csv += row.name.name.trim() + "\t";
      let aliases = row.alias ? row.alias.name : "";
      csv += aliases + "\t";
      csv += row.type.name.trim() + "\t";
      csv += row.poly_site.name.trim() + "\t";
      let genes = row.genes.map((g) => g.name.trim());
      csv += genes.join(";") + "\t";
      csv += (row.chrom.name ? row.chrom.name : "unknown") + "\t";
      csv += orig.description
        ? orig.description.replace(/,/g, ";")
        : "unknown" + "\t";
      csv += "\n";
    });
    this.downloadCsvFile(csv, "polymorphism_results.tsv");
  },
  downloadPublicationCSVData(type) {
    let csv = "Authors\tTitle\tSource\tYear\tDOI\tPMID\tKeywords\n";

    let selected_items = [];
    if (type == "all") {
      selected_items = this.items;
    } else {
      selected_items = this.items.filter((s) => s.selected);
      if (selected_items.length == 0) {
        alert("Nothing selected");
        return;
      }
    }
    selected_items.forEach((row) => {
      csv += row.authors.name.trim().replace(/,/g, ";") + "\t";
      csv += row.title.name.trim().replace(/,|\n/g, "") + "\t";
      csv += row.source.name.replace(/,/g, "") + "\t";
      csv += row.year.name + "\t";
      csv += row.doi.name + "\t";
      csv += row.pmid.name + "\t";
      csv += row.keywords.name.replace(/,/g, ";") + "\t";
      csv += "\n";
    });

    this.downloadCsvFile(csv, "publication_results.tsv");
  },
  downloadDnaCSVData(type) {
    let csv =
      "Clone Name\tAliases\tLocus Name\tGenBank Accession\tVector Type\tChromsome\tPosition\tStock Number\tTAIR Accession\n";

    let selected_items = [];
    if (type == "all") {
      selected_items = this.items;
    } else {
      selected_items = this.items.filter((s) => s.selected);
      if (selected_items.length == 0) {
        alert("Nothing selected");
        return;
      }
    }
    selected_items.forEach((row) => {
      csv += row.name.name.trim().replace(/,/g, ";") + "\t";
      csv += (row.alias ? row.alias.name : "") + "\t";
      csv += (row.locus1 ? row.locus1.name : "") + "\t";
      csv += (row.genbank ? row.genbank.name : "") + "\t";
      csv += (row.vector_type ? row.vector_type.name : "") + "\t";
      csv += (row.chrom ? row.chrom.name : "") + "\t";
      csv += (row.position ? row.position.name : "" + " bp") + "\t";
      csv += (row.stock ? row.stock.name : "") + "\t";
      csv += (row.tair_object_id ? row.tair_object_id : "") + "\t";
      csv += "\n";
    });

    this.downloadCsvFile(csv, "dna_results.tsv");
  },
  downloadMarkerCsvData(type) {
    let csv = "Name\tAliases\tType\tChromosome\tStart Position\tEnd Position\n";

    let selected_items = [];
    if (type == "all") {
      selected_items = this.items;
    } else {
      selected_items = this.items.filter((s) => s.selected);
      if (selected_items.length == 0) {
        alert("Nothing selected");
        return;
      }
    }
    selected_items.forEach((row) => {
      let orig = row.originalRow;
      csv += orig.marker_name[0] + "\t";
      csv += orig.aliases ? orig.aliases.join(";") : "" + "\t";
      csv += (orig.type ? orig.type : "") + "\t";
      csv += (orig.chrom ? orig.chrom : "") + "\t";
      csv += (orig.start_position ? orig.start_position + " bp" : "") + "\t";
      csv += (orig.end_position ? orig.end_position + " bp" : "") + "\t";
      csv += "\n";
    });
    this.downloadCsvFile(csv, "marker_results.tsv");
  },
  downloadProtocolCsvData(type) {
    let csv =
      "Protocol Title\tAuthors\tDescription\tPubmed_id\tUsage\tMethods\tKeywords\tPDF_links\n";

    let selected_items = [];
    if (type == "all") {
      selected_items = this.items;
    } else {
      selected_items = this.items.filter((s) => s.selected);
      if (selected_items.length == 0) {
        alert("Nothing selected");
        return;
      }
    }
    selected_items.forEach((row) => {
      let originalRow = row.originalRow;
      csv += originalRow.title[0].replace(/,/g, ";") + "\t";
      csv += originalRow.authors
        ? `${originalRow.authors[0].replace(/,/g, ";")},`
        : ",";
      csv += originalRow.description
        ? `${originalRow.description[0].replace(/,/g, ";")},`
        : ",";
      csv +=
        (originalRow.pubmed_id && originalRow.pubmed_id != "0"
          ? originalRow.pubmed_id[0]
          : "") + "\t";
      csv +=
        (originalRow.usage ? originalRow.usage.replace(/,/g, ";") : "") + "\t";
      csv +=
        (originalRow.methods ? originalRow.methods.replace(/,/g, ";") : "") +
        ",";
      csv +=
        (originalRow.keywords ? originalRow.keywords.join(";") : "") + "\t";
      csv +=
        (originalRow.websites ? originalRow.websites.join(";") : "") + "\t";
      csv += "\n";
    });

    this.downloadCsvFile(csv, "protocol_results.tsv");
  },
  downloadCommunityCsvData(type) {
    let csv = "Name\tDescription\tType\tKeywords\n";

    let selected_items = [];
    if (type == "all") {
      selected_items = this.items;
    } else {
      selected_items = this.items.filter((s) => s.selected);
    }
    selected_items.forEach((row) => {
      csv += row.name.name.trim().replace(/,/g, ";") + "\t";
      csv += row.description ? row.description.name : "" + "\t";
      csv += row.type ? row.type.name : "" + "\t";
      csv += row.keywords ? row.keywords.name : "" + "\t";
      csv += "\n";
    });

    this.downloadCsvFile(csv, "community_results.tsv");
  },
  downloadTransposonCsvData(type) {
    let csv = "Name\tFamily Name\tSuper Family Name\tTAIR Accession\n";

    let selected_items = [];
    if (type == "all") {
      selected_items = this.items;
    } else {
      selected_items = this.items.filter((s) => s.selected);
    }
    selected_items.forEach((row) => {
      csv += row.name.name + "\t";
      csv += (row.familyName ? row.familyName.name : "") + "\t";
      csv += (row.superFamilyName ? row.superFamilyName.name : "") + "\t";
      csv += (row.tairObjectId ? `Transposon:${row.tairObjectId}` : "") + "\t";
      csv += "\n";
    });

    this.downloadCsvFile(csv, "transposon_results.tsv");
  },
  downloadEcotypeCsvData(type) {
    let csv = "Name\tType\tChromosome\tPosition\tStock\n";

    let selected_items = [];
    if (type == "all") {
      selected_items = this.items;
    } else {
      selected_items = this.items.filter((s) => s.selected);
    }
    selected_items.forEach((row) => {
      csv += row.name.name.trim().replace(/,/g, ";") + "\t";
      csv += row.type ? row.type.name : "" + "\t";
      csv += row.chrom ? row.chrom.name : "" + "\t";
      csv += row.position ? row.position.name : "" + " bp" + "\t";
      csv += row.stock ? row.stock.name : "" + "\t";
      csv += "\n";
    });

    this.downloadCsvFile(csv, "ecotype_results.tsv");
  },
  downloadAnnotationCSVData(type) {
    let csv =
      "Locus\tGene Model\tGene Symbol\tGene Full Name\tRelationship Type\tKeyword\tKeyword Category\tEvidence\t Evidence Description\tEvidence With\tPublication Title\tPublication Author\tPublication Year\tDate Last Modified\tAnnotated By\n";

    let selected_items = [];
    if (type == "all") {
      selected_items = this.items;
    } else {
      selected_items = this.items.filter((s) => s.selected);
    }
    selected_items.forEach((row) => {
      let orig = row.original;

      csv += (orig.locus_name ? orig.locus_name[0] : "") + "\t";
      csv += (orig.gene_model ? orig.gene_model[0] : "") + "\t";
      csv += (orig.symbol ? orig.symbol : "") + "\t";
      csv += (orig.symbolFull ? orig.symbolFull[0] : "") + "\t";
      csv +=
        (orig.relationshipType
          ? orig.relationshipType.replace(/,/g, ";")
          : "") + "\t";
      csv += (orig.keyword_exact ? orig.keyword_exact : "") + "\t";
      csv += (orig.keywordTypeFull ? orig.keywordTypeFull[0] : "") + "\t";
      csv += (orig.evidenceCodeFull ? orig.evidenceCodeFull : "") + "\t";
      csv +=
        (orig.description ? orig.description.replace(/,/g, ";") : "") + "\t";
      csv += (orig.evidenceWith ? orig.evidenceWith : "") + "\t";
      csv += (orig.title ? orig.title.replace(/,/g, ";") : "") + "\t";
      csv += (orig.authors ? orig.authors.replace(/,/g, ";") : "") + "\t";
      csv += (orig.publicationYear ? orig.publicationYear : "") + "\t";
      csv += (orig.dateLastModified ? orig.dateLastModified : "") + "\t";
      csv +=
        (orig.annotatedBy ? orig.annotatedBy.replace(/,/g, ";") : "") + "\t";
      csv += "\n";
    });
    this.downloadCsvFile(csv, "annotation_results.tsv");
  },
  downloadDescriptionCSVData(type) {
    let csv =
      "Locus Identifier\tGene Model Name\tGene Description\tGene Model Type\tPrimary Gene Symbol\tAll Gene Symbols\n";
    let selected_items = [];
    if (type == "all") {
      selected_items = this.items;
    } else {
      selected_items = this.items.filter((s) => s.selected);
    }
    selected_items.forEach((row) => {
      let orig = row.orig;

      csv += (orig.locusName ? orig.locusName : "") + "\t";
      csv += (orig.geneName ? orig.geneName : "") + "\t";
      csv +=
        (orig.geneModelType ? orig.geneModelType.replace(/,/g, ";") : "") +
        "\t";
      csv +=
        (orig.description ? orig.description.replace(/,/g, ";") : "") + "\t";
      csv += (orig.primarySymbol ? orig.primarySymbol : "") + "\t";
      csv += (orig.affiliateSymbol ? orig.affiliateSymbol : "") + "\t";
      csv += "\n";
    });
    this.downloadCsvFile(csv, "description_results.tsv");
  },
  downloadLocusHistoryCSVData(type) {
    let csv = "Locus\tCurrent Status\tWho\tDate\tComments\tModification Loci\n";
    let selected_items = [];
    if (type == "all") {
      selected_items = this.items;
    } else {
      selected_items = this.items.filter((s) => s.selected);
    }
    selected_items.forEach((row) => {
      let orig = row.orig;

      csv += (orig.locus ? orig.locus : "") + "\t";
      csv += (orig.currentStatus ? orig.currentStatus : "") + "\t";
      csv += (orig.who ? orig.who : "") + "\t";
      csv += (orig.date ? orig.date : "") + "\t";
      csv +=
        (orig.modificationComments ? orig.modificationComments : "") + "\t";
      csv +=
        (orig.lociInvolvedInModification
          ? orig.lociInvolvedInModification
          : "") + "\t";
      csv += "\n";
    });
    this.downloadCsvFile(csv, "locus_history_results.tsv");
  },
  downloadMicroarrayCSVData(type) {
    let csv =
      "Array Element\tLocus Identifier\tAnnotation\tOrganism\tProbe Type\tIs Control\n";
    let selected_items = [];
    if (type == "all") {
      selected_items = this.items;
    } else {
      selected_items = this.items.filter((s) => s.selected);
    }
    selected_items.forEach((row) => {
      let orig = row.orig;

      csv += (orig.probeName ? orig.probeName : "") + "\t";
      csv += (orig.locus ? orig.locus : "") + "\t";
      csv += (orig.annotation ? orig.annotation.replace(/,/g, ";") : "") + "\t";
      csv += (orig.organism ? orig.organism.replace(/,/g, ";") : "") + "\t";
      csv += (orig.probeType ? orig.probeType : "") + "\t";
      csv += (orig.isControl ? orig.isControl : "") + "\t";
      csv += "\n";
    });
    this.downloadCsvFile(csv, "microarray_results.tsv");
  },

  getSelectedItems(type) {
    return type === "all" ? this.items : this.items.filter((s) => s.selected);
  },

  downloadCsvFile(csvContent, filename) {
    const anchor = document.createElement("a");
    anchor.href =
      "data:text/tab-separated-values;charset=utf-8," +
      encodeURIComponent(csvContent);
    anchor.target = "_blank";
    //add date (YYYY_MM_DD) to filename using one line of code
    let date = new Date().toISOString().split("T")[0];
    filename = filename.replace(".tsv", "_" + date + ".tsv");
    anchor.download = filename;
    anchor.click();
  },
};

export default CSVDownloadManager;
