<template>
  <div class="searchWrapper">
    <div class="pageTop">
      <div class="d-none d-md-block">
        <div class="d-flex flex-column">
          <b-breadcrumb :items="crumb_links"></b-breadcrumb>
          <div class="text-left ml-2 d-flex">
            <h4 style="color: #930">TAIR Marker Search</h4>
            <span class="ml-2"
              >[<a :href="helpLink" target="_blank">Help</a>]</span
            >
          </div>
          <div class="text-left m-2">
            Markers may be searched by name, features, and/or location.
            Submitting the form with no search criteria will return ALL genetic
            markers in the database without restriction.
          </div>
        </div>
      </div>
      <div class="d-md-none">
        <div class="row">
          <div class="col-4 font-weight-bold pl-2">Search Result</div>
          <div class="col-8">
            <b-dropdown id="dropdown-1" text="Menu" class="m-md-2">
              <b-dropdown-item>New Search</b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>
    </div>
    <div class="pageMain m-2">
      <div class="d-flex flex-column">
        <div class="row mt-2">
          <div class="col-sm d-flex align-items-start ml-4">
            <b-button size="sm" class="mr-2">Reset</b-button>
            <b-button size="sm" @click="onSubmit">Submit Query</b-button>
          </div>
        </div>
        <div>
          <div class="d-flex flex-column mx-4 my-2">
            <form-section
              v-for="(filter, index) in search_form.filters"
              :key="index"
              :filter="filter"
              @on-enter="onSubmit"
            />
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-sm d-flex align-items-start ml-4 mb-4">
            <b-button size="sm" class="mr-2">Reset</b-button>
            <b-button size="sm" @click="onSubmit">Submit Query</b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import FormSection from "../../components/common/FormSection.vue";
export default {
  name: "MarkerSearch",
  components: {
    FormSection,
  },
  data() {
    return {
      crumb_links: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "Search",
          href: "/search/overview",
        },
        {
          text: "Markers",
          active: true,
        },
      ],
      search_form: {
        filters: [
          {
            label: "Search by Name",
            type: "rows",
            id: "search_by_name",
            rows: [
              {
                name: "Search for:",
                value: {
                  type: "mixed",
                  elements: [
                    {
                      type: "select",
                      options: [
                        { text: "Marker Name", value: "marker_name" },
                        { text: "Gene Name", value: "gene_name" },
                        {
                          text: "Locus Name",
                          value: "locus_name",
                        },
                      ],
                      value: "marker_name",
                      id: "search_type_by_0",
                      size: "medium",
                    },
                    {
                      type: "select",
                      options: [
                        { text: "contains", value: "contains" },
                        { text: "starts with", value: "starts_with" },
                        { text: "exactly", value: "exactly" },
                      ],
                      value: "starts_with",
                      id: "search_method_0",
                      size: "medium",
                    },
                    {
                      type: "input",
                      value: "",
                      id: "search_input_0",
                      size: "large",
                    },
                  ],
                },
              },
            ],
          },
          {
            label: "Restrict by Features",
            type: "rows",
            id: "restrict_by_features",
            rows: [
              {
                name: "Genetic Marker",
                value: {
                  elements: [
                    {
                      type: "multiSelect",
                      options: [
                        { text: "Any", value: "any" },
                        { text: "All PCR", value: "all_PCR" },
                        { text: "CAPS", value: "CAPS" },
                        { text: "SSLP", value: "SSLP" },
                        { text: "AFLP", value: "AFLP" },
                        { text: "RFLP", value: "RFLP" },
                        { text: "RAPD", value: "RAPD" },
                        { text: "Hybridization Based", value: "hyb_based" },
                      ],
                      selected: ["Any"],
                      value: ["any"],
                      width: "large",
                      id: "marker_type",
                    },
                  ],
                },
                id: "marker_features",
              },
              {
                name: "Polymorphic Between",
                value: {
                  elements: [
                    {
                      type: "select",
                      options: [{ text: "any", value: "any" }],
                      value: "any",
                      id: "poly_between_1",
                      size: "medium",
                    },
                    {
                      type: "text",
                      value: "and",
                    },
                    {
                      type: "select",
                      options: [{ text: "any", value: "any" }],
                      value: "any",
                      id: "poly_between_2",
                      size: "medium",
                    },
                  ],
                },
              },
            ],
          },
          {
            label: "Restrict by Map Location",
            type: "rows",
            rows: [
              {
                name: "Chromosome",
                value: {
                  elements: [
                    {
                      type: "select",
                      options: [
                        { text: "any", value: "any" },
                        { text: "1", value: "1" },
                        { text: "2", value: "2" },
                        { text: "3", value: "3" },
                        { text: "4", value: "4" },
                        { text: "5", value: "5" },
                        { text: "chloroplast genome", value: "C" },
                        { text: "mitochondrial genome", value: "M" },
                      ],
                      id: "chromosome",
                      size: "large",
                      value: "any",
                    },
                  ],
                },
              },
              // {
              //   text: "Map Type: AGI",
              // },
              {
                name: "Range",
                value: {
                  type: "mixed",
                  elements: [
                    // {
                    //   type: "select",
                    //   options: [
                    //     { text: "between", value: "between" },
                    //     { text: "around", value: "around" },
                    //   ],
                    //   id: "range_type",
                    //   size: "small",
                    //   value: "between",
                    // },
                    {
                      type: "input",
                      value: "",
                      name: "low_range",
                      id: "low_range",
                    },
                    {
                      type: "text",
                      value: "kb",
                    },
                    {
                      type: "text",
                      value: "and",
                    },
                    {
                      type: "input",
                      value: "",
                      id: "high_range",
                    },
                    {
                      type: "text",
                      value: "kb",
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
      helpLink:
        "https://conf.phoenixbioinformatics.org/display/COM/Marker+Search+and+Results",
    };
  },
  watch: {
    "$route.query.key": function (id) {
      this.$forceUpdate();
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      document.title = "TAIR Marker Search";
      this.getEcotypeOptions();
    },
    async getEcotypeOptions() {
      let ecotypes = [
        "Ws",
        "Nd",
        "No",
        "Rld",
        "Ler",
        "Col",
        "C24",
        "Cvi",
        "En",
        "Be",
      ];
      const sortedValues = ecotypes.sort((a, b) =>
        a.toLowerCase().localeCompare(b.toLowerCase())
      );
      let optionsList = sortedValues.map((value) => {
        return { text: value, value: value };
      });
      optionsList.unshift({ text: "any", value: "any" });
      this.updateOptionsById("poly_between_1", optionsList);
      this.updateOptionsById("poly_between_2", optionsList);
    },
    async onSubmit(event) {
      event.preventDefault();

      let searchParams = {
        searchTerms: [],
        restrictByFeatures: [],
        mapLocation: [],
      };

      this.search_form.filters.forEach((filter) => {
        if (filter.type === "rows") {
          filter.rows.forEach((row) => {
            let rowParams = {};
            row.value.elements.forEach((element) => {
              let shouldAddParam = true;
              let value = element.value;

              if (element.type === "checkbox") {
                value = element.value;
              }

              if (!value || value === false || value === "any") {
                shouldAddParam = false;
              }

              //check if value is an array
              if (Array.isArray(value)) {
                if (
                  value.length === 0 ||
                  (value.length === 1 && value[0] === "any")
                ) {
                  shouldAddParam = false;
                }
              }

              if (shouldAddParam) {
                if (element.id) {
                  rowParams[element.id] = value;
                }
              }
            });
            if (Object.keys(rowParams).length > 0) {
              if (filter.label === "Search by Name") {
                const generalizedRowParams =
                  this.generalizeFieldNames(rowParams);
                searchParams.searchTerms.push(generalizedRowParams);
              } else if (filter.label === "Restrict by Features") {
                searchParams.restrictByFeatures.push(rowParams);
              } else if (filter.label === "Restrict by Map Location") {
                searchParams.mapLocation.push(rowParams);
              }
            }
          });
        }
      });

      this.showResults(searchParams);
    },

    onReset(event) {
      event.preventDefault();
    },
    showResults(inp_params) {
      this.$nextTick(() => {
        this.$router.push({
          path: "/results",
          query: {
            mainType: "detail",
            category: "markers",
            search_params: JSON.stringify(inp_params),
          },
        });
      });
    },
    getValueById(searchId) {
      let result = null;

      // Loop over each filter
      for (let filter of this.search_form.filters) {
        // Loop over each row in the current filter
        for (let row of filter.rows) {
          // Check if row has a value and elements
          if (row.value && row.value.elements) {
            // Find the element with the matching id
            let element = row.value.elements.find((el) => el.id === searchId);
            if (element) {
              result = element.value;
              break;
            }
          }
        }

        if (result !== null) {
          break;
        }
      }

      return result;
    },
    updateOptionsById(searchId, options) {
      for (let filter of this.search_form.filters) {
        for (let row of filter.rows) {
          if (row.value && row.value.elements) {
            let element = row.value.elements.find((el) => el.id === searchId);
            if (element) {
              if (element.options) {
                element.options = options;
              }
              break;
            }
          }
        }
      }
    },
    generalizeFieldNames(rowParams) {
      const generalizedParams = {};

      Object.keys(rowParams).forEach((key) => {
        if (key.startsWith("search_type_by_")) {
          generalizedParams["search_type"] = rowParams[key];
        } else if (key.startsWith("search_method_")) {
          generalizedParams["search_method"] = rowParams[key];
        } else if (key.startsWith("search_input_")) {
          generalizedParams["search_input"] = rowParams[key];
        } else {
          // Copy other fields as-is
          generalizedParams[key] = rowParams[key];
        }
      });

      return generalizedParams;
    },
  },
};
</script>

<style scoped lang="scss"></style>
