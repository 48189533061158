<template>
  <div>
    <input
      :class="sizeClass"
      :placeholder="placeholder"
      :value="value"
      :id="id"
      :aria-label="id"
      @input="$emit('input', $event.target.value)"
      @keyup.enter="$emit('on-enter', $event)"
    />
  </div>
</template>

<script>
export default {
  props: {
    placeholder: String,
    value: [String, Number],
    id: String,
    size: {
      type: String,
      default: "medium", // Default size if none is provided
      validator: function (value) {
        return ["small", "medium", "large"].includes(value);
      },
    },
  },
  computed: {
    sizeClass() {
      return {
        "input-small": this.size === "small",
        "input-medium": this.size === "medium",
        "input-large": this.size === "large",
      };
    },
    randomLabel() {
      const randomNumber = Math.floor(Math.random() * 100);
      return `${this.id}-${randomNumber}`;
    },
  },
};
</script>

<style scoped>
.input-small {
  width: 60px; /* Example size, adjust as needed */
}

.input-medium {
  width: 100px; /* Example size, adjust as needed */
}

@media (min-width: 768px) {
  .input-medium {
    width: 18vw;
  }
}

.input-large {
  width: 90%; /* Example size, adjust as needed */
}

@media (min-width: 768px) {
  .input-large {
    width: 17vw;
  }
}
</style>
