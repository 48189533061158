import DownloadView from "@/views/download/DownloadView";
import DownloadOverview from "@/views/download/DownloadOverview";
import DownloadList from "@/views/download/DownloadList";
import DownloadFile from "@/views/download/DownloadFile";

export const downloadRoutes = {
  path: '/download',
  name: 'DownloadView',
  component: DownloadView,
  children: [
    {
      path: 'overview',
      name: 'DownloadOverview',
      component: DownloadOverview,
    },
    {
      path: "list",
      name: "DownloadList",
      component: DownloadList,
    },
    {
      path: 'file',
      name: 'DownloadFile',
      component: DownloadFile,
    }
  ]
}