<template>
  <div class="searchWrapper">
    <div class="pageTop">
      <div class="d-none d-md-block">
        <div class="d-flex flex-column">
          <b-breadcrumb :items="crumb_links"></b-breadcrumb>
          <div class="text-left ml-2 d-flex">
            <h4 style="color: #930">Keyword Search</h4>
            <span class="ml-2"
              >[<a :href="helpLink" target="_blank">Help</a>]</span
            >
          </div>
          <div class="text-left m-2">
            Search and browse for a term to view term details and relationships
            among terms. It includes links to genes, publications, microarray
            experiments and annotations associated with the term or any children
            terms.
          </div>
        </div>
      </div>
      <div class="d-md-none">
        <div class="row">
          <div class="col-4 font-weight-bold pl-2">Search Result</div>
          <div class="col-8">
            <b-dropdown id="dropdown-1" text="Menu" class="m-md-2">
              <b-dropdown-item>New Search</b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>
    </div>
    <div class="pageMain m-2">
      <div class="d-flex flex-column">
        <div class="row mt-2">
          <div class="col-sm d-flex align-items-start ml-4">
            <b-button size="sm" class="mr-2">Reset</b-button>
            <b-button size="sm" @click="onSubmit">Submit Query</b-button>
          </div>
        </div>
        <div>
          <div class="d-flex flex-column mx-4 my-2">
            <form-section
              v-for="(filter, index) in search_form.filters"
              :key="index"
              :filter="filter"
              @on-enter="onSubmit"
            />
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-sm d-flex align-items-start ml-4 mb-4">
            <b-button size="sm" class="mr-2">Reset</b-button>
            <b-button size="sm" @click="onSubmit">Submit Query</b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormSection from "../../components/common/FormSection.vue";
export default {
  name: "KeywordSearch",
  components: {
    FormSection,
  },
  data() {
    return {
      crumb_links: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "Search",
          href: "/search/overview",
        },
        {
          text: "Keyword",
          active: true,
        },
      ],
      search_form: {
        filters: [
          {
            label: "Search by Keyword or ID",
            type: "rows",
            id: "search_terms_section",
            rows: [
              {
                name: "Keyword",
                value: {
                  type: "mixed",
                  elements: [
                    {
                      type: "select",
                      options: [
                        { text: "contains", value: "contains" },
                        { text: "starts with", value: "starts_with" },
                        { text: "exactly", value: "exactly" },
                      ],
                      value: "contains",
                      id: "search_method_1",
                    },
                    {
                      type: "input",
                      value: "",
                      id: "kw_search_input",
                      size: "large",
                    },
                  ],
                },
              },
              {
                name: "GO/PO ID (exact match only)",
                value: {
                  type: "mixed",
                  elements: [
                    {
                      type: "input",
                      value: "",
                      id: "go_id_search_inp",
                      size: "large",
                      placeholder: "GO:0005942 OR PO:0020133",
                    },
                  ],
                },
              },
            ],
          },
          {
            label: "Restrict by Keyword Category",
            type: "rows",
            id: "restrict_cats_section",
            rows: [
              {
                name: "",
                value: {
                  type: "mixed",
                  elements: [
                    {
                      type: "checkbox",
                      checked: false,
                      label: "GO Cellular Component",
                      id: "go_cc",
                    },
                  ],
                },
              },
              {
                name: "",
                value: {
                  type: "mixed",
                  elements: [
                    {
                      type: "checkbox",
                      checked: false,
                      label: "GO Biological Process",
                      id: "go_bp",
                    },
                  ],
                },
              },
              {
                name: "",
                value: {
                  type: "mixed",
                  elements: [
                    {
                      type: "checkbox",
                      checked: false,
                      label: "GO Molecular Function",
                      id: "go_mf",
                    },
                  ],
                },
              },
              {
                name: "",
                value: {
                  type: "mixed",
                  elements: [
                    {
                      type: "checkbox",
                      checked: false,
                      label: "Plant Growth and Development Stages",
                      id: "po",
                    },
                  ],
                },
              },
              {
                name: "",
                value: {
                  type: "mixed",
                  elements: [
                    {
                      type: "checkbox",
                      checked: false,
                      label: "Plant Anatomical Entity",
                      id: "pat",
                    },
                  ],
                },
              },
              // {
              //   name: "",
              //   value: {
              //     type: "mixed",
              //     elements: [
              //       {
              //         type: "checkbox",
              //         checked: false,
              //         label: "Experimental Method",
              //         id: "exp",
              //       },
              //     ],
              //   },
              // },
              {
                name: "",
                value: {
                  type: "mixed",
                  elements: [
                    {
                      type: "checkbox",
                      checked: false,
                      label: "User defined",
                      id: "user",
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
      helpLink:
        "https://conf.phoenixbioinformatics.org/display/COM/Keyword+search",
    };
  },
  watch: {
    "$route.query.key": function (id) {
      this.$forceUpdate();
    },
  },
  mounted() {
    // this.init();
    document.title = "Keyword Search";
  },
  methods: {
    init() {},
    generalizeFieldNames(rowParams) {
      const generalizedParams = {};

      Object.keys(rowParams).forEach((key) => {
        if (key.startsWith("search_type_by_")) {
          generalizedParams["search_type"] = rowParams[key];
        } else if (key.startsWith("search_method_")) {
          generalizedParams["search_method"] = rowParams[key];
        } else if (key.startsWith("search_input_")) {
          generalizedParams["search_input"] = rowParams[key];
        } else {
          // Copy other fields as-is
          generalizedParams[key] = rowParams[key];
        }
      });

      return generalizedParams;
    },
    async onSubmit(event) {
      event.preventDefault();
      let searchParams = {
        searchTerms: [],
        restrictCats: [],
      };
      this.search_form.filters.forEach((filter) => {
        if (filter.type === "rows") {
          filter.rows.forEach((row) => {
            let rowParams = {};
            row.value.elements.forEach((element) => {
              let shouldAddParam = true;
              let value = element.value;

              if (element.type === "checkbox") {
                value = element.value;
              }

              if (!value || value === false || value === "any") {
                shouldAddParam = false;
              }

              //check if value is an array
              if (Array.isArray(value)) {
                if (
                  value.length === 0 ||
                  (value.length === 1 && value[0] === "any")
                ) {
                  shouldAddParam = false;
                }
              }

              if (shouldAddParam) {
                if (element.id) {
                  rowParams[element.id] = value;
                }
              }
            });
            if (Object.keys(rowParams).length > 0) {
              if (filter.id === "search_terms_section") {
                const generalizedRowParams =
                  this.generalizeFieldNames(rowParams);
                searchParams.searchTerms.push(generalizedRowParams);
              } else if (filter.id === "restrict_cats_section") {
                searchParams.restrictCats.push(rowParams);
              }
            }
          });
        }
      });

      this.showResults(searchParams);
    },
    onReset(event) {
      event.preventDefault();
    },
    showResults(inp_params) {
      this.$nextTick(() => {
        this.$router.push({
          path: "/results",
          query: {
            mainType: "detail",
            category: "keyword",
            search_params: JSON.stringify(inp_params),
          },
        });
      });
    },
    getValueById(searchId) {
      let result = null;

      // Loop over each filter
      for (let filter of this.search_form.filters) {
        // Loop over each row in the current filter
        for (let row of filter.rows) {
          // Check if row has a value and elements
          if (row.value && row.value.elements) {
            // Find the element with the matching id
            let element = row.value.elements.find((el) => el.id === searchId);
            if (element) {
              result = element.value;
              break;
            }
          }
        }

        if (result !== null) {
          break;
        }
      }

      return result;
    },
  },
};
</script>

<style scoped lang="scss"></style>
