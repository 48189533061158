import BrowseView from "@/views/browse/BrowseView";
import RecentLiteratureBrowse from "@/views/browse/RecentLiteratureBrowse";
import TransposonFamiliesBrowse from "@/views/browse/TransposonFamiliesBrowse";
import GeneSymbolBrowse from "@/views/browse/GeneSymbolBrowse";
import MicroArrayExperimentsBrowse from "@/views/browse/MicroArrayExperimentsBrowse";
import GeneFamilyBrowse from "@/views/browse/GeneFamilyBrowse";
import BrowseOverview from "@/views/browse/BrowseOverview";

export const browseRoutes = {
  path: "/browse",
  name: "BrowseView",
  component: BrowseView,
  children: [
    {
      path: "overview",
      name: "BrowseOverview",
      component: BrowseOverview,
    },
    {
      path: "recent_literature",
      name: "RecentLiteratureBrowse",
      component: RecentLiteratureBrowse,
    },
    {
      path: "transposon_families",
      name: "TransposonFamiliesBrowse",
      component: TransposonFamiliesBrowse,
    },
    {
      path: "gene_symbols",
      name: "GeneSymbolBrowse",
      component: GeneSymbolBrowse,
    },
    {
      path: "microarray_experiments",
      name: "MicroArrayExperimentsBrowse",
      component: MicroArrayExperimentsBrowse,
    },
    {
      path: "gene_family",
      name: "GeneFamilyBrowse",
      component: GeneFamilyBrowse,
    },
  ],
};
