// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import "./plugins/bootstrap-vue";
import App from "./App";
import router from "./router";
import store from "./store/store"; // Import the store

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

document.documentElement.lang = "en";
