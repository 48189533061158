<template>
  <div class="gene-families container">
    <h2>Arabidopsis Gene Family Information</h2>
    <p>
      We display these Gene Family pages as historical records only. Not all
      links may be functional, especially to older and no longer maintained
      resources. If you have gene family data to share through TAIR, please
      contact us.
    </p>
    <table>
      <tr>
        <th>Gene Family Name</th>
        <th>Family&nbsp;Count/<br />Gene&nbsp;Count</th>
        <th>Submitter</th>
      </tr>

      <tr>
        <td><a href="/browse/gene_family/14-3-3">14-3-3 family</a></td>
        <td>1 family<br />13 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=organization&communityID=8075"
            >R Ferl Laboratory</a
          >
        </td>
      </tr>

      <tr>
        <td><a href="/browse/gene_family/ABC_merged">ABC Superfamily</a></td>
        <td>Subfamilies 8<br />Members 136</td>
        <td>
          <a
            href="/browse/gene_family/mailto:paul.verrier@bbsrc.ac.uk"
            target="display"
            >Paul Verrier</a
          ><br />
          <a href="/browse/gene_family//servlets/TairObject?type=person&id=2028"
            >Freddie Theodoulou</a
          ><br />
          <a href="/browse/gene_family//servlets/TairObject?type=person&id=5076"
            >Angus Murphy</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/ABI3VP1"
            >ABI3VP1 Transcription Factor Family</a
          >
        </td>
        <td>1 family<br />11 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=organization&communityID=1501423724"
            >AGRIS</a
          >
        </td>
      </tr>

      <tr>
        <td><a href="/browse/gene_family/AGC">AGC Kinase Gene Family</a></td>
        <td>1 family<br />39 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=1213417"
            >Laszlo Bogre</a
          ><br />
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=7034"
            >Laszlo Okresz</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/aldh"
            >Aldehyde Dehydrogenase Gene Family</a
          >
        </td>
        <td>9 families<br />14 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=5166"
            >Dorothea Bartels</a
          ><br />
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=210609"
            >Hans-Hubert Kirch</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/AAAP"
            >Amino Acid/Auxin Permease AAAP Family</a
          >
        </td>
        <td>1 family<br />43 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=6083"
            >John Ward</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/acyl_lipid"
            >Acyl Lipid Metabolism Gene Families</a
          >
        </td>
        <td>174 families<br />610 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=612141"
            >Fred Beisson</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/Alfinlike"
            >Alfin-like Transcription Factor Family</a
          >
        </td>
        <td>1 family<br />7 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=organizationanization&communityID=1501423724"
            >AGRIS</a
          >
        </td>
      </tr>

      <tr>
        <td><a href="/browse/gene_family/Antiporters">Antiporters</a></td>
        <td>13 families<br />70 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=6083"
            >John Ward</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/antiporter">Antiporter Super Family</a>
        </td>
        <td>1 subfamily<br />28 members</td>
        <td>
          <a target="display" href="/servlets/TairObject?type=person&id=6270"
            >Heven Sze</a
          ><br />
          <a target="display" href="/servlets/TairObject?type=person&id=5515"
            >Kendal Hirschi</a
          ><br />
          <a
            target="display"
            href="/servlets/Community?type=organization&action=view&communityID=8372"
            >H Sze Laboratory</a
          ><br />
          <a
            target="display"
            href="/servlets/Community?type=organization&action=view&communityID=9616"
            >K Hirschi Laboratory</a
          >
        </td>
      </tr>

      <tr>
        <td><a href="/browse/gene_family/Aquaporins">Aquaporins</a></td>
        <td>2 families<br />35 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=6083"
            >John Ward</a
          >
        </td>
      </tr>

      <tr>
        <td><a href="/browse/gene_family/ARIADNE">ARIADNE family</a></td>
        <td>3 subfamilies<br />16 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=4770"
            >Marie-Theres Hauser</a
          >
        </td>
      </tr>
      <tr>
        <td><a href="/browse/gene_family/ARRB">ARR-B</a></td>
        <td>1 family<br />15 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=organization&communityID=1501423724"
            >AGRIS</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/ARF">ARF Transcription Factor Family</a>
        </td>
        <td>1 family<br />24 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=organization&communityID=1501423724"
            >AGRIS</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/ARID"
            >ARID Transcription Factor Family</a
          >
        </td>
        <td>1 family<br />7 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=organization&communityID=1501423724"
            >AGRIS</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/AP2EREBP"
            >AP2-EREBP Transcription Factor Family</a
          >
        </td>
        <td>1 family<br />138 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=organization&communityID=1501423724"
            >AGRIS</a
          >
        </td>
      </tr>

      <tr>
        <td><a href="/browse/gene_family/AS2">AS2 family</a></td>
        <td>1 family<br />42 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=5685"
            >Yasunori Machida</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/AHL"
            >AT-hook Motif Nuclear Localized (AHL) Family</a
          >
        </td>
        <td>1 family<br />29 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=1501428234"
            >Jianfei Zhao</a
          ><br />
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=4762"
            >Michael Neff</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/AtRKD"
            >AtRKD Transcription Factor Family</a
          >
        </td>
        <td>1 family<br />5 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=organization&communityID=1501423724"
            >AGRIS</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/B3binding">B3 DNA Binding Superfamily</a>
        </td>
        <td>1 family<br />87 members</td>
        <td>
          <a href="/browse/gene_family/mailto:alvesfer@biologia.ufrj.br"
            >Marcio Alves-Ferreira</a
          ><br />
          <a href="/browse/gene_family/mailto:elissonromanel@yahoo.com.br"
            >Elisson Romanel</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/bpc"
            >BBR/BPC Transcription factor family</a
          >
        </td>
        <td>1 family<br />7 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&amp;type=person&amp;communityID=10631"
            >Dierk Wanke</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/bHLH"
            >bHLH Transcription Factor Family</a
          >
        </td>
        <td>1 family<br />161 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=organization&communityID=1501423724"
            >AGRIS</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/blhm"
            >Basic Helix-Loop-Helix Transcription Factor Gene Family</a
          >
        </td>
        <td>1 family<br />162 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=6272"
            >Bernd Weisshaar</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/bZIP-Jak"
            >Basic region leucine zipper (bZIP) Transcription Factor Gene
            Family</a
          >
        </td>
        <td>1 family<br />75 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=10799"
            >Marc Jakoby</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/bZIP"
            >bZIP Transcription Factor Family</a
          >
        </td>
        <td>1 family<br />73 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=organization&communityID=1501423724"
            >AGRIS</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/BZR">BZR Transcription Factor Family</a>
        </td>
        <td>1 family<br />6 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=organization&communityID=1501423724"
            >AGRIS</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/CDPK"
            >Calcium Dependent Protein Kinase</a
          >
        </td>
        <td>1 family<br />34 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?type=organization&action=view&communityID=8365"
            >J Sheen Laboratory</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/CBP">Calmodulin-binding Proteins</a>
        </td>
        <td>17 families<br />100 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=711946"
            >Vaka S Reddy</a
          >,<br />
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=1313553"
            >Gul Shad Ali</a
          >,<br />
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=5935"
            >ASN Reddy</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/C2C2COlike"
            >C2C2-CO-like Transcription Factor Family</a
          >
        </td>
        <td>1 family<br />30 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=organization&communityID=1501423724"
            >AGRIS</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/C2C2Dof"
            >C2C2-Dof Transcription Factor Family</a
          >
        </td>
        <td>1 family<br />36 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=organization&communityID=1501423724"
            >AGRIS</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/C2C2Gata"
            >C2C2-Gata Transcription Factor Family</a
          >
        </td>
        <td>1 family<br />30 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=organization&communityID=1501423724"
            >AGRIS</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/C2C2YABBY"
            >C2C2-YABBY Transcription Factor Family</a
          >
        </td>
        <td>1 family<br />6 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=organization&communityID=1501423724"
            >AGRIS</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/C2H2"
            >C2H2 Transcription Factor Family</a
          >
        </td>
        <td>1 family<br />211 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=organization&communityID=1501423724"
            >AGRIS</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/C3H">C3H Transcription Factor Family</a>
        </td>
        <td>1 family<br />165 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=organization&communityID=1501423724"
            >AGRIS</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/CarbohydrateEsterase"
            >Carbohydrate Esterase</a
          >
        </td>
        <td>5 families<br />98 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=912146"
            >B. Henrissat</a
          >,<br />
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=912144"
            >P. Coutinho</a
          >, E. Deleury
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/CAMTA"
            >CAMTA Transcription Factor Family</a
          >
        </td>
        <td>1 family<br />6 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=organization&communityID=1501423724"
            >AGRIS</a
          >
        </td>
      </tr>
      <tr>
        <td>
          <a href="/browse/gene_family/CBL#CIPK"
            >CBL-interacting serine-threonine protein kinases</a
          >
        </td>
        <td>1 family<br />25 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=6945"
            >Joerg Kudla</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/CCAATDR1"
            >CCAAT-DR1 Transcription Factor Family</a
          >
        </td>
        <td>1 family<br />2 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=organization&communityID=1501423724"
            >AGRIS</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/CCAATHAP2"
            >CCAAT-HAP2 Transcription Factor Family</a
          >
        </td>
        <td>1 family<br />10 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=organization&communityID=1501423724"
            >AGRIS</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/CCAATHAP3"
            >CCAAT-HAP3 Transcription Factor Family</a
          >
        </td>
        <td>1 family<br />10 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=organization&communityID=1501423724"
            >AGRIS</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/CCAATHAP5"
            >CCAAT-HAP5 Transcription Factor Family</a
          >
        </td>
        <td>1 family<br />13 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=organization&communityID=1501423724"
            >AGRIS</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/cellwall">Cell Wall Biosynthesis</a>
        </td>
        <td>8 families<br />52 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=4778"
            >Todd Richmond</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/Chloroplast"
            >Chloroplast and Mitochondria Gene Families</a
          >
        </td>
        <td>16 families<br />50 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=6083"
            >John Ward</a
          >
        </td>
      </tr>

      <tr>
        <td><a href="/browse/gene_family/COB">COBRA Gene Family</a></td>
        <td>2 families<br />12 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=4729"
            >Philip Benfey</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/cellcycle"
            >Core Cell Cycle Gene Families</a
          >
        </td>
        <td>7 families<br />61 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=912276"
            >Klaas Vandepoele</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/core"
            >Core DNA Replication Machinery Family</a
          >
        </td>
        <td>1 family<br />61 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=312020"
            >Randall Shultz</a
          ><br />
          <a
            target="display"
            href="/servlets/Community?type=organization&action=view&communityID=1501415562"
            >Linda Hanley-Bowdoin Laboratory</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/CPP">CPP Transcription Factor Family</a>
        </td>
        <td>1 family<br />8 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=organization&communityID=1501423724"
            >AGRIS</a
          >
        </td>
      </tr>

      <tr>
        <td><a href="/browse/gene_family/p450">Cytochrome P450</a></td>
        <td>69 families<br />256 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=958"
            >S. Bak</a
          >,<br />
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=912138"
            >D. Nelson</a
          >,<br />
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=4512"
            >Mary Schuler</a
          >
        </td>
      </tr>

      <tr>
        <td><a href="/browse/gene_family/cytochromeb5">Cytochrome b5</a></td>
        <td>1 family<br />7 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=5152"
            >Soren Bak</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/athr"
            >Cytoplasmic Ribosomal Protein Gene Family</a
          >
        </td>
        <td>79 families<br />255 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=958"
            >Julia Bailey-Serres</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/cytoskeleton"
            >Cytoskeleton Gene Families</a
          >
        </td>
        <td>2 families<br />15 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=4626"
            >Richard Meagher</a
          >
        </td>
      </tr>

      <tr>
        <td><a href="/browse/gene_family/DOF">DOF Gene Family</a></td>
        <td>1 family<br />37 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=1501417250"
            >Shuichi Yanagisawa</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/E2FDP"
            >E2F-DP Transcription Factor Family</a
          >
        </td>
        <td>1 family<br />8 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=organization&communityID=1501423724"
            >AGRIS</a
          >
        </td>
      </tr>

      <tr>
        <td><a href="/browse/gene_family/EAR">EAR repressome family</a></td>
        <td>57 families<br />403 members</td>
        <td>
          <a target="display" href="mailto:Kevin.Rozwadowski@AGR.GC.CA"
            >Kevin Rozwadowski</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/EIL">EIL Transcription Factor Family</a>
        </td>
        <td>1 family<br />6 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=organization&communityID=1501423724"
            >AGRIS</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/ef-hand">EF-hand Containing Proteins</a>
        </td>
        <td>8 families<br />234 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=912159"
            >Irene S Day</a
          >,<br />
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=711946"
            >Vaka Reddy</a
          >,<br />
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=1313553"
            >Gul Shad Ali</a
          >,<br />
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=5935"
            >ASN Reddy</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/ent"
            >Equilibrative Nucleoside Transporter Gene Family (ENT)</a
          >
        </td>
        <td>1 family<br />8 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=2062"
            >Torsten Moehlmann</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/eIF"
            >Eukaryotic Initiation Factors Gene Family</a
          >
        </td>
        <td>44 families<br />100 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=5220"
            >Karen Browning</a
          >
        </td>
      </tr>

      <tr>
        <td><a href="/browse/gene_family/expansin">Expansin Gene Family</a></td>
        <td>4 families<br />36 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=5092"
            >Daniel Cosgrove</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/exo">EXO70 exocyst subunit family</a>
        </td>
        <td>1 family<br />23 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&amp;type=person&amp;communityID=639"
            >Viktor Zarsky</a
          >
        </td>
      </tr>

      <tr>
        <td><a href="/browse/gene_family/F_box">F-Box Gene Family</a></td>
        <td>1 family<br />4 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=507"
            >Claus Schwechheimer</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/fh2">FH2 protein (formin) Gene Family</a>
        </td>
        <td>1 family<br />21 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=3301"
            >Fatima Cvrckova</a
          >
        </td>
      </tr>

      <tr>
        <td><a href="/browse/gene_family/ftsh">FtsH Gene Family</a></td>
        <td>1 family<br />12 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=1012567"
            >Adi Zaltsman</a
          >
        </td>
      </tr>
      <tr>
        <td>
          <a href="/browse/gene_family/G2like"
            >G2-like Transcription Factor Family</a
          >
        </td>
        <td>1 family<br />40 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=organization&communityID=1501423724"
            >AGRIS</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/GeBP"
            >GeBP Transcription Factor Family</a
          >
        </td>
        <td>1 family<br />16 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=organization&communityID=1501423724"
            >AGRIS</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/gdu">Glutamine Dumper (GDU) Family</a>
        </td>
        <td>1 <br />7 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=4110"
            >Guillaume Pilot</a
          >,<br />
          <a
            target="display"
            href="/servlets/Community?action=view&type=organization&communityID=1501415330"
            >Wolf Frommer Laboratory at Stanford</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/GlycosideHydrolase"
            >Glycoside Hydrolase</a
          >
        </td>
        <td>31 family<br />379 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=912146"
            >B. Henrissat</a
          >,<br />
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=912144"
            >P. Coutinho</a
          >, E. Deleury
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/Glycosyltransferase"
            >Glycosyltransferase</a
          >
        </td>
        <td>28 families<br />361 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=912146"
            >B. Henrissat</a
          >,<br />
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=912144"
            >P. Coutinho</a
          >, E. Deleury
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/gras_genefamily">GRAS Gene Family</a>
        </td>
        <td>1 family<br />33 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=1329"
            >Cordelia Bolle</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/GRAS"
            >GRAS Transcription Factor Family</a
          >
        </td>
        <td>1 family<br />33 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=organization&communityID=1501423724"
            >AGRIS</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/GRF">GRF Transcription Factor Family</a>
        </td>
        <td>1 family<br />9 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=organization&communityID=1501423724"
            >AGRIS</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/gst"
            >Glutathione S-transferase Gene Family</a
          >
        </td>
        <td>7 families<br />53 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=1501414806"
            >Pia G. Sappl</a
          >,
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=1501414127"
            >Harvey Millar</a
          >,
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=6670"
            >Karam B. Singh</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/Hsfs"
            >Heat shock transcription factors Hsfs</a
          >
        </td>
        <td>1 family<br />21 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=912135"
            >Lutz Nover</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/HisK">Histidine Kinase Gene Family</a>
        </td>
        <td>1 family<br />16 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?type=organization&action=view&communityID=8365"
            >J Sheen Lab</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/AHP"
            >Histidine Phosphotransfer Proteins</a
          >
        </td>
        <td>1 family<br />6 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?type=organization&action=view&communityID=8365"
            >J Sheen Lab</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/Homeobox"
            >Homeobox Transcription Factor Family</a
          >
        </td>
        <td>1 family<br />91 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=organization&communityID=1501423724"
            >AGRIS</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/HRT">HRT Transcription Factor Family</a>
        </td>
        <td>1 family<br />3 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=organization&communityID=1501423724"
            >AGRIS</a
          >
        </td>
      </tr>

      <tr>
        <td><a href="/browse/gene_family/HSF">HSF</a></td>
        <td>1 family<br />21 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=organization&communityID=1501423724"
            >AGRIS</a
          >
        </td>
      </tr>

      <tr>
        <td><a href="/browse/gene_family/idz">IDZ Gene Family</a></td>
        <td>1 family<br />11 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=1827"
            >Thomas Merkle</a
          >
        </td>
      </tr>

      <tr>
        <td><a href="/browse/gene_family/IMA">IMA (Ironman) Gene Family</a></td>
        <td>1 family<br />8 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=1501486867"
            >Louis Grillet</a
          >
        </td>
      </tr>
      <tr>
        <td>
          <a href="/browse/gene_family/Cotransporter"
            >Inorganic Solute Cotransporters</a
          >
        </td>
        <td>16 families<br />84 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=6083"
            >John Ward</a
          >
        </td>
      </tr>

      <tr>
        <td><a href="/browse/gene_family/Ionchannels">Ion Channels</a></td>
        <td>7 families<br />61 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=6083"
            >John Ward</a
          >,
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=211861"
            >Benoiit Lacombe</a
          >
        </td>
      </tr>

      <tr>
        <td><a href="/browse/gene_family/IQD">IQD Protein Family</a></td>
        <td>1 family<br />33 members</td>
        <td>
          <a target="display" href="/servlets/TairObject?type=person&id=5114"
            >Steffen Abel</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/JUMONJI"
            >JUMONJI Transcription Factor Family</a
          >
        </td>
        <td>1 family<br />5 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=organization&communityID=1501423724"
            >AGRIS</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/Myosin_kinesins#kinesins"
            >Kinesins gene family</a
          >
        </td>
        <td>1 family<br />61 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=5935"
            >A. S. N. Reddy</a
          >,
          <a
            href="http://arabidopsisorg/servlets/Community?action=view&type=person&communityID=912159"
            >Irene Day</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/lob"
            >Lateral Organ Boundaries Gene Family</a
          >
        </td>
        <td>1 family<br />43 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=1213505"
            >B. Shuai</a
          >,
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=1213506"
            >C. Reynaga-Pena</a
          >,
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=4469"
            >P. Springer</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/leuc"
            >Leucine-rich Repeat extension Gene Families</a
          >
        </td>
        <td>2 families<br />11 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=912379"
            >Christoph Ringli</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/Lipid">Lipid Metabolism Gene Families</a>
        </td>
        <td>28 families<br />98 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=6083"
            >John Ward</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/LYKs--LysM-Receptor-Like-Kinase"
            >LysM Receptor-like Kinase (LYKs) Gene Family</a
          >
        </td>
        <td>1 family<br />5 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=1501423620"
            >Kiwamu Tanaka</a
          >,
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=5982"
            >Gary Stacey</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/LYPs--LysM-Receptor-Like-Protein"
            >LysM Receptor-like Protein (LYPs) Gene Family</a
          >
        </td>
        <td>1 family<br />3 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=1501423620"
            >Kiwamu Tanaka</a
          >,
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=5982"
            >Gary Stacey</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/mads_tffamily"
            >MADS Transcription Factor Family</a
          >
        </td>
        <td>1 family<br />109 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=organization&communityID=1501423724"
            >AGRIS</a
          >
        </td>
      </tr>

      <tr>
        <td><a href="/browse/gene_family/MADSlike">MADS-box Gene Family</a></td>
        <td>1 family<br />108 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=1501418015"
            >Stefan de Folter</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/MADS#MADSlike">MADS like Gene Family</a>
        </td>
        <td>1 family<br />16 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=1013001"
            >Stefanie De Bodt</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/MADS">Type I MADS-box Gene Family</a>
        </td>
        <td>1 family<br />46 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=1013001"
            >Stefanie De Bodt</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/MIP">Major Intrinsic Protein MIP</a>
        </td>
        <td>1 family<br />38 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=6083"
            >John Ward</a
          >
        </td>
      </tr>

      <tr>
        <td><a href="/browse/gene_family/MAPK">MAP Kinase Gene Family</a></td>
        <td>1 family<br />20 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?type=organization&action=view&communityID=8365"
            >J Sheen Lab</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/MAPKK">MAP Kinase Kinase Gene Family</a>
        </td>
        <td>1 family<br />10 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?type=organization&action=view&communityID=8365"
            >J Sheen Lab</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/MAPKKK">MAP Kinase Kinase Kinase</a>
        </td>
        <td>3 sub families<br />80 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=1213417"
            >Laszlo Bogre</a
          >,
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=7034"
            >Laszlo Okresz</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/MAP4K"
            >MAP Kinase Kinase Kinase Kinase</a
          >
        </td>
        <td>1 family<br />10 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=1213417"
            >Laszlo Bogre</a
          >,
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=7034"
            >Laszlo Okresz</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/mechanoIon"
            >Mechanosensitive Ion Channel Family</a
          >
        </td>
        <td>1 family<br />10 members</td>
        <td>Elizabeth Haswell</td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/Miscellaneous"
            >Miscellaneous Gene Families</a
          >
        </td>
        <td>108 families<br />443 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=6083"
            >John Ward</a
          >
        </td>
      </tr>

      <tr>
        <td><a href="/browse/gene_family/mlo">MLO family</a></td>
        <td>1 family<br />15 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=1702"
            >Ralph Panstruga</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/Raes"
            >Monolignol Biosynthesis Gene Families</a
          >
        </td>
        <td>1 family<br />61 members</td>
        <td>
          <a
            target="display"
            href="/servlets/TairObject?type=person&id=1501414522"
            >Jereon Raes</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/Monos"
            >Monosaccharide Transporter(-like) Gene Family</a
          >
        </td>
        <td>7 families<br />53 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=4935"
            >Norbert Sauer</a
          >
        </td>
      </tr>

      <tr>
        <td><a href="/browse/gene_family/MRS2">MRS2 Gene Family</a></td>
        <td>1 family<br />11 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=2099"
            >Volker Knoop</a
          >,
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=5428"
            >Richard Gardner</a
          >
        </td>
      </tr>

      <tr>
        <td><a href="/browse/gene_family/MYB">MYB family</a></td>
        <td>1 family<br />133 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?type=organization&action=view&communityID=9527"
            >B Weisshaar Laboratory</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/myb-agris"
            >MYB Transcription Factor Family</a
          >
        </td>
        <td>1 family<br />131 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=organization&communityID=1501423724"
            >AGRIS</a
          >
        </td>
      </tr>
      <tr>
        <td>
          <a href="/browse/gene_family/MYBRelated"
            >MYB-Related Transcription Factor Family</a
          >
        </td>
        <td>1 family<br />2 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=organization&communityID=1501423724"
            >AGRIS</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/Myosin_kinesins">Myosins gene family</a>
        </td>
        <td>1 family<br />17 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=5935"
            >A. S. N. Reddy</a
          >,
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=912159"
            >Irene Day</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/NAC">NAC Transcription Factor Family</a>
        </td>
        <td>1 family<br />96 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=organization&communityID=1501423724"
            >AGRIS</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/cytochromeb5#NADPH_P450_reductases"
            >NADPH P450 reductases</a
          >
        </td>
        <td>1 family<br />3 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=958"
            >Soren Bak</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/NS_genefamily"
            >Nicotianamine Synthase Gene Family</a
          >
        </td>
        <td>1 family<br />4 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=11323"
            >Petra Bauer</a
          >,
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=5501"
            >Rudiger Hell</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/NLP">NLP Transcription Factor Family</a>
        </td>
        <td>1 family<br />9 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=organization&communityID=1501423724"
            >AGRIS</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/Nodulin">Nodulin-like Gene Family</a>
        </td>
        <td>3 families<br />64 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=6083"
            >John Ward</a
          >
        </td>
      </tr>

      <tr>
        <td><a href="/browse/gene_family/nse">Non-SMC Element family</a></td>
        <td>1 family<br />2 members</td>
        <td>
          <a href="/browse/gene_family//entity/person_detail/1501430322">
            Koichi Watanabe</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/nat"
            >Nucleobase Ascorbate Transporter Gene Family</a
          >
        </td>
        <td>1 family<br />12 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=1277"
            >Ulf-Ingo Flugge</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/Orphan"
            >Orphan Transcription Factor Family</a
          >
        </td>
        <td>1 family<br />2 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=organization&communityID=1501423724"
            >AGRIS</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/Organicsolute"
            >Organic Solute Cotransporters</a
          >
        </td>
        <td>35 families<br />279 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=6083"
            >John Ward</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/peroxidase">Peroxidase, class III</a>
        </td>
        <td>1 family<br />73 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=1012653"
            >Michael Tognolli</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/PHD">PHD Transcription Factor Family</a>
        </td>
        <td>1 family<br />11 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=organization&communityID=1501423724"
            >AGRIS</a
          >
        </td>
      </tr>

      <tr>
        <td><a href="/browse/gene_family/phd2">PHD Gene Family</a></td>
        <td>1 subfamily<br />5 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?type=organization&action=view&communityID=8639"
            >C Dean Laboratory</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/PhospholipaseD"
            >Phospholipase D Gene Family (Zarsky group)</a
          >
        </td>
        <td>2 sub families<br />12 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?type=organization&action=view&communityID=9532"
            >V Zarsky Laboratory</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/PLD"
            >Phospholipase D Gene Family (Wang group)</a
          >
        </td>
        <td>1 family<br />12 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=4761"
            >X. Wang</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/PhytocyaninS">Phytocyanin Superfamily</a>
        </td>
        <td>1 super family<br />22 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=1501426590"
            >Kiyoshi Mashiguchi</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/plant_defensins"
            >Plant Defensins Gene Families</a
          >
        </td>
        <td>3 families<br />15 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=10830"
            >Bart Thomma</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/PolysaccharideLyase"
            >Polysaccharide Lyase</a
          >
        </td>
        <td>2 families<br />35 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=912146"
            >B. Henrissat</a
          >,
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=912144"
            >P. Coutinho</a
          >, E. Deleury
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/PollenCoatProteome"
            >Pollen Coat Proteome</a
          >
        </td>
        <td>4 families<br />11 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=7632"
            >J. A. Mayfield</a
          >,
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=7372"
            >A. Fiebig</a
          >, S.E. Johnstone,
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=4587"
            >D. Preuss</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/PP2C"
            >PP2C-type Phosphatase Gene Family</a
          >
        </td>
        <td>1 family<br />76 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=3269"
            >Irute Meskiene</a
          >
        </td>
      </tr>

      <tr>
        <td><a href="/browse/gene_family/ATPases">Primary Pumps ATPases</a></td>
        <td>12 families<br />83 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=6083"
            >John Ward</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/primary_pumps"
            >Primary Pumps ATPases(2)</a
          >
        </td>
        <td>2 subfamilies<br />32 members</td>
        <td>
          <a target="display" href="/servlets/TairObject?type=person&id=6270"
            >Heven Sze</a
          ><br />
          <a target="display" href="/servlets/TairObject?type=person&id=210828"
            >Xiyan Li</a
          ><br />
          <a
            target="display"
            href="/servlets/Community?type=organization&action=view&communityID=8372"
            >H Sze Laboratory</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/PTP"
            >Protein tyrosine phosphatase (PTP) family</a
          >
        </td>
        <td>1 family<br />28 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=5679"
            >Sheng Luan</a
          >,
          <a
            target="display"
            href="/servlets/Community?type=organization&action=view&communityID=8310"
            >S Luan Laboratory</a
          >
        </td>
      </tr>

      <tr>
        <td><a href="/browse/gene_family/PRT">PRT Gene Family</a></td>
        <td>4 families<br />15 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=1501417380"
            >Ralf Boldt</a
          >
        </td>
      </tr>
      <tr>
        <td>
          <a href="/browse/gene_family/lobo">Rapid Response to Elicitors</a>
        </td>
        <td>1 family<br />4 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=1501415808"
            >Marta Berrocal-Lobo</a
          ><br />
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=5473"
            >Plinio Guzman</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/RAV">RAV Transcription Factor Family</a>
        </td>
        <td>1 family<br />11 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=organization&communityID=1501423724"
            >AGRIS</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/Rad5_Rad15"
            >RAD5/RAD16-like Gene Family</a
          >
        </td>
        <td>1 family<br />10 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=1665"
            >Holger Puchta</a
          >
        </td>
      </tr>

      <tr>
        <td><a href="/browse/gene_family/RCI2">RCI2 Gene Family</a></td>
        <td>1 family<br />8 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=5454"
            >Julio Salinas</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/Receptor_kinase"
            >Receptor kinase-like Gene Family</a
          >
        </td>
        <td>1 family<br />307 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=6083"
            >John Ward</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/REM">REM Transcription Factor Family</a>
        </td>
        <td>1 family<br />21 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=organization&communityID=1501423724"
            >AGRIS</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/ARR">Response Regulator Gene Family</a>
        </td>
        <td>3 families<br />32 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?type=organization&action=view&communityID=8365"
            >J Sheen Lab</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/SBP">SBP Transcription Factor Family</a>
        </td>
        <td>1 family<br />16 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=organization&communityID=1501423724"
            >AGRIS</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/sbp_box_genefamily"
            >SBP-box Gene Family</a
          >
        </td>
        <td>1 family<br />17 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=1435"
            >Peter Huijser</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/SMH_genefamily"
            >Single Myb Histone (SMH) Gene Family</a
          >
        </td>
        <td>1 family<br />5 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=3460"
            >Hank Bass</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/syntaxin"
            >SNARE and Associated Proteins</a
          >
        </td>
        <td>27 families<br />68 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=2595"
            >Anton Sanderfoot</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/lrrv">Strubbelig Receptor Gene Family</a>
        </td>
        <td>1 family<br />9 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=5928"
            >Kay Schneitz</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/smc"
            >Structural Maintenance of Chromosomes family</a
          >
        </td>
        <td>1 family<br />3 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=1501430322"
            >Koichi Watanabe</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/subtilase"
            >Subtilisin-like Serine Gene Family</a
          >
        </td>
        <td>1 family<br />55 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=5124"
            >Thomas Altman</a
          ><br />
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=611942"
            >Carsten Rauntengarten</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/sucrose"
            >Sucrose Transporter Gene Family</a
          >
        </td>
        <td>1 family<br />9 members</td>
        <td>
          <a
            href="/browse/gene_family//servlets/Community?action=view&type=person&communityID=4935"
            >Norbert Sauer</a
          >
        </td>
      </tr>
      <tr>
        <td>
          <a href="/browse/gene_family/suvdna"
            >SUVH1/3-interacting DNAJ domain-containing protein superfamily</a
          >
        </td>
        <td>1 family<br />3 members</td>
        <td>
          <a
            href="/servlets/Community?action=view&type=person&communityID=611969"
            >Xinjian He</a
          >
        </td>
      </tr>
      <tr>
        <td><a href="/browse/gene_family/suvh">Su(var)3-9 homologs</a></td>
        <td>1 family<br />2 members</td>
        <td>
          <a
            href="/servlets/Community?action=view&type=person&communityID=611969"
            >Xinjian He</a
          >
        </td>
      </tr>
      <tr>
        <td>
          <a href="/browse/gene_family/STR_genefamily"
            >Sulfurtransferasese/Rhodanese</a
          >
        </td>
        <td>1 family<br />18 members</td>
        <td>
          <a
            href="/browse/gene_family//servlets/Community?action=view&type=person&communityID=2402"
            >Jutta Papenbrock</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/TCP">TCP Transcription Factor Family</a>
        </td>
        <td>1 family<br />24 members</td>
        <td>
          <a target="display" href="/entity/person_detail/6339">Pilar Cubas</a>
        </td>
      </tr>

      <tr>
        <td><a href="/browse/gene_family/tify">Tify Gene Family</a></td>
        <td>1 family<br />18 members</td>
        <td>
          <a target="/servlets/TairObject?type=person&id=1516">Lieve Gheysen</a>
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/AtTPS"
            >Trehalose Biosynthesis Gene Families</a
          >
        </td>
        <td>2 families<br />12 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=1325"
            >Barbara Leyman</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/Trihelix"
            >Trihelix Transcription Factor Family</a
          >
        </td>
        <td>1 family<br />29 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=organization&communityID=1501423724"
            >AGRIS</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/TRM"
            >TRM (TON1 Recruiting Motif) Superfamily</a
          >
        </td>
        <td>1 family<br />34 members</td>
        <td>
          <a
            target="display"
            href="http://uat.arabidopsis.org/servlets/Community?action=view&type=person&communityID=5056"
            >David Bouchez</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/TUB">TUB Transcription Factor Family</a>
        </td>
        <td>1 family<br />10 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=organization&communityID=1501423724"
            >AGRIS</a
          >
        </td>
      </tr>
      <tr>
        <td>
          <a href="/browse/gene_family/plantubox">Plant U-box Gene Family</a>
        </td>
        <td>1 family<br />64 members</td>
        <td>
          <a
            href="/browse/gene_family/http://uat.arabidopsis.org/entity/person_detail/1501426234"
            >Marco Trujillo</a
          >
        </td>
      </tr>
      <tr>
        <td><a href="/browse/gene_family/pub">U-box Gene Family</a></td>
        <td>1 family<br />61 members</td>
        <td>
          <a
            href="/browse/gene_family/http://www.sainsbury-laboratory.ac.uk/ken-shirasu/INDEX.htm"
            >Ken Shirasu</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/VOZ9"
            >VOZ-9 Transcription Factor Family</a
          >
        </td>
        <td>1 family<br />2 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=organization&communityID=1501423724"
            >AGRIS</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/Whirly"
            >Whirly Transcription Factor Family</a
          >
        </td>
        <td>1 family<br />3 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=organization&communityID=1501423724"
            >AGRIS</a
          >
        </td>
      </tr>

      <tr>
        <td><a href="/browse/gene_family/wox">Wox Gene Family</a></td>
        <td>1 family<br />14 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=5630"
            >Thomas Laux</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/WRKY-Som"
            >WRKY Transcription Factor Superfamily</a
          >
        </td>
        <td>8 families<br />74 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=4955"
            >Imre Somssich</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/WRKY"
            >WRKY Transcription Factor Family</a
          >
        </td>
        <td>1 family<br />72 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=organization&communityID=1501423724"
            >AGRIS</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/AtFUT">Xyloglucan Fucosyltransferase1</a>
        </td>
        <td>1 family<br />13 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?type=organization&action=view&communityID=8333"
            >N Raikhel Laboratory</a
          >,
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=4257"
            >M. O'Neill</a
          >,
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=10689"
            >A. Faik</a
          >,
          <a
            target="display"
            href="/servlets/Community?type=person&action=view&communityID=807"
            >K. Keegstra</a
          >,
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=211716"
            >I Wilson</a
          >
        </td>
      </tr>

      <tr>
        <td>
          <a href="/browse/gene_family/ZF-HD"
            >Zinc finger-homeobox gene family</a
          >
        </td>
        <td>2 families<br />17 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=person&communityID=1501421667"
            >Wei Hu</a
          >
        </td>
      </tr>
      <tr>
        <td>
          <a href="/browse/gene_family/ZFHD"
            >ZF-HD Transcription Factor Family</a
          >
        </td>
        <td>1 family<br />15 members</td>
        <td>
          <a
            target="display"
            href="/servlets/Community?action=view&type=organization&communityID=1501423724"
            >AGRIS</a
          >
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: "GeneFamilies",
  data() {
    return {
      test: "ab",
      geneFamilies: [
        {
          name: "14-3-3 family",
          count: "1 family / 13 members",
          submitter: "R Ferl Laboratory",
        },
        {
          name: "ABC Superfamily",
          count: "Subfamilies 8 / Members 136",
          submitter: "Paul Verrier et al.",
        },
        {
          name: "ABI3VP1 Transcription Factor Family",
          count: "1 family / 11 members",
          submitter: "AGRIS",
        },
        {
          name: "AGC Kinase Gene Family",
          count: "1 family / 39 members",
          submitter: "Laszlo Bogre et al.",
        },
        {
          name: "Aldehyde Dehydrogenase Gene Family",
          count: "9 families / 14 members",
          submitter: "Dorothea Bartels et al.",
        },
        {
          name: "Amino Acid/Auxin Permease AAAP Family",
          count: "1 family / 43 members",
          submitter: "John Ward",
        },
        {
          name: "Acyl Lipid Metabolism Gene Families",
          count: "174 families / 610 members",
          submitter: "Fred Beisson",
        },
        {
          name: "Alfin-like Transcription Factor Family",
          count: "1 family / 7 members",
          submitter: "AGRIS",
        },
        {
          name: "Antiporters",
          count: "13 families / 70 members",
          submitter: "John Ward",
        },
        {
          name: "Antiporter Super Family",
          count: "1 subfamily / 28 members",
          submitter: "Heven Sze et al.",
        },
        {
          name: "Aquaporins",
          count: "2 families / 35 members",
          submitter: "John Ward",
        },
        {
          name: "ARIADNE family",
          count: "3 subfamilies / 16 members",
          submitter: "Marie-Theres Hauser",
        },
        { name: "ARR-B", count: "1 family / 15 members", submitter: "AGRIS" },
        {
          name: "ARF Transcription Factor Family",
          count: "1 family / 24 members",
          submitter: "AGRIS",
        },
        {
          name: "ARID Transcription Factor Family",
          count: "1 family / 7 members",
          submitter: "AGRIS",
        },
        {
          name: "AP2-EREBP Transcription Factor Family",
          count: "1 family / 138 members",
          submitter: "AGRIS",
        },
        {
          name: "AS2 family",
          count: "1 family / 42 members",
          submitter: "Yasunori Machida",
        },
        {
          name: "AT-hook Motif Nuclear Localized (AHL) Family",
          count: "1 family / 29 members",
          submitter: "Jianfei Zhao et al.",
        },
        {
          name: "AtRKD Transcription Factor Family",
          count: "1 family / 5 members",
          submitter: "AGRIS",
        },
        {
          name: "B3 DNA Binding Superfamily",
          count: "1 family / 87 members",
          submitter: "Marcio Alves-Ferreira et al.",
        },
        {
          name: "BBR/BPC Transcription factor family",
          count: "1 family / 7 members",
          submitter: "Dierk Wanke",
        },
        {
          name: "bHLH Transcription Factor Family",
          count: "1 family / 161 members",
          submitter: "AGRIS",
        },
        {
          name: "Basic Helix-Loop-Helix Transcription Factor Gene Family",
          count: "1 family / 162 members",
          submitter: "Bernd Weisshaar",
        },
        {
          name: "Basic region leucine zipper (bZIP) Transcription Factor Gene Family",
          count: "1 family / 75 members",
          submitter: "Marc Jakoby",
        },
        {
          name: "bZIP Transcription Factor Family",
          count: "1 family / 73 members",
          submitter: "AGRIS",
        },
        {
          name: "BZR Transcription Factor Family",
          count: "1 family / 6 members",
          submitter: "AGRIS",
        },
        {
          name: "Calcium Dependent Protein Kinase",
          count: "1 family / 34 members",
          submitter: "J Sheen Laboratory",
        },
        {
          name: "Calmodulin-binding Proteins",
          count: "17 families / 100 members",
          submitter: "Vaka S Reddy et al.",
        },
        {
          name: "C2C2-CO-like Transcription Factor Family",
          count: "1 family / 30 members",
          submitter: "AGRIS",
        },
        {
          name: "C2C2-Dof Transcription Factor Family",
          count: "1 family / 36 members",
          submitter: "AGRIS",
        },
        {
          name: "C2C2-Gata Transcription Factor Family",
          count: "1 family / 30 members",
          submitter: "AGRIS",
        },
        {
          name: "C2C2-YABBY Transcription Factor Family",
          count: "1 family / 6 members",
          submitter: "AGRIS",
        },
        {
          name: "C2H2 Transcription Factor Family",
          count: "1 family / 211 members",
          submitter: "AGRIS",
        },
        {
          name: "C3H Transcription Factor Family",
          count: "1 family / 165 members",
          submitter: "AGRIS",
        },
        {
          name: "Carbohydrate Esterase",
          count: "5 families / 98 members",
          submitter: "B. Henrissat et al.",
        },
        {
          name: "CAMTA Transcription Factor Family",
          count: "1 family / 6 members",
          submitter: "AGRIS",
        },
        {
          name: "CBL-interacting serine-threonine protein kinases",
          count: "1 family / 25 members",
          submitter: "Joerg Kudla",
        },
        {
          name: "CCAAT-DR1 Transcription Factor Family",
          count: "1 family / 2 members",
          submitter: "AGRIS",
        },
        {
          name: "CCAAT-HAP2 Transcription Factor Family",
          count: "1 family / 10 members",
          submitter: "AGRIS",
        },
        {
          name: "CCAAT-HAP3 Transcription Factor Family",
          count: "1 family / 10 members",
          submitter: "AGRIS",
        },
        {
          name: "CCAAT-HAP5 Transcription Factor Family",
          count: "1 family / 13 members",
          submitter: "AGRIS",
        },
        {
          name: "Cell Wall Biosynthesis",
          count: "8 families / 52 members",
          submitter: "Todd Richmond",
        },
        {
          name: "Chloroplast and Mitochondria Gene Families",
          count: "16 families / 50 members",
          submitter: "John Ward",
        },
        {
          name: "COBRA Gene Family",
          count: "2 families / 12 members",
          submitter: "Philip Benfey",
        },
        {
          name: "Core Cell Cycle Gene Families",
          count: "7 families / 61 members",
          submitter: "Klaas Vandepoele",
        },
        {
          name: "Core DNA Replication Machinery Family",
          count: "1 family / 61 members",
          submitter: "Randall Shultz et al.",
        },
        {
          name: "CPP Transcription Factor Family",
          count: "1 family / 8 members",
          submitter: "AGRIS",
        },
        {
          name: "Cytochrome P450",
          count: "69 families / 256 members",
          submitter: "S. Bak et al.",
        },
        {
          name: "Cytochrome b5",
          count: "1 family / 7 members",
          submitter: "Soren Bak",
        },
        {
          name: "Cytoplasmic Ribosomal Protein Gene Family",
          count: "79 families / 255 members",
          submitter: "Julia Bailey-Serres",
        },
        {
          name: "Cytoskeleton Gene Families",
          count: "2 families / 15 members",
          submitter: "Richard Meagher",
        },
        {
          name: "DOF Gene Family",
          count: "1 family / 37 members",
          submitter: "Shuichi Yanagisawa",
        },
        {
          name: "E2F-DP Transcription Factor Family",
          count: "1 family / 8 members",
          submitter: "AGRIS",
        },
        {
          name: "EAR repressome family",
          count: "57 families / 403 members",
          submitter: "Kevin Rozwadowski",
        },
        {
          name: "EIL Transcription Factor Family",
          count: "1 family / 6 members",
          submitter: "AGRIS",
        },
        {
          name: "EF-hand Containing Proteins",
          count: "8 families / 234 members",
          submitter: "Irene S Day et al.",
        },
        {
          name: "Equilibrative Nucleoside Transporter Gene Family (ENT)",
          count: "1 family / 8 members",
          submitter: "Torsten Moehlmann",
        },
        {
          name: "Eukaryotic Initiation Factors Gene Family",
          count: "44 families / 100 members",
          submitter: "Karen Browning",
        },
        {
          name: "Expansin Gene Family",
          count: "4 families / 36 members",
          submitter: "Daniel Cosgrove",
        },
        {
          name: "EXO70 exocyst subunit family",
          count: "1 family / 23 members",
          submitter: "Viktor Zarsky",
        },
        {
          name: "F-Box Gene Family",
          count: "1 family / 4 members",
          submitter: "Claus Schwechheimer",
        },
        {
          name: "FH2 protein (formin) Gene Family",
          count: "1 family / 21 members",
          submitter: "Fatima Cvrckova",
        },
        {
          name: "FtsH Gene Family",
          count: "1 family / 12 members",
          submitter: "Adi Zaltsman",
        },
        {
          name: "G2-like Transcription Factor Family",
          count: "1 family / 40 members",
          submitter: "AGRIS",
        },
        {
          name: "GeBP Transcription Factor Family",
          count: "1 family / 16 members",
          submitter: "AGRIS",
        },
        {
          name: "Glutamine Dumper (GDU) Family",
          count: "1 family / 7 members",
          submitter: "Guillaume Pilot et al.",
        },
        {
          name: "Glycoside Hydrolase",
          count: "31 families / 379 members",
          submitter: "B. Henrissat et al.",
        },
        {
          name: "Glycosyltransferase",
          count: "28 families / 361 members",
          submitter: "B. Henrissat et al.",
        },
        {
          name: "GRAS Gene Family",
          count: "1 family / 33 members",
          submitter: "Cordelia Bolle",
        },
        {
          name: "GRAS Transcription Factor Family",
          count: "1 family / 33 members",
          submitter: "AGRIS",
        },
        {
          name: "GRF Transcription Factor Family",
          count: "1 family / 9 members",
          submitter: "AGRIS",
        },
        {
          name: "Glutathione S-transferase Gene Family",
          count: "7 families / 53 members",
          submitter: "Pia G. Sappl et al.",
        },
        {
          name: "Heat shock transcription factors Hsfs",
          count: "1 family / 21 members",
          submitter: "Lutz Nover",
        },
        {
          name: "Histidine Kinase Gene Family",
          count: "1 family / 16 members",
          submitter: "J Sheen Lab",
        },
        {
          name: "Histidine Phosphotransfer Proteins",
          count: "1 family / 6 members",
          submitter: "J Sheen Lab",
        },
        {
          name: "Homeobox Transcription Factor Family",
          count: "1 family / 91 members",
          submitter: "AGRIS",
        },
        {
          name: "HRT Transcription Factor Family",
          count: "1 family / 3 members",
          submitter: "AGRIS",
        },
        { name: "HSF", count: "1 family / 21 members", submitter: "AGRIS" },
        {
          name: "IDZ Gene Family",
          count: "1 family / 11 members",
          submitter: "Thomas Merkle",
        },
        {
          name: "IMA (Ironman) Gene Family",
          count: "1 family / 8 members",
          submitter: "Louis Grillet",
        },
        {
          name: "Inorganic Solute Cotransporters",
          count: "16 families / 84 members",
          submitter: "John Ward",
        },
        {
          name: "Ion Channels",
          count: "7 families / 61 members",
          submitter: "John Ward et al.",
        },
        {
          name: "IQD Protein Family",
          count: "1 family / 33 members",
          submitter: "Steffen Abel",
        },
        {
          name: "JUMONJI Transcription Factor Family",
          count: "1 family / 5 members",
          submitter: "AGRIS",
        },
        {
          name: "Kinesins gene family",
          count: "1 family / 61 members",
          submitter: "A. S. N. Reddy et al.",
        },
        {
          name: "Lateral Organ Boundaries Gene Family",
          count: "1 family / 43 members",
          submitter: "B. Shuai et al.",
        },
        {
          name: "Leucine-rich Repeat extension Gene Families",
          count: "2 families / 11 members",
          submitter: "Christoph Ringli",
        },
        {
          name: "Lipid Metabolism Gene Families",
          count: "28 families / 98 members",
          submitter: "John Ward",
        },
        {
          name: "LysM Receptor-like Kinase (LYKs) Gene Family",
          count: "1 family / 5 members",
          submitter: "Kiwamu Tanaka et al.",
        },
        {
          name: "LysM Receptor-like Protein (LYPs) Gene Family",
          count: "1 family / 3 members",
          submitter: "Kiwamu Tanaka et al.",
        },
        {
          name: "MADS Transcription Factor Family",
          count: "1 family / 109 members",
          submitter: "AGRIS",
        },
        {
          name: "MADS-box Gene Family",
          count: "1 family / 108 members",
          submitter: "Stefan de Folter",
        },
        {
          name: "MADS like Gene Family",
          count: "1 family / 16 members",
          submitter: "Stefanie De Bodt",
        },
        {
          name: "Type I MADS-box Gene Family",
          count: "1 family / 46 members",
          submitter: "Stefanie De Bodt",
        },
        {
          name: "Major Intrinsic Protein MIP",
          count: "1 family / 38 members",
          submitter: "John Ward",
        },
        {
          name: "MAP Kinase Gene Family",
          count: "1 family / 20 members",
          submitter: "J Sheen Lab",
        },
        {
          name: "MAP Kinase Kinase Gene Family",
          count: "1 family / 10 members",
          submitter: "J Sheen Lab",
        },
        {
          name: "MAP Kinase Kinase Kinase",
          count: "3 subfamilies / 80 members",
          submitter: "Laszlo Bogre et al.",
        },
        {
          name: "MAP Kinase Kinase Kinase Kinase",
          count: "1 family / 10 members",
          submitter: "Laszlo Bogre et al.",
        },
        {
          name: "Mechanosensitive Ion Channel Family",
          count: "1 family / 10 members",
          submitter: "Elizabeth Haswell",
        },
        {
          name: "Miscellaneous Gene Families",
          count: "108 families / 443 members",
          submitter: "John Ward",
        },
        {
          name: "MLO family",
          count: "1 family / 15 members",
          submitter: "Ralph Panstruga",
        },
        {
          name: "Monolignol Biosynthesis Gene Families",
          count: "1 family / 61 members",
          submitter: "Jereon Raes",
        },
        {
          name: "Monosaccharide Transporter(-like) Gene Family",
          count: "7 families / 53 members",
          submitter: "Norbert Sauer",
        },
        {
          name: "MRS2 Gene Family",
          count: "1 family / 11 members",
          submitter: "Volker Knoop et al.",
        },
        {
          name: "MYB family",
          count: "1 family / 133 members",
          submitter: "B Weisshaar Laboratory",
        },
        {
          name: "MYB Transcription Factor Family",
          count: "1 family / 131 members",
          submitter: "AGRIS",
        },
        {
          name: "MYB-Related Transcription Factor Family",
          count: "1 family / 2 members",
          submitter: "AGRIS",
        },
        {
          name: "Myosins gene family",
          count: "1 family / 17 members",
          submitter: "A. S. N. Reddy et al.",
        },
        {
          name: "NAC Transcription Factor Family",
          count: "1 family / 96 members",
          submitter: "AGRIS",
        },
        {
          name: "NADPH P450 reductases",
          count: "1 family / 3 members",
          submitter: "Soren Bak",
        },
        {
          name: "Nicotianamine Synthase Gene Family",
          count: "1 family / 4 members",
          submitter: "Petra Bauer et al.",
        },
        {
          name: "NLP Transcription Factor Family",
          count: "1 family / 9 members",
          submitter: "AGRIS",
        },
        {
          name: "Nodulin-like Gene Family",
          count: "3 families / 64 members",
          submitter: "John Ward",
        },
        {
          name: "Non-SMC Element family",
          count: "1 family / 2 members",
          submitter: "Koichi Watanabe",
        },
        {
          name: "Nucleobase Ascorbate Transporter Gene Family",
          count: "1 family / 12 members",
          submitter: "Ulf-Ingo Flugge",
        },
        {
          name: "Orphan Transcription Factor Family",
          count: "1 family / 2 members",
          submitter: "AGRIS",
        },
        {
          name: "Organic Solute Cotransporters",
          count: "35 families / 279 members",
          submitter: "John Ward",
        },
        {
          name: "Peroxidase, class III",
          count: "1 family / 73 members",
          submitter: "Michael Tognolli",
        },
        {
          name: "PHD Transcription Factor Family",
          count: "1 family / 11 members",
          submitter: "AGRIS",
        },
        {
          name: "PHD Gene Family",
          count: "1 subfamily / 5 members",
          submitter: "C Dean Laboratory",
        },
        {
          name: "Phospholipase D Gene Family (Zarsky group)",
          count: "2 subfamilies / 12 members",
          submitter: "V Zarsky Laboratory",
        },
        {
          name: "Phospholipase D Gene Family (Wang group)",
          count: "1 family / 12 members",
          submitter: "X. Wang",
        },
        {
          name: "Phytocyanin Superfamily",
          count: "1 superfamily / 22 members",
          submitter: "Kiyoshi Mashiguchi",
        },
        {
          name: "Plant Defensins Gene Families",
          count: "3 families / 15 members",
          submitter: "Bart Thomma",
        },
        {
          name: "Polysaccharide Lyase",
          count: "2 families / 35 members",
          submitter: "B. Henrissat et al.",
        },
        {
          name: "Pollen Coat Proteome",
          count: "4 families / 11 members",
          submitter: "J. A. Mayfield et al.",
        },
        {
          name: "PP2C-type Phosphatase Gene Family",
          count: "1 family / 76 members",
          submitter: "Irute Meskiene",
        },
        {
          name: "Primary Pumps ATPases",
          count: "12 families / 83 members",
          submitter: "John Ward",
        },
        {
          name: "Primary Pumps ATPases(2)",
          count: "2 subfamilies / 32 members",
          submitter: "Heven Sze et al.",
        },
        {
          name: "Protein tyrosine phosphatase (PTP) family",
          count: "1 family / 28 members",
          submitter: "Sheng Luan et al.",
        },
        {
          name: "PRT Gene Family",
          count: "4 families / 15 members",
          submitter: "Ralf Boldt",
        },
        {
          name: "Rapid Response to Elicitors",
          count: "1 family / 4 members",
          submitter: "Marta Berrocal-Lobo et al.",
        },
        {
          name: "RAV Transcription Factor Family",
          count: "1 family / 11 members",
          submitter: "AGRIS",
        },
        {
          name: "RAD5/RAD16-like Gene Family",
          count: "1 family / 10 members",
          submitter: "Holger Puchta",
        },
        {
          name: "RCI2 Gene Family",
          count: "1 family / 8 members",
          submitter: "Julio Salinas",
        },
        {
          name: "Receptor kinase-like Gene Family",
          count: "1 family / 307 members",
          submitter: "John Ward",
        },
        {
          name: "REM Transcription Factor Family",
          count: "1 family / 21 members",
          submitter: "AGRIS",
        },
        {
          name: "Response Regulator Gene Family",
          count: "3 families / 32 members",
          submitter: "J Sheen Lab",
        },
        {
          name: "SBP Transcription Factor Family",
          count: "1 family / 16 members",
          submitter: "AGRIS",
        },
        {
          name: "SBP-box Gene Family",
          count: "1 family / 17 members",
          submitter: "Peter Huijser",
        },
        {
          name: "Single Myb Histone (SMH) Gene Family",
          count: "1 family / 5 members",
          submitter: "Hank Bass",
        },
        {
          name: "SNARE and Associated Proteins",
          count: "27 families / 68 members",
          submitter: "Anton Sanderfoot",
        },
        {
          name: "Strubbelig Receptor Gene Family",
          count: "1 family / 9 members",
          submitter: "Kay Schneitz",
        },
        {
          name: "Structural Maintenance of Chromosomes family",
          count: "1 family / 3 members",
          submitter: "Koichi Watanabe",
        },
        {
          name: "Subtilisin-like Serine Gene Family",
          count: "1 family / 55 members",
          submitter: "Thomas Altman et al.",
        },
        {
          name: "Sucrose Transporter Gene Family",
          count: "1 family / 9 members",
          submitter: "Norbert Sauer",
        },
        {
          name: "SUVH1/3-interacting DNAJ domain-containing protein superfamily",
          count: "1 family / 3 members",
          submitter: "Xinjian He",
        },
        {
          name: "Su(var)3-9 homologs",
          count: "1 family / 2 members",
          submitter: "Xinjian He",
        },
        {
          name: "Sulfurtransferasese/Rhodanese",
          count: "1 family / 18 members",
          submitter: "Jutta Papenbrock",
        },
        {
          name: "TCP Transcription Factor Family",
          count: "1 family / 24 members",
          submitter: "Pilar Cubas",
        },
        {
          name: "Tify Gene Family",
          count: "1 family / 18 members",
          submitter: "Lieve Gheysen",
        },
        {
          name: "Trehalose Biosynthesis Gene Families",
          count: "2 families / 12 members",
          submitter: "Barbara Leyman",
        },
        {
          name: "Trihelix Transcription Factor Family",
          count: "1 family / 29 members",
          submitter: "AGRIS",
        },
        {
          name: "TRM (TON1 Recruiting Motif) Superfamily",
          count: "1 family / 34 members",
          submitter: "David Bouchez",
        },
        {
          name: "TUB Transcription Factor Family",
          count: "1 family / 10 members",
          submitter: "AGRIS",
        },
        {
          name: "Plant U-box Gene Family",
          count: "1 family / 64 members",
          submitter: "Marco Trujillo",
        },
        {
          name: "U-box Gene Family",
          count: "1 family / 61 members",
          submitter: "Ken Shirasu",
        },
        {
          name: "VOZ-9 Transcription Factor Family",
          count: "1 family / 2 members",
          submitter: "AGRIS",
        },
        {
          name: "Whirly Transcription Factor Family",
          count: "1 family / 3 members",
          submitter: "AGRIS",
        },
        {
          name: "Wox Gene Family",
          count: "1 family / 14 members",
          submitter: "Thomas Laux",
        },
        {
          name: "WRKY Transcription Factor Superfamily",
          count: "8 families / 74 members",
          submitter: "Imre Somssich",
        },
        {
          name: "WRKY Transcription Factor Family",
          count: "1 family / 72 members",
          submitter: "AGRIS",
        },
        {
          name: "Xyloglucan Fucosyltransferase1",
          count: "1 family / 13 members",
          submitter: "N Raikhel Laboratory et al.",
        },
        {
          name: "Zinc finger-homeobox gene family",
          count: "2 families / 17 members",
          submitter: "Wei Hu",
        },
        {
          name: "ZF-HD Transcription Factor Family",
          count: "1 family / 15 members",
          submitter: "AGRIS",
        },
      ],
    };
  },
};
</script>

<style scoped>
.gene-families table {
  /* Table style */
  width: 100%;
  border-collapse: collapse;
}

.gene-families th,
.gene-families td {
  /* Table header and cell styles */
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.gene-families tr:nth-child(even) {
  background-color: #f2f2f2;
}

.gene-families tr:hover {
  background-color: #ddd;
}
.container {
  width: 100vw; /* 100% of the viewport width */
  padding: 20px; /* Optional: adjust as needed */
  box-sizing: border-box; /* Includes padding in height and width */
}
</style>
