<template>
    <div>
      <div v-if="!error" class="detail-wrapper">
        <div class="side-wrapper">
          <ul class="list-group list-group-flush">
            <a
              v-for="(col, i) in side_cols"
              :key="i"
              href="#"
              class="list-group-item list-group-item-action flex-column align-items-start side-item"
              v-bind:class="{ selected: i == colIdxSelected }"
              @click="sideColClicked(i)"
            >
              {{ col }}
            </a>
          </ul>
        </div>
        <div class="list-wrapper" v-on:scroll.passive="handleScroll">
          <div
            v-if="loading"
            class="d-flex justify-content-center align-items-center"
            style="height: 100%"
          >
            <b-spinner label="Loading..."></b-spinner>
          </div>
          <div
            v-if="json_data"
            class="text-left m-2 font-weight-bold fixed-title"
          >
            <h4>Analysis Reference: {{ json_data.name }}</h4>
          </div>
          <BaseEntry
            v-for="(e, i) in entries"
            :selectedColIdx="colIdxSelected"
            :currIdx="i"
            :key="i"
            :content="e"
            :ref="`entry` + i"
          ></BaseEntry>
        </div>
      </div>
      <div v-else>
        <PageNotFound />
      </div>
    </div>
  </template>
  
  <script>
  import BaseEntry from "@/components/detail/BaseEntry";
  import PostsService from "@/services/PostsService";
  import PageNotFound from "@/components/common/PageNotFound";
  
  export default {
    name: "AnalysisReferenceDetail",
    components: {
      BaseEntry,
      PageNotFound,
    },
    data() {
      return {
        json_data: null,
        side_cols: [
          "Summary",
          "External Links"
        ],
        loading: false,
        error: false,
        colIdxSelected: 0,
        entries: [],
        entryPosys: [],
        helpTexts: {},
      };
    },
  
    async mounted() {
      this.loading = true;
      document.title = "Analysis Reference Detail";
      let jsonId = this.$route.query.key;
      
      if (jsonId) {
        try {
          this.loading = true;
          let response = await PostsService.getAnalysisReferenceDetail({ key: jsonId });
          this.json_data = response.data;
  
          this.processEntries();
          this.loading = false;
          document.title = `Analysis Reference Detail`;
        } catch (error) {
          console.error("Error fetching analysis reference details: ", error);
          this.loading = false;
          this.error = true;
        }
      } else {
        this.loading = false;
        this.error = true;
      }
    },
    methods: {
      sideColClicked(colIdx) {
        this.scrollToElement(colIdx);
        this.colIdxSelected = colIdx;
      },
      processEntries() {
        this.side_cols.forEach((title) => {
          let entry = {
            title: title,
            name: title.replace(/\s/g, ""),
          };
          switch (title) {
            case "Summary":
              entry.type = "bands";
              entry.bands = this.fillSummary();
              break;
            case "External Links":
              entry.type = "bands";
              entry.bands = this.fillExternalLinks();
              break;
            default:
              entry.type = "bands";
              entry.bands = [];
              break;
          }
          this.entries.push(entry);
        });
      },
      fillSummary() {
        let bands = [];
        bands.push({
            key: "Name",
            text: this.json_data.name,
        });
        bands.push({
            key: "Title",
            text: this.json_data.name,
        });
        bands.push({
            key: "TAIR accession",
            text: 'AnalysisReference: ' + this.json_data.referenceId,
        });
        bands.push({
            key: "Parameters",
            text: this.json_data.parameters,
        });
        bands.push({
            key: "Output Criteria",
            text: this.json_data.outputDefinitionCriteria,
        });
        bands.push({
            key: "Analysis Date",
            text: this.formatDate(this.json_data.dateRun),
        });
        bands.push({
            key: "Nucleotide Analysis",
            text: this.json_data.isNucleotide ? "True" : "False",  // Assuming 'isNucleotide' is a boolean
        });
        bands.push({
            key: "Protein Analysis",
            text: this.json_data.isPeptide ? "True" : "False",  // Assuming 'isPeptide' is a boolean
        });
        bands.push({
            key: "Software Program",
            text: this.json_data.programName,
        });
        bands.push({
            key: "Software Description",
            text: this.json_data.description,
        });
        bands.push({
            key: "Software Function",
            text: this.json_data.softwareFunction,
        });
        bands.push({
            key: "Software Source",
            text: this.json_data.source,
        });
        bands.push({
            key: "Software Author",
            text: this.json_data.author,
        });
        bands.push({
            key: "Software Release Date",
            text: this.formatDate(this.json_data.dateReleased),
        });
        bands.push({
            key: "Software Version",
            text: this.json_data.version,
        });
        return bands;
      },
      fillExternalLinks() {
        let bands = [];
        bands.push(this.getExternalLinks());
        return bands;
      },
      getExternalLinks() {
            let entry = {
                key: "External Link",
                type: "links",
                items: [],
            };
            let links = [];
            if (this.json_data.websites) {
                for (let l of this.json_data.websites) {
                    let url = l.url;
                    let websiteName = l.websiteName || url; // Fallback for null website names
                    let link = {
                        name: websiteName,
                        link: url,
                    };
                    links.push(link);
                }
            }
            entry.items.push({
                title: "Links",
                links: links,
            });
            return entry;
        },
      formatDate(dateStr) {
        if (!dateStr) return "Not specified";  // Handle undefined or null date
        const date = new Date(dateStr);
        return date.toISOString().split('T')[0];  // Extracts date part before the 'T'
      },
      dateToYMD(dateTs) {
          if (!dateTs) {
              return "Not specified";
          }
          let date = new Date(dateTs);
          const d = date.getDate();
          const m = date.getMonth() + 1;
          const y = date.getFullYear();
          return `${y}-${m.toString().padStart(2, "0")}-${d.toString().padExpand(2, "0")}`;
      },
      scrollToElement(colIdx) {
        const entryRef = this.$refs["entry" + colIdx];
        const el = entryRef[0].$el;
        if (el) {
          const top = el.offsetTop - 210;
          el.parentElement.scrollTo(0, top);
        }
      },
      handleScroll(e) {
        const scrollTop = e.target.scrollTop + 215;
        let currTopEntry = 0;
        this.entryPosys.forEach((e, i) => {
          if (scrollTop > e) {
            currTopEntry = i;
          }
        });
        this.colIdxSelected = currTopEntry;
      },
    },
  };
  </script>
  
  <style scoped lang="scss">
  .fixed-title {
    position: sticky;
    top: 0;
    background-color: #f1efec;
    z-index: 10;
    padding-left: 10px;
    padding-top: 10px;
  }
  
  .my-custom-scrollbar {
    position: relative;
    height: 200px;
    overflow: auto;
  }
  .table-wrapper-scroll-y {
    display: block;
  }
  </style>
  