<template>
  <div>
    <b-form-textarea
      :class="sizeClass"
      :placeholder="placeholder"
      v-model="textareaValue"
      :id="id"
      :aria-label="id"
      @input="this.updateInput"
    ></b-form-textarea>
    <input
      type="file"
      id="fileInput"
      @change="handleFileUpload"
      aria-label="fileInput"
      style="display: none"
    />
    <b-button size="sm" @click="triggerFileInputClick" class="mt-2"
      >Upload</b-button
    >
  </div>
</template>

<script>
export default {
  props: {
    placeholder: String,
    value: [String, Number],
    id: String,
    size: {
      type: String,
      default: "medium", // Default size if none is provided
      validator: function (value) {
        return ["small", "medium", "large"].includes(value);
      },
    },
  },
  data() {
    return {
      textareaValue: this.value,
    };
  },
  computed: {
    sizeClass() {
      return {
        "input-small": this.size === "small",
        "input-medium": this.size === "medium",
        "input-large": this.size === "large",
      };
    },
  },
  methods: {
    triggerFileInputClick() {
      event.stopPropagation();
      this.$nextTick(() => {
        document.getElementById("fileInput").click();
      });
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.textareaValue = e.target.result;
          this.updateInput(this.textareaValue);
        };
        reader.readAsText(file);
      }
    },
    updateInput(event) {
      this.$emit("input", event);
    },
  },
};
</script>

<style scoped>
.input-small {
  width: 60px; /* Example size, adjust as needed */
}

.input-medium {
  width: 100px; /* Example size, adjust as needed */
}

@media (min-width: 768px) {
  .input-medium {
    width: 18vw;
  }
}

.input-large {
  width: 90%; /* Example size, adjust as needed */
}

@media (min-width: 768px) {
  .input-large {
    width: 20vw;
  }
}
</style>
