<template>
  <div class="d-flex pointer-cursor text-left" @click="handleClick">
    <div v-if="data.field.helpTxt" class="mr-2">
      <b-tooltip :target="'help-' + data.field.label" triggers="hover">
        <p
          v-html="data.field.helpTxt"
          class="overflow-div d-flex flex-column text-left"
        ></p>
      </b-tooltip>
      <b-icon
        class="ml-2"
        :id="'help-' + data.field.label"
        icon="question-circle-fill"
      ></b-icon>
    </div>
    <span>{{ data.field.label }}</span>

    <!-- Sortable Icon -->
    <b-icon icon="arrow-down-up" class="ml-1" style="padding-top: 3px"></b-icon>
  </div>
</template>

<script>
import { BIcon, BIconArrowsUpDown } from "bootstrap-vue";
export default {
  components: {
    BIcon,
    BIconArrowsUpDown,
  },
  props: ["data"],
  methods: {
    handleClick() {
      this.$emit("header-clicked", this.data.field.key);
    },
  },
};
</script>

<style scoped>
.overflow-div {
  max-height: 200px;
  overflow: scroll;
}
.pointer-cursor {
  cursor: pointer;
  transition: background-color 0.2s ease; /* Transition effect for hover */
}

.pointer-cursor:hover {
  background-color: #f5f5f5; /* Slight gray background on hover for feedback */
}
</style>
