import PostsService from "../PostsService";
import { getMapElementViewUrl } from "../JBrowseService";

export async function loadResults(search_params, type = "detail") {
  let fields = [
    { key: "row_num", label: " # " },
    { key: "selected", label: "" },
    {
      key: "name",
      label: "Marker Name",
      cellType: "name_link",
    },
    {
      key: "aliases",
      label: "Aliases",
      cellType: "name_link",
    },
    {
      key: "type",
      label: "Type",
      cellType: "name_link",
    },
    {
      key: "chrom",
      label: "Chrom",
      cellType: "name_link",
    },
    {
      key: "start_posn",
      label: "Start Position",
      cellType: "name_link",
    },
    {
      key: "map_links",
      label: "Map Links",
      cellType: "name_links_multi",
    },
  ];
  let items = [];
  try {
    let response = await PostsService.fetchMarkerSearch(search_params);
    if (response.data.error) {
      return { error: true, items: [], fields: [] };
    }
    let docs = response.data.docs;

    let totalMatch = response.data.total;

    for (const d of docs) {
      let jbrowseLink = "";
      if (d.start_position) {
        jbrowseLink = getMapElementViewUrl(d.chrom, d.start_position, d.end_position, "marker");
      }
      items.push({
        id: d.id,
        selected: false,
        name: {
          name: d.marker_name[0],
          link: `/marker?key=${d.markerId}`,
        },
        aliases: {
          name: d.aliases ? d.aliases.join(";") : "",
        },
        type: {
          name: d.type,
        },
        chrom: {
          name: d.chrom,
        },
        start_posn: {
          name: d.start_position ? `${d.start_position} bp` : "",
        },
        map_links: [
          {
            name: d.start_position ? "JBrowse" : "",
            externalLink: jbrowseLink,
          },
        ],
        originalRow: d,
      });
    }
    search_params.matchCount = totalMatch;

    let queryStatement = "";
    if (type === "general") {
      queryStatement = `Your query for genetic markers where name or marker type contains the term <span class="highlight-term">${search_params.searchText}</span> resulted in <span style="color: green">${totalMatch}</span> matches`;
    } else {
      queryStatement = setQueryStatement(search_params);
    }
    return {
      error: false,
      items: items,
      fields: fields,
      queryStatement: queryStatement,
    };
  } catch (error) {
    return { error: true, items: [], fields: [] };
  }
}
function setQueryStatement(params) {
  let queryStatement = `Your query for genetic markers records where `;
  let queryParts = [];
  let searchParts = [];
  params.searchTerms.forEach((s) => {
    if (s.search_input) {
      searchParts.push(
        `${s.search_type} ${s.search_method} <span class="highlight-term">${s.search_input}</span>`
      );
    }
  });
  if (searchParts.length > 0) {
    queryParts.push(searchParts.join(" and "));
  }
  let restrictParts = [];
  params.restrictByFeatures.forEach((feat) => {
    for (let key in feat) {
      let value = feat[key];
      if (Array.isArray(value)) {
        value = value.join(" or ");
      }
      restrictParts.push(
        `${key} is <span class="highlight-term">${value}</span>`
      );
    }
  });

  if (restrictParts.length > 0) {
    queryParts.push(restrictParts.join(" and "));
  }

  let mapParts = [];
  params.mapLocation &&
    params.mapLocation.forEach((map) => {
      if (map.chromosome) {
        mapParts.push(
          `chromosome is <span class="highlight-term">${map.chromosome}</span>`
        );
      }
      if (map.high_range && map.low_range) {
        mapParts.push(
          `AGI map location is between <span class="highlight-term">${map.low_range}</span> kb and <span class="highlight-term">${map.high_range}</span> kb`
        );
      }
    });

  if (mapParts.length > 0) {
    queryParts.push(mapParts.join(mapParts.length > 1 ? " and " : ""));
  }
  queryStatement += queryParts.join(" and ");
  queryStatement += ` resulted in <span style="color: green">${params.matchCount}</span> matches`;
  return queryStatement;
}
