import DetailView from "@/views/detail/DetailView.vue";

import CloneDetail from "@/views/detail/CloneDetail";
import CloneEndDetail from "@/views/detail/CloneEndDetail";
import EcotypeDetail from "@/views/detail/EcotypeDetail";
import MarkerDetail from "@/views/detail/MarkerDetail";
import HostStrainDetail from "@/views/detail/HostStrainDetail";
import LibraryDetail from "@/views/detail/LibraryDetail";
import LocusDetail from "@/views/detail/LocusDetail";
import GeneDetail from "@/views/detail/GeneDetail";
import GermplasmDetail from "@/views/detail/GermplasmDetail";
import PolymorphismDetail from "@/views/detail/PolymorphismDetail";
import KeywordDetail from "@/views/detail/KeywordDetail";
import PersonDetail from "@/views/detail/PersonDetail";
import OrganizationDetail from "@/views/detail/OrganizationDetail";
import ProteinDetail from "@/views/detail/ProteinDetail";
import ProtocolDetail from "@/views/detail/ProtocolDetail";
import PublicationDetail from "@/views/detail/PublicationDetail";
import TransposonDetail from "@/views/detail/TransposonDetail";
import TransposonFamilyDetail from "@/views/detail/TransposonFamilyDetail";
import VectorDetail from "@/views/detail/VectorDetail";
import SymbolDetail from "@/views/detail/SymbolDetail";
import MicroarrayElementDetail from "@/views/detail/MicroarrayElementDetail";
import SequenceDetail from "@/views/detail/SequenceDetail";
import AnalysisReferenceDetail from "@/views/detail/AnalysisReferenceDetail";
import CommunicationDetail from "@/views/detail/CommunicationDetail";

export const detailRoutes = {
  path: "/",
  name: "DetailView",
  component: DetailView,
  children: [
    {
      path: "/clone",
      name: "CloneDetail",
      component: CloneDetail,
    },
    {
      path: "/cloneend",
      name: "CloneEndDetail",
      component: CloneEndDetail,
    },
    {
      path: "/ecotype",
      name: "EcotypeDetail",
      component: EcotypeDetail,
    },
    {
      path: "/locus",
      name: "LocusDetail",
      component: LocusDetail,
    },
    {
      path: "/marker",
      name: "MarkerDetail",
      component: MarkerDetail,
    },
    {
      path: "/gene",
      name: "GeneDetail",
      component: GeneDetail,
    },
    {
      path: "/germplasm",
      name: "GermplasmDetail",
      component: GermplasmDetail,
    },
    {
      path: "/hoststrain",
      name: "HostStrainDetail",
      component: HostStrainDetail,
    },
    {
      path: "/library",
      name: "LibraryDetail",
      component: LibraryDetail,
    },
    {
      path: "/person",
      name: "PersonDetail",
      component: PersonDetail,
    },
    {
      path: "/organization",
      name: "OrganizationDetail",
      component: OrganizationDetail,
    },
    {
      path: "/polyallele",
      name: "PolymorphismDetail",
      component: PolymorphismDetail,
    },
    {
      path: "/protein",
      name: "ProteinDetail",
      component: ProteinDetail,
    },
    {
      path: "/protocol",
      name: "ProtocolDetail",
      component: ProtocolDetail,
    },
    {
      path: "/publication",
      name: "PublicationDetail",
      component: PublicationDetail,
    },
    {
      path: "/transposon",
      name: "TransposonDetail",
      component: TransposonDetail,
    },
    {
      path: "/transposonfamily",
      name: "TransposonFamilyDetail",
      component: TransposonFamilyDetail,
    },
    {
      path: "/keyword",
      name: "KeywordDetail",
      component: KeywordDetail,
    },
    {
      path: "/vector",
      name: "VectorDetail",
      component: VectorDetail,
    },
    {
      path: "/genesymbol",
      name: "SymbolDetail",
      component: SymbolDetail,
    },
    {
      path: "/sequence",
      name: "SequenceDetail",
      component: SequenceDetail,
    },
    {
      path: "/array_element",
      name: "MicroarrayElementDetail",
      component: MicroarrayElementDetail,
    },
    {
      path: "/analysisreference",
      name: "AnalysisReferenceDetail",
      component: AnalysisReferenceDetail,
    },
    {
      path: "/communication",
      name: "CommunicationDetail",
      component: CommunicationDetail,
    }
  ],
};
