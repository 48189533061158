<template>
  <div class="page-container">
    <div v-if="loading" class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <div v-if="error" class="alert alert-danger" role="alert">
      {{ error }}
    </div>
    <div class="list-wrapper">
      <div class="form-container edit-form">
        <h2>Edit Organization</h2>
        <form @submit.prevent="saveOrganization">
          <div class="input-group">
            <label for="name">Name:</label>
            <input
              type="text"
              id="name"
              v-model="organization.name"
              class="form-control"
              required
            />
          </div>
          <div class="input-group">
            <label for="address">Address:</label>
            <textarea
              id="address"
              v-model="formattedAddress"
              class="form-control"
              required
            ></textarea>
          </div>
          <div class="input-group">
            <label for="city">City:</label>
            <textarea
              id="city"
              v-model="organization.city"
              class="form-control"
              required
            ></textarea>
          </div>
          <div class="input-group">
            <label for="region">State:</label>
            <textarea
              id="region"
              v-model="organization.region"
              class="form-control"
              required
            ></textarea>
          </div>
          <div class="input-group">
            <label for="postalCode">Zipcode:</label>
            <textarea
              id="postalCode"
              v-model="organization.postalCode"
              class="form-control"
              required
            ></textarea>
          </div>
          <div class="input-group">
            <label for="country">Country:</label>
            <textarea
              id="country"
              v-model="organization.country"
              class="form-control"
              required
            ></textarea>
          </div>
          <div v-if="errorMessage" class="alert alert-danger" role="alert">
            {{ errorMessage }}
          </div>
          <div v-if="successMessage" class="alert alert-success" role="alert">
            {{ successMessage }}
          </div>
          <button type="submit" class="btn btn-primary" :disabled="loading">
            <span
              v-if="loading"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span v-if="!loading">Save Organization</span>
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import PostsService from "@/services/PostsService";
import Api from "@/services/Api";

export default {
  name: "EditOrganization",
  data() {
    return {
      organization: {
        name: "",
        address: "",
        city: "",
        region: "",
        postalCode: "",
        country: "",
        communityId: "",
      },
      error: "",
      loading: false,
      successMessage: "",
      errorMessage: "",
    };
  },
  async mounted() {
    await this.fetchOrganization();
  },
  computed: {
    formattedAddress: {
      get() {
        if (!this.organization.address) return "";
        // Display the address with newlines in the textarea
        return this.organization.address.replace(/\\/g, "\n");
      },
      set(value) {
        if (value) {
          // Convert newlines back to backslashes before saving
          this.organization.address = value.replace(/\n/g, "\\");
        }
      },
    },
  },
  methods: {
    async fetchOrganization() {
      const jsonId = this.$route.query.key;
      if (jsonId) {
        this.loading = true;
        try {
          const response = await PostsService.getOrganizationDetail({
            key: jsonId,
          });
          this.organization.communityId = response.data.communityId;
          this.organization.name = response.data.name;
          this.organization.address = response.data.address;
          this.organization.city = response.data.city;
          this.organization.region = response.data.region;
          this.organization.city = response.data.city;
          this.organization.postalCode = response.data.postalCode;
          this.organization.country = response.data.country;
          this.loading = false;
        } catch (error) {
          this.error = "Error fetching data.";
          this.loading = false;
        }
      }
    },
    async saveOrganization() {
      this.loading = true;
      this.successMessage = "";
      this.errorMessage = "";
      try {
        const response = await Api().put(
          "organization/update-organization",
          this.organization
        );
        this.successMessage = "Organization details updated successfully"; // Update success message
        this.loading = false;
        setTimeout(() => {
          this.$router.push({
            path: `/organization`,
            query: { key: this.organization.communityId.toString() },
          });
          this.successMessage = "";
        }, 2000); // 1000 milliseconds = 1 second
      } catch (error) {
        this.errorMessage = "Failed to update the organization."; // Update error message
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  box-sizing: border-box;
  height: auto; /* Adjusted from 700px for better fit */
  overflow-y: auto; /* Ensures scrollability if needed */
}

.list-wrapper {
  width: 100%;
  max-width: 800px; /* Max width for the forms */
  margin: 0 auto; /* Center the list wrapper */
  overflow-y: auto; /* Add scroll to the entire page */
  padding: 20px; /* Padding for aesthetic spacing */
  box-sizing: border-box; /* Include padding and border in the width */
}

.form-container {
  border: 1px solid #ccc;
  padding: 20px;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px; /* Provides spacing between multiple forms if present */
}

.edit-form {
  width: 100%; /* Ensures the form uses the full width of .form-container */
}

.input-group {
  margin-bottom: 20px;
}

.input-group label {
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
}

.input-group input,
.input-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box; /* Ensures padding doesn't add to width */
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
}

.btn-primary:hover {
  background-color: #0056b3;
  border-color: #0056b3;
}

/* Error message styles */
.error-message {
  color: #dc3545; /* Red for errors */
  margin-top: 5px;
}

/* Loading spinner style */
.spinner-border {
  width: 3rem;
  height: 3rem;
  display: inline-block;
  border-width: 0.25em;
}
.spinner-border-sm {
  width: 1rem; /* Smaller spinner size */
  height: 1rem; /* Smaller spinner size */
  border-width: 0.2em;
}

/* Alert styles */
.alert {
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid transparent;
  border-radius: 4px;
}

.alert-danger {
  background-color: #f8d7da;
  color: #721c24;
  border-color: #f5c6cb;
}

.alert-success {
  background-color: #d4edda;
  color: #155724;
  border-color: #c3e6cb;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .form-container {
    width: 90%; /* Adjusts width for smaller screens */
  }
}
</style>
