<template>
  <div class="mutant-phenotype-symbol-registration-container">
    <div class="mutant-phenotype-symbol-registration">
      <h2 v-if="symbolType === 'Mutant Phenotype Symbol'">
        Mutant Phenotype Symbol Registration
      </h2>
      <h2 v-else>Gene Product Symbol Registration</h2>
      <br />
      <form @submit.prevent="submitMutantForm" class="form-container">
        <div class="non-input-group">
          <label for="geneClassSymbol"> Gene Class Symbol: </label>
          <span id="geneClassSymbol">{{ geneClassSymbol }}</span>
        </div>

        <div class="input-group">
          <label for="fullName">Full Name: *</label>
          <input
            id="fullName"
            v-model="fullName"
            required
            placeholder="e.g., AGAMOUS for the Mutant Symbol or AGAMOUS-like for Gene Product"
          />
        </div>

        <div
          class="input-group"
          v-if="symbolType === 'Mutant Phenotype Symbol'"
        >
          <label for="phenotypeClass">Phenotype Class: *</label>
          <input
            id="phenotypeClass"
            v-model="phenotypeClass"
            required
            placeholder="e.g., altered leaf morphology"
          />
        </div>

        <div class="input-group">
          <label for="additionalDetails">Additional Details: </label>
          <textarea
            id="additionalDetails"
            v-model="additionalDetails"
            placeholder="Enter any additional details here"
          ></textarea>
        </div>

        <div class="input-group">
          <label for="researchLab"
            >Research Lab: (Must exist in our database)</label
          >
          <input
            id="researchLab"
            v-model="researchLab"
            @input="fetchLabs"
            @focus="isFocused = true"
            @blur="handleBlur"
            placeholder="Start typing lab name..."
          />
          <ul
            v-if="labSuggestions.length && isFocused"
            class="suggestions-dropdown"
          >
            <p
              v-for="lab in labSuggestions"
              :key="lab.id"
              @mousedown.prevent="fillLabName(lab.name)"
            >
              {{ lab.name }}
            </p>
          </ul>
        </div>

        <div class="input-group">
          <label for="numberOfAssociatedLoci"
            >Number of Associated Loci: *</label
          >
          <select id="numberOfAssociatedLoci" v-model="numberOfAssociatedLoci">
            <option value="">Select one</option>
            <option value="1">Symbol Describes 1 Gene</option>
            <option value="2">Symbol Describes > 1 Gene</option>
            <option value="3">Number of Genes Unknown</option>
          </select>
        </div>

        <!-- Conditional input for selecting multiple genes -->
        <div
          class="input-group"
          v-if="['1', '2'].includes(numberOfAssociatedLoci)"
        >
          <label for="genesSearch">Associate Loci:</label>
          <input
            type="text"
            id="genesSearch"
            v-model="geneSearchQuery"
            @input="fetchGeneSearchResults"
            placeholder="enter AGI id/s"
          />
          <ul class="suggestions-dropdown" v-if="geneSearchResults.length">
            <p
              v-for="result in geneSearchResults"
              :key="result.id"
              @click="selectGene(result)"
            >
              {{ result.name }}
            </p>
          </ul>
        </div>

        <!-- Display selected genes -->
        <div v-if="selectedGenes.length" class="selected-genes">
          <span
            v-for="(gene, index) in selectedGenes"
            :key="index"
            @click="removeGene(index)"
          >
            {{ gene.name }}
          </span>
        </div>

        <!-- Conditional input for selecting publications by DOI or PubMed ID -->
        <div class="input-group">
          <label for="publicationSearch">Associate Publications:</label>
          <input
            type="text"
            id="publicationSearch"
            v-model="publicationSearchQuery"
            @input="fetchPublicationSearchResults"
            placeholder="enter DOI or Pubmed ID"
          />
          <ul
            class="suggestions-dropdown"
            v-if="publicationSearchResults.length"
          >
            <li
              v-for="result in publicationSearchResults"
              :key="result.reference_id"
              @click="selectPublication(result)"
            >
              {{ result.title }} ({{ result.doi || result.pubmed_id }})
            </li>
          </ul>
        </div>

        <!-- Display selected publications -->
        <div v-if="selectedPublications.length" class="selected-publications">
          <span
            v-for="(publication, index) in selectedPublications"
            :key="index"
            @click="removePublication(index)"
          >
            {{ publication.title }} ({{
              publication.doi || publication.pubmed_id
            }})
          </span>
        </div>

        <div class="input-group">
          <label for="comments">Comments: </label>
          <textarea
            id="comments"
            v-model="comments"
            placeholder="visible only to curators"
          ></textarea>
        </div>

        <div class="form-buttons">
          <button type="reset" class="reset-btn">Reset</button>
          <button type="submit" class="submit-btn" :disabled="!isFormValid">
            Submit
          </button>
        </div>

        <p v-if="successMessage" class="success-message">
          {{ successMessage }}
        </p>
        <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
      </form>
    </div>
  </div>
</template>

<script>
import Api from "@/services/Api";
import { mapGetters } from "vuex";

export default {
  name: "GeneClassSymbolDetailRegistrationForm",
  data() {
    return {
      geneClassSymbol: "",
      fullName: "",
      phenotypeClass: "",
      additionalDetails: "",
      numberOfAssociatedLoci: "",
      comments: "",
      researchLab: "",
      labSuggestions: [],
      isFocused: false,
      labSelected: false,
      symbolType: "",
      geneSearchQuery: "",
      geneSearchResults: [],
      selectedGenes: [],
      publicationSearchQuery: "",
      publicationSearchResults: [],
      selectedPublications: [],
      successMessage: "",
      errorMessage: "",
    };
  },
  created() {
    if (this.$route.query.geneClassSymbol) {
      this.geneClassSymbol = this.$route.query.geneClassSymbol;
    }
    this.symbolType = this.$route.query.symbolType || "";
  },
  computed: {
    isFormValid() {
      // Check if geneClassSymbol and fullName are not empty
      const basicValid =
        this.geneClassSymbol.trim() &&
        this.fullName.trim() &&
        this.numberOfAssociatedLoci.trim();

      // If symbolType is 'Mutant Phenotype Symbol', also check phenotypeClass
      if (this.symbolType === "Mutant Phenotype Symbol") {
        return basicValid && this.phenotypeClass.trim();
      }

      return basicValid;
    },
  },
  methods: {
    ...mapGetters({
      getCommunityId: "authProfile/getCommunityId",
      getFirstName: "authProfile/getFirstName",
      getLastName: "authProfile/getLastName",
    }),
    async submitMutantForm() {
      // Gather form data using 'this' to access Vuex getters
      let firstName = this.getFirstName();
      let lastName = this.getLastName();
      let submitterInfo = firstName + " " + lastName;
      const formData = {
        geneClassSymbol: this.geneClassSymbol,
        fullName: this.fullName,
        phenotypeClass: this.phenotypeClass,
        additionalDetails: this.additionalDetails,
        numberOfAssociatedLoci: this.numberOfAssociatedLoci,
        comments: [
          this.comments,
          `        Research Lab: ${this.researchLab}`,
          `        Associated Loci: ${this.selectedGenes
            .map((gene) => `${gene.name} (ID: ${gene.geneId})`)
            .join(", ")}`,
          `        Associated Publications: ${this.selectedPublications
            .map((pub) => `${pub.title} (Reference ID: ${pub.referenceId})`)
            .join(", ")}`,
        ].join("\n"),
        submitterInfo: submitterInfo,
        communityId: this.getCommunityId(),
      };

      // Log the formData to ensure it's correctly structured

      try {
        // Make the API call
        const response = await Api().post(
          "gene_class_symbol/updateGeneSymbolClass",
          formData
        );

        // Handle success

        // Optionally, redirect the user or show a success message
        this.successMessage =
          "Form submitted successfully and a curator will review it shortly!"; // Set success message
        this.errorMessage = ""; // Clear any previous error message

        setTimeout(() => {
          this.$router.push("/submit/gene_class_symbol_registration"); // Change '/path-to-redirect' to your actual path
        }, 5000); // 5000 milliseconds = 5 seconds
      } catch (error) {
        console.error("Error submitting form:", error);
        this.errorMessage = "Error submitting form. Please try again."; // Set error message
        this.successMessage = ""; // Clear any previous success message
      }
    },

    async fetchLabs() {
      this.isFocused = true; // Ensure the dropdown is shown when typing
      if (this.researchLab.length >= 2) {
        try {
          const response = await Api().get("labs/search", {
            params: { labName: this.researchLab },
          });
          if (response.status === 200 && response.data.rows) {
            this.labSuggestions = response.data.rows.map((lab) => ({
              id: lab.NAME, // Use a unique identifier here
              name: lab.NAME,
            }));
          } else {
            this.labSuggestions = []; // Clear suggestions if no results
          }
        } catch (error) {
          console.error("Error fetching lab suggestions:", error);
          this.labSuggestions = []; // Ensure suggestions are cleared on error
        }
      } else {
        this.labSuggestions = []; // Clear suggestions if input is too short
      }
    },
    fillLabName(name) {
      this.researchLab = name;
      // this.lastValidSelection = name; // Update last valid selection
      this.labSuggestions = [];
      this.isFocused = false;
      this.labSelected = true;
    },
    clearSelection() {
      this.researchLab = "";
      // this.lastValidSelection = ''; // Clear last valid selection
      this.labSelected = false;
    },
    selectLab(event) {
      this.researchLab = event.target.value; // Update the researchLab with the selected option
      this.labSuggestions = []; // Clear suggestions after selection
    },
    handleBlur() {
      // Give a bit more time for `mousedown.prevent` on the suggestion to run `fillLabName`.
      setTimeout(() => {
        // Only clear the input if no lab was actually clicked/selected.
        if (!this.labSelected) {
          this.researchLab = "";
        }
        // Reset the flag regardless for the next interaction.
        this.labSelected = false;
        // Hide the suggestions list.
        this.isFocused = false;
      }, 300); // Increase the timeout to 300ms or more if necessary.
    },

    async fetchGeneSearchResults() {
      if (this.geneSearchQuery.length >= 2) {
        // Add a condition to prevent too broad searches
        try {
          // Adjust the endpoint as necessary. Here, it's assumed to be '/gene-search'
          // and expects a query parameter `searchTerm` that matches the user's input.
          const response = await Api().get("gene_class_symbol/gene-search", {
            params: { searchTerm: this.geneSearchQuery },
          });

          if (response.status === 200 && response.data && response.data.rows) {
            // Map the response to the format expected by your component
            // Here we're assuming you want to display the NAME field and use the TAIR_OBJECT_ID as an identifier
            this.geneSearchResults = response.data.rows.map((gene) => ({
              repGeneModel: gene.REPRESENTATIVE_GENE_MODEL,
              name: gene.NAME,
              geneId: gene.GENE_ID,
            }));
          } else {
            this.geneSearchResults = []; // Clear results if the response does not fit the expected format
          }
        } catch (error) {
          console.error("Error fetching gene search results:", error);
          this.geneSearchResults = []; // Clear results on error
        }
      } else {
        this.geneSearchResults = []; // Clear results if the query is cleared or too short
      }
    },
    selectGene(gene) {
      if (
        !this.selectedGenes.find(
          (selectedGene) => selectedGene.repGeneModel === gene.repGeneModel
        )
      ) {
        this.selectedGenes.push({
          repGeneModel: gene.repGeneModel,
          name: gene.name,
          geneId: gene.geneId,
        });
        this.geneSearchQuery = ""; // Reset search query
        this.geneSearchResults = []; // Clear results

        // Log the updated selectedGenes array
      }
    },
    removeGene(index) {
      this.selectedGenes.splice(index, 1); // Remove gene from selection
    },
    async fetchPublicationSearchResults() {
      if (this.publicationSearchQuery.length) {
        try {
          const response = await Api().get(
            "gene_class_symbol/publication-search",
            {
              params: { searchTerm: this.publicationSearchQuery },
            }
          );

          if (response.status === 200 && response.data && response.data.rows) {
            // Map the response to transform it into a format usable by your component
            this.publicationSearchResults = response.data.rows.map((item) => ({
              title: item.TITLE,
              referenceId: item.REFERENCE_ID,
              pubmedId: item.PUBMED_ID ? item.PUBMED_ID.trim() : null, // Trim to remove any trailing spaces
              doi: item.DOI,
            }));
          } else {
            this.publicationSearchResults = [];
          }
        } catch (error) {
          console.error("Error fetching publication search results:", error);
          this.publicationSearchResults = [];
        }
      } else {
        this.publicationSearchResults = [];
      }
    },

    selectPublication(publication) {
      // Check if the publication is already selected
      const isAlreadySelected = this.selectedPublications.some(
        (selectedPub) => selectedPub.referenceId === publication.referenceId
      );

      if (!isAlreadySelected) {
        this.selectedPublications.push(publication);
        this.publicationSearchQuery = "";
        this.publicationSearchResults = [];
      }
    },
    removePublication(index) {
      this.selectedPublications.splice(index, 1);
    },
  },
};
</script>

<style scoped>
.mutant-phenotype-symbol-registration-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
}

.mutant-phenotype-symbol-registration {
  border: 1px solid #ccc;
  padding: 20px;
  margin: 0 auto;
  width: 100%;
  max-width: 800px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background: #fff;
}

.form-container {
  display: flex;
  flex-direction: column;
}

.input-group {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

.input-group label {
  font-weight: bold; /* Optional: makes the label text bold */
  margin-bottom: 5px; /* Adjust as necessary for more spacing */
  text-align: left;
}

.input-group input,
.input-group textarea,
.input-group select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.input-group span {
  padding-left: 5px; /* Add padding to align the 'Gene Class Symbol' value */
}

.submit-btn,
.reset-btn {
  cursor: pointer;
  padding: 10px 20px; /* Adjust as necessary */
  margin-right: 10px; /* Space between buttons */
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.submit-btn {
  background-color: #007bff;
  color: white;
}

.submit-btn:hover {
  background-color: #0056b3;
}

.reset-btn {
  background-color: #6c757d;
  color: white;
}

.reset-btn:hover {
  background-color: #545b62;
}

.suggestions-dropdown {
  position: absolute;
  z-index: 1000;
  width: 100%;
  background: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ddd;
  border-top: none;
}

@media (max-width: 768px) {
  .mutant-phenotype-symbol-registration {
    width: 90%; /* Adjust width for smaller screens */
  }
}

.success-message {
  color: #28a745; /* Green for success */
  background-color: #dff0d8;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
}

.error-message {
  color: #dc3545; /* Red for error */
  background-color: #f8d7da;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
}

.submit-btn:disabled {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed; /* Changes cursor to indicate the button is not clickable */
  opacity: 0.7; /* Makes the button appear "faded out" */
}

.selected-publications {
  padding: 10px;
  margin-top: 10px;
  border: 1px solid #ddd; /* Adds a border around the list for better separation */
  background-color: #f9f9f9; /* Light background to distinguish the area */
  border-radius: 5px; /* Rounded corners */
}

.selected-publications span {
  display: inline-block;
  margin: 5px;
  padding: 5px 10px; /* Add more padding around the text */
  background-color: #eee; /* Slightly darker background for each item */
  border-radius: 5px; /* Rounded corners for each item */
  cursor: pointer; /* Change cursor to indicate clickable */
  transition: background-color 0.3s; /* Smooth transition for hover effect */
}

.selected-publications span:hover {
  background-color: #ddd; /* Darker background on hover for better interaction feedback */
}

/* Style for the remove button (×) */
.selected-publications span::after {
  content: " ×"; /* Add space before the '×' for better readability */
  font-size: 25px; /* Make the '×' larger */
  color: red; /* Change color to red */
  padding-left: 5px; /* Space out the '×' from the publication title */
}

/* Enhancements for accessibility and interaction */
.selected-publications span:focus,
.selected-publications span:active {
  outline: none; /* Remove the outline to clean up focus styles */
  box-shadow: 0 0 0 2px #0056b3; /* Add a custom focus style */
}

.selected-genes {
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd; /* Adds a border around the list for better separation */
  background-color: #f9f9f9; /* Light background to distinguish the area */
  border-radius: 5px; /* Rounded corners for the container */
}

.selected-genes span {
  display: inline-block;
  margin: 5px;
  padding: 5px 10px; /* More padding around the text */
  background-color: #eee; /* Slightly darker background for each item */
  border-radius: 5px; /* Rounded corners for each item */
  cursor: pointer; /* Changes cursor to indicate clickable */
  transition: background-color 0.3s; /* Smooth transition for hover effect */
}

.selected-genes span:hover {
  background-color: #ddd; /* Darker background on hover for better interaction feedback */
}

/* Style for the remove button (×) - same as for publications */
.selected-genes span::after {
  content: " ×"; /* Add space before the '×' for better readability */
  font-size: 25px; /* Make the '×' larger */
  color: red; /* Change color to red */
  padding-left: 5px; /* Space out the '×' from the gene name */
}

/* Enhancements for accessibility and interaction */
.selected-genes span:focus,
.selected-genes span:active {
  outline: none; /* Remove the outline to clean up focus styles */
  box-shadow: 0 0 0 2px #0056b3; /* Add a custom focus style */
}
</style>
