<template>
  <div
    v-if="band.type === 'image'"
    class="col-sm-10 d-flex flex-column justify-content-start"
  >
    <div class="d-flex justify-content-start">
      <label :for="`standard-select-${band.key}`" class="pr-2"
        >Data Source</label
      >
      <div class="select ml-2">
        <select
          :id="`standard-select-${band.key}`"
          v-model="selectedType"
          @change="loadAndSetImgUrl"
        >
          <option v-for="(type, i) in band.types" :key="i">{{ type }}</option>
        </select>
      </div>
    </div>
    <div id="myDiv" v-if="!loading && !errorImg" class="img-wrapper">
      <a :href="selectedImgLink" target="_blank">
        <img
          :src="selectedImgUrl"
          width="100%"
          :alt="`Image of ${band.metadata.locusName}`"
          @error="replaceByDefault"
        />
      </a>
      <a :href="selectedImgLink" target="_blank">Powered by BAR Webservices</a>
    </div>
    <div v-if="loading">Loading ...</div>
    <div v-if="errorImg" class="error-message">
      <p>{{ errorDetail }}</p>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: {
    band: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedType: this.band.selectedType,
      selectedImgUrl: "",
      selectedImgLink: `https://bar.utoronto.ca/eplant/?ActiveSpecies=Arabidopsis%20thaliana&Genes=${this.band.metadata.locusName}&ActiveGene=${this.band.metadata.locusName}&ActiveView=AtGenExpressView`,
      loading: false,
      errorImg: false,
      errorDetail: ""
    };
  },
  methods: {
    loadAndSetImgUrl() {
      let formattedType = this.selectedType.replace(/ /g, "_");
      const imgUrl = `https://bar.utoronto.ca/api/efp_image/efp_arabidopsis/${formattedType}/Absolute/${this.band.metadata.locusName}`;
      this.loading = true;
      this.errorImg = false;
      this.errorDetail = "";
      axios
        .get(imgUrl)
        .then((res) => {
          if (res.data && res.data.wasSuccessful === false) {
            // API returned an error
            this.handleError(res.data.error);
          } else {
            this.selectedImgUrl = imgUrl;
            this.loading = false;
            this.errorImg = false;
          }
        })
        .catch((err) => {
          this.handleError(err);
        });
    },
    handleError(err) {
      this.loading = false;
      this.errorImg = true;
      if (typeof err === 'string') {
        // Error message directly from API
        this.errorDetail = err;
      } else if (err.response && err.response.data) {
        // Error from axios with response
        this.errorDetail = err.response.data.error || 'An error occurred while fetching the image.';
      } else {
        // Generic error
        this.errorDetail = 'An unexpected error occurred. Please try again later.';
      }
      this.replaceByDefault();
    },
    replaceByDefault() {
      this.selectedImgUrl = "https://example.com/images/default.jpg";
    },
  },
  mounted() {
    this.loadAndSetImgUrl(); // Load initial image when the component mounts
  },
};
</script>

<style scoped>
.select {
  margin-left: 8px;
}
.img-wrapper img {
  width: 100%; /* Ensure image takes full width of the container */
}
.error-message {
  color: red;
  margin-top: 10px;
}
</style>
