<template>
  <div class="overview-wrapper">
    <div class="side-wrapper">
      <ul class="list-group list-group-flush">
        <a
          v-for="(col, i) in searchOptions"
          :key="i"
          :href="col.route"
          target="_blank"
          class="list-group-item list-group-item-action flex-column align-items-start side-item"
        >
          {{ col.name }}
        </a>
      </ul>
    </div>
    <div class="content-wrapper">
      <b-breadcrumb :items="crumb_links"></b-breadcrumb>
      <h2>{{ overviewTitle }}</h2>
      <p>{{ description }}</p>
      <div class="options-panel">
        <div
          v-for="(option, i) in searchOptions"
          :key="i"
          class="search-option"
        >
          <h3 class="pg-link" @click="sideColClicked(i)">{{ option.name }}</h3>
          <p>{{ option.description }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchOverview",
  data() {
    return {
      crumb_links: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "Browse",
          active: true,
        },
      ],
      overviewTitle: "Browse Overview",
      description:
        "This section provides access to the following types of data in TAIR presented in browsable form.",
      searchOptions: [
        {
          name: "ABRC Catalog",
          description:
            "Search for any DNA, which includes clones, libraries, genomic DNA, and filters.",
          route: "https://abrc.osu.edu/catalog",
        },
        {
          name: "Transposon Families",
          description:
            "Browse families of transposable elements found in the Arabidopsis genome.",
          route: "/browse/transposon_families",
        },
        {
          name: "Gene Symbols",
          description:
            "Browse the Arabidopsis gene family data submitted to TAIR by users.",
          route: "/browse/gene_symbols",
        },
        {
          name: "Recently Added Literature",
          description:
            "Browseable list of new articles added to TAIR including manually validated links to genes in TAIR.",
          route: "/browse/recent_literature",
        },
        {
          name: "Microarray  Experiments",
          description: "Browseable list of Microarray  Experiments",
          route: "/browse/microarray_experiments",
        },
        {
          name: "Gene Families",
          description: "Browse the Arabidopsis gene family data.",
          route: "/browse/gene_family",
        },
        {
          name: "2010 Projects",
          description:
            "Find the Arabidopsis functional genomic projects awarded by NSF and other funding agencies.",
          route:
            "https://www.nsf.gov/awardsearch/advancedSearchResult?PIId=&PIFirstName=&PILastName=&PIOrganization=&PIState=&PIZip=&PICountry=&ProgOrganization=&ProgEleCode=&BooleanElement=All&ProgRefCode=&BooleanRef=All&Program=the+2010+project&ProgOfficer=&Keyword=&AwardNumberOperator=&AwardAmount=&AwardInstrument=&ExpiredAwards=true&OriginalAwardDateOperator=&StartDateOperator=&ExpDateOperator=",
        },
        {
          name: "The Arabidopsis Book (TAB)",
          description:
            "This electronic book from ASPB contains dozens of invited chapters, each reviewing in detail an important and interesting aspect of Arabidopsis thaliana biology and tools, with reference to what is known in other plants and in other kingdoms. New and updated chapters are regularly added.",
          route:
            "https://bioone.org/journals/the-arabidopsis-book/issues",
        },
      ],
    };
  },
  mounted() {
    document.title = "Browse Overview";
  },
  methods: {
    sideColClicked(index) {
      window.open(this.searchOptions[index].route, "_blank");
    },
  },
};
</script>

<style scoped lang="scss">
.content-wrapper {
  //   width: 70%;
  //   height: 700px;
  padding-left: 20px;
  //   overflow-y: scroll;

  h2 {
    font-weight: bold;
    margin-bottom: 20px;
    text-align: left;
  }
  p {
    margin-bottom: 20px;
    text-align: left;
  }
  .options-panel {
    background-color: #f7f6f3;
    padding: 10px;
    .search-option {
      margin-bottom: 30px;
      h3 {
        color: #2a568f;
        font-weight: bold;
        font-size: 20px;

        margin-bottom: 10px;
        text-align: left;
      }
      p {
        margin-left: 20px;
        text-align: left;
      }
    }
  }
  .pg-link {
    cursor: pointer;
  }
  .pg-link:hover {
    background: #feffbb;
  }
}
</style>
