<template>
  <b-modal
    ref="sequenceModal"
    title="Select Options"
    @hide="resetSelections"
    :hide-footer="true"
  >
    <b-form-group label="Select Option:">
      <b-form-select
        :options="options"
        v-model="selectedOption"
        @change="onOptionChange"
      ></b-form-select>
    </b-form-group>

    <b-form-group label="Choose Format:">
      <b-form-radio-group
        v-model="selectedFormat"
        :options="formatOptions"
        :disabled="isFormatSelectionDisabled"
        stacked
      ></b-form-radio-group>
    </b-form-group>

    <b-button variant="primary" @click="submitSelection">Submit</b-button>
  </b-modal>
</template>

<script>
export default {
  props: {
    geneList: {
      type: Array,
      default: () => []
    },
    geneListType: {
      type: String,
      default: ''
    }
  },
  
  data() {
    return {
      options: [
        { value: "Araport11_cdna", text: "Araport11 transcripts" },
        { value: "Araport11_cds", text: "Araport11 coding sequences" },
        {
          value: "Araport11_seq",
          text: "Araport11 genomic locus sequences",
        },
        {
          value: "Araport11_pep",
          text: "Araport11 protein sequences",
        },
        {
          value: "Araport11_upstream_500",
          text: "Araport11 Loci Upstream Seq - 500bp",
        },
        {
          value: "Araport11_upstream_1000",
          text: "Araport11 Loci Upstream Seq - 1000bp",
        },
        {
          value: "Araport11_upstream_3000",
          text: "Araport11 Loci Upstream Seq - 3000bp",
        },
        {
          value: "Araport11_downstream_500",
          text: "Araport11 Loci Downstream Seq - 500bp",
        },
        {
          value: "Araport11_downstream_1000",
          text: "Araport11 Loci Downstream Seq - 1000bp",
        },
        {
          value: "Araport11_downstream_3000",
          text: "Araport11 Loci Downstream Seq - 3000bp",
        },
        {
          value: "Araport11_intergenic",
          text: "Intergenic Sequences",
        },
        { value: "Araport11_intron", text: "Intron Sequences" },
        { value: "Araport11_3_utr", text: "Araport11 3' UTRs" },
        { value: "Araport11_5_utr", text: "Araport11 5' UTRs" },
        { value: "TAIR10_cds", text: "TAIR10 coding sequences" },
        { value: "TAIR10_pep", text: "TAIR10 protein sequences" },
      ],
      selectedOption: "Araport11_cdna",
      selectedFormat: "rep_gene",
      formatOptions: [
        { value: "rep_gene", text: "Get one sequence per locus (representative gene model/splice form only)" },
        { value: "both", text: "Get sequences for all gene models/splice forms" },
        { value: "genemodel", text: "Get sequences for only the gene model/splice form matching my query", disabled: true }
      ]
    };
  },
  computed: {
    isFormatSelectionDisabled() {
      return !["Araport11_cdna", "Araport11_cds", "Araport11_pep", "Araport11_seq"].includes(this.selectedOption);
    },
    
    isGeneModelOptionEnabled() {
      return this.geneList.length > 0 && this.geneListType === "AGI Gene IDs";
    }
  },
  watch: {
    isGeneModelOptionEnabled: {
      immediate: true,
      handler(newValue) {
        this.formatOptions = this.formatOptions.map(option => {
          if (option.value === 'genemodel') {
            return { ...option, disabled: !newValue };
          }
          return option;
        });

        if (!newValue && this.selectedFormat === 'genemodel') {
          this.selectedFormat = 'rep_gene';
        }
      }
    }
  },
  methods: {
    show() {
      this.$nextTick(() => {
        if (this.isGeneModelOptionEnabled) {
          this.selectedFormat = "genemodel";
        } else {
          this.selectedFormat = "rep_gene";
        }
        this.$refs.sequenceModal.show();
      });
    },
    hide() {
      this.$refs.sequenceModal.hide();
    },
    resetSelections() {
      this.selectedOption = "Araport11_cdna";
      this.selectedFormat = this.isGeneModelOptionEnabled ? "genemodel" : "rep_gene";
    },
    onOptionChange() {
      if (this.isFormatSelectionDisabled) {
        this.selectedFormat = "rep_gene";
      }
    },
    submitSelection() {
      this.$emit("submit", {
        option: this.selectedOption,
        format: this.selectedFormat,
      });
      this.hide();
    },
  },
};
</script>