<template>
  <div class="overview-wrapper">
    <DownloadSideBar></DownloadSideBar>
    <div class="content-wrapper">
      <DownloadCrumbLinks></DownloadCrumbLinks>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import DownloadSideBar from "./DownloadSideBar.vue";
import DownloadCrumbLinks from "./DownloadCrumbLinks.vue";

export default {
  name: "DownloadView",
  components: {
    DownloadSideBar,
    DownloadCrumbLinks,
  },
};
</script>

<style scoped lang="scss">
.overview-wrapper {
  display: flex;
  background-color: #f1efec;
  border: 1px solid #e0e0e0;
  padding: 20px;
  .breadcrumb {
    padding: 10px 0;
    font-weight: bold;
    font-size: 18px;
  }
}
</style>
