<template>
<b-breadcrumb :items="getCrumbLinks"></b-breadcrumb> 
</template>

<script>
export default {
  name: 'DownloadCrumbLinks',
  computed: {
    getCrumbLinks() {
      let links = [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "Download",
          href: "/download/overview"
        },
      ]
      let dirList = []
      if (this.$route.query.dir) {
        dirList = this.$route.query.dir.split("/")
      } else if (this.$route.query.path) {
        dirList = this.$route.query.path.split("/")
      }
      if (dirList.length > 0) {
        let dirStr = ""
        dirList.forEach((dir, index) => {
          if (index === dirList.length - 1) {
            links.push({
              text: dir,
              active:true
            })
          } else {
            if (dirStr == "") {
              dirStr = dir
            } else {
              dirStr += ("/" + dir)
            }
            links.push({
              text: dir,
              href: "/download/list?dir=" + dirStr
            })
          }
        });
      } else {
        // handle overview page
        links.push({
          text: "Overview",
          active:true
        })
      }
      return links
    }
  },
}
</script>

<style scoped lang="scss">
</style>