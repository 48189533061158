<template>
  <div class="d-flex flex-column text-wrap">
    <span class="font-weight-bold text-left">{{ title }}</span>
    <div class="d-flex justify-content-start flex-wrap">
      <span v-for="(list_obj, j) in data" :key="j">
        <link-wrapper :link="list_obj.link" :name="list_obj.name" />
        <span v-if="j < data.length - 1">;&nbsp;</span>
      </span>
    </div>
  </div>
</template>

<script>
import LinkWrapper from "../common/LinkWrapper.vue";
export default {
  components: { LinkWrapper },
  mounted() {},
  props: ["data", "title"],
};
</script>
