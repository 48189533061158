<template>
  <b-container>
    <b-row>
      <b-col>
        <b-input-group>
          <b-form-input
            v-model="searchText"
            placeholder="Enter search text"
            aria-label="searchText"
            @keyup.enter="goSearch()"
          ></b-form-input>
          <b-input-group-append>
            <b-form-select
              v-model="selectedSearchType"
              :options="searchTypes"
              class="custom-select-width"
            ></b-form-select>
          </b-input-group-append>
          <b-input-group-append>
            <b-button @click="goSearch()" variant="primary">
              <b-icon icon="search"></b-icon>
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import FormSection from "../../components/common/FormSection.vue";
export default {
  name: "GeneralSearch",
  components: {
    FormSection,
  },
  data() {
    return {
      searchText: null,
      lastSearchText: null,
      searchTypes: [
        { text: "Gene", value: "genes" },
        { text: "Clone", value: "dna" },
        { text: "Keyword", value: "keyword" },
        { text: "Protein", value: "protein" },
        { text: "Polymorphisms", value: "polyallele" },
        { text: "Ecotype", value: "ecotype" },
        { text: "Person or Lab", value: "community" },
        { text: "Germplasm", value: "germplasm" },
        { text: "Marker", value: "markers" },
        { text: "Vector", value: "vector" },
        { text: "Transposon", value: "transposon" },
        // { text: "Exact Name Search", value: "exact_name_search" },
      ],
      selectedSearchType: "genes",
      searchAlreadyShown: false,
    };
  },
  methods: {
    goSearch() {
      if (this.searchText && this.searchText !== "") {
        this.$nextTick(() => {
          if (this.$route.path !== "/results") {
            this.searchAlreadyShown = false;
          }
          this.$router.push({
            path: "/results",
            query: {
              mainType: "general",
              searchText: this.searchText,
              category: this.selectedSearchType,
            },
          });
          this.searchText = "";
        });
      }
    },
  },
};
</script>
<style scoped>
/* Default width for larger devices */
.custom-select-width {
  width: 180px;
}

/* Adjust width for screens smaller than 768px */
@media (max-width: 767px) {
  .custom-select-width {
    width: 100%;
  }
  .custom-select {
    font-size: 13px;
  }
}
</style>
