<template>
  <div class="content-wrapper">
    <b-breadcrumb :items="crumbLinks"></b-breadcrumb>
    <h2>{{ pageTitle }}</h2>
    <p>
      This page provides news and events for the Arabidopsis research community.
    </p>
    <div class="items-panel">
      <div class="news-item">
        <router-link to="breaking-news"><h3 class="pg-link">TAIR News</h3></router-link>
        <p>News announcements for the past 9 months.</p>
      </div>
      <div class="news-item">
        <router-link to="old-news"><h3>What Was News</h3></router-link>
        <p>News announcements older than 9 months.</p>
      </div>
      <div class="news-item">
        <router-link to="events"><h3>Conferences and Events</h3></router-link>
        <p>
          Conferences, workshops, and meetings of interest to the plant scientist.
        </p>
      </div>
      <div class="news-item">
        <a :href="getICARAbstractsURL" target="_blank"><h3>ICAR Abstracts</h3></a>
        <p>Archived abstracts from past ICAR meetings.</p>
      </div>
      <div class="news-item">
        <router-link to="jobs"><h3>Job Postings</h3></router-link>
        <p>Jobs available in plant sciences.</p>
      </div>
      <div class="news-item">
        <a :href="getHowToPostAJobURL" target="_blank"
          ><h3>How to Post a Job</h3></a
        >
        <p>
          Directions on how to automatically post a job to the TAIR website. No
          fee.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "NewsOverview",
  data() {
    return {
      pageTitle: "News Overview",
      news: [],
      crumbLinks: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "News",
          href: "/news/overview",
        },
        {
          text: "Overview",
          active: true,
        },
      ],
    };
  },
  mounted() {
    document.title = "News | TAIR";
  },
  computed: {
    ...mapGetters("newsURLs", ["getHowToPostAJobURL", "getICARAbstractsURL"]),
  },
};
</script>

<style scoped lang="scss">
.items-panel {
  text-align: left;
  background-color: #f7f6f3;
  padding: 10px;
  .news-item {
    margin-bottom: 30px;
    h3 {
      color: #2a568f;
      font-weight: bold;
      font-size: 20px;

      margin-bottom: 10px;
      text-align: left;
    }
    p {
      margin-left: 20px;
      text-align: left;
    }
  }
}
</style>
