<template>
  <div class="searchWrapper">
    <div class="pageTop">
      <div class="d-none d-md-block">
        <div class="d-flex flex-column">
          <b-breadcrumb :items="crumb_links"></b-breadcrumb>
          <div class="text-left ml-2 d-flex">
            <h4 style="color: #930">TAIR Publication Search</h4>
            <span class="ml-2"
              >[<a :href="helpLink" target="_blank">Help</a>]</span
            >
          </div>
          <div class="text-left m-2">
            <p>
              Use this page to search for Arabidopsis publications including:
              journal articles, books, dissertations, abstracts from conference
              proceedings including the Annual Arabidopsis Meeting abstracts,
              Electronic Arabidopsis Information Service (AIS) and Weeds World.
              Go
              <a :href="browseUrl" target="_blank">HERE</a>
              to browse the past volumes of AIS and Weeds World.
            </p>
            <p>
              Arabidopsis publications in TAIR are compiled primarily from
              PubMed records. For the years 2000-2005, the National Agricultural
              Library provided additional Agricola and Biosis records for
              Arabidopsis publications that were not available through PubMed.
            </p>
            <p>
              To search the full text of articles, please try
              <a href="http://www.textpresso.org/arabidopsis/" taget="_blank"
                >Textpresso for Arabidopsis.</a
              >
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="pageMain m-2">
      <div class="d-flex flex-column">
        <div class="row mt-2">
          <div class="col-sm d-flex align-items-start ml-4">
            <b-button size="sm" class="mr-2" @click="resetForm">Reset</b-button>
            <b-button size="sm" @click="onSubmit">Submit Query</b-button>
          </div>
        </div>
        <form-section
          v-for="(filter, index) in search_form.filters"
          :key="index"
          :filter="filter"
          @element-changed="onElementChanged"
          @on-enter="onSubmit"
        />
        <div class="row mt-2">
          <div class="col-sm d-flex align-items-start ml-4">
            <b-button size="sm" class="mr-2" @click="resetForm">Reset</b-button>
            <b-button size="sm" @click="onSubmit">Submit Query</b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormSection from "../../components/common/FormSection.vue";

export default {
  name: "PublicationSearch",
  components: {
    FormSection,
  },
  data() {
    return {
      crumb_links: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "Search",
          href: "/search/overview",
        },
        {
          text: "Publication",
          active: true,
        },
      ],
      search_form: {
        filters: [
          {
            label: "Search Terms",
            type: "rows",
            rows: [
              {
                name: "Search Terms",
                value: {
                  type: "mixed",
                  elements: [
                    {
                      type: "select",
                      options: [
                        // { text: "Abstract", value: "abstract" },
                        { text: "Author", value: "authors" },
                        { text: "Journal/Book Title", value: "pubName" },
                        { text: "Title/Abstract", value: "title_abstract" },
                        { text: "AGI Locus ID", value: "loci" },
                        { text: "PubMed ID", value: "pubmedId" },
                        { text: "DOI", value: "doi" },
                      ],
                      value: "authors",
                      id: "search_type_by_1",
                      size: "medium",
                    },
                    {
                      type: "select",
                      options: [
                        { text: "contains", value: "contains" },
                        { text: "starts with", value: "starts_with" },
                        { text: "exactly", value: "exactly" },
                      ],
                      value: "contains",
                      id: "search_method_1",
                      size: "medium",
                    },
                    {
                      type: "input",
                      value: "",
                      id: "search_input_1",
                      size: "large",
                    },
                  ],
                },
              },
              {
                name: "",
                value: {
                  type: "mixed",
                  elements: [
                    {
                      type: "select",
                      options: [
                        // { text: "Abstract", value: "abstract" },
                        { text: "Author", value: "author" },
                        { text: "Journal/Book Title", value: "pubName" },
                        { text: "Title/Abstract", value: "title_abstract" },
                        { text: "AGI Locus ID", value: "loci" },
                        { text: "PubMed ID", value: "pubmedId" },
                        { text: "DOI", value: "doi" },
                      ],
                      value: "title_abstract",
                      id: "search_type_by_3",
                      size: "medium",
                    },
                    {
                      type: "select",
                      options: [
                        { text: "contains", value: "contains" },
                        { text: "starts with", value: "starts_with" },
                        { text: "exactly", value: "exactly" },
                      ],
                      value: "contains",
                      id: "search_method_3",
                      size: "medium",
                    },
                    {
                      type: "input",
                      value: "",
                      id: "search_input_3",
                      size: "large",
                    },
                  ],
                },
              },
              {
                name: "Year From",
                value: {
                  elements: [
                    {
                      type: "select",
                      options: [
                        { text: "Present", value: "2024" },
                        { text: "Any", value: "any" },
                      ],
                      value: "any",
                      id: "year_from",
                      size: "small",
                    },
                  ],
                },
              },
              {
                name: "Year To",
                value: {
                  elements: [
                    {
                      type: "select",
                      options: [
                        { text: "Present", value: "2024" },
                        { text: "Any", value: "any" },
                      ],
                      value: "present",
                      id: "year_to",
                      size: "small",
                    },
                  ],
                },
              },
              {
                name: "Publication Type",
                value: {
                  elements: [
                    {
                      type: "select",
                      options: [
                        { text: "All", value: "all" },
                        { text: "Book", value: "book" },
                        {
                          text: "Research Article",
                          value: "research_article",
                        },
                        { text: "Review", value: "review" },
                        { text: "Dissertation", value: "dissertation" },
                        { text: "Press Article", value: "press_article" },
                        { text: "Comment", value: "comment" },
                        { text: "Meeting Abstract", value: "abstract" },
                        { text: "Book Chapter", value: "book_chapter" },
                        { text: "Erratum", value: "erratum" },
                      ],
                      value: "all",
                      id: "publication_type",
                      size: "medium",
                    },
                  ],
                },
              },
            ],
          },
          {
            label: "Search by Associated Keyword",
            type: "rows",
            rows: [
              {
                name: "Keyword Term",
                value: {
                  type: "mixed",
                  elements: [
                    {
                      type: "select",
                      options: [
                        { text: "contains", value: "contains" },
                        { text: "starts with", value: "starts_with" },
                        { text: "exactly", value: "exactly" },
                      ],
                      value: "starts_with",
                      id: "keyword_method",
                      size: "medium",
                    },
                    {
                      type: "input",
                      value: "",
                      id: "keyword_term",
                      size: "large",
                    },
                  ],
                },
              },
              {
                name: "Keyword Type",
                value: {
                  elements: [
                    {
                      type: "multiSelect",
                      options: [
                        { text: "Any", value: "any" },
                        { text: "GO Molecular Function", value: "func" },
                        { text: "GO Biological Process", value: "proc" },
                        { text: "GO Cellular Component", value: "comp" },
                        { text: "Plant Structure", value: "stru" },
                        {
                          text: "Growth and Developmental Stage",
                          value: "grow",
                        },
                        { text: "Experimental Method", value: "meth" },
                        { text: "User defined", value: "user" },
                      ],
                      selected: ["any"],
                      value: ["any"],
                      width: "large",
                      id: "keyword_types",
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
      helpLink:
        "https://conf.phoenixbioinformatics.org/pages/viewpage.action?pageId=155058233",
      browseUrl: process.env.VUE_APP_OLD_TAIR_URL + "/browse/electricarab.jsp",
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    resetForm() {
      // Reset form logic
    },
    init() {
      document.title = `Publication Search`;
      let currentYear = new Date().getFullYear();
      let options = [
        { text: "any", value: "any" },
        { text: "present", value: currentYear },
      ];
      //Add from 1947 to present year

      for (let i = currentYear; i >= 1947; i--) {
        options.push({ text: i, value: i });
      }

      this.updateOptionsById("year_from", options);
      this.updateOptionsById("year_to", options);
    },

    generalizeFieldNames(rowParams) {
      const generalizedParams = {};

      Object.keys(rowParams).forEach((key) => {
        if (key.startsWith("search_type_by_")) {
          generalizedParams["search_type"] = rowParams[key];
        } else if (key.startsWith("search_method_")) {
          generalizedParams["search_method"] = rowParams[key];
        } else if (key.startsWith("search_input_")) {
          generalizedParams["search_input"] = rowParams[key];
        } else {
          // Copy other fields as-is
          generalizedParams[key] = rowParams[key];
        }
      });

      return generalizedParams;
    },
    onSubmit() {
      event.preventDefault();
      let searchParams = {
        searchTerms: [],
        keywordTerms: [],
      };
      this.search_form.filters.forEach((filter) => {
        if (filter.type === "rows") {
          filter.rows.forEach((row) => {
            let rowParams = {};
            row.value.elements.forEach((element) => {
              let shouldAddParam = true;
              let value = element.value;

              if (element.type === "checkbox") {
                value = element.value;
              }

              if (!value || value === false || value === "any") {
                shouldAddParam = false;
              }

              //check if value is an array
              if (Array.isArray(value)) {
                if (
                  value.length === 0 ||
                  (value.length === 1 && value[0] === "any")
                ) {
                  shouldAddParam = false;
                }
              }

              if (shouldAddParam) {
                if (element.id) {
                  rowParams[element.id] = value;
                }
              }
            });
            if (Object.keys(rowParams).length > 0) {
              if (filter.label === "Search Terms") {
                const generalizedRowParams =
                  this.generalizeFieldNames(rowParams);
                searchParams.searchTerms.push(generalizedRowParams);
              } else if (filter.label === "Search by Associated Keyword") {
                searchParams.keywordTerms.push(rowParams);
              }
            }
          });
        }
      });

      this.showResults(searchParams);
    },
    onReset(event) {
      event.preventDefault();
    },
    onElementChanged(event) {
      let elementId = event.id;
      if (elementId.startsWith("search_type_by_")) {
        let suffix = elementId.split("_")[3];
        if (event.value == "doi" || event.value == "pubmedId") {
          this.updateOptionsById("search_method_" + suffix, [
            { text: "exactly", value: "exactly" },
          ]);
        } else {
          this.updateOptionsById("search_method_" + suffix, [
            { text: "contains", value: "contains" },
            { text: "starts with", value: "starts_with" },
            { text: "exactly", value: "exactly" },
          ]);
        }
      }
    },
    showResults(inp_params) {
      this.$nextTick(() => {
        this.$router.push({
          path: "/results",
          query: {
            mainType: "detail",
            category: "publication",
            search_params: JSON.stringify(inp_params),
          },
        });
      });
    },
    updateOptionsById(searchId, options) {
      for (let filter of this.search_form.filters) {
        for (let row of filter.rows) {
          if (row.value && row.value.elements) {
            let element = row.value.elements.find((el) => el.id === searchId);
            if (element) {
              if (element.options) {
                element.options = options;
              }
              break;
            }
          }
        }
      }
    },
    getValueById(searchId) {
      let result = null;

      // Loop over each filter
      for (let filter of this.search_form.filters) {
        // Loop over each row in the current filter
        for (let row of filter.rows) {
          // Check if row has a value and elements
          if (row.value && row.value.elements) {
            // Find the element with the matching id
            let element = row.value.elements.find((el) => el.id === searchId);
            if (element) {
              result = element.value;
              break;
            }
          }
        }

        if (result !== null) {
          break;
        }
      }

      return result;
    },
  },
};
</script>

<style scoped lang="scss"></style>
