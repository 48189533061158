<!-- SearchResultsTable.vue -->
<template>
  <div class="tableWrapper">
    <div class="tableTitle">
      <div class="row">
        <div
          class="col-12 pb-2 ml-2"
          v-if="items.length > 0 && showSelect"
          style="display: flex; gap: 10px; justify-content: left"
        >
          <b-button size="sm" @click="selectAllRows">Select All</b-button>
          <b-button size="sm" @click="clearSelected">Clear Selected</b-button>
        </div>
      </div>
      <div
        v-if="loading"
        class="d-flex justify-content-center align-items-center"
        style="height: 100%"
      >
        <b-spinner label="Loading..."></b-spinner>
      </div>
      <span class="highlight-term" v-if="error">Error from the server</span>
    </div>

    <b-table
      small
      striped
      hover
      sticky-header="480px"
      :items="paginatedData"
      :fields="fields"
      ref="selectableTable"
      @row-selected="onRowSelected"
      v-if="items.length > 0"
    >
      <template v-slot:[`cell(${field.key})`]="data" v-for="field in fields">
        <div :key="field.key" class="d-flex text-left">
          {{ data.value }}
        </div>
      </template>
      <template v-slot:[`head(${field.key})`]="data" v-for="field in fields">
        <div :key="field.key" class="d-flex text-left">
          <div class="mr-2" v-if="field.helpLink">
            <a :href="field.helpLink" target="_blank" style="color: black">
              <b-icon
                class="ml-2"
                :id="field.id"
                icon="question-circle-fill"
              ></b-icon>
            </a>
          </div>
          {{ data.label }}
        </div>
      </template>
      <template #head(locus)="data">
        <div class="d-flex text-center">
          <span>{{ data.label }}</span>
        </div>
      </template>
      <template #head(protein)="data">
        <div class="d-flex text-center">
          <span>{{ data.label }}</span>
        </div>
      </template>
      <template #head(desc)="data">
        <div class="d-flex text-center">
          <span>{{ data.label }}</span>
          <b-button
            id="popover-target-2"
            class="ml-2"
            variant="light"
            style="background-color: transparent"
          >
            <b-icon icon="question-circle" font-scale="1.0"></b-icon>
          </b-button>
          <b-popover target="popover-target-2" triggers="hover" placement="top">
            <template #title>Description</template>
            A brief description of the gene. Usually a brief summary about the
            gene written by TAIR curators based upon the literature. Summaries
            are also taken from the GenBank record.
          </b-popover>
        </div>
      </template>
      <template v-slot:cell(selected)="row">
        <b-form-group>
          <input
            type="checkbox"
            v-model="row.item.selected"
            class="tight-checkbox"
            @change="onRowSelected(row.item)"
            aria-label="Select row"
          />
        </b-form-group>
      </template>

      <template #row-details="row">
        <td :colspan="fields.length">
          <div v-html="row.item.html"></div>
        </td>
      </template>

      <template #head(row_num)="data">
        <div class="d-flex justify-content-center" :style="{ width: '50px' }">
          <span class="hashtag-icon"> No. </span>
        </div>
      </template>

      <template #cell(row_num)="{ index }">
        <div class="d-flex text-center">
          <span>{{ (currentPage - 1) * perPage + index + 1 }}</span>
        </div>
      </template>

      <template v-slot:cell(name)="data">
        <a :href="data.item.url" style="text-align: left; display: block">{{
          data.item.name
        }}</a>
      </template>

      <template
        v-for="(field1, i) in fields.filter(
          (field) => field.cellType === 'name_link'
        )"
        #[`cell(${field1.key})`]="data"
      >
        <div class="d-flex text-left" :key="i">
          <link-wrapper :link="data.value.link" :name="data.value.name" />
        </div>
      </template>
      <template
        v-for="(field4, i) in fields.filter(
          (field) => field.cellType === 'name_list'
        )"
        #[`cell(${field4.key})`]="data"
      >
        <div class="d-flex flex-column text-left" :key="i">
          <div v-for="(l, j) in data.value" :key="j">
            <span> {{ l.name }}</span>
          </div>
        </div>
      </template>

      <template
        v-for="(field2, i) in fields.filter(
          (field) => field.cellType === 'name_links'
        )"
        #[`cell(${field2.key})`]="data"
      >
        <div class="d-flex text-left" :key="i">
          <div v-for="(l, j) in data.value" class="mr-2" :key="j">
            <router-link v-if="l.link" class="locusLink" :to="l.link">
              {{ l.name }}
            </router-link>
            <span v-else> {{ l.name }}</span>
          </div>
        </div>
      </template>

      <template
        v-for="(field3, i) in fields.filter(
          (field) => field.cellType === 'name_links_multi'
        )"
        #[`cell(${field3.key})`]="data"
      >
        <div :key="i" class="d-flex flex-column text-left">
          <div v-for="(l, i) in data.value" class="mr-2" :key="i">
            <router-link v-if="l.link" class="locusLink" :to="l.link">
              {{ l.name }}
            </router-link>
            <a v-else-if="l.externalLink" :href="l.externalLink" target="_blank">{{ l.name }}</a>
            <span v-else> {{ l.name }}</span>
          </div>
        </div>
      </template>

      <template
        v-for="fieldHtml in fields.filter((field) => field.cellType === 'html')"
        #[`cell(${fieldHtml.key})`]="data"
      >
        <div
          v-for="(l, i) in data.value"
          :key="i"
          class="d-flex text-left mr-2"
        >
          <div v-html="l" class="mr-2"></div>
        </div>
      </template>

      <template #cell(description)="data">
        <div class="two-line-ellipsis text-left">
          {{ data.value }}
        </div>
      </template>
      <template #cell(protein_models)="data">
        <div class="d-flex text-left">
          <span
            class="locusLink"
            @click="
              $emit('cell-clicked', {
                cellType: 'locus',
                value: data.value.locusId,
              })
            "
          >
            {{ data.value.locusName }}
          </span>
          <span v-if="data.value.locusId && data.value.geneId">/</span>
          <span
            class="locusLink"
            @click="
              $emit('cell-clicked', {
                cellType: 'gene',
                value: data.value.geneId,
              })
            "
          >
            {{ data.value.geneName }}
          </span>
        </div>
      </template>
    </b-table>
    <b-pagination
      v-if="items.length > 0"
      v-model="currentPage"
      :total-rows="items.length"
      :per-page="perPage"
    ></b-pagination>
  </div>
</template>

<script>
import LinkWrapper from "@/components/common/LinkWrapper.vue";
export default {
  components: { LinkWrapper },
  props: {
    items: Array,
    fields: Array,
    loading: Boolean,
    error: Boolean,
    showSelect: {
      type: Boolean,
      default: true,
    },
    perPageProp: {
      type: Number,
      default: 25,
    },
  },
  computed: {
    paginatedData() {
      let start = (this.currentPage - 1) * this.perPage,
        end = start + this.perPage;
      return this.items.slice(start, end);
    },
  },
  data() {
    return {
      currentPage: 1,
      perPage: this.perPageProp,
      detailsShown: [],
    };
  },
  watch: {
    currentPage(newVal) {
      this.$emit("update:currentPage", newVal);
    },
  },
  methods: {
    toggleDetails(index) {
      const position = this.detailsShown.indexOf(index);
      if (position >= 0) {
        // Remove index if it's already shown
        this.detailsShown.splice(position, 1);
      } else {
        // Add index to show details
        this.detailsShown.push(index);
      }
    },
    onRowSelected(items) {
      this.$emit("row-selected", items);
    },
    selectAllRows() {
      this.items.map((i) => (i.selected = true));
    },
    clearSelected() {
      this.items.map((i) => (i.selected = false));
    },
    getAllSequences() {
      let all_locus = this.items.map((g) => g.locus.name);
      this.$router.push({
        path: "/type_sequences",
        name: "BulkSequenceSearch",
        params: { ids: all_locus.join(",") },
      });
    },
    getCheckedSequences() {
      let all_locus = this.items
        .filter((s) => s.selected)
        .map((g) => g.locus.name);
      this.$router.push({
        path: "/type_sequences",
        name: "BulkSequenceSearch",
        params: { ids: all_locus.join(",") },
      });
    },
  },
  // methods, computed properties, etc...
};
</script>
<style lang="scss">
.tableWrapper {
  overflow: hidden;
  overflow-y: hidden !important;
}
.locusLink {
  color: #2a568f;
  // max-width: 300px;
  cursor: pointer;
  &:hover {
    background: #feffbb;
  }
}
.narrow {
  max-width: 10%;
}
.wide {
  width: 70%;
}
</style>
