<template>
  <div class="searchWrapper">
    <div class="pageTop">
      <div class="d-none d-md-block">
        <div class="d-flex flex-column">
          <b-breadcrumb :items="crumb_links"></b-breadcrumb>
          <div class="text-left ml-2 d-flex">
            <h4 style="color: #930">TAIR DNA Search</h4>
            <span class="ml-2"
              >[<a :href="helpLink" target="_blank">Help</a>]</span
            >
          </div>
          <div class="text-left m-2">
            This page allows you to search for Clones and Vectors that are in
            the TAIR database. Additional DNA stocks may be found at
            <a href="https://abrc.osu.edu/researchers" target="_blank">ABRC</a>
            and
            <a href="https://epd.brc.riken.jp/en/arabidopsis" target="_blank"
              >RIKEN</a
            >
          </div>
        </div>
      </div>
    </div>
    <div class="pageMain m-2">
      <div class="d-flex flex-column">
        <div class="row mt-2">
          <div class="col-sm d-flex align-items-start ml-4">
            <b-button size="sm" class="mr-2">Reset</b-button>
            <b-button size="sm" @click="onSubmit">Submit Query</b-button>
          </div>
        </div>
        <form-section
          v-for="(filter, index) in search_form.filters"
          :key="index"
          :filter="filter"
          @element-changed="onElementChanged"
          @on-enter="onSubmit"
        />
        <div class="row mt-2">
          <div class="col-sm d-flex align-items-start ml-4">
            <b-button size="sm" class="mr-2" @click="resetForm">Reset</b-button>
            <b-button size="sm" @click="onSubmit">Submit Query</b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormSection from "../../components/common/FormSection.vue";
export default {
  name: "DnaSearch",
  components: {
    FormSection,
  },
  data() {
    return {
      crumb_links: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "Search",
          href: "/search/overview",
        },
        {
          text: "DNA/Clones",
          active: true,
        },
      ],
      search_form: {
        filters: [
          {
            label: "Category",
            type: "rows",
            id: "category",
            rows: [
              {
                name: "Choose category",
                value: {
                  type: "mixed",
                  elements: [
                    {
                      type: "select",
                      options: [
                        { text: "Clone", value: "clone" },
                        { text: "Vector", value: "vector" },
                      ],
                      value: "clone",
                      id: "category",
                      size: "large",
                    },
                  ],
                },
              },
            ],
          },
          {
            label: "Search Terms",
            type: "rows",
            id: "search_terms",
            rows: [],
          },
          {
            label: "Restrict by Features",
            type: "rows",
            id: "restrict_by_features",
            rows: [],
          },
          {
            label: "Restrict by Map Location",
            type: "rows",
            id: "restrict_by_map_location",
            rows: [],
          },
        ],
      },
      searchTerms_clone: [
        {
          name: "Search For",
          value: {
            type: "mixed",
            elements: [
              {
                type: "select",
                options: [
                  { text: "clone name", value: "name" },
                  { text: "locus name", value: "locus_name" },
                  { text: "gene name", value: "gene_names" },
                  { text: "GenBank Accession", value: "genbank" },
                  { text: "GenBank gi", value: "genbank_gi" },
                ],
                value: "name",
                id: "search_type_by_1",
                size: "medium",
              },
              {
                type: "select",
                options: [
                  { text: "contains", value: "contains" },
                  { text: "starts with", value: "starts_with" },
                  { text: "exactly", value: "exactly" },
                ],
                value: "contains",
                id: "search_method_1",
                size: "medium",
              },
              {
                type: "input",
                value: "",
                id: "search_input_1",
                size: "large",
              },
              {
                type: "text",
                value: "and",
              },
            ],
          },
        },
        {
          name: "",
          value: {
            type: "mixed",
            elements: [
              {
                type: "select",
                options: [
                  { text: "clone name", value: "clone_name" },
                  { text: "locus name", value: "locus_name" },
                  { text: "gene name", value: "gene_names" },
                  { text: "GenBank Accession", value: "genbank" },
                ],
                value: "locus_name",
                id: "search_type_by_2",
                size: "medium",
              },
              {
                type: "select",
                options: [
                  { text: "contains", value: "contains" },
                  { text: "starts with", value: "starts_with" },
                  { text: "exactly", value: "exactly" },
                ],
                value: "contains",
                id: "search_method_2",
                size: "medium",
              },
              {
                type: "input",
                value: "",
                id: "search_input_2",
                size: "large",
              },
              {
                type: "text",
                value: "and",
              },
            ],
          },
        },
        {
          name: "",
          value: {
            type: "mixed",
            elements: [
              {
                type: "select",
                options: [
                  { text: "clone name", value: "clone_name" },
                  { text: "locus name", value: "locus_name" },
                  { text: "gene name", value: "gene_names" },
                  { text: "GenBank Accession", value: "genbank" },
                ],
                value: "genbank",
                id: "search_type_by_3",
                size: "medium",
              },
              {
                type: "select",
                options: [
                  { text: "contains", value: "contains" },
                  { text: "starts with", value: "starts_with" },
                  { text: "exactly", value: "exactly" },
                ],
                value: "exactly",
                id: "search_method_3",
                size: "medium",
              },
              {
                type: "input",
                value: "",
                id: "search_input_3",
                size: "large",
              },
            ],
          },
        },
      ],
      searchTerms_vector: [
        {
          name: "Search For",
          value: {
            type: "mixed",
            elements: [
              {
                type: "select",
                options: [{ text: "vector name", value: "name" }],
                value: "name",
                id: "search_type_by_1",
                size: "medium",
              },
              {
                type: "select",
                options: [
                  { text: "contains", value: "contains" },
                  { text: "starts with", value: "starts_with" },
                  { text: "exactly", value: "exactly" },
                ],
                value: "contains",
                id: "search_method_1",
                size: "medium",
              },
              {
                type: "input",
                value: "",
                id: "search_input_1",
                size: "large",
              },
            ],
          },
        },
      ],
      restrictFeats_clone: [
        {
          name: "Vector Type (multiple selection)",
          value: {
            elements: [
              {
                type: "multiSelect",
                options: [
                  { text: "Any", value: "any" },
                  {
                    text: "All BACS (BAC,TAC,P1,BiBAC)",
                    value: "BAC_ALL",
                  },
                  { text: "BAC (incl. BiBAC)", value: "BAC" },
                  { text: "TAC", value: "TAC" },
                  { text: "P1", value: "P1" },
                  { text: "YAC", value: "YAC" },
                  { text: "plasmid", value: "plasmid" },
                  { text: "cosmid", value: "cosmid" },
                  { text: "lambda", value: "lambda" },
                ],
                selected: ["Any"],
                value: ["any"],
                width: "large",
                id: "vector_type",
              },
            ],
          },
        },
        {
          name: "Insert Type (multiple selection)",
          value: {
            elements: [
              {
                type: "multiSelect",
                options: [
                  { text: "Any", value: "any" },
                  { text: "cDNA", value: "cDNA" },
                  { text: "construct", value: "construct" },
                  { text: "genomic", value: "genomic" },
                  { text: "inverted repeat", value: "inverted repeat" },
                  { text: "PCR fragment", value: "PCR fragment" },
                  { text: "T-DNA", value: "T-DNA" },
                  { text: "transposon", value: "transposon" },
                  { text: "transposase", value: "transposase" },
                  { text: "unknown", value: "unknown" },
                ],

                selected: ["Any"],
                value: ["any"],
                width: "large",
                id: "insert_type",
              },
            ],
          },
        },
        {
          name: "Clone End Type (multiple selection)",
          value: {
            elements: [
              {
                type: "multiSelect",
                options: [
                  { text: "Any", value: "any" },
                  {
                    text: "EST",
                    value: "EST",
                  },
                  { text: "BAC end", value: "BAC_end" },
                  { text: "YAC end", value: "YAC_end" },
                ],

                selected: ["Any"],
                value: ["any"],
                width: "large",
                id: "cloneend_type",
              },
            ],
          },
        },
        {
          name: "Other Features (multiple selection)",
          value: {
            elements: [
              {
                type: "multiSelect",
                options: [
                  { text: "none", value: "any" },
                  { text: "is sequenced", value: "is_sequenced" },
                  { text: "is genetic marker", value: "is_genetic_marker" },
                  {
                    text: "is on a map",
                    value: "is_on_map",
                  },
                  { text: "is ABRC stock", value: "is_stock" },
                  { text: "is full length cDNA", value: "is_full_cdna" },
                ],
                selected: [],
                value: ["any"],
                width: "large",
                id: "other_features",
              },
            ],
          },
        },
      ],
      restrictFeats_vector: [
        {
          name: "Vector Type (multiple selection)",
          value: {
            elements: [
              {
                type: "multiSelect",
                options: [
                  { text: "Any", value: "any" },
                  {
                    text: "All BACS (BAC,TAC,P1,BiBAC)",
                    value: "BAC_ALL",
                  },
                  { text: "BAC (incl. BiBAC)", value: "BAC" },
                  { text: "TAC", value: "TAC" },
                  { text: "P1", value: "P1" },
                  { text: "YAC", value: "YAC" },
                  { text: "plasmid", value: "plasmid" },
                  { text: "cosmid", value: "cosmid" },
                  { text: "lambda", value: "lambda" },
                ],
                selected: ["Any"],
                value: ["any"],
                width: "large",
                id: "vector_type",
              },
            ],
          },
        },
        {
          name: "Insert Type (multiple selection)",
          value: {
            elements: [
              {
                type: "multiSelect",
                options: [
                  { text: "Any", value: "any" },
                  { text: "cDNA", value: "cDNA" },
                  { text: "construct", value: "construct" },
                  { text: "genomic", value: "genomic" },
                  { text: "inverted repeat", value: "inverted repeat" },
                  { text: "PCR fragment", value: "PCR fragment" },
                  { text: "T-DNA", value: "T-DNA" },
                  { text: "transposon", value: "transposon" },
                  { text: "transposase", value: "transposase" },
                  { text: "unknown", value: "unknown" },
                ],

                selected: ["Any"],
                value: ["any"],
                width: "large",
                id: "insert_type",
              },
            ],
          },
        },
        {
          name: "Other Features (multiple selection)",
          value: {
            elements: [
              {
                type: "multiSelect",
                options: [
                  { text: "none", value: "any" },
                  { text: "is ABRC stock", value: "is_stock" },
                ],
                selected: [],
                value: ["any"],
                width: "large",
                id: "other_features",
              },
            ],
          },
        },
      ],
      mapLocations_clone: [
        {
          name: "Chromosome",
          value: {
            elements: [
              {
                type: "select",
                options: [
                  { text: "any", value: "any" },
                  { text: "1", value: "1" },
                  { text: "2", value: "2" },
                  { text: "3", value: "3" },
                  { text: "4", value: "4" },
                  { text: "5", value: "5" },
                  { text: "chloroplast genome", value: "C" },
                  { text: "mitochondrial genome", value: "M" },
                ],
                id: "chromosome",
                size: "large",
                value: "any",
              },
            ],
          },
          helpLink:
            process.env.VUE_APP_OLD_TAIR_URL +
            "/help/helppages/polyser.jsp#chromosome",
        },
        // {
        //   text: "Map Type: AGI",
        // },
        {
          name: "Range",
          value: {
            type: "mixed",
            elements: [
              // {
              //   type: "select",
              //   options: [
              //     { text: "between", value: "between" },
              //     { text: "around", value: "around" },
              //   ],
              //   id: "range_type",
              //   size: "small",
              //   value: "between",
              // },
              {
                type: "input",
                value: "",
                name: "low_range",
                id: "low_range",
              },
              {
                type: "text",
                value: "kb",
              },
              {
                type: "text",
                value: "and",
              },
              {
                type: "input",
                value: "",
                id: "high_range",
              },
              {
                type: "text",
                value: "kb",
              },
            ],
          },
          helpLink:
            process.env.VUE_APP_OLD_TAIR_URL +
            "/help/helppages/polyser.jsp#range",
        },
      ],
      mapLocations_vector: [],
      helpLink:
        "https://conf.phoenixbioinformatics.org/display/COM/DNA+search+and+results",
    };
  },
  mounted() {
    document.title = `DNA Search`;
    this.updateRowsById("search_terms", this.searchTerms_clone);
    this.updateRowsById("restrict_by_features", this.restrictFeats_clone);
    this.updateRowsById("restrict_by_map_location", this.mapLocations_clone);
    this.updateOptionsById("search_method_3", [
      { text: "exactly", value: "exactly" },
    ]);
  },
  methods: {
    onElementChanged(event) {
      let elementId = event.id;
      if (elementId == "category") {
        let newRows =
          event.value == "clone"
            ? this.searchTerms_clone
            : this.searchTerms_vector;
        this.updateRowsById("search_terms", newRows);

        let newRestrictFeats =
          event.value == "clone"
            ? this.restrictFeats_clone
            : this.restrictFeats_vector;
        this.updateRowsById("restrict_by_features", newRestrictFeats);

        let newMapLocations =
          event.value == "clone"
            ? this.mapLocations_clone
            : this.mapLocations_vector;
        this.updateRowsById("restrict_by_map_location", newMapLocations);
      } else if (elementId.startsWith("search_type_by_")) {
        let suffix = elementId.split("_")[3];
        if (event.value == "genbank" || event.value == "genbank_gi") {
          this.updateOptionsById("search_method_" + suffix, [
            { text: "exactly", value: "exactly" },
          ]);
        } else {
          this.updateOptionsById("search_method_" + suffix, [
            { text: "contains", value: "contains" },
            { text: "starts with", value: "starts_with" },
            { text: "exactly", value: "exactly" },
          ]);
        }
      }
    },
    async onSubmit(event) {
      event.preventDefault();

      let searchParams = {
        category: "clone",
        searchTerms: [],
        restrictByFeatures: [],
        mapLocation: [],
      };

      this.search_form.filters.forEach((filter) => {
        if (filter.type === "rows") {
          filter.rows.forEach((row) => {
            let rowParams = {};
            row.value.elements.forEach((element) => {
              let shouldAddParam = true;
              let value = element.value;

              if (element.type === "checkbox") {
                value = element.value;
              }

              if (!value || value === false || value === "any") {
                shouldAddParam = false;
              }

              //check if value is an array
              if (Array.isArray(value)) {
                if (
                  value.length === 0 ||
                  (value.length === 1 && value[0] === "any")
                ) {
                  shouldAddParam = false;
                }
              }

              if (shouldAddParam) {
                if (element.id) {
                  rowParams[element.id] = value;
                }
              }
            });
            if (Object.keys(rowParams).length > 0) {
              if (filter.label === "Category") {
                searchParams.category = rowParams.category;
              } else if (filter.label === "Search Terms") {
                const generalizedRowParams =
                  this.generalizeFieldNames(rowParams);
                searchParams.searchTerms.push(generalizedRowParams);
              } else if (filter.label === "Restrict by Features") {
                searchParams.restrictByFeatures.push(rowParams);
              } else if (filter.label === "Restrict by Map Location") {
                searchParams.mapLocation.push(rowParams);
              }
            }
          });
        }
      });

      this.showResults(searchParams);
    },
    resetForm() {},
    showResults(inp_params) {
      this.$nextTick(() => {
        this.$router.push({
          path: "/results",
          query: {
            mainType: "detail",
            category: "dna",
            search_params: JSON.stringify(inp_params),
          },
        });
      });
    },
    updateElementsById(id, newElements) {
      let filter = this.search_form.filters.find((filter) => {
        if (filter.type === "rows") {
          let row = filter.rows.find((row) => {
            if (row.value.id === id) {
              row.value.elements = newElements;
              return true;
            }
          });
          return row;
        }
      });
      return filter;
    },
    updateRowsById(id, newRows) {
      let filter = this.search_form.filters.find((filter) => {
        if (filter.type === "rows") {
          if (filter.id === id) {
            filter.rows = newRows;
            return true;
          }
        }
      });
      return filter;
    },
    updateOptionsById(searchId, options) {
      for (let filter of this.search_form.filters) {
        for (let row of filter.rows) {
          if (row.value && row.value.elements) {
            let element = row.value.elements.find((el) => el.id === searchId);
            if (element) {
              if (element.options) {
                element.options = options;
                element.value = options[0].value;
              }
              break;
            }
          }
        }
      }
    },
    generalizeFieldNames(rowParams) {
      const generalizedParams = {};

      Object.keys(rowParams).forEach((key) => {
        if (key.startsWith("search_type_by_")) {
          generalizedParams["search_type"] = rowParams[key];
        } else if (key.startsWith("search_method_")) {
          generalizedParams["search_method"] = rowParams[key];
        } else if (key.startsWith("search_input_")) {
          generalizedParams["search_input"] = rowParams[key];
        } else {
          // Copy other fields as-is
          generalizedParams[key] = rowParams[key];
        }
      });

      return generalizedParams;
    },
  },
};
</script>

<style scoped lang="scss">
.locusLink {
  color: #2a568f;
  cursor: pointer;
  &:hover {
    background: #feffbb;
  }
}
.form-label {
  font-size: 18px;
  color: #777;
  font-weight: bold;
}
.keySearchColor {
  color: #ff0000;
}
</style>
