import { render, staticRenderFns } from "./GermplasmSearch.vue?vue&type=template&id=aaa4d964&scoped=true"
import script from "./GermplasmSearch.vue?vue&type=script&lang=js"
export * from "./GermplasmSearch.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aaa4d964",
  null
  
)

export default component.exports