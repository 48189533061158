<template>
  <div class="mt-4 mb-4">
    <div class="text-left fw-bold ml-8">
      <div class="d-flex ml-2">
        <h5>{{ filter.label }}</h5>
        <div class="mr-2" v-if="filter.helpLink">
          <a :href="filter.helpLink" target="_blank" style="color: black">
            <b-icon
              class="ml-2"
              :id="filter.id"
              icon="question-circle-fill"
            ></b-icon>
          </a>
        </div>
      </div>
    </div>
    <div class="ml-4 mt-2">
      <div v-for="(row, rowIndex) in filter.rows" :key="rowIndex" class="row">
        <div class="col-sm-4">
          <div class="d-flex">
            <p
              class="text-left fw-bold form-label pt-2"
              style="height: 20px; color: black"
            >
              {{ row.name }}
            </p>
            <div class="mr-2" v-if="row.helpLink">
              <a :href="row.helpLink" target="_blank" style="color: black">
                <b-icon
                  class="ml-2"
                  :id="row.id"
                  icon="question-circle-fill"
                ></b-icon>
              </a>
            </div>
          </div>
        </div>
        <div
          class="col-lg-8 col-md-12 col-sm-12 d-flex flex-column flex-md-row container"
        >
          <element-builder
            v-for="(element, index) in filter.rows[rowIndex].value.elements"
            :key="index"
            :element="element"
            @element-changed="$emit('element-changed', $event)"
            @on-enter="$emit('on-enter', $event)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ElementBuilder from "./ElementBuilder.vue";

export default {
  name: "FormSection",
  components: {
    ElementBuilder,
  },
  props: {
    filter: Object,
  },
};
</script>
<style scoped lang="scss">
.form-label {
  font-size: 18px;
  color: #777;
  font-weight: bold;
}
.col-lg-8.col-md-12.col-sm-12.d-flex.flex-column {
  align-items: flex-start;
}
@media (min-width: 768px) {
  .container > div {
    margin-left: 20px; /* Example margin size, adjust as needed */
  }
}
</style>
