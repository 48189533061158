<template>
  <div class="searchWrapper">
    <div class="pageTop">
      <div class="d-none d-md-block">
        <div class="d-flex flex-column">
          <b-breadcrumb :items="crumb_links"></b-breadcrumb>
          <div class="text-left ml-2 d-flex">
            <h4 style="color: #930">TAIR Gene Search</h4>
            <span class="ml-2"
              >[<a :href="helpLink" target="_blank">Help</a>]</span
            >
          </div>
          <div class="text-left m-2">
            Genes may be searched by name, keywords, features, and/or location.
            In TAIR, a Gene Model is defined as any description of a gene
            product from a variety of sources including computational
            prediction, mRNA sequencing, or genetic characterization. A locus is
            defined as the genomic sequence corresponding to a transcribed unit
            (e.g. AT2G03340) in the genome. In TAIR, many gene models can exist
            for a given locus, therefore a search for a gene may result in
            multiple hits for the same gene name.
          </div>
        </div>
      </div>
      <div class="d-md-none">
        <div class="row">
          <div class="col-4 font-weight-bold pl-2">Search Result</div>
          <div class="col-8">
            <b-dropdown id="dropdown-1" text="Menu" class="m-md-2">
              <b-dropdown-item>New Search</b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>
    </div>
    <div class="pageMain m-2">
      <div class="d-flex flex-column">
        <div class="row mt-2">
          <div class="col-sm d-flex align-items-start ml-4">
            <b-button size="sm" class="mr-2">Reset</b-button>
            <b-button size="sm" @click="onSubmit">Submit Query</b-button>
          </div>
        </div>
        <div>
          <div class="d-flex flex-column mx-4 my-2">
            <div v-for="(f, i) in search_form.filters" :key="i">
              <div class="text-left fw-bold ml-8">
                <div class="mr-2 d-flex">
                  <h5>{{ f.type }}</h5>
                  <a
                    v-if="f.helpLink"
                    :href="f.helpLink"
                    target="_blank"
                    style="color: black"
                  >
                    <b-icon
                      class="ml-2"
                      :id="f.id"
                      icon="question-circle-fill"
                    ></b-icon>
                  </a>
                </div>
              </div>
              <div class="ml-4 mt-2">
                <div v-for="(row, j) in f.rows" :key="j" class="row">
                  <div class="col-sm-4 d-flex">
                    <p class="text-left fw-bold form-label">{{ row.name }}</p>
                    <div class="mr-2" v-if="row.helpTxt">
                      <b-tooltip
                        :target="'help-' + row.id"
                        triggers="hover"
                        custom-class="custom-tooltip"
                      >
                        <p
                          v-html="row.helpTxt"
                          class="overflow-div d-flex flex-column text-left"
                        ></p>
                      </b-tooltip>
                      <b-icon
                        class="ml-2"
                        :id="'help-' + row.id"
                        icon="question-circle-fill"
                      ></b-icon>
                    </div>
                  </div>
                  <div class="col-sm-8">
                    <div
                      v-if="row.value && row.value.type == 'select'"
                      class="responsive-width mb-4"
                    >
                      <b-form-select
                        id="inline-form-custom-select-pref"
                        class="mb-2 mr-sm-2 mb-sm-0"
                        :options="row.value.options"
                        :value="row.value.selected"
                        v-model="row.value.selected"
                      ></b-form-select>
                    </div>
                    <div
                      v-else-if="row.value && row.value.type == 'input'"
                      class="responsive-width mb-4"
                    >
                      <b-form-input
                        id="inline-form-input-name"
                        class="mb-2 mr-sm-2 mb-sm-0"
                        placeholder=""
                      ></b-form-input>
                    </div>
                    <div
                      v-else-if="row.value && row.value.type == 'multiInput'"
                      class="responsive-width mb-4"
                    >
                      <b-form-textarea
                        id="inline-form-textarea-name"
                        class="mb-2 mr-sm-2 mb-sm-0"
                        placeholder=""
                        rows="3"
                      ></b-form-textarea>
                    </div>
                    <div
                      v-else-if="row.value && row.value.type == 'multiSelect'"
                      class="responsive-width mb-4"
                    >
                      <b-form-select
                        v-if="row.value"
                        id="inline-form-custom-select-pref"
                        class="mb-2 mr-sm-2 mb-sm-0"
                        :options="row.value.options"
                        :value="row.value.selected"
                        v-model="row.value.selected"
                        :select-size="4"
                        multiple
                        @keyup.enter="onSubmit"
                      ></b-form-select>
                    </div>
                    <div
                      v-else-if="row.value && row.value.type == 'checkbox'"
                      class="text-left responsive-width mb-4"
                    >
                      <b-form-checkbox
                        :id="'checkbox-' + j"
                        :name="'checkbox-' + j"
                        v-model="row.value.checked"
                      >
                        {{ row.value.title }}
                      </b-form-checkbox>
                    </div>
                    <div
                      v-else-if="row.value && row.value.type == 'mixed'"
                      class="text-left mb-4 d-flex flex-row w-100 align-items-center"
                    >
                      <div v-for="(e, k) in row.value.elements" :key="k">
                        <div class="d-flex ml-2">
                          <div v-if="e.type == 'select'">
                            <b-form-select
                              id="inline-form-custom-select-pref"
                              class="mb-2 mr-sm-2 mb-sm-0"
                              :options="e.options"
                              :value="e.default"
                              v-model="e.selected"
                              @keyup.enter="onSubmit"
                            ></b-form-select>
                          </div>
                          <div v-else-if="e.type == 'input'">
                            <b-form-input
                              id="inline-form-input-name"
                              class="mb-2 mr-sm-2 mb-sm-0"
                              placeholder=""
                              :value="e.default"
                              v-model="e.value"
                              @keyup.enter="onSubmit"
                            ></b-form-input>
                          </div>
                          <div v-else-if="e.type == 'multiInput'">
                            <b-form-textarea
                              id="inline-form-textarea-name"
                              class="mb-2 mr-sm-2 mb-sm-0"
                              ref="textarea"
                              placeholder=""
                              rows="3"
                              v-model="textareaContent"
                            ></b-form-textarea>
                            <input
                              type="file"
                              id="fileInput"
                              @change="handleFileUpload"
                              style="display: none"
                            />
                            <b-button
                              size="sm"
                              @click="triggerFileInputClick"
                              class="mt-2"
                              >Upload</b-button
                            >
                          </div>
                          <div class="d-flex" v-else-if="e.type == 'checkbox'">
                            <b-form-checkbox
                              :id="'checkbox-' + j + '-' + k"
                              :name="'checkbox-' + j + '-' + k"
                              v-model="e.checked"
                              @keyup.enter="onSubmit"
                            >
                              {{ e.title }}
                            </b-form-checkbox>
                          </div>

                          <div class="d-flex" v-else-if="e.type == 'text'">
                            <span>{{ e.value }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else class="text-left"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-2 mb-4">
          <div class="col-sm d-flex align-items-start ml-4">
            <b-button size="sm" class="mr-2">Reset</b-button>
            <b-button size="sm" @click="onSubmit">Submit Query</b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GeneSearch",
  data() {
    return {
      textareaContent: "",
      crumb_links: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "Search",
          href: "/search/overview",
        },
        {
          text: "Genes",
          active: true,
        },
      ],
      search_form: {
        filters: [
          {
            type: "Search by Name or Phenotype",
            rows: [
              {},
              // {
              //   name: "Species",
              //   value: {
              //     type: "select",
              //     options: [
              //       "any",
              //       "Arabidopsis thaliana",
              //       "Arabidopsis lyrata",
              //     ],
              //     default: "Arabidopsis thaliana",
              //     selected: "Arabidopsis thaliana",
              //   },
              // },
              {
                name: "Search Text",
                value: {
                  type: "mixed",
                  elements: [
                    {
                      type: "select",
                      id: "search-type",
                      options: ["contains", "starts with", "exact"],
                      default: "contains",
                      selected: "contains",
                    },
                    {
                      type: "select",
                      id: "search-by",
                      options: [
                        "Gene Name",
                        "Description",
                        "Phenotype",
                        "Uniprot ID",
                        "Locus TAIR Object ID",
                        "Gene TAIR Object ID",
                      ],
                      default: "Gene Name",
                      selected: "Gene Name",
                    },
                    {
                      type: "input",
                      value: "",
                      default: "",
                    },
                    // {
                    //   type: "checkbox",
                    //   title: "Exact Match",
                    //   checked: false,
                    // },
                  ],
                },
              },
              {
                name: "Include obsoleted genes",
                value: {
                  type: "checkbox",
                  title: "",
                  checked: false,
                },
              },
            ],
          },
          {
            type: "Search by Gene List",
            rows: [
              {
                name: "Upload or type a list of AGI locus or gene IDs (separated by line return and no trailing spaces)",
                value: {
                  type: "mixed",
                  elements: [
                    {
                      type: "select",
                      options: ["AGI Locus IDs", "AGI Gene IDs"],
                      default: "AGI Locus IDs",
                      selected: "AGI Locus IDs",
                    },
                    {
                      type: "multiInput",
                      value: "",
                      default: "",
                    },
                  ],
                },
              },
            ],
          },
          {
            type: "Search by Associated Keyword",
            rows: [
              {
                name: "Keyword Term",
                value: {
                  type: "mixed",
                  elements: [
                    // {
                    //   type: "select",
                    //   options: ["contains"],
                    //   default: "contains"
                    // },
                    {
                      type: "input",
                      value: "",
                      default: "",
                    },
                  ],
                },
                helpTxt:
                  " Keywords in TAIR are controlled vocabulary terms used to describe a variety of characteristics of data. For example, functional annotation of genes includes association to keywords describing the molecular function, subcellular localization and biological process attributed to a gene as well as anatomical and temporal information related to its expression. The primary sources of keywords used in TAIR are the Gene Ontology Consortium and Arabidopsis anatomy and development terms developed as a collaboration between TAIR and other plant genome databases.",
                id: "keywordTerm",
              },
              // {
              //   name: "GO/PO ID (exact match only)",
              //   value: {
              //     type: "input"
              //   }
              // },
              {
                name: "Keyword Type",
                value: {
                  type: "multiSelect",
                  options: [
                    "Any",
                    "GO Molecular Function",
                    "GO Biological Process",
                    "GO Cellular Component",
                    "PO Anatomy",
                    "PO Developmental Stage",
                  ],
                  selected: ["Any"],
                },
              },
              {
                name: "Evidence",
                value: {
                  type: "multiSelect",
                  options: [
                    {
                      value: "inferred from experiment",
                      text: "inferred from experiment (EXP)",
                    },
                    {
                      value: "inferred from electronic annotation",
                      text: "inferred from electronic annotation (IEA)",
                    },
                    {
                      value: "inferred from expression pattern",
                      text: "inferred from expression pattern (IEP)",
                    },
                    {
                      value: "inferred from genetic interaction",
                      text: "inferred from genetic interaction (IGI)",
                    },
                    {
                      value: "inferred from mutant phenotype",
                      text: "inferred from mutant phenotype (IMP)",
                    },
                    {
                      value: "inferred from physical interaction",
                      text: "inferred from physical interaction (IPI)",
                    },
                    {
                      value: "inferred from sequence or structural similarity",
                      text: "inferred from sequence or structural similarity (ISS)",
                    },
                    {
                      value: "non-traceable author statement",
                      text: "non-traceable author statement (NAS)",
                    },
                    {
                      value: "no biological data available",
                      text: "no biological data available (ND)",
                    },
                    {
                      value: "traceable author statement",
                      text: "traceable author statement (TAS)",
                    },
                    {
                      value: "inferred by curator",
                      text: "inferred by curator (IC)",
                    },
                    {
                      value: "inferred from reviewed computational analysis",
                      text: "inferred from reviewed computational analysis (RCA)",
                    },
                    {
                      value: "Inferred from Biological aspect of Ancestor",
                      text: "Inferred from Biological aspect of Ancestor (IBA)",
                    },
                    {
                      value: "Inferred from Biological aspect of Descendant",
                      text: "Inferred from Biological aspect of Descendant (IBD)",
                    },
                    {
                      value: "Inferred from Key Residues",
                      text: "Inferred from Key Residues (IKR)",
                    },
                    {
                      value: "Inferred from Rapid Divergence",
                      text: "Inferred from Rapid Divergence (IRD)",
                    },
                    {
                      value: "inferred from sequence model",
                      text: "inferred from sequence model (ISM)",
                    },
                    {
                      value: "inferred from high throughput direct assay",
                      text: "inferred from high throughput direct assay (HDA)",
                    },
                    {
                      value: "inferred from high throughput expression pattern",
                      text: "inferred from high throughput expression pattern (HEP)",
                    },
                    {
                      value:
                        "inferred from high throughput genetic interaction",
                      text: "inferred from high throughput genetic interaction (HGI)",
                    },
                    {
                      value: "inferred from high throughput mutant phenotype",
                      text: "inferred from high throughput mutant phenotype (HMP)",
                    },
                  ],
                  selected: ["Any"],
                },
                helpTxt:
                  "A controlled vocabulary of codes used to identify the type of evidence supporting an annotation. The codes are intended to provide a quick way to ascertain the strength of the evidence. For example, the code IEA is used for annotations that are made based purely by computational methods whereas IDA means that the annotation is supported by a direct assay (experimental data). The codes can be used to rank the quality of annotations.",
                id: "evidence",
              },
            ],
          },
          {
            type: "Restrict by Features",
            rows: [
              {
                name: "Gene Model Type",
                value: {
                  type: "multiSelect",
                  options: [
                    "Any",
                    "pre trna",
                    "transposable element gene",
                    "protein coding",
                    "pseudogene",
                    "ribosomal rna",
                    "small nuclear rna",
                    "small nucleolar rna",
                    "miRNA",
                    "other rna",
                    "unknown",
                    "long noncoding rna",
                    "antisense long noncoding rna",
                    "antisense rna",
                    "novel transcribed region",
                    "uORF",
                    "miRNA primary transcript",
                  ],
                  selected: ["Any"],
                },
                helpTxt:
                  "This feature allows you to restrict your search to specific types of gene models (?).The default search returns any gene model type. To select mutiple gene model types after your first selection click on additional ones while holding down either the CTRL key (PCs) or the Apple key (Mac).",
                id: "geneModelType",
              },
              {
                name: "Advanced",
                value: {
                  type: "multiSelect",
                  options: [
                    "has associated literature",
                    "genetic (unsequenced) loci only",
                  ],
                  selected: [],
                },
                selected: null,
              },
              // },
              // {
              //   name: "Time Restriction",
              //   value: {
              //     type: "checkbox",
              //     title: "only search last 2 months"
              //   }
              // }
            ],
          },
          {
            type: "Restrict by Map Locations",
            rows: [
              {
                name: "Chromosome",
                value: {
                  type: "select",
                  options: [
                    "1",
                    "2",
                    "3",
                    "4",
                    "5",
                    "chloroplast genome",
                    "mitochondrial genome",
                  ],
                  selected: null,
                },
                selected: null,
                id: "chromosome",
                helpTxt:
                  "Lets you limit your search to a single chromosome. There are five nuclear chromosomes in Arabidopsis: 1, 2, 3, 4, and 5 and also the mitochondrial and chloroplast genomes.",
              },
              {
                name: "Map Type",
                value: {
                  type: "select",
                  options: [
                    {
                      value: "AGI",
                      text: "reference assembly (Araport11 release)",
                    },
                    {
                      value: "physical",
                      text: "physical",
                    },
                    {
                      value: "classical",
                      text: "classical",
                    },
                  ],
                  selected: null,
                },
                helpTxt:
                  "Lets you search entities by their position on a particular map and is to be used with the Range parameters. Currently, you can search on only one map type at a time. To search on multiple map types, use the TAIR Map ViewerTo search and browse the genome down to the nucleotide level, use the TAIR SeqViewer.",
                id: "mapType",
              },
              {
                name: "Range",
                value: {
                  type: "mixed",
                  elements: [
                    {
                      // type: "select",
                      // options: ["between", "around"],
                      // default: "between",
                      // selected: "between"
                    },
                    {
                      type: "input",
                      id: "mapRange1",
                      value: null,
                      default: null,
                    },
                    {
                      type: "text",
                      value: "and",
                    },
                    {
                      type: "input",
                      id: "mapRange2",
                      value: null,
                      default: null,
                    },
                    {
                      type: "select",
                      options: ["kbp"],
                      default: "kbp",
                      selected: "kbp",
                    },
                  ],
                },
                helpTxt: `Lets you specify a range search by the upper and lower bounds (when you select "Between") or a center point (when you select "Around"). The value is interpreted based on the selected range units. You can specify the range by genetic distance (cM), physical distance (kb), and by clone or by marker or gene names. When you select "Between" from the drop-down menu, your search will be within the range defined by two entities or positions on a particular map. When you select "Around" from the drop-down menu, your search will be the area +/-5 cM and/or +/- 150 kb from the specified entity or position. When you search around, the second value input and units options are disabled.`,
                id: "range",
              },
            ],
          },
        ],
      },
      helpLink:
        "https://conf.phoenixbioinformatics.org/display/COM/Gene+Search+and+Results",
    };
  },
  watch: {
    "$route.query.key": function (id) {
      this.$forceUpdate();
    },
  },
  components: {},
  mounted() {},
  methods: {
    triggerFileInputClick() {
      document.getElementById("fileInput").click();
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          // Set the content of the textarea to the file contents
          document.getElementById("inline-form-textarea-name").value =
            // e.target.result;
            this.textareaContent = e.target.result;
        };
        reader.readAsText(file);
      }
    },
    async onSubmit(event) {
      event.preventDefault();
      // Helper function to get a filter by its name
      const getFilterByName = (name) =>
        this.search_form.filters.find((filter) => filter.type === name);

      // Helper function to get a row by its name within a filter
      const getRowByName = (filter, name) =>
        filter.rows.find((row) => row.name === name);

      //Search Params
      const taxonName = "Arabidopsis thaliana";
      // Retrieve row details from "Search by Name or Phenotype" filter
      const searchFilter = getFilterByName("Search by Name or Phenotype");
      const searchRow = getRowByName(searchFilter, "Search Text");
      const obsoleteRow = getRowByName(searchFilter, "Include obsoleted genes");
      const searchBy = searchRow.value.elements.find(
        (el) => el.id === "search-by"
      ).selected;
      const searchType = searchRow.value.elements.find(
        (el) => el.id === "search-type"
      ).selected;
      const detailSearchText = searchRow.value.elements.find(
        (el) => el.type === "input"
      ).value;
      const include_obsolete = obsoleteRow.value.checked;

      //Retrieve row details from "Search by Gene List" filter
      const geneListFilter = getFilterByName("Search by Gene List");
      const geneListRow = getRowByName(
        geneListFilter,
        "Upload or type a list of AGI locus or gene IDs (separated by line return and no trailing spaces)"
      );
      //   let geneList = geneListRow.value.elements.find(
      //     (el) => el.type === "multiInput"
      //   ).value;
      const geneListUploadType = geneListRow.value.elements.find(
        (el) => el.type === "select"
      ).selected;
      let geneList = this.textareaContent;
      geneList = geneList.toLocaleUpperCase();

      //replace all '\n' with ';'
      geneList = geneList.replace(/\r/g, "");
      geneList = geneList.replace(/\n/g, ";");

      // Retrieve row details from "Search by Associated Keyword" filter
      const keywordFilter = getFilterByName("Search by Associated Keyword");
      const keywordTermRow = getRowByName(keywordFilter, "Keyword Term");
      const keywordTypeRow = getRowByName(keywordFilter, "Keyword Type");
      const evidenceRow = getRowByName(keywordFilter, "Evidence");
      const keywordTerm = keywordTermRow.value.elements.find(
        (el) => el.type === "input"
      ).value;
      const keywordType = keywordTypeRow.value.selected;
      const evidence = evidenceRow.value.selected;

      // Retrieve row details from "Restrict by Features" filter
      const featuresFilter = getFilterByName("Restrict by Features");
      const geneModelTypeRow = getRowByName(featuresFilter, "Gene Model Type");
      const advancedRow = getRowByName(featuresFilter, "Advanced");
      const geneModelType = geneModelTypeRow.value.selected;
      const advanced = advancedRow.value.selected;

      // Retrieve row details from "Restrict by Map Locations" filter
      const mapFilter = getFilterByName("Restrict by Map Locations");
      const chromosomeRow = getRowByName(mapFilter, "Chromosome");
      const mapTypeRow = getRowByName(mapFilter, "Map Type");
      const rangeRow = getRowByName(mapFilter, "Range");

      const chromosome = chromosomeRow.value.selected;
      const mapType = mapTypeRow.value.selected;
      const startPos = rangeRow.value.elements
        .filter((el) => el.id === "mapRange1")
        .map((el) => el.value);
      const endPos = rangeRow.value.elements
        .filter((el) => el.id === "mapRange2")
        .map((el) => el.value);
      const gaUnit = rangeRow.value.elements
        .filter((el) => el.type === "select")
        .map((el) => el.selected);

      const search_params = {
        geneListUploadType,
        geneList,
        taxonName,
        searchType,
        searchBy,
        detailSearchText,
        include_obsolete,
        keywordTerm,
        keywordType,
        evidence,
        geneModelType,
        advanced,
        chromosome,
        mapType,
        startPos,
        endPos,
        gaUnit,
        lociMatchCount: 0,
        geneModelCount: 0,
      };

      // Call the function to show results
      this.showResults(search_params);
    },
    onReset(event) {
      event.preventDefault();
    },
    showResults(inp_params) {
      let key = "searchParams_" + Date.now();
      localStorage.setItem(key, JSON.stringify(inp_params));
      this.$nextTick(() => {
        this.$router.push({
          path: "/results",
          query: {
            mainType: "detail",
            category: "genes",
            //search_params: JSON.stringify(inp_params),
            key: key,
          },
        });
      });
    },
  },
};
</script>

<style scoped lang="scss">
.responsive-width {
  width: 100%;
}

@media (min-width: 768px) {
  .responsive-width {
    width: 50%;
  }
}

.locusLink {
  color: #2a568f;
  cursor: pointer;
  &:hover {
    background: #feffbb;
  }
}
.form-label {
  font-size: 18px;
  color: black;
  font-weight: bold;
}
.keySearchColor {
  color: #ff0000;
}
</style>
