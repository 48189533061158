<template>
  <div class="content-wrapper">
    <h2>{{ pageTitle }}</h2>
    <div class="items-panel">
      <div
        v-for="(item, i) in getDownloadItemsWithDescription"
        :key="i"
        class="download-item"
      >
        <h3 class="pg-link" @click="navToPage(i)">{{ item.title }}</h3>
        <p v-html="item.description"></p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "DownloadOverview",
  data() {
    return {
      pageTitle: "Download Overview",
    };
  },

  computed: {
    ...mapGetters("downloadItems", ["getDownloadItemsWithDescription"]),
  },
  methods: {
    navToPage(index) {
      const route = this.getDownloadItemsWithDescription[index].route;
      // Check if the route is an external URL
      if (this.isExternal(route)) {
        // Open the external URL
        window.location.href = route;
      } else {
        // Use Vue Router for internal routes
        this.$router.push(route);
      }
    },

    // Utility method to check if a URL is external
    isExternal(url) {
      // A simple regex to test for "http://" or "https://" at the start of the URL
      // Adjust as needed for your use case
      const externalRegex = /^(https?|ftp|mailto)?:\/\//;
      return externalRegex.test(url);
    },
  },
};
</script>

<style scoped lang="scss"></style>
