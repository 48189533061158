import PostsService from "../PostsService";

export async function loadResults(search_params) {
  let fields = [
    { key: "row_num", label: " # ", thStyle: { width: "25px" } },
    { key: "selected", label: "", thStyle: { width: "50px" } },
    {
      key: "name",
      label: "Protocol Title",
      cellType: "name_link",
    },
  ];
  let items = [];
  try {
    let response = await PostsService.fetchProtocolSearch(search_params);
    if (response.data.error) {
      return { error: true, items: [], fields: [] };
    }
    let docs = response.data.docs;

    let totalMatch = response.data.total;

    for (const d of docs) {
      let htmlText = `<p class="text-left">`;
      if (d.authors) {
        htmlText += `<b>Authors: </b>${d.authors[0]}<br>`;
      }
      if (d.description) {
        htmlText += `<b>Description: </b>${d.description[0]}<br>`;
      }
      if (d.keywords && d.keywords.length > 0) {
        htmlText += `<b>Keywords: </b>${d.keywords.join(", ")}`;
      }

      htmlText += `</p>`;
      items.push({
        id: d.id,
        selected: false,
        name: { name: d.title[0], link: `/protocol?id=${d.protocolId}` },
        _showDetails: true,
        html: htmlText,
        originalRow: d,
      });
    }
    search_params.matchCount = totalMatch;

    let queryStatement = setQueryStatement(search_params);
    return {
      error: false,
      items: items,
      fields: fields,
      queryStatement: queryStatement,
    };
  } catch (error) {
    return { error: true, items: [], fields: [] };
  }
}
function setQueryStatement(params) {
  let queryStatement = "Your query for protocols";
  let queryParts = [];
  let searchParts = [];
  params.searchTerms.forEach((s) => {
    if (s.search_input) {
      searchParts.push(
        `${s.search_type} ${s.search_method} <span class="highlight-term">${s.search_input}</span>`
      );
    }
    if (s.keywords) {
      searchParts.push(
        `keywords are <span class="highlight-term">${s.keywords.join(
          ", "
        )}</span>`
      );
    }
  });
  if (searchParts.length > 0) {
    queryParts.push(searchParts.join(" and "));
  }
  if (queryParts.length > 0) {
    queryStatement += " where ";
    queryStatement += queryParts.join(" and ");
  }

  queryStatement += ` resulted in <span style="color: green">${params.matchCount}</span> matches`;
  return queryStatement;
}
