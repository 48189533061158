<template>
  <div class="searchWrapper">
    <div class="pageTop">
      <div class="d-none d-md-block">
        <div class="d-flex flex-column">
          <b-breadcrumb :items="crumb_links"></b-breadcrumb>
          <div class="text-left ml-2 d-flex">
            <h4 style="color: #930">TAIR Community Search</h4>
            <span class="ml-2"
              >[<a :href="helpLink" target="_blank">Help</a>]</span
            >
          </div>
          <div class="text-left ml-2">
            <p>
              Use this form to find People and Organizations in TAIR's
              Community. Use the input boxes and drop down menus to define your
              parameters, restrict your search and define your output options.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="pageMain m-2">
      <div class="d-flex flex-column">
        <div class="row mt-2">
          <div class="col-sm d-flex align-items-start ml-4">
            <b-button size="sm" class="mr-2" @click="resetForm">Reset</b-button>
            <b-button size="sm" @click="onSubmit">Submit Query</b-button>
          </div>
        </div>
        <form-section
          v-for="(filter, index) in search_form.filters"
          :key="index"
          :filter="filter"
          @element-changed="onElementChanged"
          @on-enter="onSubmit"
        />
        <div class="row mt-2 pb-2">
          <div class="col-sm d-flex align-items-start ml-4">
            <b-button size="sm" class="mr-2" @click="resetForm">Reset</b-button>
            <b-button size="sm" @click="onSubmit">Submit Query</b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormSection from "../../components/common/FormSection.vue";

export default {
  name: "CommunitySearch",
  components: {
    FormSection,
  },
  data() {
    return {
      crumb_links: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "Search",
          href: "/search/overview",
        },
        {
          text: "People/Labs",
          active: true,
        },
      ],
      search_form: {
        filters: [
          {
            label: "Search Terms",
            type: "rows",
            rows: [
              {
                name: "Search For",
                value: {
                  type: "mixed",
                  elements: [
                    {
                      type: "select",
                      options: [
                        { text: "Any", value: "any" },
                        { text: "person", value: "person" },
                        { text: "organization", value: "organization" },
                      ],
                      value: "any",
                      id: "search_for",
                      size: "large",
                    },
                  ],
                },
              },
              {
                name: "Organization type",
                value: {
                  type: "mixed",
                  elements: [
                    {
                      type: "select",
                      options: [
                        { text: "Any", value: "any" },
                        { text: "4 Year College", value: "4_year_college" },
                        { text: "Center", value: "center" },
                        { text: "Collaboration", value: "collaboration" },
                        { text: "Committee", value: "committee" },
                        {
                          text: "Community College",
                          value: "community_college",
                        },
                        { text: "Company", value: "company" },
                        { text: "Database", value: "database" },
                        {
                          text: "Education/Outreach Program",
                          value: "education_outreach_program",
                        },
                        { text: "Facility", value: "facility" },
                        { text: "Foundation", value: "foundation" },
                        { text: "High School", value: "high_school" },
                        { text: "Institute", value: "institute" },
                        { text: "Lab", value: "lab" },
                        { text: "Middle School", value: "middle_school" },
                        { text: "Other", value: "other" },
                        { text: "Primary School", value: "primary_school" },
                        {
                          text: "Professional Society",
                          value: "professional_society",
                        },
                        { text: "Project", value: "project" },
                        { text: "Stock Center", value: "stock_center" },
                        { text: "University", value: "university" },
                        { text: "Unknown", value: "unknown" },
                      ],
                      value: "any",
                      id: "search_org_type",
                      size: "large",
                    },
                  ],
                },
              },
              {
                name: "Person's job title",
                value: {
                  type: "mixed",
                  elements: [
                    {
                      type: "select",
                      options: [
                        { text: "Any", value: "any" },
                        {
                          text: "Advisory Board Member",
                          value: "Advisory Board Member",
                        },
                        {
                          text: "Assistant Professor",
                          value: "Assistant Professor",
                        },
                        {
                          text: "Associate Professor",
                          value: "Associate Professor",
                        },
                        { text: "Board Member", value: "Board Member" },
                        { text: "Coordinator", value: "Coordinator" },
                        { text: "Curator", value: "Curator" },
                        { text: "Director", value: "Director" },
                        {
                          text: "Elementary School Student",
                          value: "Elementary School Student",
                        },
                        {
                          text: "Elementary School Teacher",
                          value: "Elementary School Teacher",
                        },
                        { text: "Graduate Student", value: "Graduate Student" },
                        { text: "Group Leader", value: "Group Leader" },
                        {
                          text: "High School Student",
                          value: "High School Student",
                        },
                        {
                          text: "High School Teacher",
                          value: "High School Teacher",
                        },
                        { text: "Lecturer", value: "Lecturer" },
                        { text: "Librarian", value: "Librarian" },
                        {
                          text: "Middle School Student",
                          value: "Middle School Student",
                        },
                        {
                          text: "Middle School Teacher",
                          value: "Middle School Teacher",
                        },
                        {
                          text: "Post-Doctoral Researcher",
                          value: "Post-Doctoral Researcher",
                        },
                        { text: "President", value: "President" },
                        { text: "Professor", value: "Professor" },
                        { text: "Programmer", value: "Programmer" },
                        { text: "Project Leader", value: "Project Leader" },
                        {
                          text: "Research Assistant",
                          value: "Research Assistant",
                        },
                        {
                          text: "Research Associate",
                          value: "Research Associate",
                        },
                        { text: "Research Fellow", value: "Research Fellow" },
                        {
                          text: "Research Scientist",
                          value: "Research Scientist",
                        },
                        { text: "Secretary", value: "Secretary" },
                        { text: "Senior Lecturer", value: "Senior Lecturer" },
                        {
                          text: "Senior Research Officer",
                          value: "Senior Research Officer",
                        },
                        { text: "Teacher", value: "Teacher" },
                        {
                          text: "Undergraduate Student",
                          value: "Undergraduate Student",
                        },
                        { text: "Unknown", value: "Unknown" },
                        { text: "Other", value: "Other" },
                      ],
                      value: "any",
                      id: "search_job_title",
                      size: "large",
                    },
                  ],
                },
              },
              {
                name: "Last/Organization Name",
                value: {
                  type: "mixed",
                  elements: [
                    {
                      type: "select",
                      options: [
                        { text: "contains", value: "contains" },
                        { text: "starts with", value: "starts_with" },
                        { text: "exactly", value: "exactly" },
                      ],
                      value: "contains",
                      id: "search_method_1",
                      size: "medium",
                    },
                    {
                      type: "input",
                      value: "",
                      id: "org_name_input",
                      size: "large",
                    },
                  ],
                },
              },
              {
                name: "First Name",
                value: {
                  type: "mixed",
                  elements: [
                    {
                      type: "select",
                      options: [
                        { text: "contains", value: "contains" },
                        { text: "starts with", value: "starts_with" },
                        { text: "exactly", value: "exactly" },
                      ],
                      value: "contains",
                      id: "search_method_2",
                      size: "medium",
                    },
                    {
                      type: "input",
                      value: "",
                      id: "first_name_input",
                      size: "large",
                    },
                  ],
                },
              },
              {
                name: "Email",
                value: {
                  type: "mixed",
                  elements: [
                    {
                      type: "select",
                      options: [
                        { text: "contains", value: "contains" },
                        { text: "starts with", value: "starts_with" },
                        { text: "exactly", value: "exactly" },
                      ],
                      value: "contains",
                      id: "search_method_3",
                      size: "medium",
                    },
                    {
                      type: "input",
                      value: "",
                      id: "email_input",
                      size: "large",
                    },
                  ],
                },
              },
              {
                name: "City",
                value: {
                  type: "mixed",
                  elements: [
                    {
                      type: "select",
                      options: [
                        { text: "contains", value: "contains" },
                        { text: "starts with", value: "starts_with" },
                        { text: "exactly", value: "exactly" },
                      ],
                      value: "contains",
                      id: "search_method_4",
                      size: "medium",
                    },
                    {
                      type: "input",
                      value: "",
                      id: "city_input",
                      size: "large",
                    },
                  ],
                },
              },
              {
                name: "State/Province (US & Canada)",
                value: {
                  type: "mixed",
                  elements: [
                    {
                      type: "select",
                      options: [
                        { text: "Any", value: "any" },
                        { text: "Alabama", value: "AL" },
                        { text: "Alaska", value: "AK" },
                        { text: "Alberta", value: "AB" },
                        { text: "Arizona", value: "AZ" },
                        { text: "Arkansas", value: "AR" },
                        { text: "British Columbia", value: "BC" },
                        { text: "California", value: "CA" },
                        { text: "Canal Zone", value: "CZ" },
                        { text: "Colorado", value: "CO" },
                        { text: "Connecticut", value: "CT" },
                        { text: "Delaware", value: "DE" },
                        { text: "District of Columbia", value: "DC" },
                        { text: "Florida", value: "FL" },
                        { text: "Georgia", value: "GA" },
                        { text: "Guam", value: "GU" },
                        { text: "Hawaii", value: "HI" },
                        { text: "Idaho", value: "ID" },
                        { text: "Illinois", value: "IL" },
                        { text: "Indiana", value: "IN" },
                        { text: "Iowa", value: "IA" },
                        { text: "Kansas", value: "KS" },
                        { text: "Kentucky", value: "KY" },
                        { text: "Louisiana", value: "LA" },
                        { text: "Maine", value: "ME" },
                        { text: "Manitoba", value: "MB" },
                        { text: "Maryland", value: "MD" },
                        { text: "Massachusetts", value: "MA" },
                        { text: "Michigan", value: "MI" },
                        { text: "Minnesota", value: "MN" },
                        { text: "Mississippi", value: "MS" },
                        { text: "Missouri", value: "MO" },
                        { text: "Montana", value: "MT" },
                        { text: "Nebraska", value: "NE" },
                        { text: "Nevada", value: "NV" },
                        { text: "New Brunswick", value: "NB" },
                        { text: "New Hampshire", value: "NH" },
                        { text: "New Jersey", value: "NJ" },
                        { text: "New Mexico", value: "NM" },
                        { text: "New York", value: "NY" },
                        { text: "Newfoundland", value: "NF" },
                        { text: "North Carolina", value: "NC" },
                        { text: "North Dakota", value: "ND" },
                        { text: "Northwest Territories", value: "NT" },
                        { text: "Nova Scotia", value: "NS" },
                        { text: "Nunavut", value: "NU" },
                        { text: "Ohio", value: "OH" },
                        { text: "Oklahoma", value: "OK" },
                        { text: "Ontario", value: "ON" },
                        { text: "Oregon", value: "OR" },
                        { text: "Pennsylvania", value: "PA" },
                        { text: "Prince Edward Island", value: "PE" },
                        { text: "Puerto Rico", value: "PR" },
                        { text: "Quebec", value: "QC" },
                        { text: "Rhode Island", value: "RI" },
                        { text: "Saskatchewan", value: "SK" },
                        { text: "South Carolina", value: "SC" },
                        { text: "South Dakota", value: "SD" },
                        { text: "Tennessee", value: "TN" },
                        { text: "Texas", value: "TX" },
                        { text: "Utah", value: "UT" },
                        { text: "Vermont", value: "VT" },
                        { text: "Virgin Islands", value: "VI" },
                        { text: "Virginia", value: "VA" },
                        { text: "Washington", value: "WA" },
                        { text: "West Virginia", value: "WV" },
                        { text: "Wisconsin", value: "WI" },
                        { text: "Wyoming", value: "WY" },
                        { text: "Yukon Territory", value: "YT" },
                      ],
                      value: "any",
                      id: "search_state",
                      size: "large",
                    },
                  ],
                },
              },
              {
                name: "State/Province (Outside US & Canada)",
                value: {
                  type: "mixed",
                  elements: [
                    {
                      type: "input",
                      value: "",
                      id: "state_outside_input",
                      size: "large",
                    },
                  ],
                },
              },
              {
                name: "Country",
                value: {
                  type: "mixed",
                  elements: [
                    {
                      type: "select",
                      options: [
                        { text: "Any", value: "any" },
                        { text: "Albania", value: "Albania" },
                        { text: "Algeria", value: "Algeria" },
                        { text: "American Samoa", value: "American Samoa" },
                        { text: "Andorra", value: "Andorra" },
                        { text: "Angola", value: "Angola" },
                        { text: "Anguilla", value: "Anguilla" },
                        { text: "Antigua", value: "Antigua" },
                        { text: "Argentina", value: "Argentina" },
                        { text: "Armenia", value: "Armenia" },
                        { text: "Aruba", value: "Aruba" },
                        { text: "Australia", value: "Australia" },
                        { text: "Austria", value: "Austria" },
                        { text: "Azerbaijan", value: "Azerbaijan" },
                        { text: "Bahamas", value: "Bahamas" },
                        { text: "Bahrain", value: "Bahrain" },
                        { text: "Bangladesh", value: "Bangladesh" },
                        { text: "Barbados", value: "Barbados" },
                        { text: "Barbuda", value: "Barbuda" },
                        { text: "Belarus", value: "Belarus" },
                        { text: "Belgium", value: "Belgium" },
                        { text: "Belize", value: "Belize" },
                        { text: "Benin", value: "Benin" },
                        { text: "Bermuda", value: "Bermuda" },
                        { text: "Bhutan", value: "Bhutan" },
                        { text: "Bolivia", value: "Bolivia" },
                        { text: "Bonaire", value: "Bonaire" },
                        { text: "Botswana", value: "Botswana" },
                        { text: "Brazil", value: "Brazil" },
                        {
                          text: "British Virgin Islands",
                          value: "British Virgin Islands",
                        },
                        { text: "Brunei", value: "Brunei" },
                        { text: "Bulgaria", value: "Bulgaria" },
                        { text: "Burkina Faso", value: "Burkina Faso" },
                        { text: "Burundi", value: "Burundi" },
                        { text: "Cambodia", value: "Cambodia" },
                        { text: "Cameroon", value: "Cameroon" },
                        { text: "Canada", value: "Canada" },
                        { text: "Cape Verde", value: "Cape Verde" },
                        { text: "Cayman Islands", value: "Cayman Islands" },
                        {
                          text: "Central African Republic",
                          value: "Central African Republic",
                        },
                        { text: "Chad", value: "Chad" },
                        { text: "Channel Islands", value: "Channel Islands" },
                        { text: "Chile", value: "Chile" },
                        { text: "Colombia", value: "Colombia" },
                        {
                          text: "Congo-Brazzaville",
                          value: "Congo-Brazzaville",
                        },
                        { text: "Congo-Kinshasa", value: "Congo-Kinshasa" },
                        { text: "Cook Islands", value: "Cook Islands" },
                        { text: "Costa Rica", value: "Costa Rica" },
                        { text: "Croatia", value: "Croatia" },
                        { text: "Cuba", value: "Cuba" },
                        { text: "Curacao", value: "Curacao" },
                        { text: "Cyprus", value: "Cyprus" },
                        { text: "Czech Republic", value: "Czech Republic" },
                        { text: "Denmark", value: "Denmark" },
                        { text: "Djibouti", value: "Djibouti" },
                        { text: "Dominica", value: "Dominica" },
                        {
                          text: "Dominican Republic",
                          value: "Dominican Republic",
                        },
                        { text: "Ecuador", value: "Ecuador" },
                        { text: "Egypt", value: "Egypt" },
                        { text: "El Salvador", value: "El Salvador" },
                        {
                          text: "Equatorial Guinea",
                          value: "Equatorial Guinea",
                        },
                        { text: "Eritrea", value: "Eritrea" },
                        { text: "Estonia", value: "Estonia" },
                        { text: "Ethiopia", value: "Ethiopia" },
                        { text: "Faroe Islands", value: "Faroe Islands" },
                        { text: "Fiji", value: "Fiji" },
                        { text: "Finland", value: "Finland" },
                        { text: "France", value: "France" },
                        { text: "French Guiana", value: "French Guiana" },
                        { text: "French Polynesia", value: "French Polynesia" },
                        { text: "Gabon", value: "Gabon" },
                        { text: "Gambia", value: "Gambia" },
                        { text: "Georgia", value: "Georgia" },
                        { text: "Germany", value: "Germany" },
                        { text: "Ghana", value: "Ghana" },
                        { text: "Gibraltar", value: "Gibraltar" },
                        { text: "Greece", value: "Greece" },
                        { text: "Greenland", value: "Greenland" },
                        { text: "Grenada", value: "Grenada" },
                        { text: "Guadeloupe", value: "Guadeloupe" },
                        { text: "Guam", value: "Guam" },
                        { text: "Guatemala", value: "Guatemala" },
                        { text: "Guinea", value: "Guinea" },
                        { text: "Guinea Bissau", value: "Guinea Bissau" },
                        { text: "Guyana", value: "Guyana" },
                        { text: "Haiti", value: "Haiti" },
                        { text: "Honduras", value: "Honduras" },
                        { text: "Hong Kong", value: "Hong Kong" },
                        { text: "Hungary", value: "Hungary" },
                        { text: "Iceland", value: "Iceland" },
                        { text: "India", value: "India" },
                        { text: "Indonesia", value: "Indonesia" },
                        { text: "Iran", value: "Iran" },
                        { text: "Iraq", value: "Iraq" },
                        { text: "Ireland", value: "Ireland" },
                        { text: "Israel", value: "Israel" },
                        { text: "Italy", value: "Italy" },
                        { text: "Ivory Coast", value: "Ivory Coast" },
                        { text: "Jamaica", value: "Jamaica" },
                        { text: "Japan", value: "Japan" },
                        { text: "Jordan", value: "Jordan" },
                        { text: "Kazakhstan", value: "Kazakhstan" },
                        { text: "Kenya", value: "Kenya" },
                        { text: "Korea", value: "Korea" },
                        { text: "Kuwait", value: "Kuwait" },
                        { text: "Kyrgyzstan", value: "Kyrgyzstan" },
                        { text: "Latvia", value: "Latvia" },
                        { text: "Lebanon", value: "Lebanon" },
                        { text: "Lesotho", value: "Lesotho" },
                        { text: "Liberia", value: "Liberia" },
                        { text: "Libya", value: "Libya" },
                        { text: "Liechtenstein", value: "Liechtenstein" },
                        { text: "Lithuania", value: "Lithuania" },
                        { text: "Luxembourg", value: "Luxembourg" },
                        { text: "Macau", value: "Macau" },
                        { text: "Macedonia", value: "Macedonia" },
                        { text: "Madagascar", value: "Madagascar" },
                        { text: "Malawi", value: "Malawi" },
                        { text: "Malaysia", value: "Malaysia" },
                        { text: "Mali", value: "Mali" },
                        { text: "Malta", value: "Malta" },
                        { text: "Marshall Islands", value: "Marshall Islands" },
                        { text: "Martinique", value: "Martinique" },
                        { text: "Mauritania", value: "Mauritania" },
                        { text: "Mauritius", value: "Mauritius" },
                        { text: "Mexico", value: "Mexico" },
                        { text: "Micronesia", value: "Micronesia" },
                        { text: "Moldova", value: "Moldova" },
                        { text: "Monaco", value: "Monaco" },
                        { text: "Mongolia", value: "Mongolia" },
                        { text: "Montserrat", value: "Montserrat" },
                        { text: "Morocco", value: "Morocco" },
                        { text: "Mozambique", value: "Mozambique" },
                        { text: "Myanmar/Burma", value: "Myanmar/Burma" },
                        { text: "Namibia", value: "Namibia" },
                        { text: "Nepal", value: "Nepal" },
                        { text: "Netherlands", value: "Netherlands" },
                        {
                          text: "Netherlands Antilles",
                          value: "Netherlands Antilles",
                        },
                        { text: "New Caledonia", value: "New Caledonia" },
                        { text: "New Zealand", value: "New Zealand" },
                        { text: "Nicaragua", value: "Nicaragua" },
                        { text: "Niger", value: "Niger" },
                        { text: "Nigeria", value: "Nigeria" },
                        { text: "Norway", value: "Norway" },
                        { text: "Oman", value: "Oman" },
                        { text: "Pakistan", value: "Pakistan" },
                        { text: "Palau", value: "Palau" },
                        { text: "Panama", value: "Panama" },
                        { text: "Papua New Guinea", value: "Papua New Guinea" },
                        { text: "Paraguay", value: "Paraguay" },
                        {
                          text: "People's Republic of China",
                          value: "People's Republic of China",
                        },
                        { text: "Peru", value: "Peru" },
                        { text: "Philippines", value: "Philippines" },
                        { text: "Poland", value: "Poland" },
                        { text: "Portugal", value: "Portugal" },
                        { text: "Puerto Rico", value: "Puerto Rico" },
                        { text: "Qatar", value: "Qatar" },
                        { text: "Reunion", value: "Reunion" },
                        { text: "Romania", value: "Romania" },
                        { text: "Russia", value: "Russia" },
                        { text: "Rwanda", value: "Rwanda" },
                        { text: "Saba", value: "Saba" },
                        { text: "Saipan", value: "Saipan" },
                        { text: "San Marino", value: "San Marino" },
                        { text: "Saudi Arabia", value: "Saudi Arabia" },
                        { text: "Senegal", value: "Senegal" },
                        { text: "Seychelles", value: "Seychelles" },
                        { text: "Sierra Leone", value: "Sierra Leone" },
                        { text: "Singapore", value: "Singapore" },
                        { text: "Slovak Republic", value: "Slovak Republic" },
                        { text: "Slovenia", value: "Slovenia" },
                        { text: "Somalia", value: "Somalia" },
                        { text: "South Africa", value: "South Africa" },
                        { text: "Spain", value: "Spain" },
                        { text: "Sri Lanka", value: "Sri Lanka" },
                        { text: "St. Barthelemy", value: "St. Barthelemy" },
                        { text: "St. Croix", value: "St. Croix" },
                        { text: "St. Eustatius", value: "St. Eustatius" },
                        {
                          text: "St. Kitts and Nevis",
                          value: "St. Kitts and Nevis",
                        },
                        { text: "St. Lucia", value: "St. Lucia" },
                        {
                          text: "St. Maarten/St. Martin",
                          value: "St. Maarten/St. Martin",
                        },
                        { text: "St. Thomas", value: "St. Thomas" },
                        { text: "St. Vincent", value: "St. Vincent" },
                        { text: "Sudan", value: "Sudan" },
                        { text: "Suriname", value: "Suriname" },
                        { text: "Swaziland", value: "Swaziland" },
                        { text: "Sweden", value: "Sweden" },
                        { text: "Switzerland", value: "Switzerland" },
                        { text: "Syria", value: "Syria" },
                        { text: "Tadjikistan", value: "Tadjikistan" },
                        { text: "Taiwan", value: "Taiwan" },
                        { text: "Tanzania", value: "Tanzania" },
                        { text: "Thailand", value: "Thailand" },
                        { text: "Togo", value: "Togo" },
                        { text: "Tortola", value: "Tortola" },
                        {
                          text: "Trinidad and Tobago",
                          value: "Trinidad and Tobago",
                        },
                        { text: "Tunisia", value: "Tunisia" },
                        { text: "Turkey", value: "Turkey" },
                        { text: "Turkmenistan", value: "Turkmenistan" },
                        {
                          text: "Turks and Caicos Islands",
                          value: "Turks and Caicos Islands",
                        },
                        {
                          text: "U.S. Virgin Islands",
                          value: "U.S. Virgin Islands",
                        },
                        { text: "USA", value: "USA" },
                        { text: "Uganda", value: "Uganda" },
                        { text: "Ukraine", value: "Ukraine" },
                        {
                          text: "United Arab Emirates",
                          value: "United Arab Emirates",
                        },
                        { text: "United Kingdom", value: "United Kingdom" },
                        { text: "Uruguay", value: "Uruguay" },
                        { text: "Uzbekistan", value: "Uzbekistan" },
                        { text: "Vanuatu", value: "Vanuatu" },
                        { text: "Vatican City", value: "Vatican City" },
                        { text: "Venezuela", value: "Venezuela" },
                        { text: "Vietnam", value: "Vietnam" },
                        {
                          text: "Wallis and Futuna",
                          value: "Wallis and Futuna",
                        },
                        { text: "Yemen", value: "Yemen" },
                        { text: "Yugoslavia", value: "Yugoslavia" },
                        { text: "Zambia", value: "Zambia" },
                        { text: "Zimbabwe", value: "Zimbabwe" },
                      ],
                      value: "any",
                      id: "search_country_select",
                      size: "large",
                    },
                  ],
                },
                id: "search_country",
              },
              {
                name: "URL",
                value: {
                  type: "mixed",
                  elements: [
                    {
                      type: "select",
                      options: [
                        { text: "contains", value: "contains" },
                        { text: "starts with", value: "starts_with" },
                        { text: "exactly", value: "exactly" },
                      ],
                      value: "contains",
                      id: "search_method_5",
                      size: "medium",
                    },
                    {
                      type: "input",
                      value: "",
                      id: "url_input",
                      size: "large",
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
      helpLink:
        "https://conf.phoenixbioinformatics.org/pages/viewpage.action?pageId=155058233",
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    resetForm() {
      // Reset form logic
    },
    init() {
      document.title = `Community Search`;
    },

    generalizeFieldNames(rowParams) {
      const generalizedParams = {};

      Object.keys(rowParams).forEach((key) => {
        if (key.startsWith("search_type_by_")) {
          generalizedParams["search_type"] = rowParams[key];
        } else if (key.startsWith("search_method_")) {
          generalizedParams["search_method"] = rowParams[key];
        } else if (key.startsWith("search_input_")) {
          generalizedParams["search_input"] = rowParams[key];
        } else {
          // Copy other fields as-is
          generalizedParams[key] = rowParams[key];
        }
      });

      return generalizedParams;
    },
    onSubmit() {
      event.preventDefault();
      let searchParams = {
        searchTerms: [],
      };

      this.search_form.filters.forEach((filter) => {
        if (filter.type === "rows") {
          filter.rows.forEach((row) => {
            let rowParams = {};
            row.value.elements.forEach((element) => {
              let shouldAddParam = true;
              let value = element.value;

              if (element.type === "checkbox") {
                value = element.value;
              }

              if (!value || value === false || value === "any") {
                shouldAddParam = false;
              }

              //check if value is an array
              if (Array.isArray(value)) {
                if (
                  value.length === 0 ||
                  (value.length === 1 && value[0] === "any")
                ) {
                  shouldAddParam = false;
                }
              }

              if (shouldAddParam) {
                if (element.id) {
                  rowParams[element.id] = value;
                }
              }
            });
            if (Object.keys(rowParams).length > 0) {
              if (filter.label === "Search Terms") {
                const generalizedRowParams =
                  this.generalizeFieldNames(rowParams);
                searchParams.searchTerms.push(generalizedRowParams);
              } else if (filter.label === "Search by Associated Keyword") {
                searchParams.keywordTerms.push(rowParams);
              }
            }
          });
        }
      });

      let searchTermsFiltered = searchParams.searchTerms.filter(function (el) {
        // Check if the object has the 'search_method' property
        if (el.search_method) {
          // If 'search_method' is the only property, filter it out
          return Object.keys(el).length !== 1;
        }
        // Keep the element if it does not have 'search_method' property
        return true;
      });
      searchParams.searchTerms = searchTermsFiltered;

      this.showResults(searchParams);
    },
    onReset(event) {
      event.preventDefault();
    },
    showResults(inp_params) {
      this.$nextTick(() => {
        this.$router.push({
          path: "/results",
          query: {
            mainType: "detail",
            category: "community",
            search_params: JSON.stringify(inp_params),
          },
        });
      });
    },
    onElementChanged(event) {},
    updateOptionsById(searchId, options) {
      for (let filter of this.search_form.filters) {
        for (let row of filter.rows) {
          if (row.value && row.value.elements) {
            let element = row.value.elements.find((el) => el.id === searchId);
            if (element) {
              if (element.options) {
                element.options = options;
              }
              break;
            }
          }
        }
      }
    },
    getValueById(searchId) {
      let result = null;

      // Loop over each filter
      for (let filter of this.search_form.filters) {
        // Loop over each row in the current filter
        for (let row of filter.rows) {
          // Check if row has a value and elements
          if (row.value && row.value.elements) {
            // Find the element with the matching id
            let element = row.value.elements.find((el) => el.id === searchId);
            if (element) {
              result = element.value;
              break;
            }
          }
        }

        if (result !== null) {
          break;
        }
      }

      return result;
    },
  },
};
</script>

<style scoped lang="scss"></style>
