<template>
  <div class="side-wrapper">
    <ul class="list-group list-group-flush">
      <router-link to="/news/breaking-news" class="list-group-item list-group-item-action flex-column align-items-start side-item">TAIR News</router-link>
    </ul>
    <ul class="list-group list-group-flush">
      <router-link to="/news/old-news" class="list-group-item list-group-item-action flex-column align-items-start side-item">What Was News</router-link>
    </ul>

    <!-- <ul class="list-group list-group-flush">
      <a href="" class="list-group-item list-group-item-action flex-column align-items-start side-item" target="_blank">Archive</a>
    </ul> -->

    <ul class="list-group list-group-flush">
      <router-link to="/news/events" class="list-group-item list-group-item-action flex-column align-items-start side-item">Conferences and Events</router-link>
    </ul>
    <ul class="list-group list-group-flush">
      <a :href="getICARAbstractsURL" class="list-group-item list-group-item-action flex-column align-items-start side-item" target="_blank">ICAR Abstracts</a>
    </ul>

    <ul class="list-group list-group-flush">
      <router-link to="/news/jobs" class="list-group-item list-group-item-action flex-column align-items-start side-item">Job Postings</router-link>
    </ul>

    <ul class="list-group list-group-flush">
      <a :href="getHowToPostAJobURL" class="list-group-item list-group-item-action flex-column align-items-start side-item" target="_blank">How to Post a Job</a>
    </ul>
  </div>
</div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: 'NewsSidebar',
  computed: {
    ...mapGetters("newsURLs", ["getHowToPostAJobURL", "getICARAbstractsURL"]),
  }
};
</script>

<style scoped lang="scss"></style>