<template>
  <div>
    <b-breadcrumb :items="crumbLinks"></b-breadcrumb>
    <div class="d-flex">
      <h2>{{ pageTitle }}</h2>
      <b-link
        :href="rssLink"
        target="_blank"
        v-b-tooltip.hover
        title="Subscribe to RSS Feed"
        class="ml-2"
      >
        <i class="fas fa-rss"></i>
      </b-link>
    </div>
    <div class="items-panel">
      <div v-for="(item, i) in news" :key="i" class="news-item" :id="item.id">
        <div
          v-html="item.title + ' [' + item.date + ']'"
          class="news-header"
        ></div>
        <div v-html="item.content"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrFullNews } from "@/services/NewsService";

export default {
  name: "BreakingNews",
  data() {
    return {
      pageTitle: "Breaking News",
      news: [],
      crumbLinks: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "News",
          href: "/news/overview",
        },
        {
          text: "Breaking News",
          active: true,
        },
      ],
      rssLink: `${process.env.VUE_APP_API_BASE_URL}/news/breakingnews/rss`,
    };
  },
  async mounted() {
    try {
      const response = await this.fetchNews();
      this.news = response; // Update state with fetched data
    } catch (error) {
      console.error("Error fetching news:", error);
    }
  },
  methods: {
    async fetchNews() {
      return await getCurrFullNews();
    },
  },
};
</script>

<style scoped lang="scss"></style>
