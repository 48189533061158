export const downloadItems = {
  namespaced: true,
  state: {
    downloadItems: [
      {
        title: "Genes",
        description:
          "Contains TAIR's genome release files, gene family data, and lists of gene names, aliases and descriptions for all TAIR genes.",
      },
      {
        title: "GO and PO Annotations",
        description:
          "Contains files of all Gene Ontology (biological process, cellular component and molecular function) and Plant Ontology (plant growth and developmental stage, plant structure) annotations in TAIR.",
      },
      {
        title: "Maps",
        description:
          "Contains files of map coordinates for Arabidopsis genes and their features, ESTs, cDNAs, BAC clones, polymorphisms, and markers on the sequence map (SeqViewer data subdirectory). Also contains map data for older maps, including physical and genetic maps.",
      },
      {
        title: "Microarray Data",
        route: "/download/list?dir=Microarrays",
        description:
          "Contains Affymetrix, AFGC, Agilent and CATMA mapping files matching array element identifiers to AGI loci. Also contains original and reanalyzed microarray datasets for some experiments including AtGenExpress.",
      },
      {
        title: "Pathways",
        description:
          "Contains data files (including pathways, reactions, enzymes and loci) from the AraCyc and PlantCyc biochemical pathway database.",
      },
      {
        title: "Polymorphisms and Phenotypes",
        description:
          "Contains polymorphism data files describing SNPs, insertions, deletions and T-DNAs in TAIR. Also contains predicted and experimentally verified polymorphism data provided by the community and curated phenotype data.",
      },
      {
        title: "Proteins",
        description:
          "Files containing protein domains, molecular weights, isoelectric points, TargetP predictions, transmembrane domains, and SCOP structure information for all TAIR proteins.",
      },
      {
        title: "Protocols",
        description:
          "Contains protocols for growing Arabidopsis and other common laboratory procedures, along with archived Weeds World and Arabidopsis Information Service (AIS) articles.",
      },
      {
        title: "Public Data Releases",
        description:
          "Contains information added to TAIR since the end of our NSF funding period on August 31, 2013. New releases are generated every quarter and are cumulative with a one-year delay from when the data first entered TAIR. These files are freely available to all researchers, including both subscribers and non-subscribers, to facilitate data reuse.",
      },
      {
        title: "Subscriber Data Releases",
        description:
          'Contains information added to TAIR since the end of our NSF funding period on August 31, 2013. New releases are generated every quarter and are cumulative. These files are available only to subscribers. After a year, the files move to the <a href="/download?dir=Public%20Data%20Releases">Public Data Releases</a> folder.',
      },
      {
        title: "Publications",
        description: "Contains publications linked to loci.",
      },
      {
        title: "Sequences",
        description:
          "Contains TAIR's blast datasets and other sequence files in FASTA format.",
      },
      {
        title: "Software",
        description:
          "Downloadable software for analyzing microarray data, mapping mutations, finding sequence matches and looking at linkage disequilibrium.",
      },
      {
        title: "User Requests",
        description:
          'Older, custom Arabidopsis datasets requested by the community. If you have a special data requirement, mail your request to <a href="mailto:curator@arabidopsis.org">curator@arabidopsis.org</a>.',
      },
      // {
      //   title:'FTP Archive',
      //   route:'ftp://ftp.arabidopsis.org/home/tair',
      //   description:'As of April 9, 2015, TAIR FTP site is no longer actively updated. Users can still access our FTP archive at <a href="ftp://ftp.arabidopsis.org/home/tair">ftp://ftp.arabidopsis.org/home/tair</a>.'
      // },
    ],
  },

  getters: {
    getDownloadItemsWithDescription: (state) => {
      const items = state.downloadItems.map((item) => ({
        title: item.title,
        route: item.route
          ? item.route
          : "/download/list?dir=" + item.title.replace(/\s/g, "_"),
        description: item.description,
      }));
      return items;
    },

    getDownloadItems: (state) => {
      const items = state.downloadItems.map((item) => ({
        title: item.title,
        route: item.route
          ? item.route
          : "/download/list?dir=" + item.title.replace(/\s/g, "_"),
      }));
      return items;
    },
  },
};
