<template>
  <div class="searchWrapper">
    <div class="pageTop">
      <div class="d-none d-md-block">
        <div class="d-flex flex-column">
          <b-breadcrumb :items="crumb_links"></b-breadcrumb>
          <div class="text-left ml-2 d-flex">
            <h4 style="color: #930">TAIR Protein Search</h4>
            <span class="ml-2"
              >[<a :href="helpLink" target="_blank">Help</a>]</span
            >
          </div>
          <div class="text-left m-2">
            This page allows you to search for protein information using a
            variety of parameters, including gene information and domain
            information.
          </div>
        </div>
      </div>
      <div class="d-md-none">
        <div class="row">
          <div class="col-4 font-weight-bold pl-2">Search Result</div>
          <div class="col-8">
            <b-dropdown id="dropdown-1" text="Menu" class="m-md-2">
              <b-dropdown-item>New Search</b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>
    </div>
    <div class="pageMain m-2">
      <div class="d-flex flex-column">
        <div class="row mt-2">
          <div class="col-sm d-flex align-items-start ml-4">
            <b-button size="sm" class="mr-2">Reset</b-button>
            <b-button size="sm" @click="onSubmit">Submit Query</b-button>
          </div>
        </div>
        <div>
          <div class="d-flex flex-column mx-4 my-2">
            <form-section
              v-for="(filter, index) in search_form.filters"
              :key="index"
              :filter="filter"
              @on-enter="onSubmit"
            />
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-sm d-flex align-items-start ml-4 mb-4">
            <b-button size="sm" class="mr-2">Reset</b-button>
            <b-button size="sm" @click="onSubmit">Submit Query</b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormSection from "../../components/common/FormSection.vue";
export default {
  name: "ProteinSearch",
  components: {
    FormSection,
  },
  data() {
    return {
      crumb_links: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "Search",
          href: "/search/overview",
        },
        {
          text: "Proteins",
          active: true,
        },
      ],
      search_form: {
        filters: [
          {
            label: "Search by Name or Class Type",
            type: "rows",
            rows: [
              {
                name: "Search Name:",
                value: {
                  type: "mixed",
                  elements: [
                    {
                      type: "select",
                      options: [
                        { text: "Locus name", value: "locus" },
                        { text: "Gene Symbol", value: "symbol" },
                        { text: "GenPept ID", value: "genPeptId" },
                      ],
                      value: "locus",
                      id: "name_search_by",
                      size: "large",
                    },
                    {
                      type: "select",
                      options: [
                        { text: "starts with", value: "starts" },
                        { text: "contains", value: "contains" },
                        { text: "exactly", value: "exactly" },
                      ],
                      value: "contains",
                      id: "name_search_type",
                      size: "medium",
                    },
                    {
                      type: "input",
                      placeholder: "Search by name",
                      value: null,
                      id: "name_input",
                      size: "large",
                    },
                  ],
                },
              },
              {
                name: "Structural Class Type",
                value: {
                  type: "mixed",
                  elements: [
                    {
                      type: "multiSelect",
                      options: [
                        { value: "a", text: "All Alpha Proteins" },
                        { value: "b", text: "All Beta Proteins" },
                        { value: "c", text: "Alpha and beta proteins (a/b)" },
                        { value: "d", text: "Alpha and beta proteins (a+b)" },
                        {
                          value: "e",
                          text: "Multi-domain proteins (alpha and beta)",
                        },
                        {
                          value: "f",
                          text: "Membrane and cell surface proteins and peptides",
                        },
                        { value: "g", text: "Small proteins" },
                        { value: "h", text: "Coiled coil proteins" },
                      ],
                      value: [],
                      id: "class_type_multi",
                      size: 4,
                      width: "large",
                    },
                  ],
                },
              },
            ],
          },
          {
            label: "Search by Gene List",
            type: "rows",
            rows: [
              {
                name: "Upload or type in a list of AGI locus IDs (separated by line return)",
                value: {
                  type: "mixed",
                  elements: [
                    {
                      type: "multiInput",
                      value: "",
                      default: "",
                      id: "gene_list",
                    },
                  ],
                },
              },
            ],
          },
          {
            label: "Search by Physio-chemical Properties",
            type: "rows",
            rows: [
              {
                name: "Length",
                value: {
                  type: "mixed",
                  elements: [
                    {
                      type: "checkbox",
                      label: "",
                      value: false,
                      id: "length_checkbox",
                    },
                    {
                      type: "text",
                      value: "between",
                    },
                    {
                      type: "input",
                      value: 0,
                      id: "length_min",
                      size: "small",
                    },
                    {
                      type: "text",
                      value: "and",
                    },
                    {
                      type: "input",
                      value: 2000,
                      id: "length_max",
                      size: "small",
                    },
                    {
                      type: "text",
                      value: "residues",
                    },
                  ],
                },
              },
              {
                name: "Calculated MW",
                value: {
                  type: "mixed",
                  elements: [
                    {
                      type: "checkbox",
                      label: "",
                      value: false,
                      id: "calcmw_checkbox",
                    },
                    {
                      type: "text",
                      value: "between",
                    },
                    {
                      type: "input",
                      value: 0,
                      id: "calcmw_min",
                      size: "small",
                    },
                    {
                      type: "text",
                      value: "and",
                    },
                    {
                      type: "input",
                      value: 500,
                      id: "calcmw_max",
                      size: "small",
                    },
                    {
                      type: "text",
                      value: "kDa",
                    },
                  ],
                },
              },
              {
                name: "Calculated PI",
                value: {
                  type: "mixed",
                  elements: [
                    {
                      type: "checkbox",
                      label: "",
                      value: false,
                      id: "calcpi_checkbox",
                    },
                    {
                      type: "text",
                      value: "between",
                    },
                    {
                      type: "input",
                      value: 1,
                      id: "calcpi_min",
                      size: "small",
                    },
                    {
                      type: "text",
                      value: "and",
                    },
                    {
                      type: "input",
                      value: 14,
                      id: "calcpi_max",
                      size: "small",
                    },
                  ],
                },
              },
            ],
          },
          {
            label: "Search by Domains",
            type: "rows",
            rows: [],
          },
        ],
      },
      helpLink:
        "https://conf.phoenixbioinformatics.org/display/COM/Protein+Search",
    };
  },
  watch: {
    "$route.query.key": function (id) {
      this.$forceUpdate();
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      function deepClone(obj) {
        if (obj === null || typeof obj !== "object") {
          return obj;
        }

        if (Array.isArray(obj)) {
          const arrCopy = [];
          for (let i = 0; i < obj.length; i++) {
            arrCopy[i] = deepClone(obj[i]);
          }
          return arrCopy;
        }

        const objCopy = {};
        for (const key in obj) {
          if (Object.prototype.hasOwnProperty.call(obj, key)) {
            objCopy[key] = deepClone(obj[key]);
          }
        }
        return objCopy;
      }

      let databases = [
        { text: "ALPHAFOLD", value: "alphafold" },
        { text: "BLASTPRODOM", value: "blastprodom" },
        { text: "CDD", value: "cdd" },
        { text: "GENE3D", value: "gene3d" },
        { text: "HAMAP", value: "hamap" },
        { text: "HMMPFAM", value: "hmmpfam" },
        { text: "HMMPIR", value: "hmmpir" },
        { text: "HMMSMART", value: "hmmsmart" },
        { text: "INTERPRO", value: "interpro" },
        { text: "MOBIDBLITE", value: "mobidblite" },
        { text: "NCOILS", value: "ncoils" },
        { text: "PANTHER", value: "panther" },
        { text: "PATTERNSCAN", value: "patternscan" },
        { text: "PFAM", value: "pfam" },
        { text: "PRINTS", value: "prints" },
        { text: "PROFILE", value: "profile" },
        { text: "PROFILESCAN", value: "profilescan" },
        { text: "PRODOM", value: "prodom" },
        { text: "PROSITE_PROFILES", value: "prosite_profiles" },
        { text: "SEG", value: "seg" },
        { text: "SFLD", value: "sfld" },
        { text: "SMART", value: "smart" },
        { text: "SUPERFAMILY", value: "superfamily" },
        { text: "TIGRFAM", value: "tigrfam" },
        { text: "TMHMM", value: "tmhmm" },
      ];

      let domainRow = {
        name: "contains",
        value: {
          type: "mixed",
          elements: [
            {
              type: "select",
              options: [
                { text: "<", value: "<" },
                { text: ">", value: ">" },
                { text: "=", value: "=" },
              ],
              value: ">",
              id: "domains_quant_type",
              size: "small",
            },
            {
              type: "input",
              value: null,
              id: "domains_quant_number",
              size: "small",
            },
            {
              type: "select",
              options: [],
              value: "interpro",
              id: "domains_db_name",
            },
            {
              type: "text",
              value: "accession is exactly",
            },
            {
              type: "input",
              value: null,
              id: "domains_db_acc_input",
              size: "medium",
            },
          ],
        },
      };
      domainRow.value.elements[2].options = databases;
      this.search_form.filters[3].rows.push(domainRow);

      //clone the row
      let domainRow2 = deepClone(domainRow);
      domainRow2.value.elements[0].id = "domains_quant_type_1";
      domainRow2.value.elements[1].id = "domains_quant_number_1";
      domainRow2.value.elements[2].id = "domains_db_name_1";
      domainRow2.value.elements[4].id = "domains_db_acc_input_1";
      this.search_form.filters[3].rows.push(domainRow2);

      let domainRow3 = deepClone(domainRow);
      domainRow3.value.elements[0].id = "domains_quant_type_2";
      domainRow3.value.elements[1].id = "domains_quant_number_2";
      domainRow3.value.elements[2].id = "domains_db_name_2";
      domainRow3.value.elements[4].id = "domains_db_acc_input_2";
      this.search_form.filters[3].rows.push(domainRow3);
    },
    async onSubmit(event) {
      event.preventDefault();
      let search_params = {};
      const nameBy = this.getValueById("name_search_by");
      const nameType = this.getValueById("name_search_type");
      const nameText = this.getValueById("name_input");
      const nameSection = {
        nameBy,
        nameType,
        nameText,
      };

      const classType = this.getValueById("class_type_multi");

      const calcmw_checkbox = this.getValueById("calcmw_checkbox");
      const calcpi_checkbox = this.getValueById("calcpi_checkbox");
      const length_checkbox = this.getValueById("length_checkbox");

      const physioSection = [];
      if (length_checkbox) {
        const length_min = this.getValueById("length_min");
        const length_max = this.getValueById("length_max");
        physioSection.push({
          type: "length",
          min: length_min,
          max: length_max,
        });
      }
      if (calcmw_checkbox) {
        const calcmw_min = this.getValueById("calcmw_min") * 1000;
        const calcmw_max = this.getValueById("calcmw_max") * 1000;
        physioSection.push({
          type: "mw",
          min: calcmw_min,
          max: calcmw_max,
        });
      }
      if (calcpi_checkbox) {
        const calcpi_min = this.getValueById("calcpi_min");
        const calcpi_max = this.getValueById("calcpi_max");
        physioSection.push({
          type: "pi",
          min: calcpi_min,
          max: calcpi_max,
        });
      }

      let domainSection = [];
      const domains_db_acc_input = this.getValueById("domains_db_acc_input");
      if (domains_db_acc_input != null && domains_db_acc_input != "") {
        domainSection.push({
          type: "domain",
          quant_type: this.getValueById("domains_quant_type"),
          quant_number: this.getValueById("domains_quant_number"),
          db_name: this.getValueById("domains_db_name"),
          db_acc_input: this.getValueById("domains_db_acc_input"),
        });
      }
      const domains_db_acc_input_1 = this.getValueById(
        "domains_db_acc_input_1"
      );
      if (domains_db_acc_input_1 != null && domains_db_acc_input_1 != "") {
        domainSection.push({
          type: "domain",
          quant_type: this.getValueById("domains_quant_type_1"),
          quant_number: this.getValueById("domains_quant_number_1"),
          db_name: this.getValueById("domains_db_name_1"),
          db_acc_input: this.getValueById("domains_db_acc_input_1"),
        });
      }

      const domains_db_acc_input_2 = this.getValueById(
        "domains_db_acc_input_2"
      );
      if (domains_db_acc_input_2 != null && domains_db_acc_input_2 != "") {
        domainSection.push({
          type: "domain",
          quant_type: this.getValueById("domains_quant_type_2"),
          quant_number: this.getValueById("domains_quant_number_2"),
          db_name: this.getValueById("domains_db_name_2"),
          db_acc_input: this.getValueById("domains_db_acc_input_2"),
        });
      }

      // const geneList = "AT5G64930;AT3G27880";
      let geneList = this.getValueById("gene_list");
      geneList = geneList.replace(/\r/g, "");
      geneList = geneList.replace(/\n/g, ";");

      search_params = {
        nameSection,
        geneList,
        classType,
        physioSection,
        domainSection,
      };

      this.showResults(search_params);
    },
    onReset(event) {
      event.preventDefault();
    },
    showResults(inp_params) {
      this.$nextTick(() => {
        let key = "searchParams_" + Date.now();
        localStorage.setItem(key, JSON.stringify(inp_params));
        this.$router.push({
          path: "/results",
          query: {
            mainType: "detail",
            category: "protein",
            key: key,
          },
        });
      });
    },
    getValueById(searchId) {
      let result = null;

      // Loop over each filter
      for (let filter of this.search_form.filters) {
        // Loop over each row in the current filter
        for (let row of filter.rows) {
          // Check if row has a value and elements
          if (row.value && row.value.elements) {
            // Find the element with the matching id
            let element = row.value.elements.find((el) => el.id === searchId);
            if (element) {
              result = element.value;
              break;
            }
          }
        }

        if (result !== null) {
          break;
        }
      }

      return result;
    },
  },
};
</script>

<style scoped lang="scss"></style>
