<template>
  <span class="mr-2" v-if="data.helpTxt">
    <b-tooltip
      :target="'help-' + index + data.key.toLowerCase()"
      triggers="hover"
    >
      <p
        v-html="data.helpTxt"
        class="overflow-div d-flex flex-column text-left"
      ></p>
    </b-tooltip>
    <b-icon
      class="ml-2"
      :id="'help-' + index + data.key.toLowerCase()"
      icon="question-circle-fill"
    ></b-icon>
  </span>
</template>
<script>
export default {
  components: {},
  mounted() {},
  props: ["data", "index"],
};
</script>
<style scoped>
.overflow-div {
  max-height: 200px;
  overflow-y: auto;
  padding-right: 10px;
}
</style>
