<template>
  <div :id="id" class="radio-container">
    <div v-for="option in options" :key="option.value" class="radio-option">
      <input
        type="radio"
        :id="option.value"
        :value="option.value"
        :name="id"
        v-model="selectedValue"
      />
      <label :for="option.value">{{ option.text }}</label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: String,
    value: {
      type: [String, Number],
      default: "",
    },
    options: {
      type: Array,
      required: true,
    },
  },
  computed: {
    selectedValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
};
</script>

<style>
.radio-container {
  border: 1px solid #ccc; /* Box around the radio buttons */
  padding: 10px;
  margin-bottom: 15px;
}

.radio-option {
  display: flex;
  align-items: center;
  margin-bottom: 5px; /* Reduced distance between options */
}

.radio-option label {
  margin-left: 5px; /* Adjust as needed */
}
</style>
