<template>
  <div>
    <div v-if="!error" class="detail-wrapper">
      <div class="side-wrapper">
        <ul class="list-group list-group-flush">
          <a
            v-for="(col, i) in side_cols"
            :key="i"
            href="#"
            class="list-group-item list-group-item-action flex-column align-items-start side-item"
            v-bind:class="{ selected: i == colIdxSelected }"
            @click="sideColClicked(i)"
          >
            {{ col }}
          </a>
        </ul>
      </div>
      <div class="list-wrapper" v-on:scroll.passive="handleScroll">
        <div
          v-if="loading"
          class="d-flex justify-content-center align-items-center"
          style="height: 100%"
        >
          <b-spinner label="Loading..."></b-spinner>
        </div>
        <div
          v-if="json_data"
          class="text-left m-2 font-weight-bold fixed-title"
        >
          <h4>Genetic Marker: {{ json_data.markerName }}</h4>
        </div>
        <BaseEntry
          v-for="(e, i) in entries"
          :selectedColIdx="colIdxSelected"
          :currIdx="i"
          :key="i"
          :content="e"
          :ref="`entry` + i"
        ></BaseEntry>
      </div>
    </div>
    <div v-else>
      <PageNotFound />
    </div>
  </div>
</template>

<script>
//http://localhost:8082/marker?key=1
import BaseEntry from "@/components/detail/BaseEntry";
import PostsService from "@/services/PostsService";
import PageNotFound from "@/components/common/PageNotFound";
import { getMapElementViewUrl } from "@/services/JBrowseService";

export default {
  name: "MarkerDetail",
  components: {
    BaseEntry,
    PageNotFound,
  },
  data() {
    return {
      base_url: process.env.VUE_APP_S3_PATH + "marker/",
      json_data: null,
      side_cols: [
        "Summary",
        "Associated Polymorphisms",
        "Associated Nucleotide Sequences",
        "Publications",
      ],
      loading: false,
      error: false,
      colIdxSelected: 0,
      entries: [],
      entryPosys: [],
      helpTexts: {},
    };
  },

  async mounted() {
    this.loading = true;
    document.title = "Genetic Marker Detail";
    let jsonId = this.$route.query.key;
    if (this.$route.query.accession) {
      const accession = this.$route.query.accession;
      if (!isNaN(parseInt(accession)) && accession == parseInt(accession)) {
        let response2 = await PostsService.getMarkerIdByAccession(accession);
        jsonId = response2.data;
        if (jsonId == null) {
          this.error = true;
        }
      } else {
        this.error = true;
      }
    }
    if (jsonId) {
      try {
        this.loading = true;
        let response = await PostsService.getMarkerDetail({ key: jsonId });
        this.json_data = response.data;

        this.processEntries();
        this.loading = false;
        document.title = `Genetic Marker Detail`;
      } catch (error) {
        console.error("Error fetching marker details: ", error);
        this.loading = false;
        this.error = true;
      }
    } else {
      this.loading = false;
      this.error = true;
    }
  },
  methods: {
    sideColClicked(colIdx) {
      this.scrollToElement(colIdx);
      this.colIdxSelected = colIdx;
    },
    processEntries() {
      this.side_cols.forEach((title) => {
        let entry = {
          title: title,
          name: title.replace(/\s/g, ""),
        };
        switch (title) {
          case "Summary":
            entry.type = "bands";
            entry.bands = this.fillSummary();
            break;
          case "Associated Polymorphisms":
            entry.type = "bands";
            entry.bands = this.fillPoly();
            break;
          case "Associated Nucleotide Sequences":
            entry.type = "bands";
            entry.bands = this.fillSequence();
            break;
          case "Publications":
            entry.type = "bands";
            entry.bands = this.fillPublications();
            break;
          default:
            entry.type = "bands";
            entry.bands = [];
            break;
        }
        this.entries.push(entry);
      });
    },
    fillSummary() {
      let bands = [];
      bands.push({
        key: "Name",
        text: this.json_data.markerName,
      });
      bands.push({
        key: "Alias",
        text:
          this.json_data.aliases && this.json_data.aliases.length > 0
            ? this.json_data.aliases.join("; ")
            : "No data available",
      });
      bands.push({
        key: "Date Last Modified",
        text: this.dateToYMD(this.json_data.dateLastModified),
      });
      bands.push({
        key: "TAIR Accession",
        text: `GeneticMarker:${this.json_data.tairAccession}`,
      });
      bands.push({
        key: "Type",
        text: this.json_data.markerType,
      });
      bands.push({
        key: "Length",
        text: this.json_data.length,
      });
      bands.push({
        key: "Is PCR Marker",
        text: this.json_data.isPCRMarker == "T" ? "true" : "false",
      });
      bands.push({
        key: "Special Conditions",
        text: this.json_data.specialConditions
          ? this.json_data.specialConditions
          : "No data available",
      });
      bands.push({
        key: "Chromosome",
        text: this.json_data.chromosome
          ? this.json_data.chromosome
          : "No data available",
      });
      bands.push({
        key: "Associated Loci",
        text: this.json_data.associatedLoci
          ? this.json_data.associatedLoci.name
          : "No data available",
        type: "link",
        link: this.json_data.associatedLoci
          ? `/locus?key=${this.json_data.associatedLoci.locusId}`
          : "",
      });
      bands.push(this.getAssociatedGenes());
      bands.push(this.getMadeFrom());
      bands.push(this.getMapLocations());
      bands.push(this.getMapLinks());
      bands.push(this.getComments());
      // bands.push(this.getPublications());
      bands.push(this.getAttributtions());
      return bands;
    },
    fillPoly() {
      let bands = [];
      let pcrProductLengths = this.json_data.pcrProductLengths;
      if (pcrProductLengths && pcrProductLengths.length > 0) {
        bands.push(this.getPCRPoly(pcrProductLengths));
      }
      let digestPatterns = this.json_data.digestPatterns;
      if (digestPatterns && digestPatterns.length > 0) {
        bands.push(this.getDigestPatterns(digestPatterns));
      }
      if (bands.length == 0)
        bands.push({
          key: "Associated Polymorphisms",
          type: "",
          text: "No Associated Polymorphisms available",
        });
      return bands;
    },
    fillSequence() {
      let bands = [];
      bands.push(this.getSequence());
      return bands;
    },
    fillPublications() {
      let bands = [];
      bands.push(this.getPublications());
      return bands;
    },

    //Getters
    getMadeFrom() {
      let entry = {
        key: "Made From",
        type: "table",
        items: [],
        fields: [],
      };
      let madeFrom = this.json_data.madeFrom;
      if (madeFrom == null) {
        entry.type = "";
        entry.text = "No Made From data available";
        return entry;
      }
      entry.fields = [
        {
          key: "name",
          label: "Name",
          cellType: "name_link",
        },
        {
          key: "map_element_type",
          label: "map element type",
          cellType: "name_link",
        },
      ];
      let item = {
        name: {
          name: madeFrom.obsoleteName,
          link: `/${madeFrom.mapElementType}?key=${madeFrom.cloneId}`,
        },
        map_element_type: { name: madeFrom.mapElementType },
      };
      entry.items.push(item);

      return entry;
    },
    getAssociatedGenes() {
      let entry = {
        key: "Associated Genes",
        type: "table",
        items: [],
        fields: [],
      };
      let associatedGenes = this.json_data.associatedGene;
      if (associatedGenes == null) {
        entry.type = "";
        entry.text = "No data available";
        return entry;
      }
      entry.fields = [
        {
          key: "gene_model",
          label: "Gene Model",
          cellType: "name_link",
        },
        {
          key: "locus",
          label: "Locus",
          cellType: "name_link",
        },
        {
          key: "type",
          label: "Association Type",
          cellType: "name_link",
        },
      ];
      associatedGenes.forEach((g) => {
        let item = {
          gene_model: {
            name: g.geneName,
            link: `/gene?key=${g.geneId}`,
          },
          locus: { name: g.locusName, link: `/locus?key=${g.locusId}` },
          type: { name: g.associationType },
        };
        entry.items.push(item);
      });

      return entry;
    },
    getPCRPoly(poly) {
      let entry = {
        key: "PCR Product Length",
        type: "table",
        items: [],
        fields: [],
      };
      entry.fields = [
        { key: "poly", label: "Polymorphism", cellType: "name_link" },
        {
          key: "variants",
          label: "species variant name",
          cellType: "name_link",
        },
        { key: "length", label: "product length", cellType: "name_link" },
        { key: "attribution", label: "attribution", cellType: "name_link" },
      ];
      let items = [];
      let uniquePolys = {};

      poly.forEach((p) => {
        if (uniquePolys[p.polymorphismId]) {
          uniquePolys[p.polymorphismId].push(p);
        } else {
          uniquePolys[p.polymorphismId] = [p];
        }
      });

      for (let key in uniquePolys) {
        let poly = uniquePolys[key][0];
        let variantNames = uniquePolys[key].map((p) => p.abbrevName);
        variantNames = variantNames.join(", ");
        let item = {
          poly: {
            name: poly.polymorphismName,
            link: `polyallele?key=${poly.polymorphismId}`,
          },
          variants: { name: variantNames },
          length: { name: poly.productLength },
          attribution:
            poly.attributionId != 0
              ? {
                  name: "details",
                  link: `${process.env.VUE_APP_OLD_TAIR_URL}/servlets/TairObject?id=${poly.polymorphismId}&type=attrib`,
                }
              : { name: "" },
        };
        items.push(item);
      }
      entry.items = items;
      return entry;
    },
    getDigestPatterns(poly) {
      let entry = {
        key: "Digest Patterns",
        type: "table",
        items: [],
        fields: [],
      };
      entry.fields = [
        { key: "poly", label: "Polymorphism", cellType: "name_link" },
        {
          key: "variants",
          label: "species variant",
          cellType: "name_link",
        },
        {
          key: "enzymes",
          label: "restrict enzyme",
          cellType: "name_link",
        },
        { key: "numsites", label: "num sites", cellType: "name_link" },
        { key: "length", label: "fragemnt length", cellType: "name_link" },
        { key: "attribution", label: "attribution", cellType: "name_link" },
      ];
      let items = [];
      let uniquePolys = {};

      poly.forEach((p) => {
        if (uniquePolys[p.polymorphismId]) {
          uniquePolys[p.polymorphismId].push(p);
        } else {
          uniquePolys[p.polymorphismId] = [p];
        }
      });

      for (let key in uniquePolys) {
        let poly = uniquePolys[key][0];
        let variantNames = uniquePolys[key].map((p) => p.abbrevName);
        variantNames = variantNames.join(", ");
        let item = {
          poly: {
            name: poly.polymorphismName,
            link: `polyallele?key=${poly.polymorphismId}`,
          },
          variants: { name: variantNames },
          enzymes: {
            name: poly.restrictionEnzymeName,
            link: `${process.env.VUE_APP_OLD_TAIR_URL}/servlets/TairObject?type=restrictionenzyme&id=${poly.restrictionEnzymeId}`,
          },
          length: { name: `${poly.fragmentationLength} bp` },
          numsites: { name: poly.numSites },
          attribution:
            poly.attributionId != 0
              ? {
                  name: "details",
                  link: `${process.env.VUE_APP_OLD_TAIR_URL}/servlets/TairObject?id=${poly.polymorphismId}&type=attrib`,
                }
              : { name: "" },
        };
        items.push(item);
      }
      entry.items = items;
      return entry;
    },
    getSequence() {
      let entry = {
        key: "Flanking Sequences",
        type: "multiline",
        fields: [],
        items: [],
      };
      if (this.json_data.flankSequence) {
        let s = this.json_data.flankSequence;
        if (s.sequence1 || s.sequence2) {
          entry.key = "Flanking Sequences";
          entry.fields.push({
            key: "flank1",
            label: "primer 1",
            cellType: "name_link",
            width: "100%",
          });
          entry.fields.push({
            key: "flank2",
            label: "primer 2",
            cellType: "name_link",
            width: "100%",
          });
          let item = {
            flank1: { name: s.sequence1 },
            flank2: { name: s.sequence2 },
          };
          entry.items.push(item);
        } else {
          entry.type = "";
          entry.text = "No Flanking Sequences available";
        }
      }

      return entry;
    },
    getMapLocations() {
      let entry = {
        key: "Map Locations",
        type: "table",
        items: [],
        fields: [],
      };

      // Define table fields
      entry.fields.push({
        key: "chrom",
        label: "Chromosome",
        cellType: "name_link",
      });
      entry.fields.push({ key: "map", label: "Map", cellType: "name_link" });
      entry.fields.push({
        key: "mapType",
        label: "Map Type",
        cellType: "name_link",
      });
      entry.fields.push({
        key: "startEnd",
        label: "coordinates",
        cellType: "name_link",
      });
      entry.fields.push({
        key: "orientation",
        label: "Orientation",
        cellType: "name_link",
      });
      entry.fields.push({
        key: "attrib",
        label: "Attrib",
        cellType: "name_link",
      });

      let mapLocations = this.json_data.globalAssignments; // Adjust the path according to your data structure
      if (!mapLocations || mapLocations.length === 0) {
        entry.type = "";
        entry.text = "No Map Locations available";
        return entry;
      }

      // Map map location data to table items
      entry.items = mapLocations.map((loc) => {
        if (loc.name == null) return null;
        let orientation = "";
        if (loc.orientation == "F") {
          orientation = "forward";
        } else if (loc.orientation == "R") {
          orientation = "reverse";
        } else {
          orientation = "unknown";
        }

        // Determine the appropriate link based on the mapType
        let mapLink = `${process.env.VUE_APP_OLD_TAIR_URL}/servlets/TairObject?type=map&id=${loc.mapId}`;
        if (loc.mapType === "assembly_unit") {
          mapLink = `${process.env.VUE_APP_OLD_TAIR_URL}/servlets/TairObject?type=assembly_unit&id=${loc.mapId}`;
        }

        return {
          chrom: { name: loc.chromosome },
          map: {
            name: loc.name,
            link: mapLink,
          },
          mapType: { name: loc.mapType },
          startEnd: {
            name: `${loc.startPosition} - ${loc.endPosition} ${loc.units}`,
          },
          orientation: { name: orientation },
          attrib: loc.attributionIdForLink
            ? {
                name: "details",
                link: `${process.env.VUE_APP_OLD_TAIR_URL}/servlets/TairObject?id=${loc.attributionIdForLink}&type=global_assignment`, // Adjust link format as needed
              }
            : { name: "No data available" },
        };
      });

      return entry;
    },
    getMapLinks() {
      let entry = {
        key: "Map Links",
        type: "link_list",
        items: [],
      };
      entry.items.push({
        text: "MapViewer",
        link: ` ${process.env.VUE_APP_OLD_TAIR_URL}/servlets/mapper?value=${this.json_data.markerName}&action=search`,
      });
      let mapLocations = this.json_data.globalAssignments;
      if (!mapLocations) {
        return {
          key: "Map Links",
          text: "No Map Links Available",
          type: "",
        };
      }
      let agiMapLocation = mapLocations.find((loc) => loc.name == "AGI");

      if (!agiMapLocation)
        return {
          key: "Map Links",
          text: "No Map Links Available",
          type: "",
        };
      entry.items.push({
        text: "JBrowse",
        link: getMapElementViewUrl(
          agiMapLocation.chromosome,
          agiMapLocation.startPosition,
          agiMapLocation.endPosition,
          "marker"
        ),
      });
      return entry;
    },
    getAttributtions() {
      let entry = {
        key: "Attribution",
        type: "table",
        items: [],
        fields: [],
      };
      let attributions = this.json_data.attributions;
      if (attributions == null || attributions.length == 0) {
        entry.type = "";
        entry.text = "No Attribution available";
        return entry;
      }
      entry.fields = [
        {
          key: "type",
          label: "Type",
          cellType: "name_link",
        },
        {
          key: "name",
          label: "Name",
          cellType: "name_link",
        },
        {
          key: "date",
          label: "Date",
          cellType: "name_link",
        },
      ];
      attributions.forEach((a) => {
        let name = a.organizationName; // Default to the organization name
        if (a.firstName && a.lastName) {
          name = `${a.firstName} ${a.lastName}`; // Use person's name if available
        }

        // Determine the correct link based on the communityType
        let linkPath = "/organization?key=" + a.communityId;
        if (a.communityType === "person") {
          linkPath = "/person?key=" + a.communityId; // Adjust path for persons
        }

        let item = {
          type: { name: a.attributionType },
          name: {
            name: name,
            link: linkPath,
          },
          date: { name: this.dateToYMD(a.attributionDate) }, // Convert the date to YMD format
        };

        entry.items.push(item);
      });

      return entry;
    },
    getComments() {
      let entry = {
        key: "Community Comments",
        type: "comment_list",
        showComments: true,
        showRecent: true,
        items: [],
        helpTxt: this.helpTexts.comments,
      };
      let items = [];
      if (this.json_data.comments) {
        this.json_data.comments.forEach((c) => {
          let item = {};
          item.profile = {
            name: c.firstName,
            url: "/person?key=" + c.communityId,
          };
          item.comment = {
            text: c.notepadComment,
            url:
              process.env.VUE_APP_OLD_TAIR_URL +
              "/servlets/TairObject?type=notepad&id=" +
              1,
          };
          item.date_posted = this.dateToYMD(c.dateEntered);
          items.push(item);
        });
      }
      entry.items = items;
      if (entry.items.length == 0) {
        entry.items.push({ comment: { text: "No comments found" } });
      }
      return entry;
    },
    getPublications() {
      let entry = {
        key: "Publications",
        type: "table",
        items: [],
        fields: [],
      };
      let publications = this.json_data.publications;
      if (publications == null || publications.length == 0) {
        entry.type = "";
        entry.text = "No publications available";
        return entry;
      }
      entry.fields = [
        {
          key: "title",
          label: "Title",
          cellType: "name_link",
        },
        {
          key: "source",
          label: "Source",
          cellType: "name_link",
        },
        {
          key: "year",
          label: "Year",
          cellType: "name_link",
        },
      ];
      publications.forEach((p) => {
        let item = {
          title: { name: p.title, link: `/publication?key=${p.referenceId}` },
          source: { name: p.sourceName },
          year: { name: p.publicationYear },
        };
        entry.items.push(item);
      });
      return entry;
    },
    dateToYMD(dateTs) {
      let date = new Date(dateTs);
      const d = date.getDate();
      const m = date.getMonth() + 1;
      const y = date.getFullYear();
      return `${y}-${m.toString().padStart(2, "0")}-${d
        .toString()
        .padStart(2, "0")}`;
    },
    //
    scrollToElement(colIdx) {
      const entryRef = this.$refs["entry" + colIdx];
      const el = entryRef[0].$el;
      if (el) {
        const top = el.offsetTop - 210;
        el.parentElement.scrollTo(0, top);
      }
    },
    handleScroll(e) {
      const scrollTop = e.target.scrollTop + 215;
      let currTopEntry = 0;
      this.entryPosys.forEach((e, i) => {
        if (scrollTop > e) {
          currTopEntry = i;
        }
      });
      this.colIdxSelected = currTopEntry;
    },
  },
};
</script>

<style scoped lang="scss">
.fixed-title {
  position: sticky;
  top: 0;
  background-color: #f1efec;
  z-index: 10;
  padding-left: 10px;
  padding-top: 10px;
}

.my-custom-scrollbar {
  position: relative;
  height: 200px;
  overflow: auto;
}
.table-wrapper-scroll-y {
  display: block;
}
</style>
