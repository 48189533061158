import PostsService from "../PostsService";

export async function loadResults(search_params, type = "detail") {
  let fields = [
    { key: "row_num", label: " # " },
    { key: "selected", label: "" },
    {
      key: "name",
      label: "Ecotype Name",
      cellType: "name_link",
    },
    {
      key: "abbrev_name",
      label: "Abbrev Name",
      cellType: "name_link",
    },
    {
      key: "alias",
      label: "Alias",
      cellType: "name_link",
    },
    {
      key: "location",
      label: "Location",
      cellType: "name_link",
    },
    {
      key: "country",
      label: "Country",
      cellType: "name_link",
    },
    {
      key: "stock_number",
      label: "Stock Number",
      cellType: "name_links_multi",
    },
  ];
  let items = [];
  try {
    let response = await PostsService.fetchEcotypeSearch(search_params);
    if (response.data.error) {
      return { error: true, items: [], fields: [] };
    }
    let docs = response.data.docs;

    let totalMatch = response.data.total;

    for (const d of docs) {
      items.push({
        id: d.id,
        selected: false,
        name: {
          name: d.full_name ? d.full_name[0] : d.abbrev_name[0],
        },
        abbrev_name: {
          name: d.abbrev_name ? d.abbrev_name[0] : "",
          link: `/ecotype?key=${d.speciesVariantId}`,
        },
        alias: {
          name: d.aliases ? d.aliases.join(", ") : "",
        },
        location: {
          name: d.location ? d.location[0] : "",
        },
        country: {
          name: d.country ? d.country : "",
        },
        stock_number: d.stock_number
          ? d.stock_number.map((s) => {
              return {
                name: s,
                link: `https://abrc.osu.edu/stocks/number/${s}`,
              };
            })
          : [],
      });
    }
    search_params.matchCount = totalMatch;
    let queryStatement = "";
    if (type === "general") {
      queryStatement = `Your query for ecotypes where ecotype name or abbrev. name contains the term <span class="highlight-term">${search_params.searchText}</span> resulted in <span style="color: green">${totalMatch}</span> matches`;
    } else {
      queryStatement = setQueryStatement(search_params);
    }
    return {
      error: false,
      items: items,
      fields: fields,
      queryStatement: queryStatement,
    };
  } catch (error) {
    return { error: true, items: [], fields: [] };
  }
}

function setQueryStatement(params) {
  let queryStatement = "Your query for ecotypes";
  let queryParts = [];
  let searchParts = [];
  params.searchTerms.forEach((s) => {
    if (s.search_input) {
      searchParts.push(
        `${s.search_type} ${s.search_method} <span class="highlight-term">${s.search_input}</span>`
      );
    }
  });
  let restrictParts = [];
  params.restrictByFeatures.forEach((feat) => {
    if (feat.location_input) {
      restrictParts.push(
        `location ${feat.location_method} <span class="highlight-term">${feat.location_input}</span>`
      );
    }
    if (feat.habitat_input) {
      restrictParts.push(
        `habitat ${feat.habitat_method} <span class="highlight-term">${feat.habitat_input}</span>`
      );
    }
    if (feat.country) {
      restrictParts.push(
        `country is <span class="highlight-term">${feat.country}</span>`
      );
    }
  });

  if (restrictParts.length > 0) {
    queryParts.push(restrictParts.join(" and "));
  }
  if (searchParts.length > 0) {
    queryParts.push(searchParts.join(" and "));
  }
  if (queryParts.length > 0) {
    queryStatement += " where ";
    queryStatement += queryParts.join(" and ");
  }

  queryStatement += ` resulted in <span style="color: green">${params.matchCount}</span> matches`;
  return queryStatement;
}
