<!-- LinkWrapper.vue -->
<template>
  <span>
    <!-- External link -->
    <a
      v-if="isExternalLink"
      :href="link"
      target="_blank"
      rel="noopener noreferrer"
    >
      {{ name }}
    </a>

    <!-- Internal link using router-link -->
    <router-link
      v-else-if="link && !isExternalLink"
      :to="link"
      rel="noopener noreferrer"
    >
      {{ name }}
    </router-link>

    <!-- Display name only if link is null -->
    <span v-else>
      {{ name }}
    </span>
  </span>
</template>

<script>
export default {
  props: {
    link: {
      type: String,
      required: false,
    },
    name: {
      required: false,
    },
  },
  computed: {
    // Determines if the link is external based on "www."
    isExternalLink() {
      return (
        this.link &&
        (this.link.includes("www.") ||
          this.link.includes("http") ||
          this.link.includes("ftp://") ||
          this.link.startsWith("mailto:"))
      );
    },
  },
};
</script>
