<template>
  <div>
    <div v-if="!error" class="detail-wrapper">
      <div class="side-wrapper">
        <ul class="list-group list-group-flush">
          <a
            v-for="(col, i) in side_cols"
            :key="i"
            href="#"
            class="list-group-item list-group-item-action flex-column align-items-start side-item"
            v-bind:class="{ selected: i == colIdxSelected }"
            @click="sideColClicked(i)"
          >
            {{ col }}
          </a>
        </ul>
      </div>
      <div
        v-if="loading"
        class="d-flex justify-content-center align-items-center"
        style="height: 100%"
      >
        <b-spinner label="Loading..."></b-spinner>
      </div>
      <div class="list-wrapper" v-on:scroll.passive="handleScroll">
        <div
          v-if="json_data"
          class="text-left m-2 font-weight-bold fixed-title"
        >
          <h4>TAIR Organization Detail</h4>
        </div>
        <BaseEntry
          v-for="(e, i) in entries"
          :selectedColIdx="colIdxSelected"
          :currIdx="i"
          :key="i"
          :content="e"
          :ref="`entry` + i"
        ></BaseEntry>
      </div>
    </div>
    <div v-else>
      <PageNotFound />
    </div>
  </div>
</template>

<script>
//http://localhost:8082/organization?key=1313640
import BaseEntry from "@/components/detail/BaseEntry";
import PostsService from "@/services/PostsService";
import { mapGetters } from "vuex";
import PageNotFound from "@/components/common/PageNotFound";

export default {
  name: "OrganizationDetail",
  components: {
    BaseEntry,
    PageNotFound,
  },
  data() {
    return {
      base_url: process.env.VUE_APP_S3_PATH + "organization/",
      json_data: null,
      side_cols: ["Summary"],
      loading: false,
      error: false,
      colIdxSelected: 0,
      entries: [],
      entryPosys: [],
      helpTexts: {},
    };
  },

  async mounted() {
    document.title = "Organization Detail";
    let jsonId = this.$route.query.key;
    if (jsonId) {
      try {
        let response = await PostsService.getOrganizationDetail({
          key: jsonId,
        });
        this.json_data = response.data;

        this.loading = true;
        this.processEntries();
        this.loading = false;
        document.title = `Organization Detail`;
      } catch (error) {
        console.error("Error fetching Organization details: ", error);
        this.loading = false;
        this.error = true;
      }
    } else {
      this.loading = false;
      this.error = true;
    }
  },
  methods: {
    ...mapGetters({
      getCommunityId: "authProfile/getCommunityId",
      getIsTairCurator: "authProfile/getIsTairCurator",
    }),
    sideColClicked(colIdx) {
      this.scrollToElement(colIdx);
      this.colIdxSelected = colIdx;
    },
    processEntries() {
      this.side_cols.forEach((title) => {
        let entry = {
          title: title,
          name: title.replace(/\s/g, ""),
        };
        switch (title) {
          case "Summary":
            entry.type = "bands";
            entry.bands = this.fillSummary();
            break;
          default:
            entry.type = "bands";
            entry.bands = [];
            break;
        }
        this.entries.push(entry);
      });
    },
    fillSummary() {
      let bands = [];
      bands.push({
        key: "Name",
        text: this.json_data.name,
      });
      bands.push({
        key: "TAIR Accession",
        text: `Organization: ${this.json_data.communityId}`,
      });
      bands.push({
        key: "Organization Type",
        text: `${this.json_data.organizationType}`,
      });
      bands.push(this.getAddress());
      bands.push({
        key: "Main Phone",
        text: this.getPhoneNumber(this.json_data, "work"),
      });
      bands.push({
        key: "Fax",
        text: this.getPhoneNumber(this.json_data, "fax"),
      });
      bands.push({
        key: "Websites",
        type: "links",
        links: this.json_data.websites
          ? this.json_data.websites.map((u) => {
              return {
                name: u.url,
                link: u.url,
              };
            })
          : [],
      });
      bands.push(this.getAffiliations());
      bands.push({
        key: "Organization ID",
        text: this.json_data.organizationId,
      });
      bands.push({
        key: "Community ID",
        text: this.json_data.communityId,
      });
      bands.push({
        key: "Record last updated",
        text: this.json_data.dateLastUpdated
          ? this.dateToYMD(this.json_data.dateLastUpdated)
          : "No data available",
      });
      if (
        this.json_data.affiliations.some(
          (affiliation) => affiliation.personId === this.getCommunityId()
        ) ||
        this.getIsTairCurator()
      ) {
        bands.push({
          key: "Update Record",
          html: `If you find this information to be inaccurate, or wish to add other information, please <a href="/edit/organization?key=${this.json_data.communityId}" target="_blank">UPDATE YOUR RECORD HERE</a>`,
          type: "html",
        });
      }
      //   bands.push({
      //     key: "Update Record",
      //     html: `If you find this information to be inaccurate, or wish to add other information, please <a href="/edit/organization?key=${this.json_data.communityId}">UPDATE YOUR RECORD HERE</a>`,
      //     type: "html",
      //   });
      //   bands.push({
      //     key: "Seed Stock Center",
      //     text: this.json_data.seedStockCenter,
      //   });
      //   bands.push({
      //     key: "Order History",
      //     text: "View History",
      //     type: "link",
      //     link: `${process.env.VUE_APP_OLD_TAIR_URL}/servlets/Order?state=search&mode=pi&communityID=${this.json_data.communityId}`,
      //   });
      return bands;
    },

    //Getters
    getAddress() {
      let address = this.json_data.address;
      // replace "\" with "<br>" for new line
      if (address) {
        address = address.replace(/\\/g, "<br>");
      }
      let addressLine3 =
        this.json_data.city +
        "," +
        (this.json_data.region ? this.json_data.region : "") +
        " " +
        this.json_data.postalCode;
      address = address + "<br>" + addressLine3;
      let country = this.json_data.country;
      if (country) {
        address = address + "<br>" + country;
      }
      return {
        key: "Address",
        type: "html",
        html: `${address}`,
      };
    },
    getPhoneNumber(phoneType) {
      if (!this.json_data.phones || !Array.isArray(this.json_data.phones)) {
        return "No data available";
      }

      const phone = this.json_data.phones.find(
        (p) => p.phoneType === phoneType
      );
      return phone ? phone.phoneNumber : "No data available";
    },
    getAffiliations() {
      let entry = {
        key: "Affiliations",
        type: "table",
        items: [],
        fields: [],
      };
      let affiliations = this.json_data.affiliations;
      if (!affiliations || affiliations.length == 0) {
        return {
          key: "Affiliations",
          text: "No data available",
        };
      }
      entry.fields = [
        {
          key: "person",
          label: "Person",
          cellType: "name_link",
        },
        {
          key: "title",
          label: "Job Title",
          cellType: "name_link",
        },
      ];
      entry.items = affiliations.map((a) => {
        let name = a.firstName + " " + a.lastName;
        let personName = a.isPi == "T" ? "*" + name : name;
        return {
          person: {
            name: personName,
            link: `/person?key=${a.personId}`,
          },
          title: {
            name: a.jobTitle,
          },
        };
      });
      return entry;
    },

    dateToYMD(dateTs) {
      let date = new Date(dateTs);
      const d = date.getDate();
      const m = date.getMonth() + 1;
      const y = date.getFullYear();
      return `${y}-${m.toString().padStart(2, "0")}-${d
        .toString()
        .padStart(2, "0")}`;
    },
    //
    scrollToElement(colIdx) {
      const entryRef = this.$refs["entry" + colIdx];
      const el = entryRef[0].$el;
      if (el) {
        const top = el.offsetTop - 210;
        el.parentElement.scrollTo(0, top);
      }
    },
    handleScroll(e) {
      const scrollTop = e.target.scrollTop + 215;
      let currTopEntry = 0;
      this.entryPosys.forEach((e, i) => {
        if (scrollTop > e) {
          currTopEntry = i;
        }
      });
      this.colIdxSelected = currTopEntry;
    },
  },
};
</script>

<style scoped lang="scss">
.fixed-title {
  position: sticky;
  top: 0;
  background-color: #f1efec;
  z-index: 10;
  padding-left: 10px;
  padding-top: 10px;
}
</style>
