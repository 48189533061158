<!-- TopMenu.vue -->
<template>
  <div class="topMenu p-2 d-flex flex-column" style="background-color: #e7e7e2">
    <div class="d-flex justify-content-start">
      <b-button
        v-if="
          category != 'descriptions' &&
          category != 'annotations' &&
          category != 'microarray'
        "
        size="sm"
        class="mr-2"
        @click="$emit('new-search')"
        >New Search</b-button
      >
      <b-button
        size="sm"
        class="mr-2"
        @click="$emit('download-csv', { category: category, type: 'all' })"
        >Download All</b-button
      >
      <b-button
        size="sm"
        class="mr-2"
        @click="$emit('download-csv', { category: category, type: 'checked' })"
        >Download Checked</b-button
      >
    </div>

    <div class="d-flex justify-content-start mt-2" v-if="category == 'genes'">
      <b-button
        v-if="category == 'genes'"
        size="sm"
        @click="$emit('go-search')"
        class="mr-2"
        >Get GO Annotations</b-button
      >
      <b-button
        v-if="category == 'genes'"
        size="sm"
        @click="$emit('po-search')"
        class="mr-2"
        >Get PO Annotations</b-button
      >

      <b-button
        v-if="category == 'genes'"
        size="sm"
        @click="$emit('get-sequences')"
        class="mr-2"
        >Get Sequences</b-button
      >
      <b-button
        v-if="category == 'genes'"
        size="sm"
        @click="$emit('get-descriptions')"
        class="mr-2"
        >Get Gene Descriptions</b-button
      >
      <b-button
        v-if="category == 'genes'"
        size="sm"
        @click="$emit('get-locus-history')"
        class="mr-2"
        >Get Locus History</b-button
      >
      <b-button
        v-if="category == 'genes'"
        size="sm"
        @click="$emit('get-microarray')"
        class="mr-2"
        >Get Microarray Elements</b-button
      >
    </div>

    <!-- <b-button
      v-if="category == 'genes'"
      size="sm"
      @click="$emit('get-checked-sequences')"
      >Get Checked Sequences</b-button
    > -->
  </div>
</template>

<script>
export default {
  props: {
    category: String,
  },
};
</script>
