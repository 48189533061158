import Api from "@/services/Api";
import store from "../store/store";
// response data
// {status: null/"subscribed"/"not subscribed", name: null/{institution_name}}
export async function getInstitutionByIP() {
  try {
    const response = await Api().get("subscription/institution");
    return response.data;
  } catch (error) {
    return [];
  }
}

// response data
// { subscriptionType: null/'institutional'/'individual', subscribed: false/true, expDate: ''/{date} }
export async function getSubscriptionStatus() {
  try {
    const response = await Api().get("subscription/status", {
      params: {
        userIdentifier: store.getters["authProfile/getCommunityId"],
      },
    });
    return response.data;
  } catch (error) {
    return [];
  }
}
