<template>
  <div class="overview-wrapper">
    <div class="side-wrapper">
      <ul class="list-group list-group-flush">
        <a
          v-for="(col, i) in searchOptions"
          :key="i"
          href="#"
          class="list-group-item list-group-item-action flex-column align-items-start side-item"
          @click="sideColClicked(i)"
        >
          {{ col.name }}
        </a>
      </ul>
    </div>
    <div class="content-wrapper">
      <b-breadcrumb :items="crumb_links"></b-breadcrumb>
      <h2>{{ overviewTitle }}</h2>
      <p>{{ description }}</p>
      <div class="options-panel">
        <div
          v-for="(option, i) in searchOptions"
          :key="i"
          class="search-option"
        >
          <h3 class="pg-link" @click="sideColClicked(i)">{{ option.name }}</h3>
          <p>{{ option.description }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchOverview",
  data() {
    return {
      crumb_links: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "Search",
          active: true,
        },
      ],
      overviewTitle: "Search Overview",
      description:
        "This section provides advanced search forms for TAIR data. These forms are useful for more complex queries (for example searching by name plus gene model type or map position). A simple query using a name or keyword can be done using the search box in the top right corner of each TAIR page.",
      searchOptions: [
        {
          name: "DNA/Clones",
          description:
            "Search for any DNA, which includes clones, libraries, genomic DNA, and filters.",
          route: "/search/dna",
        },
        {
          name: "Genes",
          description:
            "Genes may be searched by name, keywords, features, and/or location.",
          route: "/search/genes",
        },
        {
          name: "Proteins",
          description:
            "Search using a variety of parameters, including gene information, domain information, and genome position.",
          route: "/search/protein",
        },
        {
          name: "Polymorphisms and Alleles",
          description: "Search by name, features, and/or location.",
          route: "/search/polyallele",
        },
        {
          name: "Publications",
          description:
            "The publications in the TAIR database are derived primarily from PubMed. Entries with the word 'Arabidopsis' in the title, abstract, or MeSH headings are downloaded on a monthly basis.",
          route: "/search/publication",
        },
        {
          name: "People/Labs",
          description:
            "Find contact information for a colleague, register or update your profile.",
          route: "/search/community",
        },
        {
          name: "Seeds/Germplasms",
          description: "Search for ABRC seed stocks or other mutant lines.",
          route: "/search/germplasm",
        },
        {
          name: "Keyword",
          description:
            "Search for any keyword in the TAIR database, including gene names, gene symbols, gene descriptions, and gene product names.",
          route: "/search/keyword",
        },
      ],
    };
  },
  methods: {
    sideColClicked(index) {
      this.$router.push(this.searchOptions[index].route);
    },
  },
};
</script>

<style scoped lang="scss"></style>
