import { render, staticRenderFns } from "./PersonEditDetail.vue?vue&type=template&id=7048a16c&scoped=true"
import script from "./PersonEditDetail.vue?vue&type=script&lang=js"
export * from "./PersonEditDetail.vue?vue&type=script&lang=js"
import style0 from "./PersonEditDetail.vue?vue&type=style&index=0&id=7048a16c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7048a16c",
  null
  
)

export default component.exports