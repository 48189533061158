<template>
  <div class="home">
    <div class="container">
      <div class="mt-2">
        <div class="row">
          <div class="col-sm-9 text-white main_bg">
            <div class="myHeader mt-4 mb-2">
              The Arabidopsis Information Resource
            </div>
            <div>
              <a href="/news/breaking-news#289" target="_blank">
                <img
                  src="https://tair-public.s3.us-west-2.amazonaws.com/banners/mascbanner.png"
                  alt="masc report announcement"
              /></a>
              <div class="mt-4 d-flex flex-column align-items-start text-black">
                <h2>About TAIR</h2>
                <p class="flex text-left">
                  The Arabidopsis Information Resource (TAIR) maintains a
                  database of genetic and
                  <a
                    href="https://conf.phoenixbioinformatics.org/display/COM/Data+Sources"
                    target="_blank"
                    >molecular biology data</a
                  >
                  for the model higher plant
                  <a
                    href="https://conf.phoenixbioinformatics.org/display/COM/Education+and+Outreach+Resources"
                    target="_blank"
                    >Arabidopsis thaliana.</a
                  >
                  Data available from TAIR includes the complete genome sequence
                  along with gene structure, gene product information, gene
                  expression, DNA and seed stocks, genome maps, genetic and
                  physical markers, publications, and information about the
                  Arabidopsis research community. Gene product function data is
                  updated every week from the latest published research
                  literature and community data submissions. TAIR also provides
                  extensive linkouts from our data pages to other Arabidopsis
                  resources.
                </p>
                <p class="desc">
                  <a
                    href="http://www.phoenixbioinformatics.org/"
                    target="_blank"
                  >
                    <img
                      src="@/public/phoenix-logo.png"
                      alt="Phoenix Bioinformatics logo"
                      id="phoenix"
                      width="69"
                      height="40"
                    />
                  </a>
                  <span>
                    TAIR is located at
                    <a
                      href="http://www.phoenixbioinformatics.org/"
                      target="_blank"
                      >Phoenix Bioinformatics
                    </a>
                    and funded by
                    <a
                      href="https://phoenixbioinfo.org/tair/#Pricing"
                      target="_blank"
                      >subscriptions</a
                    >.</span
                  >
                </p>
                <p>
                  Full access to TAIR requires a subscription. Please see our
                  <a
                    href="https://phoenixbioinfo.org/tair/#Pricing"
                    target="_blank"
                  >
                    subscription page </a
                  >for further details.
                </p>

                <!-- <p>
                  Note: This site has been tested with Chrome, Firefox, Safari,
                  and Edge browsers. Some pages may not work as expected if you
                  are using Internet Explorer. For best results, update your
                  browser and enable Javascript and cookies (see help).
                  Scheduled Maintenance: This site may be down for maintenance
                  on any Saturday from 8 am to 10 am PDT.
                </p> -->
              </div>
            </div>
          </div>
          <div class="col-sm-3" style="height: 600px">
            <div class="card main_bg text-black">
              <div class="card-header subHeader">Breaking News</div>
              <div style="height: 600px; overflow-y: scroll">
                <div
                  v-for="(item, i) in news"
                  :key="i"
                  href=""
                  class="card mt-2 news-card"
                >
                  <div class="card-header">
                    <router-link
                      :to="'/news/breaking-news#' + item.id"
                      v-html="item.title"
                    ></router-link>
                    <div>[{{ item.date }}]</div>
                  </div>
                  <div class="card-body" v-html="item.content"></div>
                </div>
              </div>
              <div class="card-footer d-flex justify-content-between">
                <span class="icon-text-wrapper">
                  <a href="https://www.facebook.com/tairnews" target="_blank"
                    ><i class="fab fa-facebook"></i
                  ></a>
                </span>
                <span class="icon-text-wrapper">
                  <a href="http://twitter.com/tair_news" target="_blank"
                    ><i class="fab fa-x-twitter"></i
                  ></a>
                </span>
                <span class="icon-text-wrapper">
                  <a
                    href="https://www.youtube.com/channel/UCqYlBTnoVz6s1beB058lBqQ"
                    target="_blank"
                    ><i class="fab fa-youtube"></i
                  ></a>
                </span>
                <span class="icon-text-wrapper">
                  <a
                    href="https://bsky.app/profile/tairnews.bsky.social"
                    target="_blank"
                    ><i class="fa-brands fa-bluesky"></i
                  ></a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { version, date } from "../../package.json";
import { getShortNews } from "@/services/NewsService";

export default {
  name: "TairHome",
  components: {},
  data() {
    return {
      version: "NO",
      date: "DATE",
      news: [],
    };
  },
  mounted() {
    this.version = version;
    this.date = date;
    document.title = "TAIR - Home";
    getShortNews()
      .then((response) => {
        this.news = response;
      })
      .catch((error) => {
        console.error("Error fetching news:", error);
      });
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.myHeader {
  font-weight: bold;
  color: black;
  font-size: 25px;
}
.subHeader {
  font-size: 20px;
  font-weight: bold;
  color: black;
}
.container {
  max-width: 1300px;
}
.main_bg {
  background-color: #fff;
}
.intro {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
}
.text-black {
  color: black;
}
.news-card {
  text-align: left;
}
.icon-text-wrapper {
  .icon {
    margin-right: 10px;
  }
  .folder-icon {
    padding-bottom: 10px;
  }
  .file-icon {
    padding-bottom: 3px;
  }
  .download-icon {
    margin-left: 10px;
  }
}
</style>
