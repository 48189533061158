<template>
  <div class="searchWrapper">
    <div class="pageTop">
      <div class="d-none d-md-block">
        <div class="d-flex flex-column">
          <b-breadcrumb :items="crumb_links"></b-breadcrumb>
          <div class="text-left ml-2"><h4>Sequence Bulk Download</h4></div>
          <div class="text-left m-2">
            This tool can be used to download a variety of sequences from the
            Arabidopsis Genome Initiative (AGI) in FASTA or tab-delimited
            formats. Individual or sets of AGI locus identifiers (e.g.
            At1g01030) can be typed into the textbox below or uploaded from your
            desktop computer. More information on how to use the tool can be
            found by following the link to the Help document.
          </div>
          <div class="text-left m-2">
            Please use the ftp server if you want to download entire datasets.
            Click HERE to obtain details about the sequence datasets used at
            TAIR.
          </div>
          <div class="text-left m-2">
            For Intron Sequences ONLY: A special format is required for
            obtaining intron sequences using the Intron Sequences dataset.The
            format must include the locus identifier followed by the gene model
            suffix. To specify which intron, the model id.suffix is followed by
            a hyphen (-), and the number of the intron you wish to retrieve. For
            example, to obtain the sequence of the first intron on the AGAMOUS
            gene the format would be AT4G18960.1-1
          </div>
          <div class="text-left m-2">
            For Upstream/Downstream/Intergenic sequences: For these data types
            we ONLY provide the sequence relative to the locus itself (based on
            the maximum extent of all gene models for that locus) regardless of
            whether a locus name or a specific gene model name is used for the
            search.
          </div>
        </div>
      </div>
      <div class="d-md-none">
        <!-- <div class="row">
          <div class="col-4 font-weight-bold pl-2">Search Result</div>
          <div class="col-8">
            <b-dropdown id="dropdown-1" text="Menu" class="m-md-2">
              <b-dropdown-item>New Search</b-dropdown-item>
            </b-dropdown>
          </div>
        </div> -->
      </div>
    </div>
    <div class="pageMain m-2">
      <div class="d-flex flex-column">
        <!-- <div class="row mt-2">
          <div class="col-sm">
            <b-button size="sm">Reset</b-button>
            <b-button size="sm">Submit Query</b-button>
          </div>
        </div> -->
        <div>
          <div class="d-flex flex-column m-4">
            <div v-for="(f, i) in search_form.filters" :key="i">
              <div class="text-left fw-bold ml-8">
                <h5>{{ f.type }}</h5>
              </div>
              <div class="ml-4 mt-2">
                <div v-for="(row, j) in f.rows" :key="j" class="row">
                  <div class="col-sm-4">
                    <p class="text-left fw-bold form-label">{{ row.name }}</p>
                  </div>
                  <div class="col-sm-8">
                    <div
                      v-if="row.value && row.value.type == 'select'"
                      class="w-50 mb-4"
                    >
                      <b-form-select
                        id="inline-form-custom-select-pref"
                        class="mb-2 mr-sm-2 mb-sm-0"
                        :options="row.value.options"
                        :value="row.value.default"
                      ></b-form-select>
                    </div>
                    <div
                      v-if="row.value && row.value.type == 'textbox'"
                      class="w-50 mb-4"
                    >
                      <b-form-textarea
                        id="textarea"
                        v-model="row.value.text"
                        rows="5"
                        max-rows="6"
                      ></b-form-textarea>
                    </div>

                    <div
                      v-else-if="row.value && row.value.type == 'input'"
                      class="w-50 mb-4"
                    >
                      <b-form-input
                        id="inline-form-input-name"
                        class="mb-2 mr-sm-2 mb-sm-0"
                        placeholder=""
                      ></b-form-input>
                    </div>
                    <div
                      v-else-if="row.value && row.value.type == 'multiSelect'"
                      class="w-50 mb-4"
                    >
                      <b-form-select
                        id="inline-form-custom-select-pref"
                        class="mb-2 mr-sm-2 mb-sm-0"
                        :options="row.value.options"
                        :value="null"
                        :select-size="4"
                      ></b-form-select>
                    </div>
                    <div
                      v-else-if="row.value && row.value.type == 'checkbox'"
                      class="text-left w-50 mb-4"
                    >
                      <b-form-checkbox
                        id="checkbox-1"
                        v-model="status"
                        name="checkbox-1"
                        value="accepted"
                        unchecked-value="not_accepted"
                      >
                        {{ row.value.title }}
                      </b-form-checkbox>
                    </div>
                    <div
                      v-else-if="row.value && row.value.type == 'radio'"
                      class="text-left w-80 mb-4"
                    >
                      <b-form-group :label="row.value.text">
                        <b-form-radio
                          v-for="(val, k) in row.value.options"
                          :key="k"
                          :value="val"
                          >{{ val }}</b-form-radio
                        >
                      </b-form-group>
                    </div>
                    <div
                      v-else-if="row.value && row.value.type == 'mixed'"
                      class="text-left mb-4"
                    >
                      <div v-for="(e, k) in row.value.elements" :key="k">
                        <div class="d-flex">
                          <div v-if="e.type == 'select'">
                            <b-form-select
                              id="inline-form-custom-select-pref"
                              class="mb-2 mr-sm-2 mb-sm-0"
                              :options="e.options"
                              :value="e.default"
                            ></b-form-select>
                          </div>
                          <div v-else-if="e.type == 'input'">
                            <b-form-input
                              id="inline-form-input-name"
                              class="mb-2 mr-sm-2 mb-sm-0"
                              placeholder=""
                            ></b-form-input>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else class="text-left">Nothing</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-sm">
                <b-button size="sm">Reset</b-button>
                <b-button size="sm">Get Sequences</b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BulkSequenceSearch",
  data() {
    return {
      crumb_links: [
        {
          text: "Home",
          href: "#",
        },
        {
          text: "Tools",
          href: "#",
        },
        {
          text: "Bulk Data Retrieval",
          active: true,
        },
      ],
      path_ids: [],
      search_form: {
        filters: [
          {
            type: "Sequences",
            rows: [
              {
                name: "Locus/Gene Model Identifiers or Sequences:",
                value: {
                  type: "textbox",
                  text: "",
                },
              },
              {
                name: "Dataset",
                value: {
                  type: "select",
                  options: [
                    "any",
                    "Arabidopsis thaliana",
                    "Arabidopsis lyrata",
                  ],
                  default: "Arabidopsis thaliana",
                },
              },
              {
                name: "Search Against:",
                value: {
                  type: "radio",
                  title: "",
                  options: [
                    "Get one sequence per locus (representative gene model/splice form only)",
                    "Get sequences for all gene models/splice forms",
                    "Get sequences for only the gene model/splice form matching my query",
                  ],
                  default: 0,
                },
              },
            ],
          },
        ],
      },
    };
  },
  watch: {
    "$route.query.key": function (id) {
      this.$forceUpdate();
    },
  },
  components: {},
  mounted() {
    let path_ids = this.$route.params.ids.split(",");
    this.search_form.filters[0].rows[0].value.text = path_ids.join("\n");
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      alert(JSON.stringify(this.form));
    },
    onReset(event) {
      event.preventDefault();
    },
  },
};
</script>

<style scoped lang="scss">
.locusLink {
  color: #2a568f;
  cursor: pointer;
  &:hover {
    background: #feffbb;
  }
}
.form-label {
  font-size: 18px;
  color: #777;
  font-weight: bold;
}
.keySearchColor {
  color: #ff0000;
}
</style>
