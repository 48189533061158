import NewsView from "@/views/news/NewsView";
import NewsOverview from "@/views/news/NewsOverview";
import BreakingNews from "@/views/news/BreakingNews";
import OldNews from "@/views/news/OldNews";
import Events from "@/views/news/Events";
import Jobs from "@/views/news/Jobs";
import ViewJob from "@/views/news/ViewJob";

export const newsRoutes = {
  path: '/news',
  name: 'NewsView',
  component: NewsView,
  children: [
    {
      path: 'overview',
      name: 'NewsOverview',
      component: NewsOverview,
    },
    {
      path: 'breaking-news',
      name: 'BreakingNews',
      component: BreakingNews,
    },
    {
      path: 'old-news',
      name: 'OldNews',
      component: OldNews,
    },
    {
      path: 'events',
      name: 'Events',
      component: Events,
    },
    {
      path: 'jobs',
      name: 'Jobs',
      component: Jobs,
    },
    {
      path: 'jobs/view',
      name: 'ViewJob',
      component: ViewJob,
    },
  ]
}