import axios from "axios";

export default () => {
  // Create an Axios instance with your base URL
  const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
    withCredentials: true,
  });

  // Response interceptor for handling 401 responses with redirectUri
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      // Applicable to both meter block or need login page
      if (error.response && error.response.status === 401) {
        const { data } = error.response;
        if (data && data.redirectUri) {
          // Redirect the user to the redirectUri with information of the current page
          window.location.href = `${
            data.redirectUri
          }&redirect=${encodeURIComponent(window.location.href)}`;
          return Promise.reject(error);
        }
      }
      return Promise.reject(error);
    }
  );

  // Return the configured Axios instance
  return axiosInstance;
};
