<template>
  <div class="d-flex flex-column text-left">
    <div class="d-flex">
      <div class="mr-2" v-if="helpTxt">
        <b-tooltip :target="'help-header'" triggers="hover">
          <p
            v-html="helpTxt"
            class="overflow-div d-flex flex-column text-left"
          ></p>
        </b-tooltip>
        <b-icon
          class="ml-2"
          :id="'help-header'"
          icon="question-circle-fill"
        ></b-icon>
      </div>
      <span class="font-weight-bold text-left">{{ title }}</span>
    </div>
    <div v-if="data">
      <link-wrapper v-if="data.link" :link="data.link" :name="data.name" />
      <span v-else>{{ data.name }}</span>
    </div>
  </div>
</template>

<script>
import LinkWrapper from "../common/LinkWrapper.vue";
export default {
  components: { LinkWrapper },
  mounted() {},
  props: ["data", "title", "helpTxt"],
};
</script>
