<template>
  <div class="searchWrapper d-flex flex-column">
    <div class="pageTop">
      <div class="d-none d-md-block">
        <div class="font-weight-bold text-left title ml-2">
          Browse Gene Class Symbols
        </div>
      </div>
      <div>
        <div class="pageDesc mt-2">
          <span class="text-left px-4 statement" v-html="queryStatement"></span>
        </div>
      </div>
    </div>
    <div class="pageContent">
      <div class="custom-gap filter-section">
        <label for="letterFilter" class="filter-label"
          >Filter by Symbol's First Letter:</label
        >
        <select
          id="letterFilter"
          v-model="filterLetter"
          @change="applyFilter"
          class="filter-dropdown"
        >
          <option value="">All</option>
          <option v-for="char in 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('')">
            {{ char }}
          </option>
        </select>
      </div>
      <div class="d-flex align-content-left m-2">
        Displaying {{ localCurrentPage * perPage - perPage + 1 }} -
        {{
          items.length < localCurrentPage * perPage
            ? items.length
            : localCurrentPage * perPage
        }}
        of {{ items.length }} results
      </div>
      <div class="tableWrapper">
        <ResultsTable
          :items="items"
          :fields="fields"
          :loading="loading"
          :error="error"
          :showSelect="false"
          :perPageProp="perPage"
          @update:currentPage="localCurrentPage = $event"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PostsService from "@/services/PostsService";
import { BPagination } from "bootstrap-vue";
import ResultsTable from "@/components/search/ResultsTable.vue";

export default {
  name: "GeneSymbolBrowse",
  data() {
    return {
      searchTerm: "",
      lociMatchCount: 0,
      geneModelCount: 0,
      selected: [],
      items: [],
      fields: [],
      loading: false,
      error: false,
      queryStatement: "",
      localCurrentPage: 1,
      perPage: 500,
      pages: 0,
      allItems: [], // to store all fetched items
      filterLetter: "",
    };
  },
  mounted() {
    document.title = "Gene Class Symbols";
    this.init();
  },
  components: {
    BPagination,
    ResultsTable,
  },
  methods: {
    init() {
      let search_params = {
        searchTerms: [],
      };
      this.loadGeneSymbolResults(search_params);
    },
    async loadGeneSymbolResults(search_params) {
      this.fields = [];
      this.fields.push({ key: "row_num", label: " # " });
      this.fields.push({
        key: "symbol",
        label: "Symbol",
        cellType: "name_link",
      });
      this.fields.push({
        key: "full_name",
        label: "Symbol Full name",
        cellType: "name_link",
      });
      this.fields.push({
        key: "type",
        label: "Type",
        cellType: "name_link",
      });
      this.fields.push({
        key: "status",
        label: "Status",
        cellType: "name_link",
      });
      this.fields.push({
        key: "reference",
        label: "Reference",
        cellType: "name_link",
      });
      this.fields.push({
        key: "contact",
        label: "Contact",
        cellType: "name_link",
      });
      this.fields.push({
        key: "submitted",
        label: "Submitted",
        cellType: "name_link",
      });
      this.loading = true;
      let items = [];
      try {
        let response = await PostsService.fetchGeneSymbolSearch(search_params);
        if (response.data.error) {
          this.error = true;
          return;
        }
        // this.allItems = response.data.docs.map(d => this.formatItem(d));
        this.applyFilter(); // Apply filter immediately after fetching
        let docs = response.data.docs;

        let totalMatch = response.data.total;

        for (const d of docs) {
          let date = new Date(d.submitted);
          let formatted_date = date.toLocaleDateString("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
          });
          let symbolType = "G";
          if (d.type == "T") {
            symbolType = "P";
          }
          items.push({
            id: d.id,
            selected: false,
            symbol: {
              name: d.symbol,
              link: `/genesymbol?key=${d.tair_object_id}`,
            },
            full_name: {
              name: d.full_name,
            },
            type: {
              name: symbolType,
            },
            status: {
              name: d.status,
            },
            reference: {
              name: d.reference ? d.reference.split("_")[0] : "",
            },
            contact: {
              name: d.reference ? d.reference.split("_")[0] : "",
              link: d.reference
                ? `/community?key=${d.reference.split("_")[1]}`
                : "",
            },
            submitted: {
              name: formatted_date,
            },
          });
        }

        this.items = items;
        this.allItems = items;
        search_params.matchCount = totalMatch;
        this.loading = false;
      } catch (error) {
        console.log(
          error.response ? error.response.statusText : "Unknwon error"
        );
        this.loading = false;
        this.error = true;
        return;
      }
    },
    applyFilter() {
      if (!this.filterLetter) {
        this.items = this.items; // No filter applied
      } else {
        this.items = this.allItems.filter((item) =>
          item.symbol.name.startsWith(this.filterLetter)
        );
      }
    },
  },
};
</script>

<style scoped lang="scss">
.title {
  font-size: 24px;
}
.statement {
  font-size: 20px;
}

.custom-gap {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 10px;
}

.filter-section {
  padding: 10px;
  background-color: #f4f4f4; // A light grey background for the filter section
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.filter-label {
  padding-top: 10px;
  font-weight: bold;
  margin-right: 10px;
  color: #333; // Dark grey color for text
}

.filter-dropdown {
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 4px;
  border: 1px solid #ccc; // Light grey border
  background-color: white;
  cursor: pointer;
  &:focus {
    outline: none;
    border-color: #0056b3; // Highlight focus with a brighter color
  }
}
</style>
