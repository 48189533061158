<template>
  <div class="multi-select-component">
    <select
      :class="widthClass"
      class="mt-2"
      v-model="selectedValues"
      multiple
      :size="size"
      aria-label="multi_select"
    >
      <option
        v-for="option in options"
        :key="option.value"
        :value="option.value"
      >
        {{ option.text }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: "MultiSelectComponent",
  props: {
    options: {
      type: Array,
      required: true,
    },
    value: {
      type: Array,
      default: () => [],
    },
    size: {
      type: Number,
      default: 4,
    },
    width: {
      type: String,
      default: "medium", // Default width if none is provided
      validator: function (value) {
        return ["small", "medium", "large"].indexOf(value) !== -1;
      },
    },
  },
  data() {
    return {
      selectedValues: this.value,
    };
  },
  computed: {
    widthClass() {
      // Compute the class to bind based on the width prop
      switch (this.width) {
        case "small":
          return "select-small";
        case "medium":
          return "select-medium";
        case "large":
          return "select-large";
        default:
          return ""; // If not specified, no width class is applied
      }
    },
  },
  watch: {
    selectedValues(newVal) {
      this.$emit("input", newVal);
    },
  },
};
</script>
