const JBROWSE_URI = process.env.VUE_APP_JBROWSE_DOMAIN;
const JBROWSE2_URI = process.env.VUE_APP_JBROWSE2_DOMAIN;
const DISPLAY_MARGIN_PERCENTAGE = 5;
const DISPLAY_MIN_HEIGHT = 130;
const DISPLAY_MAX_HEIGHT = 400;
const DISPLAY_BASE_HEIGHT = 100;
const DISPLAY_GENE_UNIT_HEIGHT = 45;
const CHROMOSOME_MITOCHONDRIAL_GENOME = "ChrM";

function getDefaultJBrowseUrl() {
  const defaultChr = 1;
  const defaultStartPos = 22047;
  const defaultEndPos = 32332;
  const defaultGeneModelType = "protein_coding"
  return getFullViewUrl(defaultChr, defaultStartPos, defaultEndPos, defaultGeneModelType);
}

function getDefaultJBrowse2Url() {
  return `${JBROWSE2_URI}/index.html`;
}

function getSlimViewUrl(chr, start, end, geneModelType) {
    const chrMarker = getMarker(chr, start, end);
    const tracks = getSlimViewTracks(geneModelType);
    const params = new URLSearchParams();
    params.set('data', "Araport11");
    params.set('loc', chrMarker);
    params.set('tracks', tracks);
    params.set('tracklist', '0');
    params.set('nav', '0');
    params.set('overview', '0');
    params.set('fullviewlink', '0');

    const queryString = params.toString();
    return `${JBROWSE_URI}/index_slim.html?${queryString}`;
}

function getFullViewUrl(chr, start, end, geneModelType) {
    const chrMarker = getMarker(chr, start, end);
    const tracks = getFullViewTracks(chr, geneModelType);
    const params = new URLSearchParams();
    params.set('data', "Araport11");
    params.set('loc', chrMarker);
    params.set('tracks', tracks);
    const queryString = params.toString();
    return `${JBROWSE_URI}/index.html?${queryString}`;
}

function getSlimViewHeight(numGenes) {
    const height = DISPLAY_BASE_HEIGHT + numGenes * DISPLAY_GENE_UNIT_HEIGHT;
    return Math.min(DISPLAY_MAX_HEIGHT, Math.max(height, DISPLAY_MIN_HEIGHT));
}

function getMapElementViewUrl(chr, start, end, mapElementType) {
    const chrMarker = getMarker(chr, start, end);
    const tracks = getMapElementViewTracks(mapElementType);
    const params = new URLSearchParams();
    params.set('data', "Araport11");
    params.set('loc', chrMarker);
    params.set('tracks', tracks);
    const queryString = params.toString();
    return `${JBROWSE_URI}/index.html?${queryString}`;
}

function getMarker(chr, start, end) {
  const marginLength = Math.round((Number(end) - Number(start)) * DISPLAY_MARGIN_PERCENTAGE / 100);
  const bufferedStart = Number(start) - marginLength;
  const bufferedEnd = Number(end) + marginLength;
  return `${chr}:${bufferedStart}..${bufferedEnd}`;
}

function getFullViewTracks(chr, geneModelType) {
    let tracks = "";
    if (geneModelType != null) {
        tracks = "TAIR10_genome,A11-GL"; // Genome Reference (TAIR10), Araport11 - Gene Locus
        switch (geneModelType) {
            case "protein_coding":
            case "transposable_element_gene":
                tracks += ",A11-PC"; // Araport11 - Protein Coding Genes
                break;
            case "uORF":
                tracks += ",A11-UO"; // Araport11 - Upstream ORFs
                break;
            case "antisense_long_noncoding_rna":
            case "long_noncoding_rna":
            case "antisense_rna":
            case "other_rna":
                tracks = "A11-NCR"; // Araport 11 - Non-Coding RNA
                break;
            case "miRNA_primary_transcript":
            case "small_nucleolar_rna":
            case "pre_trna":
            case "small_nuclear_rna":
            case "ribosomal_rna":
                tracks = "A11-SRL"; // Araport11 - small RNA Loci
                break;
            case "novel_transcribed_region":
                tracks += ",A11-NTR"; // Araport11 - Novel Transcribed Regions
                break;
            case "pseudogene":
                tracks += ",A11-PG"; // Araport11 - Pseudogenes
                break;
            default:
                tracks += ",A11-PC,A11-TE,A11-UO,A11-NTR,A11-PG,A11-NCR";
                // A full set of tracks
                break;
        }
        if (chr && chr !== CHROMOSOME_MITOCHONDRIAL_GENOME) {
            tracks += ",SALK_tDNAs"; // TDNA-seq (O'Malley R et al. 2014)
        }
    } else {
        tracks = "TAIR10_genome,A11-SRL,A11-UO,A11-NTR,A11-PG,A11-NCR,A11-PC,A11-GL,SALK_tDNA";
    }
    return tracks;
}

function getSlimViewTracks(geneModelType) {
    let tracks = "";
    if (geneModelType != null) {
        switch (geneModelType) {
            case "protein_coding":
            case "transposable_element_gene":
                tracks = "A11-PC"; // Araport11 - Protein Coding Genes
                break;
            case "uORF":
                tracks = "A11-UO"; // Araport11 - Upstream ORFs
                break;
            case "antisense_long_noncoding_rna":
            case "long_noncoding_rna":
            case "antisense_rna":
            case "other_rna":
                tracks = "A11-NCR"; // Araport 11 - Non-Coding RNA
                break;
            case "miRNA_primary_transcript":
            case "small_nucleolar_rna":
            case "pre_trna":
            case "small_nuclear_rna":
            case "ribosomal_rna":
                tracks = "A11-SRL"; // Araport11 - small RNA Loci
                break;
            case "novel_transcribed_region":
                tracks = "A11-NTR"; // Araport11 - Novel Transcribed Regions
                break;
            case "pseudogene":
                tracks = "A11-PG"; // Araport11 - Pseudogenes
                break;
            default:
                tracks = "TAIR10_genome,A11-TE,A11-UO,A11-NTR,A11-PG,A11-NCR,A11-PC,A11-GL";
                break;
        }
    } else {
        tracks = "TAIR10_genome,A11-SRL,A11-UO,A11-NTR,A11-PG,A11-NCR,A11-PC,A11-GL";
    }
    return tracks;
}

function getMapElementViewTracks(mapElementType) {
    let tracks = "";
    if (mapElementType != null) {
        tracks = "A11-GL"; // Araport11 - Gene Locus
        switch (mapElementType.toLowerCase()) {
            case "genetic_marker":
            case "marker":
                tracks += ",TAIR9_markers"; // Genetic Marker (TAIR9)
                break;
            case "polymorphism":
            case "polyallele":
                tracks += ",TAIR9_polymorphisms,TAIR9_tDNAs,SALK_tDNAs"; // Polymorphism (TAIR9), TDNA Classical (TAIR9), TDNA-seq (O'Malley R et al. 2014)
                break;
            case "transposon":
                tracks += ",A11-TE"; // Araport11 - Transposable elements
                break;
            case "assembly_unit":
                tracks += ",TAIR9_assemblies"; // Tiling Path (TAIR9)
                break;
            default:
                tracks = "TAIR10_genome,A11-GL,A11-PC,SALK_tDNAs"; 
                break;
        }
    } else {
        tracks = "TAIR10_genome,A11-GL,A11-PC,SALK_tDNAs";
    }
    return tracks;
}


module.exports = {
  getDefaultJBrowseUrl,
  getDefaultJBrowse2Url,
  getSlimViewUrl,
  getFullViewUrl,
  getMapElementViewUrl
};