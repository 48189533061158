export const newsURLs = {
  namespaced: true,
  state: {
    ICARAbstractsURL: "https://conf.phoenixbioinformatics.org/display/COM/Abstracts+and+Programs+from+the+International+Arabidopsis+Meetings",
    howToPostAJobURL: "https://conf.phoenixbioinformatics.org/display/COM/FAQ#FAQ-HowdoIpostajobopeningatTAIR?"
  },

  getters: {
    getICARAbstractsURL: state => {
      return state.ICARAbstractsURL
    },

    getHowToPostAJobURL: state => {
      return state.howToPostAJobURL
    }
  }
}