<template>
  <div class="w-full">
    <div class="d-flex justify-content-start">
      <span class="font-weight-bold text-left">{{ title }}</span>
    </div>
    <div class="d-flex justify-content-start" v-if="!data || data.length === 0">
      <span class="text-left">No data available</span>
    </div>
    <div class="d-flex justify-content-start">
      <span v-for="(p, l) in data" :key="l">
        <a v-if="p.img_url" :href="p.full_url" target="_blank"
          ><img class="mr-2 img-link" :src="p.img_url" height="100px"
        /></a>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    // console.log(this.data.length);
  },
  props: ["data", "title"],
};
</script>
<style scoped>
.img-link {
  cursor: pointer;
}
.img-link:hover {
  background: #feffbb;
  border: 1px solid #f0f0f0;
}
</style>
