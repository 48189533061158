<template>
  <div class="items-panel">
    <img
      v-if="contentType === 'image'"
      :src="contentUrl"
      alt="Loaded Content"
    />
    <pre v-else-if="contentType === 'text'">{{ textContent }}</pre>
    <iframe
      v-else-if="contentType === 'pdf'"
      :src="contentUrl"
      frameborder="0"
    ></iframe>
    <div v-if="loaded && !contentUrl && !textContent">
      Cannot load file content. Please try again later or contact
      <a href="mailto:curator@arabidopsis.org">curator@arabidopsis.org</a>
    </div>
  </div>
</template>

<script>
import { getDownloadBlob } from "@/services/DownloadService";

export default {
  name: "DownloadFile",
  data() {
    return {
      contentUrl: "",
      textContent: "",
      contentType: "",
      loaded: false,
    };
  },
  async beforeRouteEnter(to, from, next) {
    const filePath = to.query.path;
    const fileName = filePath.split("/").at(-1);

    const blob = await getDownloadBlob(filePath);
    const contentType = blob.type;
    const objectUrl = URL.createObjectURL(blob);

    if (contentType.startsWith("text/")) {
      const reader = new FileReader();
      reader.onload = function () {
        next((vm) => {
          vm.textContent = reader.result;
          vm.contentType = "text";
          vm.loaded = true;
        });
      };
      reader.readAsText(blob);
    } else if (contentType.startsWith("image/")) {
      next((vm) => {
        vm.contentUrl = objectURL;
        vm.contentType = "image";
        vm.loaded = true;
      });
    } else if (contentType === "application/pdf") {
      // It's a PDF
      next((vm) => {
        vm.contentType = "pdf";
        vm.contentUrl = objectUrl;
      });
    } else {
      // download the file and go back to its parent dir page

      // Create a hidden link
      const link = document.createElement("a");
      link.style.display = "none";
      document.body.appendChild(link);

      link.href = objectUrl;
      link.setAttribute("download", fileName);
      link.click();

      window.URL.revokeObjectURL(objectUrl);
      document.body.removeChild(link);

      const dirPath = filePath.split("/").slice(0, -1).join("/");

      next({
        name: "DownloadList",
        query: {
          dir: dirPath,
        },
      });
    }
  },
  methods: {
    displayContent() {
      if (this.isImage) {
        // Display image
        return `<img src="${this.contentUrl}" alt="Blob Image">`;
      } else {
        // Display text or other content types
        return `<pre>${this.contentUrl}</pre>`;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.icon-text-wrapper {
  display: flex;
  align-items: center;

  // Ensure icons align nicely with the text
  .margin-right {
    margin-right: 10px; // Adjust spacing between icon and text as needed
  }

  h3,
  a {
    display: inline; // Ensure text does not break onto a new line
    margin: 0; // Remove any default margins that might cause layout issues
  }
}

iframe {
  width: 100%;
  height: 600px;
  border: none; /* Remove default border */
}
</style>
