import PostsService from "../PostsService";

export async function loadResults(search_params, type = "detail") {
  let fields = [
    { key: "row_num", label: " # " },
    { key: "selected", label: "" },
    {
      key: "type",
      label: "Type",
      cellType: "name_link",
      class: "narrow",
    },
    {
      key: "type",
      label: "Type",
      cellType: "name_link",
      class: "narrow",
    },
    {
      key: "name",
      label: "Name",
      cellType: "name_link",
    },
    {
      key: "location",
      label: "Location (state, country)",
      cellType: "name_link",
    },
    {
      key: "aff_orgs",
      label: "Affiliated Organizations",
      cellType: "name_links_multi",
    },
  ];
  let items = [];
  try {
    let response = await PostsService.fetchCommunitySearch(search_params);
    if (response.data.error) {
      return { error: true, items: [], fields: [] };
    }
    let docs = response.data.docs;

    let totalMatch = response.data.total;

    for (const d of docs) {
      let communityName = { name: "" };
      let aff_orgs = [];
      if (d.type == "organization") {
        communityName = {
          name: d.org_name ? d.org_name[0] : "",
          link: `/organization?key=${d.communityId}`,
        };
      } else {
        communityName = {
          name: d.first_name ? d.last_name[0] + ", " + d.first_name[0] : "",
          link: `/person?key=${d.communityId}`,
        };
        if (d.affiliated_orgs) {
          d.affiliated_orgs.forEach((a) => {
            let aObj = a.split(";");
            aff_orgs.push({
              name: aObj[0],
              link: `/organization?key=${aObj[1]}`,
            });
          });
        }
      }
      let location = "";
      if (d.state) {
        location = d.state[0] + ",";
      }
      if (d.country) {
        location += d.country;
      }
      if (d.state == null && d.country == null) {
        location = "Unknown";
      }
      items.push({
        id: d.id,
        selected: false,
        type: {
          name:
            d.type == "organization"
              ? `${d.type}: ${d.org_type ? d.org_type : "Unknown"}`
              : `${d.type}: ${d.job_title ? d.job_title : "Unknown"}`,
        },
        name: communityName,
        location: {
          name: location,
        },
        aff_orgs: aff_orgs,
      });
    }
    items.sort((a, b) => {
      let nameA = a.name.name.toUpperCase();
      let nameB = b.name.name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    search_params.matchCount = totalMatch;
    let queryStatement = "";
    if (type === "general") {
      queryStatement = `Your query for community records where last/org name contains the term <span class="highlight-term">${search_params.searchText}</span> resulted in <span style="color: green">${totalMatch}</span> matches`;
    } else {
      queryStatement = setQueryStatement(search_params);
    }
    return {
      error: false,
      items: items,
      fields: fields,
      queryStatement: queryStatement,
    };
  } catch (error) {
    //   this.loading = false;
    return { error: true, items: [], fields: [] };
  }
}
function setQueryStatement(params) {
  let paramMap = {
    org_name_input: "Last/Organization Name",
  };
  let queryStatement = `Your query for community records where `;
  let queryParts = [];
  params.searchTerms.forEach((s) => {
    if (s.search_method) {
      //Find key of s
      let key = Object.keys(s)[1];
      let value = s[key];
      if (paramMap[key]) {
        key = paramMap[key];
      }
      queryParts.push(
        `${key} ${s.search_method} <span class="highlight-term">${value}</span>`
      );
    } else {
      let key = Object.keys(s)[0];
      queryParts.push(
        `${key} is <span class="highlight-term">${s[key]}</span>`
      );
    }
  });
  queryStatement += queryParts.concat(" and ").join(" ");
  queryStatement += ` resulted in <span style="color: green">${params.matchCount}</span> matches`;
  return queryStatement;
}
