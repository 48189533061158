<template>
  <div class="registration-container">
    <h3>Register a Gene Class Symbol</h3>
    <p>
      To register a Gene Class Symbol, ensure you're registered with TAIR.
      <a href="/register">Click here to register</a>.
    </p>
    <p>
      Gene Class Symbols are typically 3 or 4 letters, identifying a gene or
      gene family. We discourage the use of 2-letter symbols for new loci,
      unless scientifically justified.
    </p>
    <p>
      Use UPPERCASE for Mutant Symbols (e.g., EMBRYONIC LETHAL) and lowercase
      for Gene Product Symbols, with exceptions for domains.
    </p>
    <p>
      The registration process checks the symbol against the Registered Symbol
      list and TAIR for unpublished symbols to avoid duplicates.
    </p>
    <p>
      Here is the link to the form to register gene class symbol:
      <a
        href="/submit/gene_class_symbol_registration/form"
        @click.prevent="handleRegisterClick"
        >Register a Gene Class Symbol</a
      >
    </p>
    <div v-if="showError" class="error-box">
      You must be logged in to register a Gene Class Symbol.
    </div>

    <h5>View Registered Symbols:</h5>
    <ul>
      <li><a href="/browse/gene_symbols">All Registered Symbols</a></li>
      <!-- <li><a href="MUTANT_PHENOTYPES_LINK">Mutant Phenotype Symbols</a></li>
      <li><a href="GENE_PRODUCTS_LINK">Gene Product Symbols</a></li> -->
    </ul>
    <p>
      For queries or more information, email the curator at
      <a href="mailto:curator@arabidopsis.org">curator@arabidopsis.org</a>.
    </p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "GeneClassSymbolRegistration",
  data() {
    return {
      loginUrl: process.env.VUE_APP_LOGIN_URL,
      showError: false,
    };
  },
  computed: {
    ...mapGetters({
      isLoggedIn: "authProfile/isLoggedIn",
    }),
  },
  methods: {
    handleRegisterClick(event) {
      // Check if user is logged in
      if (!this.isLoggedIn) {
        this.showError = true; // Show error message
        setTimeout(() => (this.showError = false), 5000); // Optional: hide error after 5 seconds
      } else {
        // If logged in, proceed to the link (could also redirect programmatically here)
        window.location.href = "/submit/gene_class_symbol_registration/form";
      }
    },
  },
};
</script>

<style scoped>
.registration-container {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
  text-align: left;
}
.registration-container h3,
.registration-container h5 {
  color: #333;
}
.registration-container a {
  color: #007bff;
}
.error-box {
  color: #ff0000; /* Red text for error */
  background-color: #ffecec; /* Light red background */
  border: 1px solid #d6b5b5; /* Light red border */
  margin-top: 10px;
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 5px;
}
</style>
