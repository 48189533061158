import PostsService from "../PostsService";
export async function loadResults(search_params, type = "detail") {
  if (search_params.category == "clone") {
    return loadCloneResults(search_params, type);
  } else {
    return loadVectorResults(search_params, type);
  }
}

export async function loadCloneResults(search_params, type = "detail") {
  let fields = [
    { key: "row_num", label: " # " },
    { key: "selected", label: "" },
    {
      key: "name",
      label: "Clone Name",
      cellType: "name_link",
    },
    {
      key: "alias",
      label: "Aliases",
      cellType: "name_link",
    },
    {
      key: "locus1",
      label: "Locus",
      cellType: "name_link",
    },
    {
      key: "genbank",
      label: "GenBank Accession",
      cellType: "name_link",
    },
    {
      key: "vector_type",
      label: "Vector Type",
      cellType: "name_link",
    },
    { key: "chrom", label: "Chrom", cellType: "name_link" },
    {
      key: "position",
      label: "Position",
      cellType: "name_link",
    },
    {
      key: "stock",
      label: "Stock Number",
      cellType: "name_link",
    },
  ];
  let items = [];
  try {
    let response = await PostsService.fetchDnaDetailSearch(search_params);
    if (response.data.error) {
      return { error: true, items: [], fields: [] };
    }
    let docs = response.data.docs;

    let totalMatch = response.data.total;
    search_params.matchCount = totalMatch;
    for (const d of docs) {
      items.push({
        id: d.id,
        selected: false,
        tair_object_id: `Clone:${d.tairObjectId}`,
        name: {
          name: d.name[0],
          link: `/clone?key=${d.cloneId}`,
        },
        locus1: {
          name: d.locus_name ? d.locus_name[0] : "",
          link: d.locus_id ? `/locus?key=${d.locus_id}` : "",
        },
        alias: {
          name: d.alias ? d.alias.join(";") : "",
        },
        vector_type: {
          name: d.vector_type ? d.vector_type[0] : "",
        },
        genbank: {
          name: d.genbank ? d.genbank[0] : "",
          link: d.genbank
            ? `http://www.ncbi.nlm.nih.gov/entrez/viewer.cgi?db=nucleotide&val=${d.genbank[0]}`
            : "",
        },
        chrom: {
          name: d.chrom ? d.chrom : "",
        },
        position: {
          name: d.start_position ? d.start_position + " bp" : "",
        },
        stock: {
          name: d.stock_number ? d.stock_number[0] : "",
          link: d.stock_number
            ? `https://abrc.osu.edu/stocks/number/${d.stock_number[0]}`
            : "",
        },
      });
    }
    let queryStatement = "";
    if (type === "general") {
      queryStatement = `Your query for clones where name or GenBank accession contains the term <span class="highlight-term">${search_params.searchText}</span> resulted in <span style="color: green">${totalMatch}</span> matches`;
    } else {
      queryStatement = setQueryStatement(search_params);
    }
    return {
      error: false,
      items: items,
      fields: fields,
      queryStatement: queryStatement,
    };
  } catch (error) {
    //   this.loading = false;
    return { error: true, items: [], fields: [] };
  }
}

export async function loadVectorResults(search_params, type = "detail") {
  let fields = [
    { key: "row_num", label: " # " },
    { key: "selected", label: "" },
    {
      key: "name",
      label: "Vector Name",
      cellType: "name_link",
    },
    {
      key: "alias",
      label: "Aliases",
      cellType: "name_link",
    },
    {
      key: "vector_type",
      label: "Vector Type",
      cellType: "name_link",
    },
    {
      key: "stock",
      label: "Stock Number",
      cellType: "name_link",
    },
  ];
  let items = [];
  try {
    let response = await PostsService.fetchDnaDetailSearch(search_params);
    if (response.data.error) {
      this.error = true;
      return;
    }
    let docs = response.data.docs;

    for (const d of docs) {
      items.push({
        id: d.id,
        selected: false,
        name: {
          name: d.name[0],
          link: `/vector?key=${d.vectorId}`,
        },
        alias: {
          name: d.alias ? d.alias.join(";") : "",
        },
        vector_type: {
          name: d.vector_type ? d.vector_type[0] : "",
        },
        stock: {
          name: d.stock_number ? d.stock_number[0] : "",
          link: d.stock_number
            ? `https://abrc.osu.edu/stocks/number/${d.stock_number[0]}`
            : "",
        },
      });
    }
    let totalMatch = response.data.total;
    search_params.matchCount = totalMatch;
    let queryStatement = "";
    if (type === "general") {
      queryStatement = `Your query for vectors where name contains the term <span class="highlight-term">${search_params.searchText}</span> resulted in <span style="color: green">${totalMatch}</span> matches`;
    } else {
      queryStatement = setQueryStatement(search_params);
    }
    return {
      error: false,
      items: items,
      fields: fields,
      queryStatement: queryStatement,
    };
  } catch (error) {
    return { error: true, items: [], fields: [] };
  }
}

export function setQueryStatement(params) {
  try {
    let queryStatement = `Your query for <span class="highlight-term">${params.category}</span> `;
    let queryParts = [];
    let searchParts = [];

    params.searchTerms.forEach((s, index) => {
      if (s.search_input) {
        searchParts.push(
          `${s.search_type} ${s.search_method} <span class="highlight-term">${s.search_input}</span>`
        );
      }
    });
    if (searchParts.length > 0) {
      if (params.searchTerms.length > 1) {
        queryParts.push(searchParts.join(" and "));
      } else {
        queryParts.push(searchParts.join(""));
      }
    }

    let restrictParts = [];
    params.restrictByFeatures &&
      params.restrictByFeatures.forEach((feat) => {
        for (let key in feat) {
          restrictParts.push(
            `${key} is <span class="highlight-term">${feat[key]}</span>`
          );
        }
      });

    if (restrictParts.length > 0) {
      queryParts.push(restrictParts.join(" and "));
    }

    let mapParts = [];
    params.mapLocation &&
      params.mapLocation.forEach((map) => {
        if (map.chromosome) {
          mapParts.push(
            `chromosome is <span class="highlight-term">${map.chromosome}</span>`
          );
        }
        if (map.high_range && map.low_range) {
          mapParts.push(
            `AGI map location is between <span class="highlight-term">${map.low_range}</span> kb and <span class="highlight-term">${map.high_range}</span> kb`
          );
        }
      });

    if (mapParts.length > 0) {
      queryParts.push(mapParts.join(mapParts.length > 1 ? " and " : ""));
    }

    if (queryParts.length > 0) {
      queryStatement += "where ";
      queryStatement += queryParts.join(" and ");
    }
    queryStatement += ` resulted in <span style="color: green">${params.matchCount}</span> matches`;
    return queryStatement;
  } catch (error) {
    console.error("dnaSearch: setQueryStatement", error);
    return "";
  }
}
