<template>
  <div class="searchWrapper">
    <div class="pageTop">
      <div class="d-none d-md-block">
        <div class="d-flex flex-column">
          <b-breadcrumb :items="crumb_links"></b-breadcrumb>
          <div class="text-left ml-2 d-flex">
            <h4 style="color: #930">Ecotype (Natural Variant) Search</h4>
            <span class="ml-2"
              >[<a :href="helpLink" target="_blank">Help</a>]</span
            >
          </div>
          <div class="text-left m-2">
            This page allows you to identify natural variants of
            <i>Arabidopsis thaliana</i> and closely related species using
            various parameters.
          </div>
        </div>
      </div>
      <div class="d-md-none">
        <div class="row">
          <div class="col-4 font-weight-bold pl-2">Search Result</div>
          <div class="col-8">
            <b-dropdown id="dropdown-1" text="Menu" class="m-md-2">
              <b-dropdown-item>New Search</b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>
    </div>
    <div class="pageMain m-2">
      <div class="d-flex flex-column">
        <div class="row mt-2">
          <div class="col-sm d-flex align-items-start ml-4">
            <b-button size="sm" class="mr-2">Reset</b-button>
            <b-button size="sm" @click="onSubmit">Submit Query</b-button>
          </div>
        </div>
        <div>
          <div class="d-flex flex-column mx-4 my-2">
            <form-section
              v-for="(filter, index) in search_form.filters"
              :key="index"
              :filter="filter"
              @on-enter="onSubmit"
            />
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-sm d-flex align-items-start ml-4 mb-4">
            <b-button size="sm" class="mr-2">Reset</b-button>
            <b-button size="sm" @click="onSubmit">Submit Query</b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormSection from "../../components/common/FormSection.vue";
export default {
  name: "EcotypeSearch",
  components: {
    FormSection,
  },
  data() {
    return {
      crumb_links: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "Search",
          href: "/search/overview",
        },
        {
          text: "Ecotypes (Natural Variants)",
          active: true,
        },
      ],
      search_form: {
        filters: [
          {
            label: "Search by Name",

            type: "rows",
            rows: [
              {
                name: "Search Terms",
                value: {
                  type: "mixed",
                  elements: [
                    {
                      type: "select",
                      options: [
                        {
                          text: "ecotype abbrev. name (eg. Col)",
                          value: "abbrev_name",
                        },
                        {
                          text: "ecotype full name (eg. Columbia)",
                          value: "full_name",
                        },
                        {
                          text: "description (eg. serrated)",
                          value: "description",
                        },
                      ],

                      value: "abbrev_name",
                      id: "search_type_1",
                      size: "medium",
                    },
                    {
                      type: "select",
                      options: [
                        { text: "contains", value: "contains" },
                        { text: "starts with", value: "starts_with" },
                        { text: "exact", value: "exact" },
                      ],
                      value: "starts_with",
                      id: "search_method_1",
                      size: "medium",
                    },
                    {
                      type: "input",
                      value: "",
                      id: "search_input_1",
                      size: "large",
                    },
                  ],
                },
              },
              {
                name: "",
                value: {
                  type: "mixed",
                  elements: [
                    {
                      type: "select",
                      options: [
                        {
                          text: "ecotype abbrev. name (eg. Col)",
                          value: "abbrev_name",
                        },
                        {
                          text: "ecotype full name (eg. Columbia)",
                          value: "full_name",
                        },
                        {
                          text: "description (eg. serrated)",
                          value: "description",
                        },
                      ],

                      value: "full_name",
                      id: "search_type_2",
                      size: "medium",
                    },
                    {
                      type: "select",
                      options: [
                        { text: "contains", value: "contains" },
                        { text: "starts with", value: "starts_with" },
                        { text: "exact", value: "exact" },
                      ],
                      value: "starts_with",
                      id: "search_method_2",
                      size: "medium",
                    },
                    {
                      type: "input",
                      value: "",
                      id: "search_input_2",
                      size: "large",
                    },
                  ],
                },
              },
              {
                name: "",
                value: {
                  type: "mixed",
                  elements: [
                    {
                      type: "select",
                      options: [
                        {
                          text: "ecotype abbrev. name (eg. Col)",
                          value: "abbrev_name",
                        },
                        {
                          text: "ecotype full name (eg. Columbia)",
                          value: "full_name",
                        },
                        {
                          text: "description (eg. serrated)",
                          value: "description",
                        },
                      ],

                      value: "description",
                      id: "search_type_3",
                      size: "medium",
                    },
                    {
                      type: "select",
                      options: [
                        { text: "contains", value: "contains" },
                        { text: "starts with", value: "starts_with" },
                        { text: "exact", value: "exact" },
                      ],
                      value: "starts_with",
                      id: "search_method_3",
                      size: "medium",
                    },
                    {
                      type: "input",
                      value: "",
                      id: "search_input_3",
                      size: "large",
                    },
                  ],
                },
              },
            ],
          },
          {
            label: "Restrict by Collection Details",
            type: "rows",
            rows: [
              {
                name: "Country of Collection",
                value: {
                  type: "mixed",
                  elements: [
                    {
                      type: "select",
                      options: [],
                      value: "",
                      id: "country",
                      size: "medium",
                    },
                  ],
                },
              },
              {
                name: "Location/City/Region",
                value: {
                  type: "mixed",
                  elements: [
                    {
                      type: "select",
                      options: [
                        { text: "contains", value: "contains" },
                        { text: "starts with", value: "starts_with" },
                        { text: "exact", value: "exact" },
                      ],
                      value: "starts_with",
                      id: "location_method",
                      size: "medium",
                    },
                    {
                      type: "input",
                      value: "",
                      id: "location_input",
                      size: "large",
                    },
                  ],
                },
              },
              {
                name: "Habitat (eg. sandy ground)",
                value: {
                  type: "mixed",
                  elements: [
                    {
                      type: "select",
                      options: [
                        { text: "contains", value: "contains" },
                        { text: "starts with", value: "starts_with" },
                        { text: "exact", value: "exact" },
                      ],
                      value: "starts_with",
                      id: "habitat_method",
                      size: "medium",
                    },
                    {
                      type: "input",
                      value: "",
                      id: "habitat_input",
                      size: "large",
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
      countries: [
        { text: "Any", value: "any" },
        { text: "AUT", value: "AUT" },
        { text: "Afghanistan", value: "Afghanistan" },
        { text: "Argentina", value: "Argentina" },
        { text: "Armenia", value: "Armenia" },
        { text: "Austria", value: "Austria" },
        { text: "Azerbaijan", value: "Azerbaijan" },
        { text: "Belgium", value: "Belgium" },
        { text: "Bulgaria", value: "Bulgaria" },
        { text: "CZE", value: "CZE" },
        { text: "Canada", value: "Canada" },
        { text: "Cape Verde", value: "Cape Verde" },
        { text: "Cape Verdi", value: "Cape Verdi" },
        { text: "China", value: "China" },
        { text: "Croatia", value: "Croatia" },
        { text: "Czech Republic", value: "Czech Republic" },
        { text: "Denmark", value: "Denmark" },
        { text: "England", value: "England" },
        { text: "Finland", value: "Finland" },
        { text: "France", value: "France" },
        { text: "Georgia", value: "Georgia" },
        { text: "Germany", value: "Germany" },
        { text: "Greece", value: "Greece" },
        { text: "Hungary", value: "Hungary" },
        { text: "India", value: "India" },
        { text: "Iran", value: "Iran" },
        { text: "Ireland", value: "Ireland" },
        { text: "Italy", value: "Italy" },
        { text: "Japan", value: "Japan" },
        { text: "Kazakhstan", value: "Kazakhstan" },
        { text: "Kyrgyzstan", value: "Kyrgyzstan" },
        { text: "Kyrgyzstan ", value: "Kyrgyzstan " },
        { text: "Lebanon", value: "Lebanon" },
        { text: "Libya", value: "Libya" },
        { text: "Lithuania", value: "Lithuania" },
        { text: "Mongolia", value: "Mongolia" },
        { text: "Morocco", value: "Morocco" },
        { text: "Nepal", value: "Nepal" },
        { text: "Netherlands", value: "Netherlands" },
        { text: "New Zealand", value: "New Zealand" },
        { text: "Norway", value: "Norway" },
        { text: "PORTUGAL", value: "PORTUGAL" },
        { text: "Pakistan", value: "Pakistan" },
        {
          text: "People's Republic of China",
          value: "People's Republic of China",
        },
        { text: "Poland", value: "Poland" },
        { text: "Portugal", value: "Portugal" },
        { text: "Romania", value: "Romania" },
        { text: "Russia", value: "Russia" },
        { text: "SPAIN", value: "SPAIN" },
        { text: "SWE", value: "SWE" },
        { text: "Serbia", value: "Serbia" },
        { text: "Slovakia", value: "Slovakia" },
        { text: "Spain", value: "Spain" },
        { text: "Sweden", value: "Sweden" },
        { text: "Switzerland", value: "Switzerland" },
        { text: "Tadjikistan", value: "Tadjikistan" },
        { text: "Taiwan", value: "Taiwan" },
        { text: "Takikistan", value: "Takikistan" },
        { text: "Tanzania", value: "Tanzania" },
        { text: "Turkey", value: "Turkey" },
        { text: "UK", value: "UK" },
        { text: "UNKNOWN", value: "UNKNOWN" },
        { text: "USA", value: "USA" },
        { text: "Ukraine", value: "Ukraine" },
        { text: "United Kingdom", value: "United Kingdom" },
        {
          text: "United Kingdom of Great Britain and Northern Ireland",
          value: "United Kingdom of Great Britain and Northern Ireland",
        },
      ],
      helpLink:
        "https://conf.phoenixbioinformatics.org/pages/viewpage.action?pageId=155058213",
    };
  },
  watch: {
    "$route.query.key": function (id) {
      this.$forceUpdate();
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.updateOptionsById("country", this.countries);
      document.title = "Ecotype (Natural Variant) Search";
    },
    async onSubmit(event) {
      event.preventDefault();

      let searchParams = {
        searchTerms: [],
        restrictByFeatures: [],
      };

      this.search_form.filters.forEach((filter) => {
        if (filter.type === "rows") {
          filter.rows.forEach((row) => {
            let rowParams = {};
            row.value.elements.forEach((element) => {
              let shouldAddParam = true;
              let value = element.value;

              if (element.type === "checkbox") {
                value = element.value;
              }

              if (!value || value === false || value === "any") {
                shouldAddParam = false;
              }

              //check if value is an array
              if (Array.isArray(value)) {
                if (
                  value.length === 0 ||
                  (value.length === 1 && value[0] === "any")
                ) {
                  shouldAddParam = false;
                }
              }

              if (shouldAddParam) {
                if (element.id) {
                  rowParams[element.id] = value;
                }
              }
            });
            if (Object.keys(rowParams).length > 0) {
              if (filter.label === "Search by Name") {
                const generalizedRowParams =
                  this.generalizeFieldNames(rowParams);
                searchParams.searchTerms.push(generalizedRowParams);
              } else if (filter.label === "Restrict by Collection Details") {
                searchParams.restrictByFeatures.push(rowParams);
              }
            }
          });
        }
      });

      this.showResults(searchParams);
    },

    onReset(event) {
      event.preventDefault();
    },
    generalizeFieldNames(rowParams) {
      const generalizedParams = {};

      Object.keys(rowParams).forEach((key) => {
        if (key.startsWith("search_type_")) {
          generalizedParams["search_type"] = rowParams[key];
        } else if (key.startsWith("search_method_")) {
          generalizedParams["search_method"] = rowParams[key];
        } else if (key.startsWith("search_input_")) {
          generalizedParams["search_input"] = rowParams[key];
        } else {
          // Copy other fields as-is
          generalizedParams[key] = rowParams[key];
        }
      });

      return generalizedParams;
    },
    showResults(inp_params) {
      this.$nextTick(() => {
        this.$router.push({
          path: "/results",
          query: {
            mainType: "detail",
            category: "ecotype",
            search_params: JSON.stringify(inp_params),
          },
        });
      });
    },
    getValueById(searchId) {
      let result = null;

      // Loop over each filter
      for (let filter of this.search_form.filters) {
        // Loop over each row in the current filter
        for (let row of filter.rows) {
          // Check if row has a value and elements
          if (row.value && row.value.elements) {
            // Find the element with the matching id
            let element = row.value.elements.find((el) => el.id === searchId);
            if (element) {
              result = element.value;
              break;
            }
          }
        }

        if (result !== null) {
          break;
        }
      }

      return result;
    },
    updateOptionsById(searchId, options) {
      for (let filter of this.search_form.filters) {
        for (let row of filter.rows) {
          if (row.value && row.value.elements) {
            let element = row.value.elements.find((el) => el.id === searchId);
            if (element) {
              if (element.options) {
                element.options = options;
                element.value = options[0].value;
              }
              break;
            }
          }
        }
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
