<template>
  <div class="checkbox-component">
    <input
      type="checkbox"
      :id="id"
      :checked="isChecked"
      @change="onChange"
      :aria-label="id"
    />
    <label class="ml-2" :for="id" :aria-label="id">{{ label }}</label>
  </div>
</template>

<script>
export default {
  name: "CheckboxComponent",
  props: {
    id: String,
    label: String,
    value: Boolean,
  },
  computed: {
    isChecked: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
  methods: {
    onChange(event) {
      // When the checkbox changes, emit the new value
      this.isChecked = event.target.checked;
    },
  },
};
</script>
