import { render, staticRenderFns } from "./PersonDetail.vue?vue&type=template&id=2f9b2045&scoped=true"
import script from "./PersonDetail.vue?vue&type=script&lang=js"
export * from "./PersonDetail.vue?vue&type=script&lang=js"
import style0 from "./PersonDetail.vue?vue&type=style&index=0&id=2f9b2045&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f9b2045",
  null
  
)

export default component.exports