<template>
  <div>
    <b-breadcrumb :items="crumbLinks"></b-breadcrumb>
    <div class="d-flex">
      <h2>{{ pageTitle }}</h2>
      <b-link
        :href="jobsRssLink"
        target="_blank"
        v-b-tooltip.hover
        title="Subscribe to RSS Feed"
        class="ml-2"
      >
        <i class="fas fa-rss"></i>
      </b-link>
    </div>
    <div class="items-panel">
      <div v-for="(item, i) in jobs" :key="i" class="news-item">
        <div class="news-header">
          <a
            v-if="item.uri.startsWith('http')"
            :href="item.uri"
            target="_blank"
            v-html="`${item.issuedOn}, ${item.headline}`"
          ></a>
          <router-link
            v-else
            :to="`jobs/view?file=${item.uri}`"
            v-html="`${item.issuedOn}, ${item.headline}`"
          ></router-link>
        </div>
        <div v-html="`${item.employerName}, ${item.address}`"></div>
        <div v-if="item.deadline">
          <i>*Application Deadline: {{ item.deadline }}</i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getJobs } from "@/services/NewsService";

export default {
  name: "Jobs",
  data() {
    return {
      pageTitle: "Job Postings",
      jobs: [],
      crumbLinks: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "News",
          href: "/news/overview",
        },
        {
          text: "Job Postings",
          active: true,
        },
      ],
      jobsRssLink: `${process.env.VUE_APP_API_BASE_URL}/news/jobs/rss`,
    };
  },
  async mounted() {
    try {
      const response = await this.fetchJobs();
      this.jobs = response; // Update state with fetched data
      document.title = "Jobs | TAIR";
    } catch (error) {
      console.error("Error fetching news:", error);
    }
  },
  methods: {
    async fetchJobs() {
      const response = await getJobs();
      return response;
    },
  },
};
</script>

<style scoped lang="scss"></style>
