<template>
  <div>
    <div v-if="!error" class="detail-wrapper">
      <div class="side-wrapper">
        <ul class="list-group list-group-flush">
          <a
            v-for="(col, i) in side_cols"
            :key="i"
            href="#"
            class="list-group-item list-group-item-action flex-column align-items-start side-item"
            v-bind:class="{ selected: i == colIdxSelected }"
            @click="sideColClicked(i)"
          >
            {{ col }}
          </a>
        </ul>
      </div>
      <div class="list-wrapper" v-on:scroll.passive="handleScroll">
        <div
          v-if="loading"
          class="d-flex justify-content-center align-items-center"
          style="height: 100%"
        >
          <b-spinner label="Loading..."></b-spinner>
        </div>
        <div
          v-if="json_data"
          class="text-left m-2 font-weight-bold fixed-title"
        >
          <h4>TAIR Person Detail</h4>
        </div>
        <BaseEntry
          v-for="(e, i) in entries"
          :selectedColIdx="colIdxSelected"
          :currIdx="i"
          :key="i"
          :content="e"
          :ref="`entry` + i"
        ></BaseEntry>
      </div>
    </div>
    <div v-else>
      <PageNotFound />
    </div>
  </div>
</template>

<script>
//http://localhost:8082/person?key=4602
import BaseEntry from "@/components/detail/BaseEntry";
import { mapGetters } from "vuex";
import PostsService from "@/services/PostsService";
import PageNotFound from "@/components/common/PageNotFound";

export default {
  name: "PersonDetail",
  components: {
    BaseEntry,
    PageNotFound,
  },
  data() {
    return {
      base_url: process.env.VUE_APP_S3_PATH + "person/",
      json_data: null,
      side_cols: ["Summary"],
      loading: false,
      error: false,
      colIdxSelected: 0,
      entries: [],
      entryPosys: [],
      helpTexts: {},
    };
  },

  async mounted() {
    document.title = "Person Detail";
    let jsonId = this.$route.query.key;
    if (jsonId) {
      let timestamp = new Date().getTime(); //cache-busting parameter
      try {
        let response = await PostsService.getPersonDetail({ key: jsonId });
        this.json_data = response.data;

        this.loading = true;
        this.processEntries();
        this.loading = false;
        document.title = `Person Detail`;
      } catch (error) {
        console.error("Error fetching person details: ", error);
        this.loading = false;
        this.error = true;
      }
    } else {
      this.loading = false;
      this.error = true;
    }
  },
  methods: {
    ...mapGetters({
      getCommunityId: "authProfile/getCommunityId",
      getIsTairCurator: "authProfile/getIsTairCurator",
    }),
    sideColClicked(colIdx) {
      this.scrollToElement(colIdx);
      this.colIdxSelected = colIdx;
    },
    processEntries() {
      this.side_cols.forEach((title) => {
        let entry = {
          title: title,
          name: title.replace(/\s/g, ""),
        };
        switch (title) {
          case "Summary":
            entry.type = "bands";
            entry.bands = this.fillSummary();
            break;
          default:
            entry.type = "bands";
            entry.bands = [];
            break;
        }
        this.entries.push(entry);
      });
    },
    fillSummary() {
      let bands = [];
      bands.push({
        key: "Name",
        text: this.json_data.firstName + " " + this.json_data.lastName,
      });
      if (this.json_data.authorNames) {
        bands.push({
          key: "Author Names",
          text: this.formatStringList(this.json_data.authorNames),
        });
      }
      if (this.json_data.otherNames) {
        bands.push({
          key: "Other Names",
          text: this.formatStringList(this.json_data.otherNames),
        });
      }
      if (this.json_data.tairAccession) {
        bands.push({
          key: "TAIR Accession",
          text: `${
            this.json_data.tairAccession
              ? this.json_data.tairAccession
              : "No data available"
          }`,
        });
      }
      if (this.json_data.organisms) {
        bands.push({
          key: "Organisms",
          text: this.formatStringList(this.json_data.organisms),
        });
      }
      //primaryJobTitle
      if (this.json_data.primaryJobTitle) {
        bands.push({
          key: "Job Title",
          text: this.json_data.primaryJobTitle
            ? this.json_data.primaryJobTitle
            : "No data available",
        });
      }
      if (this.json_data.researchInterest) {
        bands.push({
          key: "Research Interest",
          text: this.json_data.researchInterest,
        });
      }
      if (this.json_data.keywords) {
        bands.push({
          key: "Keywords",
          text: this.formatStringList(this.json_data.keywords),
        });
      }
      if (this.json_data.address) {
        bands.push(this.getAddress());
      }

      if (this.json_data.email) {
        bands.push({
          key: "Email",
          text: this.json_data.email,
          type: "link",
          link: `mailto:${this.json_data.email}`,
        });
      }
      if (this.json_data.websites) {
        bands.push({
          key: "Websites",
          type: "links",
          links: this.json_data.websites
            ? this.json_data.websites.map((u) => {
                return {
                  name: u.url,
                  link: u.url,
                };
              })
            : [],
        });
      }
      if (this.json_data.phones) {
        bands.push({
          key: "Office Phone",
          text: this.json_data.phones
            ? this.json_data.phones.find((p) => p.phoneType == "work")
              ? this.json_data.phones.find((p) => p.phoneType == "work")
                  .phoneNumber
              : "No data available"
            : "No data available",
        });
        bands.push({
          key: "Mobile Phone",
          text: this.json_data.phones
            ? this.json_data.phones.find((p) => p.phoneType == "mobile")
              ? this.json_data.phones.find((p) => p.phoneType == "mobile")
                  .phoneNumber
              : "No data available"
            : "No data available",
        });
        bands.push({
          key: "Fax",
          text: this.json_data.phones
            ? this.json_data.phones.find((p) => p.phoneType == "fax")
              ? this.json_data.phones.find((p) => p.phoneType == "fax")
                  .phoneNumber
              : "No data available"
            : "No data available",
        });
      }

      if (this.json_data.affiliations) {
        bands.push(this.getAffiliations());
      }
      if (this.json_data.publications) {
        bands.push(this.getPublications());
      }

      bands.push({
        key: "Record last updated",
        text: this.dateToYMD(this.json_data.lastUpdated),
      });
      bands.push({
        key: "Community ID",
        text: this.json_data.communityId,
      });
      bands.push({
        key: "Person ID",
        text: this.json_data.personId,
      });
      if (
        this.json_data.communityId == this.getCommunityId() ||
        this.getIsTairCurator()
      ) {
        bands.push({
          key: "Update Record",
          html: `If you find this information to be inaccurate, or wish to add other information, please <a href="/edit/person?key=${this.json_data.communityId}" target="_blank">UPDATE YOUR RECORD HERE</a>`,
          type: "html",
        });
      }
      return bands;
    },

    //Getters
    getAddress() {
      let address = this.json_data.address;
      // replace "\" with "<br>" for new line
      if (address != null) {
        address = address.replace(/\\/g, "<br>");
      } else {
        return {
          key: "Address",
          text: "No data available",
        };
      }
      let addressLine3 =
        this.json_data.city +
        "," +
        (this.json_data.region ? this.json_data.region : "") +
        " " +
        this.json_data.postalCode;
      address = address + "<br>" + addressLine3;
      let country = this.json_data.country;
      if (country) {
        address = address + "<br>" + country;
      }
      return {
        key: "Address",
        type: "html",
        html: `${address}`,
      };
    },
    getAffiliations() {
      let entry = {
        key: "Affiliations",
        type: "table",
        items: [],
        fields: [],
      };
      let affiliations = this.json_data.affiliations;
      if (!affiliations || affiliations.length == 0) {
        return {
          key: "Affiliations",
          text: "No data available",
        };
      }
      entry.fields = [
        {
          key: "org",
          label: "Organization",
          cellType: "name_link",
          helpTxt: "* - denotes Primary Investigator for this organization",
        },
        {
          key: "title",
          label: "Job Title",
          cellType: "name_link",
        },
      ];
      entry.items = affiliations.map((a) => {
        let orgName =
          a.isPi == "T" ? "*" + a.organizationName : a.organizationName;
        return {
          org: {
            name: orgName,
            link: `/organization?key=${a.communityId}`,
          },
          title: {
            name: a.jobTitle,
          },
        };
      });
      return entry;
    },
    getPublications() {
      let entry = {
        key: "Publications",
        type: "table",
        items: [],
        fields: [],
      };
      let publications = this.json_data.publications;
      if (publications == null || publications.length == 0) {
        entry.type = "";
        entry.text = "No publications available";
        return entry;
      }
      entry.fields = [
        {
          key: "title",
          label: "Title",
          cellType: "name_link",
        },
        {
          key: "source",
          label: "Source",
          cellType: "name_link",
        },
        {
          key: "year",
          label: "Year",
          cellType: "name_link",
        },
      ];
      publications.forEach((p) => {
        let item = {
          title: { name: p.title, link: `/publication?key=${p.referenceId}` },
          source: { name: p.sourceName },
          year: { name: p.publicationYear },
        };
        entry.items.push(item);
      });
      entry.count = entry.items.length;
      return entry;
    },
    dateToYMD(dateTs) {
      let date = new Date(dateTs);
      const d = date.getDate();
      const m = date.getMonth() + 1;
      const y = date.getFullYear();
      return `${y}-${m.toString().padStart(2, "0")}-${d
        .toString()
        .padStart(2, "0")}`;
    },
    formatStringList(list) {
      if (list && list.length > 0) {
        return list.join(";");
      } else {
        return "No data available";
      }
    },
    //
    scrollToElement(colIdx) {
      const entryRef = this.$refs["entry" + colIdx];
      const el = entryRef[0].$el;
      if (el) {
        const top = el.offsetTop - 210;
        el.parentElement.scrollTo(0, top);
      }
    },
    handleScroll(e) {
      const scrollTop = e.target.scrollTop + 215;
      let currTopEntry = 0;
      this.entryPosys.forEach((e, i) => {
        if (scrollTop > e) {
          currTopEntry = i;
        }
      });
      this.colIdxSelected = currTopEntry;
    },
  },
};
</script>

<style scoped lang="scss">
.fixed-title {
  position: sticky;
  top: 0;
  background-color: #f1efec;
  z-index: 10;
  padding-left: 10px;
  padding-top: 10px;
}

.my-custom-scrollbar {
  position: relative;
  height: 200px;
  overflow: auto;
}
.table-wrapper-scroll-y {
  display: block;
}
</style>
