<!-- SelectComponent.vue -->
<template>
  <select
    :id="id"
    :class="widthClass"
    :value="value"
    :aria-label="id"
    @change="handleChange($event)"
  >
    <option disabled value="">{{ defaultOptionText }}</option>
    <option v-for="option in options" :key="option.value" :value="option.value">
      {{ option.text }}
    </option>
  </select>
</template>

<script>
export default {
  props: {
    id: String,
    value: [String, Number],
    defaultOptionText: {
      type: String,
      default: "Please select...",
    },
    options: {
      type: Array,
    },
    size: {
      type: String,
      default: "medium", // Default width if none is provided
      validator: function (value) {
        return ["small", "medium", "large"].indexOf(value) !== -1;
      },
    },
  },
  methods: {
    handleChange(event) {
      const newValue = event.target.value;
      this.$emit("input", newValue); // Update the v-model
      this.$emit("selection-changed", { id: this.id, value: newValue }); // Emit the custom event
    },
  },
  computed: {
    widthClass() {
      // Compute the class to bind based on the width prop
      switch (this.size) {
        case "small":
          return "select-small";
        case "medium":
          return "select-medium";
        case "large":
          return "select-large";
        default:
          return ""; // If not specified, no width class is applied
      }
    },
    selectedValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
};
</script>
<style>
.select-small {
  width: 60px;
}

.select-medium {
  width: 200px;
}

.select-large {
  width: 400px;
}

@media (max-width: 768px) {
  .select-medium {
    width: 200px;
  }
  .select-large {
    width: 320px;
  }
}
</style>
