import axios from "axios";
import { formatDate, formatEventDates } from "./ServiceUtils";
import Api from "@/services/Api";

export async function getShortNews() {
  try {
    const response = await await Api().get("news/breakingnews", {
      params: {
        type: "short",
      },
    });
    return Object.entries(response.data).map(([key, item]) => {
      return {
        id: item.id,
        date: formatDate(item.date),
        title: item.title,
        content: item.content,
      };
    });
  } catch (error) {
    return [];
  }
}

export async function getCurrFullNews() {
  try {
    const response = await Api().get("news/breakingnews", {
      params: {
        type: "full",
        period: "curr",
      },
    });
    return Object.entries(response.data).map(([key, item]) => {
      return {
        id: item.id,
        date: formatDate(item.date),
        title: item.title,
        content: item.content,
      };
    });
  } catch (error) {
    return [];
  }
}

export async function getPrevFullNews() {
  try {
    const response = await Api().get("news/breakingnews", {
      params: {
        type: "full",
        period: "prev",
      },
    });
    return Object.entries(response.data).map(([key, item]) => {
      return {
        id: item.id,
        date: formatDate(item.date),
        title: item.title,
        content: item.content,
      };
    });
  } catch (error) {
    return [];
  }
}

// only returns future events
export async function getEvents() {
  try {
    // get list content from API
    const response = await Api().get("news/events");
    return Object.entries(response.data).map(([key, item]) => {
      // List of potential string parts
      const parts = [item.venue, getAddressLine(item.location)];

      // Filter out empty values and join with a comma and space
      const address = parts.filter(Boolean).join(", ");

      return {
        headline: item.headline,
        eventDates: formatEventDates(item.begins, item.ends),
        address: address,
        uri: item.uri,
      };
    });
  } catch (error) {
    return [];
  }
}

export async function getJobs() {
  try {
    // get list content from API
    const response = await Api().get("news/jobs");
    return Object.entries(response.data).map(([key, item]) => {
      let formattedItem = {
        headline: item.headline,
        issuedOn: formatDate(item.issuedOn),
        employerName: item.employerName,
        address: getAddressLine(item.location),
        uri: item.uri,
      };
      if (item.deadline) {
        formattedItem.deadline = formatDate(item.deadline);
      }
      return formattedItem;
    });
  } catch (error) {
    return [];
  }
}

export async function getJobBlob(fileName) {
  try {
    // get list content from API
    const response = await Api().get("news/view-job", {
      params: {
        file: fileName,
      },
      responseType: "blob",
    });

    return response.data;
  } catch (error) {
    return;
  }
}

function getAddressLine(location) {
  let addressList = [];
  if (location) {
    if (location.CityName) addressList.push(location.CityName);
    if (location.RegionName) addressList.push(location.RegionName);
    if (location.CountryName) addressList.push(location.CountryName);
  }
  return addressList.join(", ");
}
