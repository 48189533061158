<template>
  <div class="mb-2">
    <div class="mb-2 d-flex justify-content-start" v-if="data.buttons">
      <a
        v-for="(btn, i) in data.buttons"
        :key="i"
        :href="btn.url"
        class="btn btn-info ml-2"
        target="_blank"
        >{{ btn.name }}</a
      >
    </div>
    <div class="p-2">
      <div v-for="(item, index) in data.items" :key="index">
        <!-- First row of fields -->
        <div class="row mb-2 mr-2">
          <div
            v-for="field in data.fields.filter((f) =>
              shouldDisplayField(f, 'first')
            )"
            :key="field.key"
            class="col"
          >
            <component
              :is="getComponentType(field.cellType)"
              :data="item[field.key]"
              :title="field.label"
              :helpTxt="field.helpTxt"
            />
          </div>
        </div>
        <!-- Second row of fields -->
        <div class="row mb-2 mr-2">
          <div
            v-for="field in data.fields.filter((f) =>
              shouldDisplayField(f, 'second')
            )"
            :key="field.key"
            class="col"
          >
            <component
              :is="getComponentType(field.cellType)"
              :data="item[field.key]"
              :title="field.label"
              :helpTxt="field.helpTxt"
            />
          </div>
        </div>
        <hr />
      </div>
    </div>
  </div>
</template>

<script>
import NameLinkComponent from "@/components/detail/NameLinkComponent.vue";
import BulletListComponent from "@/components/detail/BulletListComponent.vue";
import LinkListComponent from "@/components/detail/LinkListComponent.vue";
import ImageListComponent from "@/components/detail/ImageListComponent.vue";
export default {
  mounted() {},
  props: ["data", "title"],
  components: {
    NameLinkComponent,
    LinkListComponent,
    BulletListComponent,
    ImageListComponent,
  },
  methods: {
    getComponentType(cellType) {
      switch (cellType) {
        case "name_link":
          return NameLinkComponent;
        case "link_list":
          return LinkListComponent;
        case "image_list":
          return ImageListComponent;
        case "bullet_list":
          return BulletListComponent;
        default:
          console.error("Unknown cellType: " + cellType);
          return null;
      }
    },
    shouldDisplayField(field, rowType) {
      if (rowType === "first") {
        return (
          (field.width && field.width !== "100%") ||
          ["name_link", "link_list"].includes(field.cellType)
        );
      } else if (rowType === "second") {
        return field.cellType === "pub_list" || field.width === "100%";
      }
      return false;
    },
  },
};
</script>

<style scoped lang="scss">
.scrollable-container {
  max-height: calc(
    1.5em * 20
  ); /* Assuming each item is approximately 1.5em tall */
  overflow-y: auto;
  border: 1px solid black;
}
</style>
