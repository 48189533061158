<template>
  <div class="searchWrapper">
    <div class="pageTop">
      <div class="d-none d-md-block">
        <div class="d-flex flex-column">
          <b-breadcrumb :items="crumb_links"></b-breadcrumb>
          <div class="text-left ml-2 d-flex">
            <h4 style="color: #930">Germplasm Search</h4>
            <span class="ml-2"
              >[<a :href="helpLink" target="_blank">Help</a>]</span
            >
          </div>
          <div class="text-left m-2">
            If you are looking for specific natural variants, please use our
            Ecotype (Natural Variant) Search which allows you to narrow your
            search using collection details.
          </div>
        </div>
      </div>
      <div class="d-md-none">
        <div class="row">
          <div class="col-4 font-weight-bold pl-2">Search Result</div>
          <div class="col-8">
            <b-dropdown id="dropdown-1" text="Menu" class="m-md-2">
              <b-dropdown-item>New Search</b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>
    </div>
    <div class="pageMain m-2">
      <div class="d-flex flex-column">
        <div class="row mt-2">
          <div class="col-sm d-flex align-items-start ml-4">
            <b-button size="sm" class="mr-2">Reset</b-button>
            <b-button size="sm" @click="onSubmit">Submit Query</b-button>
          </div>
        </div>
        <div>
          <div class="d-flex flex-column mx-4 my-2">
            <form-section
              v-for="(filter, index) in search_form.filters"
              :key="index"
              :filter="filter"
              @on-enter="onSubmit"
            />
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-sm d-flex align-items-start ml-4 mb-4">
            <b-button size="sm" class="mr-2">Reset</b-button>
            <b-button size="sm" @click="onSubmit">Submit Query</b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import FormSection from "../../components/common/FormSection.vue";
export default {
  name: "GermplasmSearch",
  components: {
    FormSection,
  },
  data() {
    return {
      crumb_links: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "Search",
          href: "/search/overview",
        },
        {
          text: "Seed/Germplasm",
          active: true,
        },
      ],
      ecotype_s3_url:
        "https://tair-data.s3.us-west-2.amazonaws.com/prod/germplasm_ecotypes.json",
      ecotype_options: [],
      species_list: [
        "Crucihimalaya lasiocarpa",
        "Arabidopsis suecica",
        "Thellungiella parvula",
        "Myagrum perfoliatum",
        "Crambe hispanica",
        "Capsella bursa-pastoris",
        "Zea mays",
        "Noccaea caerulescens",
        "Lepidium sativum",
        "Diplotaxis tenuifolia",
        "Brassica oleracea",
        "Thlaspi arvense",
        "Barbarea intermedia",
        "Euclidium syriacum",
        "Caulanthus amplexicaulis",
        "Arabidopsis kamchatica",
        // "Arabidopsis thaliana",
        "Arabidopsis korshinskyi",
        "Sisymbrium irio",
        "Iberis amara",
        "Arabis verna",
        "Hirschfeldia incana",
        "Thellungiella salsuginea",
        "Arabidopsis lyrata",
        "Brassica napus",
        "Capsella rubella",
        "Rorippa islandica",
        "Olimarabidopsis pumila",
        "Crucihimalaya himalaica",
        "Brassica rapa",
        "Arabidopsis shokei",
        "Cleome violacea",
        "Diptychocarpus strictus",
        "Descurainia sophioides",
        "Boechera stricta",
        "Draba nemerosa",
        "Arabidopsis kamchatica ssp. kawasakiana",
        "Arabidopsis arenosa",
        "Arabidopsis unknown",
        "Malcolmia maritima",
        "Eruca vesicaria",
        "Arabis alpina",
        "Lobularia maritima",
        "Diplotaxis muralis",
        "Capsella grandiflora",
        "Diplotaxis erucoides",
      ],
      search_form: {
        filters: [
          {
            label: "Search by Type",
            type: "rows",
            rows: [
              {
                name: "Search for:",
                value: {
                  type: "mixed",
                  elements: [
                    {
                      type: "select",
                      options: [
                        { text: "any germplasm type", value: "any" },
                        { text: "individual lines", value: "individual_line" },
                        { text: "individual pools", value: "individual_pool" },
                        { text: "sets of lines", value: "set_of_lines" },
                        { text: "sets of pools", value: "set_of_pools" },
                      ],
                      value: "any",
                      id: "type_search_by",
                      size: "large",
                    },
                  ],
                },
              },
            ],
          },
          {
            label: "Search by Name or Phenotype",
            type: "rows",
            rows: [
              {
                name: "Species:",
                value: {
                  type: "mixed",
                  elements: [
                    {
                      type: "select",
                      options: [],
                      value: "Arabidopsis thaliana",
                      id: "species_search_by",
                      size: "large",
                    },
                  ],
                },
              },
              {
                name: "",
                value: {
                  type: "mixed",
                  elements: [
                    {
                      type: "select",
                      options: [
                        {
                          text: "gene name (e.g. APETALA1, AP1, AT1G69120)",
                          value: "gene_name",
                        },
                        {
                          text: "germplasm name (e.g. CS28, SALK_007042)",
                          value: "name",
                        },
                        {
                          text: "phenotype/description",
                          value: "phenotypes",
                        },
                        // },
                      ],
                      value: "gene_name",
                      id: "search_type_by_1",
                      size: "medium",
                    },
                    {
                      type: "select",
                      options: [
                        { text: "contains", value: "contains" },
                        { text: "starts with", value: "starts_with" },
                        { text: "exactly", value: "exactly" },
                      ],
                      value: "starts_with",
                      id: "search_method_1",
                    },
                    {
                      type: "input",
                      value: "",
                      id: "search_input_1",
                      size: "large",
                    },
                    {
                      type: "text",
                      value: "and",
                    },
                  ],
                },
              },
              {
                name: "",
                value: {
                  type: "mixed",
                  elements: [
                    {
                      type: "select",
                      options: [
                        {
                          value: "gene_name",
                          text: "gene name (e.g. APETALA1, AP1, AT1G69120)",
                        },
                        {
                          value: "name",
                          text: "germplasm name (e.g. CS28, SALK_007042)",
                        },
                        {
                          value: "phenotypes",
                          text: "phenotype/description",
                        },
                      ],
                      value: "phenotypes",
                      id: "search_type_by_2",
                      size: "medium",
                    },
                    {
                      type: "select",
                      options: [
                        { text: "contains", value: "contains" },
                        { text: "starts with", value: "starts_with" },
                        { text: "exactly", value: "exactly" },
                      ],
                      value: "contains",
                      id: "search_method_2",
                    },
                    {
                      type: "input",
                      value: "",
                      id: "search_input_2",
                      size: "large",
                    },
                    {
                      type: "text",
                      value: "and",
                    },
                  ],
                },
              },
              {
                name: "",
                value: {
                  type: "mixed",
                  elements: [
                    {
                      type: "select",
                      options: [
                        {
                          value: "gene_name",
                          text: "gene name (e.g. APETALA1, AP1, AT1G69120)",
                        },
                        {
                          value: "name",
                          text: "germplasm name (e.g. CS28, SALK_007042)",
                        },
                        {
                          value: "germplasm_phenotype",
                          text: "phenotype/description",
                        },
                      ],
                      value: "name",
                      id: "search_type_by_3",
                      size: "medium",
                    },
                    {
                      type: "select",
                      options: [
                        { text: "contains", value: "contains" },
                        { text: "starts with", value: "starts_with" },
                        { text: "exactly", value: "exactly" },
                      ],
                      value: "exactly",
                      id: "search_method_3",
                    },
                    {
                      type: "input",
                      value: "",
                      id: "search_input_3",
                      size: "large",
                    },
                  ],
                },
              },
            ],
          },
          {
            label: "Search by Allele",
            type: "rows",
            rows: [
              {
                name: "mutant allele 1 (eg. stm-1)",
                value: {
                  type: "mixed",
                  elements: [
                    {
                      type: "select",
                      options: [
                        { text: "contains", value: "contains" },
                        { text: "starts with", value: "starts_with" },
                        { text: "exactly", value: "exactly" },
                      ],
                      value: "starts_with",
                      id: "allele_search_method_1",
                    },
                    {
                      type: "input",
                      value: "",
                      id: "allele_search_input_1",
                      size: "large",
                    },
                    {
                      type: "text",
                      value: "is",
                    },
                    {
                      type: "select",
                      options: [
                        { text: "any", value: "any" },
                        { text: "homozygous", value: "homozygous" },
                        { text: "heterozygous", value: "heterozygous" },
                        { text: "hemizygous", value: "hemizygous" },
                      ],
                      value: "homozygous",
                      id: "allele_search_is_1",
                    },
                    {
                      type: "text",
                      value: "and",
                    },
                  ],
                },
              },
              {
                name: "mutant allele 2 (eg. clv3-1)",
                value: {
                  type: "mixed",
                  elements: [
                    {
                      type: "select",
                      options: [
                        { text: "contains", value: "contains" },
                        { text: "starts with", value: "starts_with" },
                        { text: "exactly", value: "exactly" },
                      ],
                      value: "starts_with",
                      id: "allele_search_method_2",
                    },
                    {
                      type: "input",
                      value: "",
                      id: "allele_search_input_2",
                      size: "large",
                    },
                    {
                      type: "text",
                      value: "is",
                    },
                    {
                      type: "select",
                      options: [
                        { text: "any", value: "any" },
                        { text: "homozygous", value: "homozygous" },
                        { text: "heterozygous", value: "heterozygous" },
                        { text: "hemizygous", value: "hemizygous" },
                      ],
                      value: "any",
                      id: "allele_search_is_2",
                    },
                    {
                      type: "text",
                      value: "and",
                    },
                  ],
                },
              },
              {
                name: "mutant allele 3 (eg. hst-7)",
                value: {
                  type: "mixed",
                  elements: [
                    {
                      type: "select",
                      options: [
                        { text: "contains", value: "contains" },
                        { text: "starts with", value: "starts_with" },
                        { text: "exactly", value: "exactly" },
                      ],
                      value: "starts_with",
                      id: "allele_search_method_3",
                    },
                    {
                      type: "input",
                      value: "",
                      id: "allele_search_input_3",
                      size: "large",
                    },
                    {
                      type: "text",
                      value: "is",
                    },
                    {
                      type: "select",
                      options: [
                        { text: "any", value: "any" },
                        { text: "homozygous", value: "homozygous" },
                        { text: "heterozygous", value: "heterozygous" },
                        { text: "hemizygous", value: "hemizygous" },
                      ],
                      value: "any",
                      id: "allele_search_is_3",
                    },
                  ],
                },
              },
            ],
          },
          {
            label: "Restrict by Features",
            type: "rows",
            rows: [
              {
                name: "Germplasm Features",
                value: {
                  type: "mixed",
                  elements: [
                    {
                      type: "checkbox",
                      checked: false,
                      label: "is ABRC stock",
                      id: "isAbrcStock",
                    },
                  ],
                },
              },
              //   {
              //     name: "",
              //     value: {
              //       type: "mixed",
              //       elements: [
              //         {
              //           type: "checkbox",
              //           checked: false,
              //           label: "has observable phenotype",
              //           id: "hasObservablePheno",
              //         },
              //       ],
              //     },
              //   },
              {
                name: "",
                value: {
                  type: "mixed",
                  elements: [
                    {
                      type: "checkbox",
                      checked: false,
                      label: "has polymorphism",
                      id: "hasPoly",
                    },
                  ],
                },
              },
              {
                name: "",
                value: {
                  type: "mixed",
                  elements: [
                    {
                      type: "checkbox",
                      checked: false,
                      label: "is transgenic",
                      id: "isTransgenic",
                    },
                  ],
                },
              },
              // {
              //   name: "",
              //   value: {
              //     type: "mixed",
              //     elements: [
              //       {
              //         type: "checkbox",
              //         checked: false,
              //         label: "is mapping/RI line",
              //         id: "is_mapping_ri_line",
              //       },
              //     ],
              //   },
              // },
              {
                name: "",
                value: {
                  type: "mixed",
                  elements: [
                    {
                      type: "checkbox",
                      checked: false,
                      label: "has images",
                      id: "hasImages",
                    },
                  ],
                },
              },
              //   {
              //     name: "",
              //     value: {
              //       type: "mixed",
              //       elements: [
              //         {
              //           type: "checkbox",
              //           checked: false,
              //           label: "is aneuploid",
              //           id: "isAneuploid",
              //         },
              //       ],
              //     },
              //   },
              // {
              //   name: "",
              //   value: {
              //     type: "mixed",
              //     elements: [
              //       {
              //         type: "checkbox",
              //         checked: false,
              //         label: "is ecotype",
              //         id: "is_ecotype",
              //       },
              //     ],
              //   },
              // },
              {
                name: "Chromosomal Constitution",
                value: {
                  type: "mixed",
                  elements: [
                    {
                      type: "text",
                      value: "ploidy",
                    },
                    {
                      type: "select",
                      options: [
                        { text: "any", value: "any" },
                        { text: "2n", value: "2n" },
                        { text: "not 2n", value: "not_2n" },
                      ],
                      id: "cc_ploidy",
                      value: "any",
                    },
                  ],
                },
              },
              // {
              //   name: "",
              //   value: {
              //     type: "mixed",
              //     elements: [
              //       {
              //         type: "text",
              //         value: "variant chromosome",
              //       },
              //       {
              //         type: "select",
              //         options: [
              //           { text: "any", value: "any" },
              //           { text: "1", value: "1" },
              //           { text: "2", value: "2" },
              //           { text: "3", value: "3" },
              //           { text: "4", value: "4" },
              //           { text: "5", value: "5" },
              //         ],
              //         id: "variant_chromosome",
              //         value: "any",
              //       },
              //     ],
              //   },
              // },
              {
                name: "Background Ecotype (Multiple)",
                value: {
                  elements: [
                    {
                      type: "multiSelect",
                      options: [
                        { text: "Any", value: "any" },
                        { text: "Ler", value: "Ler_217" },
                        { text: "Amel", value: "Amel-1_500100788" },
                      ],
                      selected: ["Any"],
                      value: ["any"],
                      width: "large",
                      id: "background_ecotype",
                    },
                  ],
                },
              },
              {
                name: "Mutagen",
                value: {
                  elements: [
                    {
                      type: "multiSelect",
                      options: [
                        { text: "Any", value: "any" },
                        {
                          text: "transposon insertion",
                          value: "transposon insertion",
                        },
                        { text: "T-DNA insertion", value: "T-DNA insertion" },
                        {
                          text: "Agrobacterium transformation",
                          value: "Agrobacterium transformation",
                        },
                        {
                          text: "5-bromouracil (5-BU)",
                          value: "5-bromouracil",
                        },
                        { text: "diepoxybutane (DEB)", value: "diepoxybutane" },
                        {
                          text: "ethylmethane sulfonate (EMS)",
                          value: "ethylmethane sulfonate",
                        },
                        {
                          text: "ethyl-nitrosourea (ENU)",
                          value: "ethyl-nitrosourea",
                        },
                        { text: "gamma rays", value: "gamma rays" },
                        {
                          text: "ionizing radiation",
                          value: "ionizing radiation",
                        },
                        { text: "fast neutrons", value: "fast neutrons" },
                        {
                          text: "nitroguanidine (NQ)",
                          value: "nitroguanidine",
                        },
                        {
                          text: "nitrosomethyl biuret (NMB)",
                          value: "nitrosomethyl biuret",
                        },
                        {
                          text: "nitrosomethyl urea (NMU)",
                          value: "nitrosomethyl urea",
                        },
                        { text: "spontaneous", value: "spontaneous" },
                        { text: "tissue culture", value: "tissue culture" },
                        { text: "X-rays", value: "X-rays" },
                        { text: "dsRNA silencing", value: "dsRNA silencing" },
                        {
                          text: "zinc finger nuclease",
                          value: "zinc finger nuclease",
                        },
                        { text: "sodium ascorbate", value: "sodium ascorbate" },
                        {
                          text: "carbon ionization",
                          value: "carbon ionization",
                        },
                        { text: "CRISPR", value: "CRISPR" },
                        { text: "HZE Ne", value: "HZE Ne" },
                        { text: "HZE Kr", value: "HZE Kr" },
                        { text: "HZE C", value: "HZE C" },
                        { text: "HZE U", value: "HZE U" },
                        { text: "unknown", value: "unknown" },
                      ],
                      selected: ["Any"],
                      value: ["any"],
                      width: "large",
                      id: "mutagen",
                    },
                  ],
                },
              },
              {
                name: "Insertion Type (Multiple)",
                value: {
                  elements: [
                    {
                      type: "multiSelect",
                      options: [
                        { text: "Any", value: "any" },
                        { text: "transposant", value: "transposant" },
                        { text: "T-DNA", value: "T-DNA" },
                        { text: "short", value: "short" },
                      ],
                      selected: ["Any"],
                      value: ["any"],
                      width: "large",
                      id: "insertionTypes",
                    },
                  ],
                },
              },
              {
                name: "Transgene Construct Type (Multiple)",
                value: {
                  elements: [
                    {
                      type: "multiSelect",
                      options: [
                        { text: "Any", value: "any" },
                        { text: "antisense", value: "antisense" },
                        { text: "activation tag", value: "activation_tag" },
                        {
                          text: "cre-lox recombination",
                          value: "cre-lox_recombination",
                        },
                        { text: "enhancer trap", value: "enhancer_trap" },
                        { text: "gene trap", value: "gene_trap" },
                        { text: "over-expression", value: "over-expression" },
                        { text: "protein fusion", value: "protein_fusion" },
                        {
                          text: "promoter reporter",
                          value: "promoter_reporter",
                        },
                        { text: "promoter trap", value: "promoter_trap" },
                        { text: "RNAi", value: "RNAi" },
                        { text: "simple insert", value: "simple_insert" },
                      ],
                      selected: ["Any"],
                      value: ["any"],
                      width: "large",
                      id: "constructTypes",
                    },
                  ],
                },
              },
            ],
          },
          {
            label: "Restrict by Map Location",
            type: "rows",
            rows: [
              {
                name: "Chromosome",
                value: {
                  elements: [
                    {
                      type: "select",
                      options: [
                        { text: "any", value: "any" },
                        { text: "1", value: "1" },
                        { text: "2", value: "2" },
                        { text: "3", value: "3" },
                        { text: "4", value: "4" },
                        { text: "5", value: "5" },
                        { text: "chloroplast genome", value: "C" },
                        { text: "mitochondrial genome", value: "M" },
                      ],
                      id: "chromosome",
                      size: "large",
                      value: "any",
                    },
                  ],
                },
              },
              // {
              //   text: "Map Type: AGI",
              // },
              {
                name: "Range",
                value: {
                  type: "mixed",
                  elements: [
                    // {
                    //   type: "select",
                    //   options: [
                    //     { text: "between", value: "between" },
                    //     { text: "around", value: "around" },
                    //   ],
                    //   id: "range_type",
                    //   size: "small",
                    //   value: "between",
                    // },
                    {
                      type: "input",
                      value: "",
                      name: "low_range",
                      id: "low_range",
                    },
                    {
                      type: "text",
                      value: "kb",
                    },
                    {
                      type: "text",
                      value: "and",
                    },
                    {
                      type: "input",
                      value: "",
                      id: "high_range",
                    },
                    {
                      type: "text",
                      value: "kb",
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
      helpLink:
        "https://conf.phoenixbioinformatics.org/display/COM/Germplasm+Search+and+Results",
    };
  },
  watch: {
    "$route.query.key": function (id) {
      this.$forceUpdate();
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      document.title = "Germplasm Search";
      this.getEcotypeOptions();
      this.species_list = this.species_list.sort();
      let species_options = this.species_list.map((value) => {
        return { text: value, value: value };
      });
      species_options.unshift({ text: "any", value: "any" });
      species_options.unshift({
        text: "Arabidopsis thaliana",
        value: "Arabidopsis thaliana",
      });
      this.updateOptionsById(
        "species_search_by",
        species_options,
        "Arabidopsis thaliana"
      );
    },
    async getEcotypeOptions() {
      let response = await axios.get(this.ecotype_s3_url);
      let ecotypes = response.data;
      const sortedValues = ecotypes.sort((a, b) =>
        a.toLowerCase().localeCompare(b.toLowerCase())
      );
      let optionsList = sortedValues.map((value) => {
        return { text: value, value: value };
      });
      optionsList.unshift({ text: "any", value: "any" });
      this.updateOptionsById("background_ecotype", optionsList);
    },
    async onSubmit(event) {
      event.preventDefault();

      let searchParams = {
        searchType: [],
        searchTerms: [],
        searchAlleles: [],
        restrictByFeatures: [],
        mapLocation: [],
      };

      this.search_form.filters.forEach((filter) => {
        if (filter.type === "rows") {
          filter.rows.forEach((row) => {
            let rowParams = {};
            row.value.elements.forEach((element) => {
              let shouldAddParam = true;
              let value = element.value;

              if (element.type === "checkbox") {
                value = element.value;
              }

              if (!value || value === false || value === "any") {
                shouldAddParam = false;
              }

              //check if value is an array
              if (Array.isArray(value)) {
                if (
                  value.length === 0 ||
                  (value.length === 1 && value[0] === "any")
                ) {
                  shouldAddParam = false;
                }
              }

              if (shouldAddParam) {
                if (element.id) {
                  rowParams[element.id] = value;
                }
              }
            });
            if (Object.keys(rowParams).length > 0) {
              if (filter.label === "Search by Type") {
                searchParams.searchType.push(rowParams);
              } else if (filter.label === "Search by Name or Phenotype") {
                const generalizedRowParams =
                  this.generalizeFieldNames(rowParams);
                searchParams.searchTerms.push(generalizedRowParams);
              } else if (filter.label === "Search by Allele") {
                const generalizedRowParams =
                  this.generalizeFieldNames(rowParams);
                searchParams.searchAlleles.push(generalizedRowParams);
              } else if (filter.label === "Restrict by Features") {
                searchParams.restrictByFeatures.push(rowParams);
              } else if (filter.label === "Restrict by Map Location") {
                searchParams.mapLocation.push(rowParams);
              }
            }
          });
        }
      });

      this.showResults(searchParams);
    },
    onReset(event) {
      event.preventDefault();
    },
    showResults(inp_params) {
      this.$nextTick(() => {
        this.$router.push({
          path: "/results",
          query: {
            mainType: "detail",
            category: "germplasm",
            search_params: JSON.stringify(inp_params),
          },
        });
      });
    },
    getValueById(searchId) {
      let result = null;

      // Loop over each filter
      for (let filter of this.search_form.filters) {
        // Loop over each row in the current filter
        for (let row of filter.rows) {
          // Check if row has a value and elements
          if (row.value && row.value.elements) {
            // Find the element with the matching id
            let element = row.value.elements.find((el) => el.id === searchId);
            if (element) {
              result = element.value;
              break;
            }
          }
        }

        if (result !== null) {
          break;
        }
      }

      return result;
    },
    updateOptionsById(searchId, options, value = null) {
      for (let filter of this.search_form.filters) {
        for (let row of filter.rows) {
          if (row.value && row.value.elements) {
            let element = row.value.elements.find((el) => el.id === searchId);
            if (element) {
              if (element.options) {
                element.options = options;
                if (value != null) {
                  element.value = value;
                  element.selected = value;
                }
              }
              break;
            }
          }
        }
      }
    },
    generalizeFieldNames(rowParams) {
      const generalizedParams = {};

      Object.keys(rowParams).forEach((key) => {
        if (key.startsWith("search_type_by_")) {
          generalizedParams["search_type"] = rowParams[key];
        } else if (key.startsWith("search_method_")) {
          generalizedParams["search_method"] = rowParams[key];
        } else if (key.startsWith("search_input_")) {
          generalizedParams["search_input"] = rowParams[key];
        } else if (key.startsWith("allele_search_is_")) {
          generalizedParams["search_type"] = rowParams[key];
        } else if (key.startsWith("allele_search_method_")) {
          generalizedParams["search_method"] = rowParams[key];
        } else if (key.startsWith("allele_search_input_")) {
          generalizedParams["search_input"] = rowParams[key];
        } else {
          // Copy other fields as-is
          generalizedParams[key] = rowParams[key];
        }
      });

      return generalizedParams;
    },
  },
};
</script>

<style scoped lang="scss"></style>
