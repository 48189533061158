<template>
  <div>
    <b-breadcrumb :items="crumbLinks"></b-breadcrumb>
    <h2>{{ pageTitle }}</h2>
    <div class="items-panel">
      <div v-for="(item, i) in news" :key="i" class="news-item" :id="item.id">
        <div
          v-html="item.title + ' [' + item.date + ']'"
          class="news-header"
        ></div>
        <div v-html="item.content"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { getPrevFullNews } from "@/services/NewsService";

export default {
  name: "OldNews",
  data() {
    return {
      pageTitle: "What was News",
      news: [],
      crumbLinks: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "News",
          href: "/news/overview",
        },
        {
          text: "What was News",
          active: true,
        },
      ],
    };
  },
  async mounted() {
    try {
      const response = await this.fetchNews();
      this.news = response; // Update state with fetched data
    } catch (error) {
      console.error("Error fetching news:", error);
    }
  },
  methods: {
    async fetchNews() {
      return await getPrevFullNews();
    },
  },
};
</script>

<style scoped lang="scss"></style>
