<!-- ElementBuilder.vue -->
<template>
  <div>
    <component
      :is="determineComponent(element.type)"
      v-model="element.value"
      v-bind="elementProps"
      @selection-changed="$emit('element-changed', $event)"
      @on-enter="$emit('on-enter', $event)"
    ></component>
  </div>
</template>

<script>
import InputComponent from "./InputComponent.vue";
import MultiInputComponent from "./MultiInputComponent.vue";
import TextComponent from "./TextComponent.vue";
import SelectComponent from "./SelectComponent.vue";
import MultiSelectComponent from "./MultiSelectComponent.vue";
import CheckboxComponent from "./CheckboxComponent.vue";
import RadioComponent from "./RadioComponent.vue";

export default {
  components: {
    InputComponent,
    MultiInputComponent,
    TextComponent,
    SelectComponent,
    MultiSelectComponent,
    CheckboxComponent,
    RadioComponent,
    // other components...
  },
  props: {
    element: Object,
  },
  computed: {
    elementProps() {
      // Exclude 'type' and 'value' from the props as they are handled separately
      const { type, value, ...otherProps } = this.element;
      return otherProps;
    },
  },
  methods: {
    determineComponent(type) {
      const componentMap = {
        input: InputComponent,
        multiInput: MultiInputComponent,
        text: TextComponent,
        select: SelectComponent,
        multiSelect: MultiSelectComponent,
        checkbox: CheckboxComponent,
        radio: RadioComponent,
      };
      return componentMap[type] || null;
    },
  },
};
</script>
