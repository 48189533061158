<template>
  <div class="searchWrapper d-flex flex-column container">
    <div class="pageTop">
      <div class="d-none d-md-block">
        <div class="font-weight-bold text-left title ml-2">
          Browse Microarray Experiments
        </div>
      </div>
      <div>
        <div class="pageDesc mt-2">
          <span class="text-left px-4 statement" v-html="queryStatement"></span>
        </div>
      </div>
    </div>
    <div class="pageContent">
      <div class="d-flex align-content-left m-2">
        Displaying {{ localCurrentPage * perPage - perPage + 1 }} -
        {{
          items.length < localCurrentPage * perPage
            ? items.length
            : localCurrentPage * perPage
        }}
        of {{ items.length }} results
      </div>
      <div class="tableWrapper">
        <ResultsTable
          :items="items"
          :fields="fields"
          :loading="loading"
          :error="error"
          :perPageProp="perPage"
          :showSelect="false"
          @update:currentPage="localCurrentPage = $event"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import PostsService from "@/services/PostsService";
import { BPagination } from "bootstrap-vue";
import TopMenu from "@/components/search/TopMenu.vue";
import ResultsTable from "@/components/search/ResultsTable.vue";

export default {
  name: "MicroArrayExperiments",
  data() {
    return {
      selected: [],
      items: [],
      fields: [
        { key: "index", label: "No." },
        { key: "name", label: "Experiment Name" },
      ],
      loading: false,
      error: false,
      queryStatement: "",
      localCurrentPage: 1,
      perPage: 200,
      pages: 0,
    };
  },
  mounted() {
    document.title = "Microarray Experiments";
    this.fetchFileNames();
  },
  components: {
    BPagination,
    ResultsTable,
  },
  methods: {
    async fetchFileNames() {
      this.loading = true;
      this.error = false;
      try {
        const response = await axios.get(
          "https://tair-data.s3.us-west-2.amazonaws.com/prod/microarrayexperiments/experiment_names.json"
        );
        let items = response.data.map((name, index) => ({
          index: index + 1, // Add 1 to start the numbering from 1 instead of 0
          name,
          url: `/browse/microarray_experiments/${encodeURIComponent(
            name
          )}`,
        }));
        // Sort items alphabetically by name (keeping the numbering sequential to the sorted list)
        items.sort((a, b) => a.name.localeCompare(b.name));
        // Reassign the index based on the new sort order
        items = items.map((item, index) => ({
          ...item,
          index: index + 1, // Update the index after sorting
        }));
        this.items = items;
        this.loading = false;
      } catch (error) {
        console.error("Error fetching file names: ", error);
        this.error = true;
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.title {
  font-size: 24px;
}
.statement {
  font-size: 20px;
}
.container {
  width: 100vw; /* 100% of the viewport width */
  padding: 20px; /* Optional: adjust as needed */
  box-sizing: border-box; /* Includes padding in height and width */
}
</style>
