<template>
  <div class="gene-class-symbol-registration-container">
    <div class="gene-class-symbol-registration">
      <h1>Gene Class Symbol Registration</h1>
      <p>
        Please enter your proposed symbol here. Symbols should be 3-4 letters,
        ideally, and should not include numbers or the prefix "At".
        <a
          href="https://conf.phoenixbioinformatics.org/display/COM/Arabidopsis+Nomenclature"
          aria-label="Information on Arabidopsis Nomenclature"
          >Click here for information on Arabidopsis Nomenclature</a
        >
        and
        <a
          href="https://conf.phoenixbioinformatics.org/display/COM/Data+Submission#DataSubmission-GeneClassSymbolRegistration"
          aria-label="What Constitutes a Gene Class Symbol"
          >What Constitutes a Gene Class Symbol</a
        >.
      </p>

      <form
        @submit.prevent="submitForm"
        class="form-container"
        aria-label="Gene Class Symbol Registration Form"
      >
        <div class="input-group">
          <label for="symbolType" class="form-label"
            >Click on the Appropriate Symbol Type</label
          >
          <select id="symbolType" v-model="symbolType" required>
            <option value="" disabled selected>Select your option</option>
            <option value="Mutant Phenotype Symbol">
              Mutant Phenotype Symbol
            </option>
            <option value="Gene Product Symbol">Gene Product Symbol</option>
          </select>
        </div>

        <div class="input-group">
          <label for="proposedSymbol" class="form-label"
            >Proposed Gene Class Symbol</label
          >
          <input
            id="proposedSymbol"
            v-model="proposedSymbolComputed"
            placeholder="Enter symbol here"
            aria-describedby="symbolHelp"
          />
          <div v-if="error2" class="error-box">
            {{ error2 }}
          </div>
          <small id="symbolHelp"
            >Acceptable examples: ABA, CH, XYZ. Unacceptable examples: CH6,
            AtXYZ. All characters will be converted into uppercase.</small
          >
        </div>

        <div class="input-group">
          <p>
            We will use the email address from your profile to send further
            information.
          </p>
          <a :href="editProfileUrl" aria-label="Modify your profile email"
            >If you prefer another email address, please click here to modify
            your profile.</a
          >
        </div>

        <div class="form-buttons">
          <button type="button" class="reset-btn" @click="resetForm">
            Reset
          </button>
          <button type="submit" class="submit-btn" :disabled="!isFormValid">
            Submit
          </button>
        </div>
      </form>
      <div v-if="error" class="error-box">
        {{ error }}
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/services/Api";
import { mapGetters } from "vuex";

export default {
  name: "GeneClassSymbolForm",
  data() {
    return {
      symbolType: "",
      proposedSymbol: "",
      error: "",
      error2: "",
      proposedSymbolTouched: false,
    };
  },
  computed: {
    ...mapGetters("authProfile", {
      isLoggedIn: "isLoggedIn",
      getLoginUrl: "getLoginUrl",
      communityId: "getCommunityId", // Ensure this getter exists in your store
      getFirstName: "getFirstName",
      getLastName: "getLastName",
    }),
    proposedSymbolComputed: {
      get() {
        return this.proposedSymbol;
      },
      set(value) {
        this.proposedSymbol = value.toUpperCase();
        this.proposedSymbolTouched = true; // Set to true when user modifies the symbol
      },
    },
    editProfileUrl() {
      return `/edit/person?key=${this.communityId}`;
    },
    isFormValid() {
      const symbolRegex = /^(?!AT)[A-Z]{2,5}$/;
      // Only check and set errors if the user has interacted with the proposedSymbol input
      if (this.proposedSymbolTouched) {
        this.error2 = "";
        if (symbolRegex.test(this.proposedSymbol) && this.symbolType !== "") {
          return true;
        } else {
          if (
            !symbolRegex.test(this.proposedSymbol) &&
            this.proposedSymbol.length > 0
          ) {
            this.error2 =
              'Invalid symbol: Must be 2-5 uppercase letters and cannot start with "AT".';
          } else if (this.symbolType === "") {
            this.error2 = "Symbol type cannot be empty.";
            setTimeout(() => {
              this.error2 = "";
              this.proposedSymbolTouched = false;
            }, 5000);
          }
          return false;
        }
      }
      // If proposedSymbolTouched is false, do not set any error message yet
      return false;
    },
    formattedSymbol() {
      return this.proposedSymbol.toUpperCase();
    },
  },
  methods: {
    async submitForm() {
      try {
        const response = await Api().get("gene_class_symbol/search", {
          params: { geneClassSymbol: this.formattedSymbol },
        });

        if (response.status === 200) {
          if (response.data.rows.length === 0) {
            this.$router.push({
              name: "GeneClassSymbolDetailRegistrationForm",
              query: {
                geneClassSymbol: this.formattedSymbol,
                symbolType: this.symbolType, // Adding symbolType to the query
              },
            });
          } else {
            this.error =
              "This gene class symbol already exists in the database. If you wish to submit this symbol despite the conflict, contact a TAIR curator.";
            setTimeout(() => (this.error = ""), 5000);
          }
        }
      } catch (error) {
        console.error("Error fetching Gene Class Symbols suggestions:", error);
        this.error = "An error occurred while fetching Gene Class Symbols.";
      }
    },
    resetForm() {
      this.symbolType = "";
      this.proposedSymbol = "";
      this.error = "";
    },
  },
};
</script>

<style scoped>
.gene-class-symbol-registration-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
}

.gene-class-symbol-registration {
  border: 1px solid #ccc;
  padding: 20px;
  margin: 0 auto;
  width: 100%;
  max-width: 800px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background: #fff;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.input-group {
  margin-bottom: 20px;
}

/* Adjust the .input-group for better spacing and alignment */
.input-group label,
.input-group p,
.input-group small {
  margin: 10px 0; /* Increase space between form elements */
}

.submit-btn,
.reset-btn {
  cursor: pointer;
  padding: 10px 15px;
  margin-right: 10px; /* Space between buttons */
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.submit-btn {
  background-color: #007bff;
  color: white;
}

.submit-btn:hover {
  background-color: #0056b3;
}

.reset-btn {
  background-color: #6c757d;
  color: white;
}

.reset-btn:hover {
  background-color: #545b62;
}

.form-buttons {
  display: flex;
  justify-content: space-between; /* Pushes items to the edges */
  align-items: left; /* Vertically aligns items if needed */
  padding-left: 10px;
}

.form-label::after {
  content: ":"; /* Adds a colon */
  margin-right: 10px; /* Adjust the space as needed */
}

.form-label {
  display: inline-block;
  margin-right: 5px; /* Adjust the value as needed for your design */
}

/* Optional: Additional styling for buttons */
.reset-btn,
.submit-btn {
  padding: 10px 20px; /* Adjust padding as needed */
  cursor: pointer; /* Changes cursor to pointer on hover */
  /* Add more styles here as required */
}

.submit-btn:disabled {
  background-color: #cccccc; /* A light grey color to indicate it is disabled */
  color: #666666; /* A darker grey to keep the text readable but clearly show it's disabled */
  cursor: not-allowed; /* The cursor indicates that the button is not clickable */
  border: 1px solid #cccccc; /* Optional: if you want a border to match the disabled state */
}

.submit-btn:not(:disabled):hover {
  background-color: #0056b3;
}

.error-box {
  color: #ff0000; /* Red text for error */
  background-color: #ffecec; /* Light red background */
  border: 1px solid #d6b5b5; /* Light red border */
  margin-top: 10px;
  padding: 10px;
  border-radius: 5px;
}

select {
  padding: 5px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: white;
}

@media (max-width: 768px) {
  .gene-class-symbol-registration {
    width: 90%; /* Adjust width for smaller screens */
  }
}
</style>
