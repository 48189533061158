import PostsService from "../PostsService";
import { filterResults } from "./filterUtils";

let evidenceCodeOptsMap = {
  "inferred from experiment": "EXP",
  "inferred from electronic annotation": "IEA",
  "inferred from expression pattern": "IEP",
  "inferred from genetic interaction": "IGI",
  "inferred from mutant phenotype": "IMP",
  "inferred from physical interaction": "IPI",
  "inferred from sequence or structural similarity": "ISS",
  "non-traceable author statement": "NAS",
  "no biological data available": "ND",
  "traceable author statement": "TAS",
  "inferred by curator": "IC",
  "inferred from reviewed computational analysis": "RCA",
  "Inferred from Biological aspect of Ancestor": "IBA",
  "Inferred from Biological aspect of Descendant": "IBD",
  "Inferred from Key Residues": "IKR",
  "Inferred from Rapid Divergence": "IRD",
  "inferred from sequence model": "ISM",
  "inferred from high throughput direct assay": "HDA",
  "inferred from high throughput expression pattern": "HEP",
  "inferred from high throughput genetic interaction": "HGI",
  "inferred from high throughput mutant phenotype": "HMP",
};

// TAIR3-538
// Helper function to check for exact match in other_names for GENE SEARCH
function hasExactMatch(doc, searchText) {
  if (!doc.other_names || !Array.isArray(doc.other_names)) return false;
  const normalizedSearch = searchText.toLowerCase().trim();
  return doc.other_names.some(name => 
    name.toLowerCase().trim() === normalizedSearch
  );
}

// Helper function to check for partial match in other_names for GENE SEARCH
function hasPartialMatch(doc, searchText) {
  if (!doc.other_names || !Array.isArray(doc.other_names)) return false;
  const normalizedSearch = searchText.toLowerCase().trim();
  return doc.other_names.some(name => 
    name.toLowerCase().trim().includes(normalizedSearch) ||
    normalizedSearch.includes(name.toLowerCase().trim())
  );
}

// Helper to get the matching other_name (for sorting by match position if needed) for GENE SEARCH
function getMatchingName(doc, searchText) {
  if (!doc.other_names || !Array.isArray(doc.other_names)) return null;
  const normalizedSearch = searchText.toLowerCase().trim();
  return doc.other_names.find(name => 
    name.toLowerCase().trim().includes(normalizedSearch) ||
    normalizedSearch.includes(name.toLowerCase().trim())
  );
}

// Sort function for documents based on match type for GENE SEARCH
function sortByMatchPriority(docs, searchText) {
  if (!searchText) return docs;
  
  return docs.sort((a, b) => {
    const aExactMatch = hasExactMatch(a, searchText);
    const bExactMatch = hasExactMatch(b, searchText);
    const aPartialMatch = hasPartialMatch(a, searchText);
    const bPartialMatch = hasPartialMatch(b, searchText);
    
    // First priority: Exact matches
    if (aExactMatch && !bExactMatch) return -1;
    if (!aExactMatch && bExactMatch) return 1;
    
    // Second priority: Partial matches
    if (aPartialMatch && !bPartialMatch) return -1;
    if (!aPartialMatch && bPartialMatch) return 1;
    
    // If both have partial matches, sort by match position
    if (aPartialMatch && bPartialMatch) {
      const aMatch = getMatchingName(a, searchText);
      const bMatch = getMatchingName(b, searchText);
      if (aMatch && bMatch) {
        // Sort by which match occurs earlier in the name
        const aIndex = aMatch.toLowerCase().indexOf(searchText.toLowerCase());
        const bIndex = bMatch.toLowerCase().indexOf(searchText.toLowerCase());
        if (aIndex !== bIndex) return aIndex - bIndex;
      }
    }
    
    // Keep original order for items with same match type
    return 0;
  });
}

export async function loadResults(search_params, type = "detail") {
  let fields = [
    { key: "row_num", label: " # ", thStyle: { width: "25px" } },
    { key: "selected", label: "", thStyle: { width: "50px" } },
    { key: "locus", label: "Locus", cellType: "name_link" },
    { key: "desc", label: "Description", cellType: "html" },
  ];
  try {
    let response = await PostsService.fetchGeneDetailSearch(search_params);
    if (response.data.error) {
      return { error: true, items: [], fields: [] };
    }
    let items = [];
    let docs = response.data.docs;

    let totalMatch = response.data.total;
    docs = filterResults(docs, search_params);

    // Sort documents by match priority before processing
    docs = sortByMatchPriority(docs, search_params.searchText);

    for (const d of docs) {
      let otherNames = d.other_names ? d.other_names.join(";") : "";
      let description = d.description ? d.description[0] : "";
      let htmlText = `<p>Other Names: ${otherNames}</p><p>${description}</p>`;
      items.push({
        selected: false,
        tair_accession: d.tairAccession,
        id: d.id,
        desc: {
          text: htmlText,
        },
        locus: { name: d.gene_name[0], link: `/locus?key=${d.id}` },
        originalDoc: d,
      });
    }
    let totalMatches = items.length;
    if (totalMatches == 10000 && totalMatch > totalMatches) {
      totalMatches = totalMatch;
    }
    search_params.matchCount = totalMatches;
    let queryStatement = "";
    if (type === "general") {
      queryStatement = `Your query for genes where gene name, description, phenotype, locus name, uniprot id or GenBank accession contains the term <span class="highlight-term">${search_params.searchText}</span> resulted in <span style="color: green">${totalMatches}</span> matches`;
    } else {
      queryStatement = setQueryStatement(search_params);
    }
    let filteredStatement = "";
    let geneListData = null;
    if (search_params.geneList != null && search_params.geneList !== "") {
      let geneList = search_params.geneList.split(";");
      filteredStatement = `From the uploaded <span style="color: green">${
        geneList.length
      }</span> gene list, <span class="highlight-term">${
        geneList.length - totalMatches
      }</span> were filtered out`;
      geneListData = search_params.geneList;
    }
    return {
      error: false,
      items: items,
      fields: fields,
      queryStatement: queryStatement,
      filteredStatement: filteredStatement,
      geneListData: geneListData,
      totalMatches: totalMatches,
    };
  } catch (error) {
    //   this.loading = false;
    return { error: true, items: [], fields: [] };
  }
}
function setQueryStatement(params) {
  let queryStatement = `Your query for genes where`;
  if (params.taxonName != null && params.taxonName !== "") {
    queryStatement += ` taxon name is ${params.taxonName},`;
  }
  if (params.detailSearchText != null && params.detailSearchText !== "") {
    queryStatement += ` <span class="highlight-term">${params.searchBy}</span> <span class="highlight-term">${params.searchType}</span> the term <span class="highlight-text">${params.detailSearchText}</span>`;
  }
  if (params.keywordTerm != null && params.keywordTerm !== "") {
    queryStatement += `, keyword contains the term <span class="highlight-term">${params.keywordTerm}</span>`;
  }
  if (params.keywordExact != null && params.keywordExact !== "") {
    queryStatement += `, keyword contains the term <span class="highlight-term">${params.keywordExact}</span>`;
  }
  if (params.keywordCombo != null && params.keywordCombo !== "") {
    queryStatement += `, keyword and it's children contains the term <span class="highlight-term">${params.keywordCombo}</span>`;
  }
  if (params.keywordChild != null && params.keywordChild !== "") {
    queryStatement += `, are children of keyword <span class="highlight-term">${params.keywordChild}</span>`;
  }
  if (params.keywordType != null) {
    queryStatement += `, keyword type of <span class="highlight-term">${params.keywordType}</span>`;
  }
  if (params.geneModelType != null) {
    queryStatement += `, gene model type is <span class="highlight-term">${params.geneModelType}</span>`;
  }
  if (params.advanced != null && params.advanced.length > 0) {
    params.advanced.forEach((a) => {
      queryStatement += `, <span class="highlight-term">${a}</span>`;
    });
  }
  if (params.evidence != null) {
    queryStatement += `, evidence are `;
    params.evidence.forEach((e) => {
      queryStatement += `<span class="highlight-term">${getEvidenceCode(
        e
      )}</span>, `;
    });
  }
  if (params.mapType != null) {
    if (params.mapType == "AGI") {
      params.mapType = "reference assembly (Araport11 release)";
    }
    queryStatement += `, map type is <span class="highlight-term">${params.mapType}</span>`;
  }
  if (params.chromosome != null) {
    queryStatement += `, chromosome is <span class="highlight-term">${params.chromosome}</span>`;
  }
  if (params.startPos && params.startPos[0] != null) {
    queryStatement += `, range: ${params.startPos[0]} ${params.gaUnit} to ${params.endPos[0]} ${params.gaUnit}`;
  }
  queryStatement += ` resulted in <span style="color: green">${params.matchCount}</span> loci matches`;
  return queryStatement;
}
function getEvidenceCode(ec_text) {
  if (ec_text == "Any") {
    return "Any";
  }
  return evidenceCodeOptsMap[ec_text] || "Unknown";
}

export async function loadDescriptions(search_params) {
  let fields = [
    { key: "row_num", label: " # " },
    { key: "selected", label: "" },
    { key: "locusName", label: "Locus Identifier" },
    { key: "geneName", label: "Representative Gene Model Name" },
    { key: "description", label: "Gene Description" },
    { key: "geneModelType", label: "Gene Model Type" },
    { key: "primarySymbol", label: "Primary Gene Symbol" },
    { key: "affiliateSymbol", label: "All Gene Symbols" },
  ];
  try {
    let response = await PostsService.fetchGeneDescriptionSearch(search_params);
    if (response.data.error) {
      return { error: true, items: [], fields: [] };
    }
    let items = [];
    let docs = response.data.docs;

    let totalMatch = response.data.total;
    for (const d of docs) {
      items.push({
        selected: false,
        locusName: d.locusName,
        geneName: d.geneName,
        description: d.geneModelType,
        geneModelType: d.description,
        primarySymbol: d.primarySymbol,
        affiliateSymbol: d.affiliateSymbol,
        orig: d,
      });
    }
    search_params.matchCount = totalMatch;
    // let queryStatement = setQueryStatement(search_params);
    return {
      error: false,
      items: items,
      fields: fields,
      queryStatement: "",
      searchResultsHeader: "Gene Descriptions",
    };
  } catch (error) {
    //   this.loading = false;
    return { error: true, items: [], fields: [] };
  }
}

export async function loadMicroarray(search_params) {
  let fields = [
    { key: "row_num", label: " # " },
    { key: "selected", label: "" },
    { key: "probeName", label: "Array Element", cellType: "name_link" },
    { key: "locus_name", label: "Locus Identifier", cellType: "name_link" },
    { key: "annotation", label: "Locus Description" },
    { key: "organism", label: "Organism" },
    { key: "probeType", label: "Probe Type" },
    { key: "isControl", label: "Is Control" },
    // { key: "isAmbiguous", label: "Is Ambiguous" },
  ];

  try {
    let response = await PostsService.fetchGeneMicroarraySearch(search_params);
    if (response.data.error) {
      return { error: true, items: [], fields: [] };
    }
    let items = [];
    let docs = response.data.docs;

    let totalMatch = response.data.total;
    for (const d of docs) {
      items.push({
        selected: false,
        probeName: {
          name: d.probeName,
          link: `/array_element?key=${d.probeName}`,
        },
        probeType: d.probeType,
        organism: d.organism,
        isControl: d.isControl,
        locus_name: { name: d.locus, link: `/locus?name=${d.locus}` },
        annotation: d.annotation,
        isAmbiguous: d.isAmbiguous,
        orig: d,
      });
    }
    search_params.matchCount = totalMatch;
    // let queryStatement = setQueryStatement(search_params);
    return {
      error: false,
      items: items,
      fields: fields,
      queryStatement: "",
      searchResultsHeader: "Microarray Elements",
    };
  } catch (error) {
    return { error: true, items: [], fields: [] };
  }
}

export async function loadLocusHistory(search_params) {
  let fields = [
    { key: "row_num", label: " # " },
    { key: "selected", label: "" },
    { key: "locusName", label: "Locus Identifier", cellType: "name_link" },
    { key: "current_status", label: "Current Status" },
    { key: "who", label: "Modified by" },
    { key: "date", label: "Modification Date" },
    { key: "comments", label: "Modification Comments" },
    { key: "lociInvolved", label: "Loci Involved in Modification" },
  ];
  try {
    let response = await PostsService.fetchLocusHistory(search_params);
    if (response.data.error) {
      return { error: true, items: [], fields: [] };
    }
    let items = [];
    let docs = response.data.docs;

    let totalMatch = response.data.total;
    for (const d of docs) {
      items.push({
        selected: false,
        locusName: { name: d.locus, link: "/locus?name=" + d.locus },
        current_status: d.currentStatus == "obsolete" ? "obsolete" : "in use",
        who: d.who,
        date: d.date,
        comments: d.currentStatus,
        lociInvolved: d.modificationComments,
        orig: d,
      });
    }
    search_params.matchCount = totalMatch;
    // let queryStatement = setQueryStatement(search_params);
    return {
      error: false,
      items: items,
      fields: fields,
      queryStatement: "",
      searchResultsHeader: "Locus History",
    };
  } catch (error) {
    //   this.loading = false;
    return { error: true, items: [], fields: [] };
  }
}
