<template>
  <div class="items-panel">
    <img
      v-if="contentType === 'image'"
      :src="contentUrl"
      alt="Loaded Content"
    />
    <pre v-else-if="contentType === 'text'">{{ textContent }}</pre>
    <iframe
      v-else-if="contentType === 'pdf'"
      :src="contentUrl"
      frameborder="0"
    ></iframe>
    <div v-if="loaded && !contentUrl && !textContent">
      Cannot load job postings. Please try again later or contact
      <a href="mailto:curator@arabidopsis.org">curator@arabidopsis.org</a>
    </div>
  </div>
</template>

<script>
import { getJobBlob } from "@/services/NewsService";

export default {
  name: "ViewJob",
  data() {
    return {
      pageTitle: "Job Postings",
      contentUrl: "",
      textContent: "",
      contentType: "",
      loaded: false,
      crumbLinks: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "News",
          href: "/news/overview",
        },
        {
          text: "Job Postings",
          active: true,
        },
      ],
    };
  },
  mounted() {
    document.title = "Jobs | TAIR";
  },
  async beforeRouteEnter(to, from, next) {
    const fileName = to.query.file;

    const blob = await getJobBlob(fileName);
    const contentType = blob.type;
    const objectUrl = URL.createObjectURL(blob);

    if (contentType.startsWith("text/")) {
      const reader = new FileReader();
      reader.onload = function () {
        next((vm) => {
          vm.textContent = reader.result;
          vm.contentType = "text";
          vm.loaded = true;
        });
      };
      reader.readAsText(blob);
    } else if (contentType.startsWith("image/")) {
      next((vm) => {
        vm.contentUrl = objectURL;
        vm.contentType = "image";
        vm.loaded = true;
      });
    } else if (contentType === "application/pdf") {
      // It's a PDF
      next((vm) => {
        vm.contentType = "pdf";
        vm.contentUrl = objectUrl;
      });
    }
  },
  methods: {
    displayContent() {
      if (this.isImage) {
        // Display image
        return `<img src="${this.contentUrl}" alt="Blob Image">`;
      } else {
        // Display text or other content types
        return `<pre>${this.contentUrl}</pre>`;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.icon-text-wrapper {
  display: flex;
  align-items: center;

  // Ensure icons align nicely with the text
  .margin-right {
    margin-right: 10px; // Adjust spacing between icon and text as needed
  }

  h3,
  a {
    display: inline; // Ensure text does not break onto a new line
    margin: 0; // Remove any default margins that might cause layout issues
  }
}

iframe {
  width: 100%;
  height: 600px;
  border: none; /* Remove default border */
}
</style>
