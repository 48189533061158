import PostsService from "../PostsService";

export async function loadPubResults(search_params) {
  let fields = [
    { key: "row_num", label: " # " },
    { key: "selected", label: "" },
    {
      key: "title",
      label: "Title",
      cellType: "name_link",
      class: "wide",
    },
    {
      key: "year",
      label: "Year",
      cellType: "name_link",
    },
    {
      key: "doi",
      label: "DOI",
      cellType: "name_link",
    },
    {
      key: "pmid",
      label: "PMID",
      cellType: "name_link",
    },
  ];
  let items = [];
  try {
    let response = await PostsService.fetchPublicationSearch(search_params);
    if (response.data.error) {
      return { error: true, items: [], fields: [] };
    }
    let docs = response.data.docs;

    let totalMatch = response.data.total;
    search_params.matchCount = totalMatch;
    for (const d of docs) {
      let footerHtml = `<p class="text-left">Authors: ${d.authors[0]} `;
      footerHtml += `<br> Source: ${d.pubName[0]}`;
      footerHtml += `<br> Keywords: ${
        d.keywords ? d.keywords.join(", ") : "No keywords available"
      }`;
      footerHtml += `</br></p>`;
      items.push({
        id: d.id,
        selected: false,
        authors: {
          name: d.authors[0],
        },
        title: {
          name: d.title[0],
          link: `/publication?key=${d.referenceId}`,
        },
        source: {
          name: d.pubName[0],
        },
        year: {
          name: d.year,
        },
        doi: {
          name: d.doi,
          link: `https://doi.org/${d.doi}`,
        },
        pmid: {
          name: d.pubmedId,
          link: `https://pubmed.ncbi.nlm.nih.gov/${d.pubmedId}/`,
        },
        keywords: {
          name: d.keywords ? d.keywords.join(";") : "No keywords available",
        },
        _showDetails: true,
        html: footerHtml,
      });
    }

    let queryStatement = setPubQueryStatement(search_params);
    return {
      error: false,
      items: items,
      fields: fields,
      queryStatement: queryStatement,
    };
  } catch (error) {
    //   this.loading = false;
    return { error: true, items: [], fields: [] };
  }
}
function setPubQueryStatement(params) {
  let queryStatement = `Your query for publications where `;
  let queryParts = [];
  let searchParts = [];
  let yearParts = [];
  params.searchTerms &&
    params.searchTerms.forEach((s) => {
      if (s.search_input) {
        searchParts.push(
          `${s.search_type} ${s.search_method} <span class="highlight-term">${s.search_input}</span>`
        );
      }
      if (s.year_from) {
        yearParts.push(
          `year is between <span class="highlight-term">${s.year_from}</span> and `
        );
      }
      if (s.year_to && yearParts.length > 0) {
        yearParts.push(`<span class="highlight-term">${s.year_to}</span>`);
      }

      if (s.publication_type && s.publication_type != "all") {
        searchParts.push(
          `publication type is <span class="highlight-term">${s.publication_type}</span>`
        );
      }
      // searchParts.push(
      //   `${s.search_by} ${s.search_type} <span class="highlight-term">${s.search_input}</span>`
      // );
    });
  if (yearParts.length > 0) {
    searchParts.push(yearParts.join(" "));
  }
  if (searchParts.length > 0) {
    queryParts.push(searchParts.join(" and "));
  }
  let keywordParts = [];
  if (params.keywordTerms) {
    let keywordParam = params.keywordTerms[0];
    if (keywordParam.keyword_term) {
      keywordParts.push(
        `keyword name ${keywordParam.keyword_method} <span class="highlight-term">${keywordParam.keyword_term}</span>`
      );
    }
  }

  let typesToFullName = {
    func: "GO Molecular Function",
    proc: "GO Biological Process",
    comp: "GO Cellular Component",
    stru: "Plant Structure",
    grow: "Growth and Developmental Stage",
    meth: "Experimental Method",
    user: "User defined",
  };

  if (params.keywordTerms && params.keywordTerms.length > 1) {
    let types = params.keywordTerms[1].keyword_types;
    if (types && types.length > 0) {
      let fullNames = types.map((t) => typesToFullName[t]);
      keywordParts.push(
        `keyword type is <span class="highlight-term">${fullNames.join(
          ", "
        )}</span>`
      );
    }
  }

  if (keywordParts.length > 0) {
    queryParts.push(keywordParts.join(" and "));
  }

  if (params.keywordCombo) {
    queryParts.push(
      `keyword and children of keyword is <span class="highlight-term">${params.keywordCombo}</span>`
    );
  } else if (params.keywordChild) {
    queryParts.push(
      `children of keyword is <span class="highlight-term">${params.keywordChild}</span>`
    );
  }

  if (params.loci) {
    queryParts.push(
      `loci is <span class="highlight-term">${params.loci}</span>`
    );
  }
  queryStatement += queryParts.concat(" and ").join(" ");
  queryStatement += ` resulted in <span style="color: green">${params.matchCount}</span> matches`;
  return queryStatement;
}
