import PostsService from "../PostsService";

export async function loadResults(search_params, type = "detail") {
  let fields = [
    { key: "row_num", label: " # " },
    { key: "selected", label: "" },
    {
      key: "keyword",
      label: "Keyword",
      cellType: "name_link",
    },
    {
      key: "category",
      label: "Keyword Category",
      cellType: "name_link",
    },
    {
      key: "tv",
      label: "Tree View",
      cellType: "name_link",
    },
    {
      key: "assoc_data",
      label: "Associated Data To Keyword",
      cellType: "name_links_multi",
    },
    {
      key: "assoc_data_child",
      label: "Associated Data To Children",
      cellType: "name_links_multi",
    },
  ];
  let items = [];
  try {
    let response = await PostsService.fetchKeywordDetailSearch(search_params);
    if (response.data.error) {
      return { error: true, items: [], fields: [] };
    }
    let totalMatch = response.data.total;
    let docs = response.data.docs;
    let keywordTypes = {
      "molecular function": "GO Molecular Function",
      "biological process": "GO Biological Process",
      "cellular component": "GO Cellular Component",
      "growth and developmental stages": "Growth and Developmental Stages",
      "plant structure": "Plant Structure",
      method: "Method",
      "user-defined": "User Defined",
    };

    //sort docs by keyword category and then by keyword name
    docs.sort((a, b) => {
      if (a.kwCategory[0] === b.kwCategory[0]) {
        if (a.kwName[0] < b.kwName[0]) {
          return -1;
        }
        if (a.kwName[0] > b.kwName[0]) {
          return 1;
        }
        return 0;
      }
      return a.kwCategory[0] < b.kwCategory[0] ? -1 : 1;
    });
    for (const d of docs) {
      let assoc_data = [];
      let assoc_data_child = [];

      if (d.lociCount > 0) {
        let searchParams = { keywordExact: d.kwName[0] };
        let encodedParams = encodeURIComponent(JSON.stringify(searchParams));
        let link = `/results?mainType=detail&category=genes&search_params=${encodedParams}`;
        assoc_data.push({
          name: `${d.lociCount} loci`,
          link: link,
        });
      }
      if (d.lociCount_child > 0) {
        let searchParams = { keywordChild: d.kwName[0] };
        let encodedParams = encodeURIComponent(JSON.stringify(searchParams));
        let link = `/results?mainType=detail&category=genes&search_params=${encodedParams}`;
        assoc_data_child.push({
          name: `${d.lociCount_child} loci`,
          link: link,
        });
      }
      if (d.pubCount > 0) {
        let searchParams = {
          keywordTerms: [
            {
              keyword_method: "exactly",
              keyword_term: d.kwName[0],
            },
          ],
        };
        let encodedParams = encodeURIComponent(JSON.stringify(searchParams));
        let link = `/results?mainType=detail&category=publication&search_params=${encodedParams}`;
        assoc_data.push({
          name: `${d.pubCount} publications`,
          link: link,
        });
      }
      if (d.pubCount_child > 0) {
        let searchParams = { keywordChild: d.kwName[0] };
        let encodedParams = encodeURIComponent(JSON.stringify(searchParams));
        let link = `/results?mainType=detail&category=publication&search_params=${encodedParams}`;
        assoc_data_child.push({
          name: `${d.pubCount_child} publications`,
          link: link,
        });
      }
      if (d.annotCount > 0) {
        let searchParams = { keywordExact: d.kwName[0] };
        let encodedParams = encodeURIComponent(JSON.stringify(searchParams));
        let link = `/results?mainType=detail&category=annotations&search_params=${encodedParams}`;
        assoc_data.push({
          name: `${d.annotCount} annotations`,
          link: link,
        });
      }
      if (d.annotCount_child > 0) {
        let searchParams = { keywordChild: d.kwName[0] };
        let encodedParams = encodeURIComponent(JSON.stringify(searchParams));
        let link = `/results?mainType=detail&category=annotations&search_params=${encodedParams}`;
        assoc_data_child.push({
          name: `${d.annotCount_child} annotations`,
          link: link,
        });
      }
      let browserLink = ``;
      if (d.gopoId && d.gopoId.length > 0) {
        let gopoId = d.gopoId[0];
        if (gopoId.startsWith("GO:")) {
          browserLink = `https://amigo.geneontology.org/amigo/term/${gopoId}#display-lineage-tab`;
        } else if (gopoId.startsWith("PO:")) {
          browserLink = `https://browser.planteome.org/amigo/term/${gopoId}#display-lineage-tab`;
        }
      }
      items.push({
        id: d.id,
        selected: false,
        keyword: {
          name: d.kwName[0],
          link: `/keyword?key=${d.kwId}`,
        },
        category: {
          name: keywordTypes[d.kwCategory[0]],
        },
        tv: {
          name: browserLink ? "treeview" : "",
          link: browserLink,
        },
        assoc_data: assoc_data,
        assoc_data_child: assoc_data_child,
        originalDoc: d,
      });
    }
    search_params.matchCount = totalMatch;
    let queryStatement = setQueryStatement(search_params);
    if (type === "general") {
      queryStatement = `Your query for keywords where keyword or external accession contains the term <span class="highlight-term">${search_params.searchText}</span> resulted in <span style="color: green">${totalMatch}</span> matches`;
    }
    return {
      error: false,
      items: items,
      fields: fields,
      queryStatement: queryStatement,
    };
  } catch (error) {
    //   this.loading = false;
    return { error: true, items: [], fields: [] };
  }
}
function setQueryStatement(params) {
  let queryStatement = `Your query for keywords where `;
  if (params.searchTerms && params.searchTerms.length > 0) {
    let searchParts = [];
    params.searchTerms.forEach((search, index) => {
      if (search.kw_search_input) {
        searchParts.push(
          `name ${search.search_method} <span class="highlight-term">${search.kw_search_input}</span>`
        );
      }
      if (search.go_id_search_inp) {
        searchParts.push(
          `GO/PO ID is <span class="highlight-term">${search.go_id_search_inp}</span>`
        );
      }
    });
    if (searchParts.length > 0) {
      queryStatement += searchParts.join(" and ");
    }
  }
  if (params.restrictCats && params.restrictCats.length > 0) {
    let restrictCats = params.restrictCats.map((r) => {
      return getRestrictCatLabel(r);
    });
    if (restrictCats.length > 1) {
      queryStatement += ` and keyword categories are <span class="highlight-term">${restrictCats.join(
        ", "
      )}</span>`;
    } else {
      queryStatement += ` keyword category is <span class="highlight-term">${restrictCats.join(
        ", "
      )}</span>`;
    }
  }
  queryStatement += ` resulted in <span style="color: green">${params.matchCount}</span> matches`;
  return queryStatement;
}
function getRestrictCatLabel(kwId) {
  let key = Object.keys(kwId)[0];

  const keywordCategoryMap = {
    go_cc: "cellular component",
    go_bp: "biological process",
    go_mf: "molecular function",
    po: "growth and developmental stages",
    pat: "plant structure",
    exp: "method",
    user: "user-defined",
  };
  return keywordCategoryMap[key];
}
