import PostsService from "../PostsService";

export async function loadResults(search_params, type = "detail") {
  let fields = [
    { key: "row_num", label: " # " },
    { key: "selected", label: "" },
    { key: "protein", label: "Name", cellType: "name_link" },
    { key: "calcmw", label: "Calc MW" },
    { key: "calcPi", label: "Calc PI" },
    { key: "length", label: "Length" },
    { key: "locus", label: "Locus", cellType: "name_link" },
    { key: "gene_model", label: "Gene Models", cellType: "name_link" },
    {
      key: "symbol",
      label: "Gene Symbol/Full Name",
    },
    { key: "description", label: "Description" },
  ];
  let items = [];
  try {
    let response = await PostsService.fetchProteinDetailSearch(search_params);
    if (response.data.error) {
      return { error: true, items: [], fields: [] };
    }
    let totalMatch = response.data.total;
    let docs = response.data.docs;

    docs.sort((a, b) => {
      if (a.name[0] < b.name[0]) {
        return -1;
      }
      if (a.name[0] > b.name[0]) {
        return 1;
      }
      return 0;
    });
    for (const d of docs) {
      let symbolTxt = "";
      if (d.symbol && d.symbol.length > 0) {
        symbolTxt = d.symbol[0];
        if (d.fullName && d.fullName != "") {
          symbolTxt += "/" + d.fullName;
        }
      }
      items.push({
        id: d.id,
        selected: false,
        name: d.name[0],
        protein: { name: d.name[0], link: `protein?key=${d.proteinId}` },
        length: d.length != 0 ? d.length : null,
        calcmw: d.mw != 0 ? d.mw : null,
        calcPi: d.pi != 0 ? d.pi : null,
        // protein_models: {
        //   locusName: d.locusName ? d.locusName : null,
        //   locusId: d.locusId ? d.locusId : null,
        //   geneName: d.geneName ? d.geneName : null,
        //   geneId: d.geneId ? d.geneId : null,
        // },
        locus: {
          name: d.locusName ? d.locusName : null,
          link: d.locusName ? `locus?key=${d.locusId}` : null,
        },
        gene_model: {
          name: d.geneName ? d.geneName : null,
          link: d.geneName ? `gene?key=${d.geneId}` : null,
        },
        symbol: symbolTxt,
        description: d.description ? d.description : "",
      });
    }
    search_params.matchCount = totalMatch;
    let queryStatement = setQueryStatement(search_params);
    if (type === "general") {
      queryStatement = `Your query for proteins where locus name, gene name, GenBank accession, SwissProt accession, protein domain accession, uniprot id or interpro accession contains the term <span style="color: green">${search_params.searchText}</span> resulted in <span class="highlight-term">${totalMatch}</span> matches`;
    }
    let filteredStatement = "";
    if (search_params.geneList != null && search_params.geneList !== "") {
      let geneList = search_params.geneList.split(";");
      filteredStatement = `From the uploaded <span style="color: green">${geneList.length}</span> gene list, <span class="highlight-term">0</span> were filtered out`;
    }
    return {
      error: false,
      items: items,
      fields: fields,
      queryStatement: queryStatement,
      filteredStatement: filteredStatement,
    };
  } catch (error) {
    //   this.loading = false;
    return { error: true, items: [], fields: [] };
  }
}
function setQueryStatement(params) {
  let queryStatement = "Your query for proteins";

  let paramsStatement = "";
  if (params.nameSection && params.nameSection.nameText) {
    if (params.nameSection.nameBy === "locus") {
      paramsStatement += ` locus name ${params.nameSection.nameType} <span class="highlight-term">${params.nameSection.nameText}</span>,`;
    } else if (params.nameSection.nameBy === "genPeptId") {
      paramsStatement += ` GenPeptId ${params.nameSection.nameType} <span class="highlight-term">${params.nameSection.nameText}</span>,`;
    } else if (params.nameSection.nameBy === "symbol") {
      paramsStatement += ` gene symbol ${params.nameSection.nameType} <span class="highlight-term">${params.nameSection.nameText}</span>,`;
    }
  }

  if (params.classType && params.classType.length > 0) {
    const classTypesFormatted = params.classType.map(
      (type) => `<span class="highlight-term">${getClassTypeTitle(type)}</span>`
    );
    if (paramsStatement.length > 0) {
      paramsStatement += ` and`;
    }
    paramsStatement += ` structural class types is ${classTypesFormatted.join(
      " or "
    )}`;
  }

  if (params.physioSection && params.physioSection.length > 0) {
    let physioStatements = params.physioSection.map((physio) => {
      let statement = "";

      if (physio.type === "length") {
        if (physio.min && physio.max) {
          statement = ` sequence length is greater than or equal to ${physio.min} residues and less than or equal to ${physio.max} residues`;
        } else if (physio.min) {
          statement = ` sequence length is greater than or equal to ${physio.min} residues`;
        } else if (physio.max) {
          statement = ` sequence length is less than or equal to ${physio.max} residues`;
        }
      }

      if (physio.type === "pi") {
        if (physio.min && physio.max) {
          statement = ` calculated PI is greater than or equal to ${physio.min} and less than or equal to ${physio.max}`;
        } else if (physio.min) {
          statement = ` calculated PI is greater than or equal to ${physio.min}`;
        } else if (physio.max) {
          statement = ` calculated PI is less than or equal to ${physio.max}`;
        }
      }

      if (physio.type === "mw") {
        if (physio.min && physio.max) {
          statement = ` calculated MW is greater than or equal to ${
            physio.min / 1000
          } kDa and less than or equal to ${physio.max / 1000} kDa`;
        } else if (physio.min) {
          statement = ` calculated MW is greater than or equal to ${physio.min}`;
        } else if (physio.max) {
          statement = ` calculated MW is less than or equal to ${physio.max}`;
        }
      }
      // Additional conditions for other types can be added here

      return statement;
    });
    if (physioStatements.length > 0) {
      if (paramsStatement.length > 0) {
        paramsStatement += ` and`;
      }
      paramsStatement += physioStatements.join(" , ");
    }
  }

  if (params.domainSection && params.domainSection.length > 0) {
    let domainStatements = params.domainSection.map((domain) => {
      let statement = "";
      if (domain.db_name == "interpro") {
        statement +=
          " Interpro accession matches exactly <span style='color: red'>" +
          domain.db_acc_input +
          "</span>";
      } else {
        statement +=
          " " +
          domain.db_name +
          " accession matches exactly <span style='color: red'>" +
          domain.db_acc_input +
          "</span>";
      }
      return statement;
    });
    if (domainStatements.length > 0) {
      if (paramsStatement.length > 0) {
        paramsStatement += ` and`;
      } else {
        paramsStatement += ` where`;
      }
      paramsStatement += domainStatements.join(" , ");
    }
  }

  if (paramsStatement.length > 0) {
    //add " where" to beginning
    paramsStatement = ` where ${paramsStatement}`;
  }

  queryStatement += paramsStatement;

  queryStatement += ` resulted in <span style="color: green">${params.matchCount}</span> matches`;

  return queryStatement;
}

function getClassTypeTitle(code) {
  switch (code) {
    case "a":
      return "All Alpha Proteins";
    case "b":
      return "All Beta Proteins";
    case "c":
      return "Alpha and beta proteins (a/b)";
    case "d":
      return "Alpha and beta proteins (a+b)";
    case "e":
      return "Multi-domain proteins (alpha and beta)";
    case "f":
      return "Membrane and cell surface proteins and peptides";
    case "g":
      return "Small proteins";
    case "h":
      return "Coiled coil proteins";
    default:
      return "Unknown";
  }
}
