<template>
  <div>
    <div v-if="!error" class="detail-wrapper">
      <div class="side-wrapper">
        <ul class="list-group list-group-flush">
          <a
            v-for="(col, i) in side_cols"
            :key="i"
            href="#"
            class="list-group-item list-group-item-action flex-column align-items-start side-item"
            v-bind:class="{ selected: i == colIdxSelected }"
            @click="sideColClicked(i)"
          >
            {{ col }}
          </a>
        </ul>
      </div>
      <div v-if="loading">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <div class="list-wrapper" v-on:scroll.passive="handleScroll">
        <div
          v-if="json_data"
          class="text-left m-2 font-weight-bold fixed-title"
        >
          <h4>Polymorphism: {{ json_data.originalName }}</h4>
        </div>
        <BaseEntry
          v-for="(e, i) in entries"
          :selectedColIdx="colIdxSelected"
          :currIdx="i"
          :key="i"
          :content="e"
          :ref="`entry` + i"
        ></BaseEntry>
      </div>
    </div>
    <div v-else>
      <PageNotFound />
    </div>
  </div>
</template>

<script>
//http://localhost:8082/polyallele?key=503305657
import BaseEntry from "@/components/detail/BaseEntry";
import PostsService from "@/services/PostsService";
import PageNotFound from "@/components/common/PageNotFound";
import { getMapElementViewUrl } from "@/services/JBrowseService";

export default {
  name: "PolymorphismDetail",
  components: {
    BaseEntry,
    PageNotFound,
  },
  data() {
    return {
      base_url: process.env.VUE_APP_S3_PATH + "polymorphism/",
      json_data: null,
      side_cols: [
        "Summary",
        "Associated Genes",
        "Associated Polymorphisms",
        "Genetic Markers",
        "Germplasms",
        "Maps",
        "Associated Nucleotide Sequences",
        "Publications",
      ],
      loading: false,
      error: false,
      colIdxSelected: 0,
      entries: [],
      entryPosys: [],
      helpTexts: {
        name: `The name of a polymorphism such as an allele name, or an alphanumeric identifying code (e.g. for SALK T-DNA insertions or TILLing lines) or the name of the marker used to detect the polymorphism.`,
        dateLastModified: `The date the data for the object was last altered such as updates,inserts and obsoletion.`,
        alias: `Alternative names for data other than genes. For example alternative marker names.`,
        type: `Types of polymorphisms in TAIR.
        <li>
compound: Refers to polymorphisms between two lines where an insertion and a substitution or a deletion and a substitution occur at the same genomic location.
deletion: A variation where a segment of DNA is absent in one variant with respect to a reference polymorphism. In TAIR the reference polymorphism corresponds to the wild type Columbia ecotype but other reference ecotypes may be used if the Columbia variant is not known.
digest_pattern: The characteristic pattern of products of a restriction enzyme digestion. Also refers to a type of polymorphism where the pattern of restriction enzyme fragments in one variant differs from that of another variant.
INDEL: Insertion/deletions (INDELS) are sequence variants where the Columbia (reference) polymorphism is an insertion relative to one ecotype and a deletion relative to another, different ecotype.
insertion: A variant in which a segment of DNA is present in one genotype and absent in another. In TAIR, insertions are defined with respect to a reference polymorphism. The reference polymorphism typically corresponds to the wild type Columbia variant but other reference ecotypes may be used if the Columbia variant is not known.
PCR_product_length: Refers to polymorphisms that are represented by different PCR product lengths. The polymorphisms are linked to the genetic markers (SSLP, AFLP) that can be used to detect them
substitution: Replacement of one nucleotide sequence by another nucleotide or one amino acid in a protein by another amino acid. Also known as a single nucleotide polymorphism (SNP).
visible: Allelic variations in a visible or biochemical phenotype at one or more loci that can be detected by quantitative or qualitative methods.
</li>
`,
        insertionType: `insertion_type: Classification of DNA sequences that are either foreign (e.g. introduced) or present in one variant and absent in another .

short: Insertions of DNA less than 25 base pairs in length.
T-DNA: The portion of the Ti plasmid from the soil bacteria Argrobacteruim tumafaciens that is transferred into the host genome. The transferred DNA(T-DNA). Inoculation of whole plants or bombardment of plant cells with Agrobacterium is routinely used to introduce foreign DNA into plants. Engineered constructs can be introduced into plants by inserting the desired sequences between the T-DNA left and right border sequences.
`,
        chromosome: `chromosome: A linkage structure consisting of a specific linear sequence of genetic information.`,
        gene_model: `Gene Model: In TAIR each gene entry corresponds to a single gene model. A gene model is defined as any description of a gene product from a variety of sources including computational prediction, mRNA sequencing, or genetic characterization.`,
        gene_locus: `Locus: In TAIR a locus is a mapped element that corresponds to a transcribed region in Arabidopsis genome, or a genetic locus that segregates as a single genetic locus or quantitative trait. Loci are mapped based upon sequence match, or by recombination frequencies. A locus can have one or more associated gene models such as alternatively spliced variants.`,
        gene_type: `relationship_type: Defines the type of relationship between an associated map element and a gene in TAIR.

insert_contains: For clones that contain a gene, this indicates if the insertion in the clone contains a gene .
is_an_allele_of: Indicates if a polymorphism is an allele of a gene.
`,
        gene_desc: `description: Descriptions come from definition lines supplied with TIGR gene annotation records (description is generally based on sequence similarity), as well as definition lines from GenBank records (written by the submitter). Other descriptions may be written by a curator and based upon information obtained from the available literature.`,
        mutagen: `mutagen: The physical or chemical agent used to cause a mutation.`,
        mutagen_site: `mutation site: One of a defined set of structural or physical characteristics of a chromosome. In the context of polymorphisms, this field is used to describe the chromosome feature in which the mutation resides.

intergenic: An interval on a chromosome between two genes on the same strand.
`,
        inheritence: `inheritance: For any given allele this refers to the genotype associated with the phenotypic character.

dominant: An allele that produces the same character whether present in the homozygous or heterozygous state.
incompletely dominant: An allele combination that produces a phenotype in the heterozygous state that is distinct from the dominant homozygote and the recessive homozygote phenotypes. Also known as semi-dominant.
recessive: An allele that lacks effect when accompanied in the same diploid by a dominant form of the same gene.
`,
        alleleType: `allele type: A classification of alleles based upon the phenotype and genotype of alleles.

antimorphic: A type of mutation in which the mutated gene product has an altered function that acts antagonistically to the wild type allele. Antimorphic mutants are usually dominant or semi-dominant.
gain of function: A type of mutation in which the altered gene product possesses a new function or a new pattern of gene expression. Gain of function mutants are usually dominant or semidominant.
haplo-insufficient: A description applied to a gene that produces a mutant phenotype when present in a diploid individual heterozygous for an amorphic allele.
hypermorphic: A type of mutation in which the altered gene product possesses an increased level of activity, or in which the wild-type gene product is expressed at a increased level.
hypomorphic: A type of mutation in which the altered gene product possesses an increased level of activity, or in which the wild-type gene product is expressed at a increased level.
loss of function: A type of mutation in which the altered gene product lacks the function of the wild-type gene. Also called amorphic or null mutation.
unknown: Used when the type of the allele is not known or the information is not yet available.
`,
        description: `description: A free text description of the polymorphism supplied by curators, or obtained from submitters or publications.`,
        polymorphism: `Associated Polymorphisms: Links to polymorphisms associated to the displayed data. For example, alleles found in germplasms or all polymorphisms detected with a single genetic marker.`,
        poly_type: `type: Types of polymorphisms in TAIR.

compound: Refers to polymorphisms between two lines where an insertion and a substitution or a deletion and a substitution occur at the same genomic location.
deletion: A variation where a segment of DNA is absent in one variant with respect to a reference polymorphism. In TAIR the reference polymorphism corresponds to the wild type Columbia ecotype but other reference ecotypes may be used if the Columbia variant is not known.
digest_pattern: The characteristic pattern of products of a restriction enzyme digestion. Also refers to a type of polymorphism where the pattern of restriction enzyme fragments in one variant differs from that of another variant.
INDEL: Insertion/deletions (INDELS) are sequence variants where the Columbia (reference) polymorphism is an insertion relative to one ecotype and a deletion relative to another, different ecotype.
insertion: A variant in which a segment of DNA is present in one genotype and absent in another. In TAIR, insertions are defined with respect to a reference polymorphism. The reference polymorphism typically corresponds to the wild type Columbia variant but other reference ecotypes may be used if the Columbia variant is not known.
PCR_product_length: Refers to polymorphisms that are represented by different PCR product lengths. The polymorphisms are linked to the genetic markers (SSLP, AFLP) that can be used to detect them
substitution: Replacement of one nucleotide sequence by another nucleotide or one amino acid in a protein by another amino acid. Also known as a single nucleotide polymorphism (SNP).
visible: Allelic variations in a visible or biochemical phenotype at one or more loci that can be detected by quantitative or qualitative methods.
`,
        poly_variante: `species variant: In TAIR species variants include natural variants of Arabidopsis thaliana collected from a variety of sources (ecotypes) as well as related Genera and species of Arabidopsis.`,
        poly_sequence: `polymorphic_sequence: The nucleotide or amino acid sequence that is polymorphic between two genotypes.`,
        nucl_sequence: `Associated Nucleotide Sequence: This band displays all types of nucleotide sequences related to the date being displayed. This includes short flanking sequences for polymorphisms, longer insertion flanking sequences corresponding to plant genomic sequences flanking a T-DNA or Tn insertion, and a variety of cDNA and genomic DNA sequences.`,
        flank_sequence: `type: Types of flanking sequences in TAIR.
        <li><ul>allele_probe: Not really a flanking sequence per se. This refers to a oligonucleotide sequence that is used to detect SNP polymorphisms. The allele probe is complementary to one of two or more polymorphic sequences and used as a radiolabled probe to detect the polymorphism.</ul>
<ul>flank: The twenty or more base pairs on either side of a polymorphism. The flank sequences are used to map and define the boundaries of a polymorphic region.</ul>
<ul>PCR_primer: For genetic markers that use PCR as a part the detection method (e.g. SSLP and CAPS markers) these are the PCR primers used for amplification of the marker. Primer sequences are used to define the location of a given marker on the Arabidopsis sequence map.</ul>
</li>
`,
        map_locations: `Map Locations: Displays the location of the object (e.g. gene, clone, polymorphism) on all maps for which the data is available.`,
        map_type: `map_type: Types of maps in TAIR.

null: Two or more overlapping BAC, cosmid or similarly large clone.
genetic: A linkage map of a chromosome based upon recombination frequencies. Sets of markers are mapped by crossing parents with two different forms of the marker (in the case of many classical markers, these are the wild-type and the mutant) and scoring how many recombination events separate the marker from other markers on the same map.
nuc_sequence: A linkage map based on the ordered assembly nucleotide sequences of BACS obtained from Arabidopsis Genome Initiative (AGI). BAC clone sequences used to generate the sequence map are termed Annotation Units which may differ from the original BAC clone sequence due to trimming of sequences at the ends of the clones.The order of sequences is based upon the tiling path provided by the AGI.
physical_framework: Physical maps are collections of overlapping clones that have been arranged into a tiling path based on either fingerprinting (digestion of clones with restriction enzymes and comparison of the fragment sizes) or hybridization. The framework designates the physical map used in AtDB as the reference for scaling multiple maps for display.
`,
        map_links: `Map Links: Map links are displayed for any type of data that has been placed on a map. Selecting the SeqViewer pages take you to a close up or nucleotide sequence view centered on the object (e.g. a gene, clone or polymorphism). Links to the MapViewer will display the object on all of the maps (genetic, physical and sequence) where it has been located.`,
        germplasm: `Germplasm: In TAIR, germplasms correspond to individual strains having unique genotypes, pooled strains and sets of pools and strains.`,
        germplasm_stockname: `name: Stock names correspond to the stock number used by ABRC. Stock numbers may be an accession such as CSXXXX, or a clone name or the name of the stock designated by the donor.`,
        germplasm_pheno: `phenotype: Any detectable manifestation of the genotype of an organism.`,
        attribution: `Attribution: Associations to a member of TAIR's community who supplied information, materials, or a specific piece of data. It can be used to identify the person or organization from which additional information or specific materials can be obtained. Each attribution is linked to a record for that community member, from which you can obtain their contact information.`,
        comments: `Comments: Comments displayed include comments contributed by TAIR curators or other members of the community. Comments are associated to particular data on the data detail pages. Registered users can submit comments on any detail page. The comments are then displayed along with the name of the contributor. Comments are reviewed by curators who may perform updates or corrections to data based upon the contributors comments.`,
      },
    };
  },

  async mounted() {
    let polymorphismId = this.$route.query.key;
    if (this.$route.query.name) {
      let response2 = await PostsService.getPolymorphisIdByName(
        this.$route.query.name.toUpperCase()
      );
      polymorphismId = response2.data;
      if(polymorphismId == null){
        this.error = true;
      }
    }
    if (this.$route.query.accession) {
      const accession = this.$route.query.accession;
      if (!isNaN(parseInt(accession)) && accession == parseInt(accession)) {
        let response2 = await PostsService.getPolymorphisIdByAccession(accession);
        polymorphismId = response2.data;
        if (polymorphismId == null) {
          this.error = true;
        }
      } else {
        this.error = true;
      }
    }
    if (polymorphismId) {
      try {
        let response = await PostsService.getPolymorphismDetail({
          key: polymorphismId,
        });
        this.json_data = response.data;
        this.loading = true;
        // this.side_cols = Object.keys(this.json_data.polymorphism);

        this.processEntries();
        this.loading = false;
        document.title = `Polymorphism: ${this.json_data.name}`;
      } catch (error) {
        console.error("Error fetching polymorphism details: ", error);
        this.loading = false;
        this.error = true;
      }
    } else {
      this.loading = false;
      this.error = true;
    }
  },
  methods: {
    sideColClicked(colIdx) {
      this.scrollToElement(colIdx);
      this.colIdxSelected = colIdx;
    },
    processEntries() {
      this.side_cols.forEach((title) => {
        let entry = {
          title: title,
          name: title.replace(/\s/g, ""),
        };
        switch (title) {
          case "Summary":
            entry.type = "bands";
            entry.bands = this.fillSummary();
            break;
          case "Associated Genes":
            entry.type = "bands";
            entry.bands = this.fillGene();
            break;
          case "Associated Polymorphisms":
            entry.type = "bands";
            entry.bands = this.fillPoly();
            break;
          case "Germplasms":
            entry.type = "bands";
            entry.bands = this.fillGermplasm();
            break;
          case "Genetic Markers":
            entry.type = "bands";
            entry.bands = this.fillGeneticMarkers();
            break;
          case "Maps":
            entry.type = "bands";
            entry.bands = this.fillMaps();
            break;
          case "Associated Nucleotide Sequences":
            entry.type = "bands";
            entry.bands = this.fillSequences();
            break;
          case "Publications":
            entry.type = "bands";
            entry.bands = this.fillPublications();
            break;
          default:
            entry.type = "bands";
            entry.bands = [];
            break;
        }
        this.entries.push(entry);
      });
      setTimeout(() => {
        this.entries.forEach((e, i) => {
          let entryPagePosY = this.$refs["entry" + i][0].$el.offsetTop;
          this.entryPosys.push(entryPagePosY);
        });
      }, 1000);
    },
    fillSummary() {
      let bands = [];
      bands.push({
        key: "Name",
        text: this.json_data.originalName,
        helpTxt: this.helpTexts.name,
      });
      bands.push({
        key: "Date Last Modified",
        text: this.dateToYMD(this.json_data.dateLastModified),
        helpTxt: this.helpTexts.dateLastModified,
      });
      bands.push({
        key: "Aliases",
        text: this.getAliases(),
        helpTxt: this.helpTexts.alias,
      });
      bands.push({
        key: "Tair Accession",
        text: `Polymorphism: ${this.json_data.polymorphismTairObjectId}`,
      });
      bands.push(this.getMutationType());
      bands.push({
        key: "Chromosome",
        text: this.json_data.chromosome ? this.json_data.chromosome : "Unknown",
        helpTxt: this.helpTexts.chromosome,
      });
      bands.push({
        key: "Construct Type",
        text: this.json_data.constructType
          ? this.json_data.constructType
          : "None available",
      });
      bands.push({
        key: "Mutagen",
        text: this.json_data.mutagen
          ? this.json_data.mutagen
          : "None available",
        helpTxt: this.helpTexts.mutagen,
      });
      bands.push({
        key: "Mutation Site",
        text: this.json_data.mutationSite
          ? this.json_data.mutationSite
          : "None available",
        helpTxt: this.helpTexts.mutagen_site,
      });
      bands.push({
        key: "Inheritance",
        text: this.json_data.inheritance
          ? this.json_data.inheritance
          : "None available",
        helpTxt: this.helpTexts.inheritence,
      });
      bands.push({
        key: "Allele Type",
        text: this.json_data.alleleMode
          ? this.json_data.alleleMode
          : "None available",
        helpTxt: this.helpTexts.alleleType,
      });
      bands.push({
        key: "Description",
        text: this.json_data.description
          ? this.json_data.description
          : "None available",
        helpTxt: this.helpTexts.description,
      });
      bands.push(this.getAttributions());
      bands.push(this.getComments());

      return bands;
    },
    fillGene() {
      let bands = [];
      bands.push(this.getAssociatedGenes());
      // if (this.json_data.loci) {
      //   bands.push({
      //     key: "Associated Loci",
      //     type: "link",
      //     text: this.json_data.loci.name,
      //     link: "/locus?key=" + this.json_data.loci.locusId,
      //   });
      //
      // } else {
      //   bands.push({
      //     key: "Associated Loci",
      //     type: "link",
      //     text: "No Associated Loci Found",
      //   });
      // }

      return bands;
    },
    fillPoly() {
      let bands = [];
      let band2 = this.getAssociatedPolymorphisms_noDG();
      if (band2) {
        bands.push(band2);
      }
      let band = this.getAssociatedPolymorphisms_DG();
      if (band) {
        bands.push(band);
      }
      return bands;
    },
    fillGeneticMarkers() {
      let bands = [];
      bands.push(this.getGeneticMarkers());
      return bands;
    },
    fillGermplasm() {
      let bands = [];
      bands.push(this.getGermplasms());
      // bands.push(this.getAnnotations());
      return bands;
    },
    fillMaps() {
      let bands = [];
      bands.push(this.getMapLocations());
      return bands;
    },
    fillSequences() {
      let bands = [];
      bands.push(this.getSequence());
      let s = this.json_data.associatedNucleotideSequences[0];
      if (s) {
        if (s.genBankAccession) {
          bands.push({
            key: "GenBank Accession",
            type: "link",
            text: s.genBankAccession,
            link: `https://www.ncbi.nlm.nih.gov/nucleotide/${s.genBankAccession}`,
          });
        }
      }
      return bands;
    },
    fillPublications() {
      let bands = [];
      bands.push(this.getPublications());
      return bands;
    },

    //Getters
    getAliases() {
      let aliases = this.json_data.aliases;
      if (aliases == null || aliases.length == 0) {
        return "None available";
      }
      if (aliases.length == 1) {
        if (!aliases[0]) return "None available";
      }
      return aliases.join(", ");
    },
    getMutationType() {
      let entry = {
        key: "Type",
        type: "multiline",
        items: [],
        fields: [],
        helpTxt: this.helpTexts.type,
      };
      // let mutationType = `${this.json_data.polymorphismType}. <b>Insertion Type</b>: ${this.json_data.insertionType}`;
      entry.fields = [
        {
          key: "mutationType",
          label: "",
          cellType: "name_link",
          //   width: "100%",
        },
      ];
      if (
        this.json_data.polymorphismType == "insertion" ||
        this.json_data.polymorphismType == "deletion"
      ) {
        entry.fields.push({
          key: "insertionType",
          label: "Insertion Type",
          cellType: "name_link",
          //   width: "100%",
          helpTxt: this.helpTexts.insertionType,
        });
      }
      entry.items.push({
        mutationType: { name: this.json_data.polymorphismType },
        insertionType: { name: this.json_data.insertionType },
      });
      return entry;
    },
    getAttributions() {
      let entry = {
        key: "Attribution",
        type: "table",
        items: [],
        fields: [],
      };
      let attributions = this.json_data.attributions;
      if (attributions == null || attributions.length == 0) {
        entry.type = "";
        entry.text = "No Attributions available";
        return entry;
      }
      entry.fields = [
        {
          key: "type",
          label: "Type",
          cellType: "name_link",
        },
        {
          key: "name",
          label: "Name",
          cellType: "name_link",
        },
        {
          key: "date",
          label: "Date",
          cellType: "name_link",
        },
      ];
      attributions.forEach((a) => {
        let name = a.organizationName;
        if (a.firstName && a.lastName) {
          name = `${a.firstName} ${a.lastName}`;
        }
        let a_link = "";
        if (a.communityType == "organization") {
          a_link = `/organization?key=${a.communityId}`;
        } else {
          a_link = `/person?key=${a.communityId}`;
        }
        let item = {
          type: { name: a.attributionType },
          name: {
            name: name,
            link: a_link,
          },
          date: { name: this.dateToYMD(a.attributionDate) },
        };
        entry.items.push(item);
      });
      return entry;
    },
    getAssociatedGenes() {
      let entry = {
        key: "Associated Genes",
        type: "multiline",
        items: [],
        fields: [],
      };
      let genes = this.json_data.associatedGenes;
      if (genes == null || genes.length == 0) {
        entry.type = "";
        entry.text = "No Associated Genes available";
        return entry;
      }
      entry.fields = [
        {
          key: "gene_model",
          label: "Gene Model",
          cellType: "name_link",
          helpTxt: this.helpTexts.gene_model,
        },
        {
          key: "locus",
          label: "Locus",
          cellType: "name_link",
        },
        // {
        //   key: "mapType",
        //   label: "map type",
        //   cellType: "name_link",
        // },
        {
          key: "poly_site",
          label: "Polymorphism site",
          cellType: "name_link",
        },
        {
          key: "assoc_type",
          label: "Association Type",
          cellType: "name_link",
        },
        {
          key: "desc",
          label: "Description",
          cellType: "name_link",
          width: "100%",
        },
      ];
      genes.forEach((m) => {
        let item = {
          gene_model: {
            name: m.geneModel,
            link: `/gene?key=${m.geneId}`,
          },
          locus: {
            name: m.locus,
            link: `/locus?key=${m.locusId}`,
          },
          mapType: { name: m.mapType },
          poly_site: { name: m.geneFeatureSite },
          assoc_type: { name: m.relationshipType },
          desc: { name: m.description },
        };
        entry.items.push(item);
      });
      this.json_data.loci = {
        name: genes[0].locus,
        locusId: genes[0].locusId,
      };
      return entry;
    },
    getAssociatedPolymorphisms_noDG() {
      let entry = {
        key: "Associated Polymorphisms",
        type: "table",
        items: [],
        fields: [],
        helpTxt: this.helpTexts.poly_type,
      };
      if (this.json_data.tfcPolymorphisms == null) {
        return {
          type: "",
          text: "No Associated Polymorphisms available",
        };
      }
      let polymorphisms = this.json_data.tfcPolymorphisms;
      if (polymorphisms == null || polymorphisms.length == 0) {
        return null;
      }
      let non_dg_polymorphisms = this.json_data.tfcPolymorphisms.filter(
        (p) => p.polymorphismType != "digest_pattern"
      );
      if (non_dg_polymorphisms.length == 0) {
        return null;
      }

      let groupPolys = {};
      non_dg_polymorphisms.forEach((p) => {
        if (groupPolys[p.groupPolymorphismId] == null) {
          groupPolys[p.groupPolymorphismId] = [];
        }
        groupPolys[p.groupPolymorphismId].push(p);
      });
      entry.fields = [
        // {
        //   key: "type",
        //   label: "Type",
        //   cellType: "name_link",
        // },
        {
          key: "attributions",
          label: "Species Variant (attribution)",
          cellType: "link_commasep",
          helpTxt: this.helpTexts.poly_variante,
        },
        {
          key: "length",
          label: "Length",
          cellType: "name_link",
        },
        {
          key: "sequence",
          label: "Polymorphic Sequence",
          cellType: "name_link",
          helpTxt: this.helpTexts.poly_sequence,
        },
        {
          key: "verified",
          label: "Polymorphism Verified",
          cellType: "name_link",
        },
      ];

      Object.keys(groupPolys).forEach((key) => {
        let group = groupPolys[key];
        let item = {
          type: { name: group[0].polymorphismType },
          sequence: { name: group[0].polymorphicSequence },
          verified: { name: group[0].verified ? group[0].verified : "unknown" },
        };
        let attributions = [];
        group.forEach((p) => {
          attributions.push({
            name: p.abbrevName,
            link: `/ecotype?key=${p.speciesVariantId}`,
          });
        });
        if (group[0].lengths != null && group[0].lengths != 0) {
          length = group[0].lengths + " " + group[0].units;
          item.length = { name: length };
        } else {
          item.length = { name: "unknown" };
        }
        item.attributions = { link_commasep: attributions };
        entry.items.push(item);
      });
      entry.key = entry.items[0].type.name;
      return entry;
    },
    getAssociatedPolymorphisms_DG() {
      let entry = {
        key: "Associated Polymorphisms",
        type: "table",
        items: [],
        fields: [],
        helpTxt: this.helpTexts.poly_type,
      };
      if (!this.json_data.tfcPolymorphisms) return null;
      let dg_polymorphisms = this.json_data.tfcPolymorphisms.filter(
        (p) => p.polymorphismType == "digest_pattern"
      );
      if (dg_polymorphisms.length == 0) {
        return null;
      }
      entry.fields = [
        {
          key: "type",
          label: "Type",
          cellType: "name_link",
        },
        {
          key: "attribution",
          label: "Species Variant",
          cellType: "name_link",
          helpTxt: this.helpTexts.poly_variante,
        },
        {
          key: "enzymes",
          label: "REnzyme",
          cellType: "link_rows",
        },
        {
          key: "num_sites",
          label: "Num Sites",
          cellType: "link_rows",
        },
        {
          key: "fragment",
          label: "Fragment Length",
          cellType: "name_link",
        },
        {
          key: "verified",
          label: "Polymorphism Verified",
          cellType: "name_link",
        },
        {
          key: "attrib",
          label: "Attribution",
          cellType: "name_link",
        },
      ];

      let groupPolys = {};
      dg_polymorphisms.forEach((p) => {
        if (groupPolys[p.groupPolymorphismId] == null) {
          groupPolys[p.groupPolymorphismId] = [];
        }
        groupPolys[p.groupPolymorphismId].push(p);
      });
      Object.keys(groupPolys).forEach((key) => {
        let group = groupPolys[key];
        let item = {
          type: { name: group[0].polymorphismType },
          verified: { name: group[0].verified ? group[0].verified : "unknown" },
        };
        if (group[0].lengths != null && group[0].lengths != 0) {
          let length = group[0].lengths + " " + group[0].units;
          item.fragment = { name: length };
        } else {
          item.fragment = { name: "unknown" };
        }
        let link_rows_enz = [];
        let link_rows_nums = [];
        group.forEach((p) => {
          if (p.enzymeName) {
            link_rows_enz.push({
              name: p.enzymeName,
              link: `${process.env.VUE_APP_OLD_TAIR_URL}/servlets/TairObject?type=restrictionenzyme&id=${p.restrictionEnzymeId}`,
            });
            link_rows_nums.push({
              name: p.numSites == 0 ? "" : p.numSites,
            });
          }
        });
        item.enzymes = { link_rows: link_rows_enz };
        item.num_sites = { link_rows: link_rows_nums };
        let attributions = [];
        group.forEach((p) => {
          attributions.push({
            name: p.abbrevName,
            link: `/species?key=${p.speciesVariantId}`,
          });
        });
        item.attribution = { name: group[0].abbrevName };
        entry.items.push(item);
      });
      entry.key = "Digest Pattern";
      return entry;
    },
    getComments() {
      let entry = {
        key: "Community Comments",
        type: "comment_list",
        showComments: true,
        showRecent: true,
        items: [],
        helpTxt: this.helpTexts.comments,
      };
      let items = [];
      if (this.json_data.comments) {
        this.json_data.comments.forEach((c) => {
          let item = {};
          item.profile = {
            name: c.firstName,
            url: "/person?key=" + c.communityId,
          };
          item.comment = {
            text: c.notepadComment,
            url:
              process.env.VUE_APP_OLD_TAIR_URL +
              "/servlets/TairObject?type=notepad&id=" +
              1,
          };
          item.date_posted = this.dateToYMD(c.dateEntered);
          items.push(item);
        });
      }
      entry.items = items;
      if (entry.items.length == 0) {
        entry.items.push({ comment: { text: "No comments found" } });
      }
      return entry;
    },
    getGermplasms() {
      let entry = {
        key: "Germplasm",
        type: "multiline",
        items: [],
        fields: [],
        buttons: [],
        helpTxt: this.helpTexts.germplasm,
      };
      if (
        this.json_data.germplasmCollections == null ||
        this.json_data.germplasmCollections.length == 0
      ) {
        entry.type = "";
        entry.text = "No Germplasm available";
        return entry;
      }
      let germplasms = Object.values(this.json_data.germplasmCollections);
      entry.buttons.push({
        name: "Search at ABRC",
        url: "https://abrc.osu.edu/researchers",
      });
      entry.buttons.push({
        name: "Search at NASC",
        url: "https://arabidopsis.info/BasicForm",
      });

      entry.fields.push({
        key: "name",
        label: "Name",
        cellType: "name_link",
      });
      entry.fields.push({
        key: "poly",
        label: "Polymorphisms",
        cellType: "link_list",
      });
      entry.fields.push({
        key: "background",
        label: "Background",
        cellType: "name_link",
      });
      entry.fields.push({
        key: "stockName",
        label: "Stock Name",
        cellType: "name_link",
        helpTxt: this.helpTexts.germplasm_stockname,
      });
      entry.fields.push({
        key: "images",
        label: "Images",
        cellType: "image_list",
      });
      entry.fields.push({
        key: "phenotypes",
        label: "Phenotypes",
        cellType: "bullet_list",
        width: "100%",
        helpTxt: this.helpTexts.germplasm_pheno,
      });
      germplasms.forEach((g) => {
        //pub list formatting
        let phenotypes = [];

        if (g.phenotypeList) {
          g.phenotypeList.forEach((p) => {
            let bulletObj = {};
            bulletObj.title = "";
            bulletObj.points = [];
            let author = "Phenotype curated by ABRC.";
            if (p.authors) {
              let first = p.authors.split(",");
              author = `<a href="/publication?key=${p.referenceId}" target="_blank">${first[0]} et al. (${p.publication_year})</a>`;
            }
            // let point = p.phenotypetype + author;
            let point_html =
              "<span>" + p.phenotypetype + " " + author + "</span>";
            bulletObj.points.push(point_html);
            phenotypes.push(bulletObj);
          });
        }
        //polymorphisms
        let polyList = [];
        if (g.polymorphismList) {
          g.polymorphismList.forEach((p) => {
            let item = { name: p.originalName };
            if (p.polyId) {
              item.link = `/polymorphism?key=${p.polyId}`;
            }
            polyList.push(item);
          });
        }
        let stockName = { name: "" };
        if (g.stockList) {
          g.stockList.forEach((s) => {
            let item = {
              name: s.name,
              link: `https://abrc.osu.edu/stocks/number/${s.name}`,
            };
            stockName = item;
          });
        }

        let item = {
          name: { name: g.name, link: `/germplasm?key=${g.tairObjectId}` },
          background: { name: g.background },
          stockName: stockName,
          phenotypes: phenotypes,
          poly: polyList,
        };
        entry.items.push(item);
      });
      return entry;
    },
    getAnnotations() {
      let entry = {
        key: "Annotations",
        type: "table",
        items: [],
        fields: [],
      };
      let annotations = this.json_data.annotations;
      if (annotations == null || annotations.length == 0) {
        entry.type = "";
        entry.text = "No Annotations available";
        return entry;
      }
      entry.fields = [
        {
          key: "date",
          label: "Date",
          cellType: "name_link",
        },
        {
          key: "by",
          label: "By",
          cellType: "name_link",
        },
        {
          key: "annotation",
          label: "Annotation",
          cellType: "name_link",
        },
      ];
      annotations.forEach((a) => {
        let item = {
          date: { name: this.dateToYMD(a.date) },
          by: { name: a.annotatedBy },
          annotation: { name: a.annotation },
        };
        entry.items.push(item);
      });
      return entry;
    },
    getMapLocations() {
      let entry = {
        key: "Map Locations",
        type: "table",
        items: [],
        fields: [],
        buttons: [],
        helpTxt: this.helpTexts.map_locations,
      };
      let mapLocations = this.json_data.mapLocations;
      if (mapLocations == null || mapLocations.length == 0) {
        entry.type = "";
        entry.text = "No Map Locations available";
        return entry;
      }
      // entry.buttons.push({
      //   name: "Sequence Viewer",
      //   url: `https://seqviewer.arabidopsis.org/?action=accession&type=tdna&id=${this.json_data.polymorphismTairObjectId}&chr=${this.json_data.chromosome}`,
      // });
      // entry.buttons.push({
      //   name: "GBrowse",
      //   url: `https://gbrowse.arabidopsis.org/cgi-bin/gb2/gbrowse/arabidopsis/?name=${this.json_data.name}`,
      // });
      entry.fields = [
        {
          key: "chrom",
          label: "chrom",
          cellType: "name_link",
        },
        {
          key: "map",
          label: "map",
          cellType: "name_link",
        },
        {
          key: "mapType",
          label: "map type",
          cellType: "name_link",
          helpTxt: this.helpTexts.map_type,
        },
        {
          key: "coordinates",
          label: "coordinates",
          cellType: "name_link",
        },
        {
          key: "orientation",
          label: "orientation",
          cellType: "name_link",
        },
        {
          key: "views",
          label: "views",
          cellType: "name_link",
        },
      ];
      mapLocations.forEach((m) => {
        let item = {
          chrom: { name: m.chromosome },
          map: { name: m.name, link: `/map?key=${m.mapId}` },
          mapType: { name: m.mapType },
          coordinates: {
            name: `${m.startPosition} - ${m.endPosition} ${m.units}`,
          },
          orientation: { name: m.orientation == "F" ? "forward" : "reverse" },
          views: {
            name: "JBrowse",
            link: getMapElementViewUrl(
              m.chromosome,
              m.startPosition,
              m.endPosition,
              "polymorphism"
            ),
          },
        };
        entry.items.push(item);
      });
      return entry;
    },
    getSequence() {
      let entry = {
        key: "Insertion Flanking Sequence",
        type: "multiline",
        fields: [],
        items: [],
        helpTxt: this.helpTexts.flank_sequence,
      };
      if (
        this.json_data.associatedNucleotideSequences &&
        this.json_data.associatedNucleotideSequences.length > 0
      ) {
        let s = this.json_data.associatedNucleotideSequences[0];
        if (!s.flank1 && !s.flank2 && !s.insertionFlankingSequence) {
          entry.type = "";
          entry.text = "No Sequence available";
          return entry;
        }
        if (s.flank1 || s.flank2) {
          entry.key = "Flanking Sequence";
          entry.fields.push({
            key: "flank1",
            label: "Flank 1",
            cellType: "name_link",
            width: "100%",
          });
          entry.fields.push({
            key: "flank2",
            label: "Flank 2",
            cellType: "name_link",
            width: "100%",
          });
          this.json_data.associatedNucleotideSequences.forEach((s) => {
            let item = {
              flank1: { name: s.flank1 },
              flank2: { name: s.flank2 },
            };
            entry.items.push(item);
          });
        }
        if (s.insertionFlankingSequence) {
          entry.type = "sequence";
          const lines = [];
          const sequenceLength = s.insertionFlankingSequence.length;
          let lineNumber = 1;

          for (let i = 0; i < sequenceLength; i += 50) {
            const lineChunks = [];
            for (let j = i; j < i + 50 && j < sequenceLength; j += 10) {
              lineChunks.push(s.insertionFlankingSequence.slice(j, j + 10));
            }

            lines.push(
              `${lineNumber.toString().padStart(3, "0")} ${lineChunks.join(
                " "
              )}`
            );
            lineNumber += 50;
          }
          entry.chunks = lines;
        }
      }

      return entry;
    },
    getPublications() {
      let entry = {
        key: "Publications",
        type: "table",
        items: [],
        fields: [],
      };
      let publications = this.json_data.publications;
      if (publications == null || publications.length == 0) {
        entry.type = "";
        entry.text = "No publications available";
        return entry;
      }
      entry.fields = [
        {
          key: "title",
          label: "Title",
          cellType: "name_link",
        },
        {
          key: "source",
          label: "Source",
          cellType: "name_link",
        },
        {
          key: "year",
          label: "Year",
          cellType: "name_link",
        },
      ];
      publications.forEach((p) => {
        let item = {
          title: { name: p.title, link: `/publication?key=${p.referenceId}` },
          source: { name: p.sourceName },
          year: { name: p.publicationYear },
        };
        entry.items.push(item);
      });
      return entry;
    },
    getGeneticMarkers() {
      let entry = {
        key: "Genetic Markers",
        type: "table",
        items: [],
        fields: [],
      };
      let markers = this.json_data.geneticMarkers;
      if (markers == null || markers.length == 0) {
        entry.type = "";
        entry.text = "No markers available";
        return entry;
      }
      entry.fields = [
        {
          key: "name",
          label: "Name",
          cellType: "name_link",
        },
        {
          key: "type",
          label: "Type",
          cellType: "name_link",
        },
        {
          key: "alias",
          label: "Alias",
          cellType: "name_link",
        },
        {
          key: "chrom",
          label: "Chromosome",
          cellType: "name_link",
        },
        {
          key: "position",
          label: "Position",
          cellType: "name_link",
        },
        {
          key: "map",
          label: "Map",
          cellType: "name_link",
        },
      ];
      markers.forEach((p) => {
        let item = {
          name: {
            name: p.geneticMarkerName,
            link: `/marker?key=${p.geneticMarkerId}`,
          },
          type: { name: p.geneticMarkerType },
          alias: { name: p.originalAlias },
          chrom: { name: p.chromosome },
          position: { name: `${p.startPosition}-${p.endPosition} ${p.units}` },
        };
        entry.items.push(item);
      });
      return entry;
    },

    dateToYMD(dateTs) {
      let date = new Date(dateTs);
      const d = date.getDate();
      const m = date.getMonth() + 1;
      const y = date.getFullYear();
      return `${y}-${m.toString().padStart(2, "0")}-${d
        .toString()
        .padStart(2, "0")}`;
    },
    //
    scrollToElement(colIdx) {
      const entryRef = this.$refs["entry" + colIdx];
      const el = entryRef[0].$el;
      if (el) {
        const top = el.offsetTop - 210;
        el.parentElement.scrollTo(0, top);
      }
    },
    handleScroll(e) {
      const scrollTop = e.target.scrollTop + 215;
      let currTopEntry = 0;
      this.entryPosys.forEach((e, i) => {
        if (scrollTop > e) {
          currTopEntry = i;
        }
      });
      this.colIdxSelected = currTopEntry;
    },
  },
};
</script>

<style scoped lang="scss">
.fixed-title {
  position: sticky;
  top: 0;
  background-color: #f1efec;
  z-index: 10;
  padding-left: 10px;
  padding-top: 10px;
}

.my-custom-scrollbar {
  position: relative;
  height: 200px;
  overflow: auto;
}
.table-wrapper-scroll-y {
  display: block;
}
</style>
