<template>
  <div v-if="!error" class="detail-wrapper">
    <div class="side-wrapper">
      <ul class="list-group list-group-flush">
        <a
          v-for="(col, i) in side_cols"
          :key="i"
          href="#"
          class="list-group-item list-group-item-action flex-column align-items-start side-item"
          v-bind:class="{ selected: i == colIdxSelected }"
          @click="sideColClicked(i)"
        >
          {{ col }}
        </a>
      </ul>
    </div>
    <div class="list-wrapper" v-on:scroll.passive="handleScroll">
      <div
        v-if="loading"
        class="d-flex justify-content-center align-items-center"
        style="height: 100%"
      >
        <b-spinner label="Loading..."></b-spinner>
      </div>
      <div v-if="json_data" class="text-left m-2 font-weight-bold fixed-title">
        <h4>Array Element: {{ json_data.name }}</h4>
      </div>
      <div class="container text-left">
        <p>
          Please note that TAIR stopped accepting new microarray data
          submissions in June 2005. Newer and more comprehensive microarray data
          sets are available at
          <a href="http://www.ncbi.nlm.nih.gov/geo/" target="_blank"
            >GEO (Gene Expression Omnibus)</a
          >
          and
          <a href="http://www.ebi.ac.uk/arrayexpress/" target="_blank"
            >ArrayExpress</a
          >.
        </p>
      </div>
      <BaseEntry
        v-for="(e, i) in entries"
        :selectedColIdx="colIdxSelected"
        :currIdx="i"
        :key="i"
        :content="e"
        :ref="`entry` + i"
      ></BaseEntry>
    </div>
  </div>
  <div v-else>
    <PageNotFound />
  </div>
</template>

<script>
//http://localhost:8082/array_element?key=264152_at
//http://localhost:8082/array_element?id=33327
import BaseEntry from "@/components/detail/BaseEntry";
import PostsService from "@/services/PostsService";
import PageNotFound from "@/components/common/PageNotFound";
export default {
  name: "ArrayElementDetail",
  components: {
    BaseEntry,
    PageNotFound,
  },
  data() {
    return {
      base_url: process.env.VUE_APP_S3_PATH + "arrayelement/",
      json_data: null,
      side_cols: ["Summary", "Slides", "Experiments", "Array Designs"],
      loading: false,
      error: false,
      colIdxSelected: 0,
      entries: [],
      entryPosys: [],
      helpTexts: {},
    };
  },

  async mounted() {
    if (this.$route.query.id || this.$route.query.key || this.$route.query.accession) {
      let arrayName = null;
      this.loading = true;
      if (this.$route.query.key) {
        arrayName = this.$route.query.key;
      } else if(this.$route.query.id) {
        let id = this.$route.query.id;
        try {
          let response = await PostsService.getArrayElementById(id);
          arrayName = response.data.toLowerCase().replace(/\s+/g, "_");
        } catch (error) {
          console.error("Error fetching array element details: ", error);
          this.error = true;
          this.loading = false;
          return;
        }
      }else{
        let id = this.$route.query.accession;
        try {
          let response = await PostsService.getArrayElementByAccession(id);
          arrayName = response.data.toLowerCase().replace(/\s+/g, "_");
        } catch (error) {
          console.error("Error fetching array element details: ", error);
          this.error = true;
          this.loading = false;
          return;
        }
      }
      try {
        let response = await PostsService.getArrayElementDetail({
          key: arrayName,
        });
        this.json_data = response.data;

        this.loading = true;
        this.processEntries();
        this.loading = false;
        document.title = `Array Element Detail`;
      } catch (error) {
        console.error("Error fetching array element details: ", error);
        this.error = true;
        this.loading = false;
      }
    } else {
      this.error = true;
      this.loading = false;
    }
  },
  methods: {
    sideColClicked(colIdx) {
      this.scrollToElement(colIdx);
      this.colIdxSelected = colIdx;
    },
    processEntries() {
      this.side_cols.forEach((title) => {
        let entry = {
          title: title,
          name: title.replace(/\s/g, ""),
        };
        switch (title) {
          case "Summary":
            entry.type = "bands";
            entry.bands = this.fillSummary();
            break;
          case "Slides":
            entry.type = "bands";
            entry.bands = this.fillSlides();
            break;
          case "Experiments":
            entry.type = "bands";
            entry.bands = this.fillExperiments();
            break;
          case "Array Designs":
            entry.type = "bands";
            entry.bands = this.fillArrayDesigns();
            break;
          default:
            entry.type = "bands";
            entry.bands = [];
            break;
        }
        this.entries.push(entry);
      });
    },
    fillSummary() {
      let bands = [];
      bands.push({
        key: "Name",
        text: this.json_data.name,
      });
      bands.push({
        key: "Type",
        text: this.json_data.type,
      });
      bands.push({
        key: "Is a Control",
        text: this.json_data.isControl === "F" ? "No" : "Yes",
      });
      bands.push({
        key: "Sequence",
        text: this.json_data.sequence.sequenceName,
        type: "link",
        link: `/sequence?key=${this.json_data.sequence.sequenceId}`,
      });
      // bands.push({
      //   key: "Locus",
      //   text: this.json_data.locus.locusName,
      //   type: "link",
      //   link: `/locus?key=${this.json_data.locus.locusId}`,
      // });
      // bands.push({
      //   key: "Locus Description",
      //   text: this.json_data.locus.description,
      // });
      bands.push(this.getLoci());
      bands.push({
        key: "Organism",
        text:
          this.json_data.locus && this.json_data.locus[0]
            ? this.json_data.locus[0].organism
            : "No Data Available",
        helpTxt:
          "The taxonomic classification of organisms at the level of genus and species.",
      });
      bands.push({
        key: "Avg. Signal Intensity (Std. Error)",
        text: `${this.json_data.avgIntensity} (${this.json_data.avgIntensityStdError})`,
        helpTxt:
          " Average signal intensity value of the array element across all experiments stored in TAIR microarray database.",
      });
      return bands;
    },
    fillArrayDesigns() {
      let bands = [];
      bands.push(this.getArrayDesigns());
      return bands;
    },
    fillExperiments() {
      let bands = [];
      bands.push(this.getExperiments());
      return bands;
    },
    fillSlides() {
      let bands = [];
      bands.push(this.getSlides());
      return bands;
    },

    //Getters
    getLoci() {
      let entry = {
        key: "Loci",
        type: "table",
        items: [],
        fields: [],
      };
      let loci = this.json_data.locus;
      if (loci == null || loci.length == 0) {
        entry.type = "";
        entry.text = "No Loci available";
        return entry;
      }
      entry.fields = [
        {
          key: "name",
          label: "Name",
          cellType: "name_link",
        },
        {
          key: "description",
          label: "Description",
          cellType: "name_link",
        },
      ];
      //if loci is not an array, make it an array
      if (!Array.isArray(loci)) {
        loci = [loci];
      }
      loci.forEach((a) => {
        let item = {
          name: { name: a.locusName, link: `/locus?key=${a.locusId}` },
          description: { name: a.description },
        };
        entry.items.push(item);
      });
      if (entry.items.length > 1) {
        entry.count = entry.items.length;
      }
      return entry;
    },
    getArrayDesigns() {
      let entry = {
        key: "Array Designs",
        type: "table",
        items: [],
        fields: [],
      };
      let arrayDesigns = this.json_data.arrayDesigns;
      if (arrayDesigns == null || arrayDesigns.length == 0) {
        entry.type = "";
        entry.text = "No Array Designs available";
        return entry;
      }
      entry.fields = [
        {
          key: "name",
          label: "Name",
          cellType: "name_link",
        },
        {
          key: "manufacturer",
          label: "Manufacturer",
          cellType: "name_link",
        },
        {
          key: "type",
          label: "Type",
          cellType: "name_link",
        },
        {
          key: "elements",
          label: "Number of elements",
          cellType: "name_link",
        },
      ];
      arrayDesigns.forEach((a) => {
        let item = {
          name: { name: a.name },
          manufacturer: { name: a.manufacturer },
          type: { name: a.platformType },
          elements: { name: a.numSpots },
        };
        entry.items.push(item);
      });
      if (entry.items.length > 1) {
        entry.count = entry.items.length;
      }
      return entry;
    },
    getSlides() {
      let entry = {
        key: "Slides",
        type: "table",
        items: [],
        fields: [],
      };
      let slides = this.json_data.slides;
      if (slides == null || slides.length == 0) {
        entry.type = "";
        entry.text = "No slides available";
        return entry;
      }
      entry.fields = [
        {
          key: "name",
          label: "Slide Name",
          cellType: "name_link",
          helpTxt: "The name that identifies the slide.",
        },
        {
          key: "signal",
          label: "Signal (signal percentile)",
          cellType: "name_link",
          helpTxt:
            "Quantitative metric calculated for each probe set using Affymetrix software, which represents the relative level of expression of a transcript. Affymetrix Signal calculation algorithm uses the One-Step Tukey’s Biweight Estimate which yields a robust weighted mean that is relatively insensitive to outliers, even when extreme. Each probe pair in a probe set is considered as having a potential vote in determining the Signal value. The vote is defined as an estimate of the real signal due to hybridization of the target. The mismatch intensity is used to estimate stray signal. The real signal is estimated by taking the log of the Perfect Match intensity after subtracting the stray signal estimate. The probe pair vote is weighted more strongly if this probe pair Signal value is closer to the median value for a probe set. Once the weight of each probe pair is determined, the mean of the weighted intensity values for a probe set is identified. This mean value is corrected back to linear scale and is output as Signal.",
        },
        // {
        //   key: "replicate",
        //   label: "Replicate ID (name)",
        //   cellType: "name_link",
        //   helpTxt:
        //     "The number that uniquely identifies a replicate set from all others within the same experiment",
        // },
        {
          key: "experiment_name",
          label: "Experiment Name",
          cellType: "name_link",
          helpTxt:
            "A set of microarray hybridizations that together comprise a single experiment designed to test a given hypothesis.",
        },
        // {
        //   key: "experiment_cat",
        //   label: "Experiment Category",
        //   cellType: "name_link",
        //   helpTxt:
        //     "Classification of experiments based on the types of variables being tested (e.g., abiotic treatment)",
        // },
      ];
      slides.sort((a, b) => {
        // Compare experiment names
        if (a.experimentName < b.experimentName) return -1;
        if (a.experimentName > b.experimentName) return 1;
        // If experiment names are equal, compare names
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });
      slides.forEach((a) => {
        let linkName = a.experimentName.replace("AtGenExpress: ", "");
        let expLink = `https://tair-data.s3.us-west-2.amazonaws.com/prod/microarrayexperiments/${linkName}.html`;
        let item = {
          name: { name: a.name },
          signal: { name: `${a.signal} (${a.signalPercentile})` },
          experiment_name: { name: a.experimentName, link: expLink },
          // experiment_cat: {name: a.experimentCategory},
        };
        entry.items.push(item);
      });
      entry.count = entry.items.length;
      return entry;
    },
    getExperiments() {
      let entry = {
        key: "Experiments",
        type: "table",
        items: [],
        fields: [],
      };
      let experiments = this.json_data.experiments;
      if (experiments == null || experiments.length == 0) {
        entry.type = "";
        entry.text = "No Experiments available";
        return entry;
      }
      entry.fields = [
        {
          key: "name",
          label: "Name",
          cellType: "name_link",
        },
      ];
      experiments.forEach((a) => {
        let linkName = a.name.replace("AtGenExpress: ", "");
        let expLink = `https://tair-data.s3.us-west-2.amazonaws.com/prod/microarrayexperiments/${linkName}.html`;
        let item = {
          name: { name: a.name, link: expLink },
        };
        entry.items.push(item);
      });
      entry.count = entry.items.length;
      return entry;
    },
    dateToYMD(dateTs) {
      let date = new Date(dateTs);
      const d = date.getDate();
      const m = date.getMonth() + 1;
      const y = date.getFullYear();
      return `${y}-${m.toString().padStart(2, "0")}-${d
        .toString()
        .padStart(2, "0")}`;
    },
    //
    scrollToElement(colIdx) {
      const entryRef = this.$refs["entry" + colIdx];
      const el = entryRef[0].$el;
      if (el) {
        const top = el.offsetTop - 210;
        el.parentElement.scrollTo(0, top);
      }
    },
    handleScroll(e) {
      const scrollTop = e.target.scrollTop + 215;
      let currTopEntry = 0;
      this.entryPosys.forEach((e, i) => {
        if (scrollTop > e) {
          currTopEntry = i;
        }
      });
      this.colIdxSelected = currTopEntry;
    },
  },
};
</script>

<style scoped lang="scss">
.fixed-title {
  position: sticky;
  top: 0;
  background-color: #f1efec;
  z-index: 10;
  padding-left: 10px;
  padding-top: 10px;
}

.my-custom-scrollbar {
  position: relative;
  height: 200px;
  overflow: auto;
}
.table-wrapper-scroll-y {
  display: block;
}
</style>
