<template>
  <span :id="id">{{ value }}</span>
</template>

<script>
export default {
  name: "TextComponent",
  props: {
    id: String,
    value: String,
  },
};
</script>
