<template>
  <div class="side-wrapper">
    <ul class="list-group list-group-flush">
      <a
        v-for="(item, i) in downloadItems"
        :key="i"
        href=""
        class="list-group-item list-group-item-action flex-column align-items-start side-item"
        @click.prevent="sideColClicked(i)"
      >
        {{ item.title }}
      </a>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "DownloadSideBar",
  data() {
    return {
      downloadItems: [],
    };
  },
  created() {
    this.downloadItems = this.getDownloadItemsWithDescription;
  },
  computed: {
    ...mapGetters("downloadItems", ["getDownloadItemsWithDescription"]),
  },
  methods: {
    sideColClicked(index) {
      const route = this.downloadItems[index].route;

      // Check if the route is an external URL
      if (this.isExternal(route)) {
        // Open the external URL
        window.location.href = route;
      } else {
        // Use Vue Router for internal routes
        this.$router.push(route);
      }
    },

    // Utility method to check if a URL is external
    isExternal(url) {
      // A simple regex to test for "http://" or "https://" at the start of the URL
      // Adjust as needed for your use case
      const externalRegex = /^(https?|ftp|mailto)?:\/\//;
      return externalRegex.test(url);
    },
  },
};
</script>

<style scoped lang="scss"></style>
