import Cookies from "js-cookie";
import Api from "@/services/Api";

export async function getAuthProfile() {
  const { credentialId, secretKey } = getCredentialFromCookies();
  if (!credentialId) return null;
  try {
    // get list content from API
    const response = await Api().get("auth/profile", {
      params: {
        userId: credentialId,
      },
    });

    let profile = response.data;
    profile.credentialId = credentialId;
    profile.secretKey = secretKey;
    return profile;
  } catch (error) {
    return null;
  }
  return null;
}

export async function clearCookie() {
  //Clear the cookie
  Cookies.remove("credentialId");
  Cookies.remove("secretKey");
  const response = await Api().get("auth/logout");
  return response;
}

function getCredentialFromCookies() {
  const credentialId = Cookies.get("credentialId");
  const secretKey = Cookies.get("secretKey");
  return { credentialId, secretKey };
}
