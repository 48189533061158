import PostsService from "../PostsService";

export async function loadResults(search_params, type = "detail") {
  let fields = [
    { key: "row_num", label: " # " },
    { key: "selected", label: "" },
    {
      key: "name",
      label: "Transposon Name",
      cellType: "name_link",
    },
    {
      key: "familyName",
      label: "Family Name",
      cellType: "name_link",
    },
    {
      key: "superFamilyName",
      label: "Super Family Name",
      cellType: "name_link",
    },
  ];
  let items = [];
  try {
    let response = await PostsService.fetchTransposonSearch(search_params);
    if (response.data.error) {
      return { error: true, items: [], fields: [] };
    }
    let docs = response.data.docs;

    let totalMatch = response.data.total;

    for (const d of docs) {
      items.push({
        id: d.id,
        selected: false,
        name: {
          name: d.transposon_name[0],
          link: `/transposon?key=${d.transposonId}`,
        },
        familyName: {
          name: d.family_name ? d.family_name[0] : "",
          link: d.family_name
            ? `/transposonfamily?key=${d.transposonFamilyId}`
            : "",
        },
        superFamilyName: {
          name: d.super_family_name ? d.super_family_name[0] : "",
        },
        tairObjectId: d.tairObjectId ? d.tairObjectId : "",
      });
    }
    search_params.matchCount = totalMatch;
    let queryStatement = "";
    if (type === "general") {
      queryStatement = `Your query for transposons where name or transposon family name or transposon super family name contains the term <span class="highlight-term">${search_params.searchText}</span> resulted in <span style="color: green">${totalMatch}</span> matches`;
    } else {
      queryStatement = setQueryStatement(search_params);
    }
    return {
      error: false,
      items: items,
      fields: fields,
      queryStatement: queryStatement,
    };
  } catch (error) {
    return { error: true, items: [], fields: [] };
  }
}

function setQueryStatement(params) {
  let queryStatement = "Your query for transposons";
  let queryParts = [];
  let searchParts = [];
  params.searchTerms.forEach((s) => {
    if (s.input_name) {
      searchParts.push(
        `transposon name ${s.type_name} <span class="highlight-term">${s.input_name}</span>`
      );
    }
    if (s.input_family) {
      searchParts.push(
        `family name ${s.type_family} <span class="highlight-term">${s.input_family}</span>`
      );
    }
    if (s.input_super) {
      searchParts.push(
        `super family name ${s.type_super} <span class="highlight-term">${s.input_super}</span>`
      );
    }
  });
  if (searchParts.length > 0) {
    queryParts.push(searchParts.join(" and "));
  }
  if (queryParts.length > 0) {
    queryStatement += " where ";
    queryStatement += queryParts.join(" and ");
  }

  queryStatement += ` resulted in <span style="color: green">${params.matchCount}</span> matches`;
  return queryStatement;
}
