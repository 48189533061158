<template>
  <div>
    <b-breadcrumb :items="crumbLinks"></b-breadcrumb>
    <div class="d-flex">
      <h2>{{ pageTitle }}</h2>
      <b-link
        :href="eventssRssLink"
        target="_blank"
        v-b-tooltip.hover
        title="Subscribe to RSS Feed"
        class="ml-2"
      >
        <i class="fas fa-rss"></i>
      </b-link>
    </div>
    <p>
      Please send suggestions for details of conferences, meetings, etc. of
      interest to Arabidopsis folks to:
      <a href="mailto:curator@arabidopsis.org">curator@arabidopsis.org</a>
    </p>
    <div class="items-panel">
      <div v-for="(item, i) in events" :key="i" class="news-item">
        <div class="news-header">
          <a :href="item.uri" v-html="item.headline" target="_blank"></a>
        </div>
        <div v-html="`${item.address}, ${item.eventDates}`"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { getEvents } from "@/services/NewsService";

export default {
  name: "Events",
  data() {
    return {
      pageTitle: "Conferences and Events",
      events: [],
      crumbLinks: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "News",
          href: "/news/overview",
        },
        {
          text: "Conferences and Events",
          active: true,
        },
      ],
      eventssRssLink: `${process.env.VUE_APP_API_BASE_URL}/news/events/rss`,
    };
  },
  async mounted() {
    try {
      const response = await this.fetchEvents();
      this.events = response; // Update state with fetched data
    } catch (error) {
      console.error("Error fetching news:", error);
    }
  },
  methods: {
    async fetchEvents() {
      const response = await getEvents();
      return response;
    },
  },
};
</script>

<style scoped lang="scss"></style>
