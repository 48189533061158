import SearchView from "@/views/search/SearchView";
import SearchOverview from "@/views/search/SearchOverview";
import GeneSearch from "@/views/search/GeneSearch";
import DnaSearch from "@/views/search/DnaSearch";
import EcotypeSearch from "@/views/search/EcotypeSearch";
import GermplasmSearch from "@/views/search/GermplasmSearch";
import MarkerSearch from "@/views/search/MarkerSearch";
import PolyalleleSearch from "@/views/search/PolyalleleSearch";
import ProteinSearch from "@/views/search/ProteinSearch";
import PublicationSearch from "@/views/search/PublicationSearch";
import ProtocolSearch from "@/views/search/ProtocolSearch";
import TransposonSearch from "@/views/search/TransposonSearch";
import KeywordSearch from "@/views/search/KeywordSearch";
import CommunitySearch from "@/views/search/CommunitySearch";

export const searchRoutes = {
  path: "/search",
  name: "SearchView",
  component: SearchView,
  children: [
    {
      path: "overview",
      name: "SearchOverview",
      component: SearchOverview,
    },
    {
      path: "ecotype",
      name: "EcotypeSearch",
      component: EcotypeSearch,
    },
    {
      path: "genes",
      name: "GeneSearch",
      component: GeneSearch,
    },
    {
      path: "germplasm",
      name: "GermplasmSearch",
      component: GermplasmSearch,
    },
    {
      path: "markers",
      name: "MarkerSearch",
      component: MarkerSearch,
    },
    {
      path: "polyallele",
      name: "PolyalleleSearch",
      component: PolyalleleSearch,
    },
    {
      path: "keyword",
      name: "KeywordSearch",
      component: KeywordSearch,
    },
    {
      path: "protein",
      name: "ProteinSearch",
      component: ProteinSearch,
    },
    {
      path: "publication",
      name: "PublicationSearch",
      component: PublicationSearch,
    },
    {
      path: "dna",
      name: "DnaSearch",
      component: DnaSearch,
    },
    {
      path: "vector",
      name: "VectorSearch",
      component: DnaSearch,
    },
    {
      path: "community",
      name: "CommunitySearch",
      component: CommunitySearch,
    },
    {
      path: "protocol",
      name: "ProtocolSearch",
      component: ProtocolSearch,
    },
    {
      path: "transposon",
      name: "TransposonSearch",
      component: TransposonSearch,
    },
  ],
};
