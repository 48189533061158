import PostsService from "../PostsService";

export async function loadResults(search_params, type = "detail") {
  let fields = [
    { key: "row_num", label: " # " },
    { key: "selected", label: "" },
    {
      key: "germplasm",
      label: "Germplasm Name",
      cellType: "name_link",
    },
    {
      key: "polymorphism",
      label: "Polymorphism",
      cellType: "name_links",
    },
    {
      key: "locusList",
      label: "Locus",
      cellType: "name_links",
    },
    {
      key: "background",
      label: "Background",
      cellType: "name_link",
    },
    {
      key: "donor",
      label: "Donor/Originator",
      cellType: "name_link",
    },
    {
      key: "stock",
      label: "Stock Number/Type",
      cellType: "name_links_multi",
    },
  ];
  let items = [];
  try {
    let response = await PostsService.fetchGermplasmDetailSearch(search_params);
    if (response.data.error) {
      return { error: true, items: [], fields: [] };
    }
    let docs = response.data.docs;
    let totalMatch = response.data.total;
    //debug
    for (const d of docs) {
      let polymorphisms = [];

      if (d.polyList) {
        let alllist = [...new Set(d.polyList)];
        alllist.forEach((a) => {
          let splits = a.split(";");
          let entry = {
            name: splits[0],
            link: `/polyallele?key=${splits[1]}`,
          };
          polymorphisms.push(entry);
        });
      }
      let locusList = [];
      if (d.locusList) {
        d.locusList.forEach((a) => {
          let splits = a.split(";");
          if (splits[0] != "null") {
            let entry2 = { name: splits[0], link: `/locus?key=${splits[1]}` };
            locusList.push(entry2);
          }
        });
      }
      let background = null;
      if (d.bgEcotypes && d.bgEcotypes.length > 0) {
        let splits = d.bgEcotypes[0].split(";");
        background = {
          name: splits[0],
          link: `/ecotype?key=${splits[1]}`,
        };
      }
      let phenotypeText = "";
      if (d.phenotypes) {
        phenotypeText = `<p class="text-left"><b>Phenotype: </b>${d.phenotypes.join(
          ";"
        )}</p>`;
      }
      let donor = { name: "" };
      if (d.donors && d.donors.length > 0) {
        let splits = d.donors[0].split(";");
        donor = { name: splits[0], link: `/person?key=${splits[1]}` };
      }
      let stock = [
        {
          name: d.stockNo,
          externalLink: `https://abrc.osu.edu/stocks/number/${d.stockNo}`,
        },
        {
          name: d.germplasmType,
        },
      ];
      let item = {
        id: d.id,
        selected: false,
        germplasm: {
          name: d.name[0],
          link: `/germplasm?key=${d.tairObjectId}`,
        },
        donor: donor,
        stock: stock,
        polymorphism: polymorphisms,
        locusList: locusList,
        originalDoc: d,
        html: phenotypeText,
        _showDetails: phenotypeText != "" ? true : false,
      };
      if (background) {
        item.background = background;
      } else {
        item.background = { name: "" };
      }
      items.push(item);
    }
    search_params.matchCount = totalMatch;

    let queryStatement = "";
    if (type === "general") {
      queryStatement = `Your query for any germplasms where germplasm name contains the term <span class="highlight-term">${search_params.searchText}</span> resulted in <span style="color: green">${totalMatch}</span> matches`;
    } else {
      queryStatement = setQueryStatement(search_params);
    }
    return {
      error: false,
      items: items,
      fields: fields,
      queryStatement: queryStatement,
    };
  } catch (error) {
    return { error: true, items: [], fields: [] };
  }
}
function setQueryStatement(params) {
  const typeSearchByMap = {
    any: "any germplasms",
    individual_line: "individual lines",
    individual_pool: "individual pools",
    set_of_lines: "sets of lines",
    set_of_pools: "sets of pools",
  };

  let queryStatement = `Your query for germplasms where `;
  let queryParts = [];
  let searchTypeParts = [];
  params.searchType &&
    params.searchType.forEach((search, index) => {
      if (search.type_search_by) {
      } else {
        searchTypeParts.push(
          `<span class="highlight-term">${
            typeSearchByMap[search.type_search_by]
          }</span>`
        );
      }
    });
  if (searchTypeParts.length > 0) {
    queryParts.push(
      searchTypeParts.join(searchTypeParts.length > 1 ? " and " : "")
    );
  }

  let searchTermParts = [];
  params.searchTerms &&
    params.searchTerms.forEach((search, index) => {
      if (search.search_input) {
        searchTermParts.push(
          `${search.search_type} ${search.search_method} <span class="highlight-term">${search.search_input}</span>`
        );
      } else if (search.species_search_by) {
        searchTermParts.push(`species is ${search.species_search_by}`);
      }
    });
  if (searchTermParts.length > 0) {
    queryParts.push(
      searchTermParts.join(searchTermParts.length > 1 ? " and " : "")
    );
  }

  let alleleParts = [];
  params.searchAlleles &&
    params.searchAlleles.forEach((search, index) => {
      if (search.search_input) {
        let prefix = `allele name <span class="highlight-term">${search.search_method}</span> <span class="highlight-term">${search.search_input}</span>`;
        if (search.search_type) {
          prefix += ` with <span class="highlight-term">${search.search_type}</span> genotype`;
        }
        alleleParts.push(prefix);
      }
    });
  if (alleleParts.length > 0) {
    queryParts.push(alleleParts.join(alleleParts.length > 1 ? " and " : ""));
  }

  let restrictParts = [];
  params.restrictByFeatures &&
    params.restrictByFeatures.forEach((feat) => {
      for (let key in feat) {
        restrictParts.push(
          `${key} is <span class="highlight-term">${feat[key]}</span>`
        );
      }
    });

  if (restrictParts.length > 0) {
    queryParts.push(
      restrictParts.join(restrictParts.length > 1 ? " and " : "")
    );
  }

  let mapParts = [];
  params.mapLocation &&
    params.mapLocation.forEach((map) => {
      if (map.chromosome) {
        mapParts.push(
          `chromosome is <span class="highlight-term">${map.chromosome}</span>`
        );
      }
      if (map.high_range && map.low_range) {
        mapParts.push(
          `AGI map location is between <span class="highlight-term">${map.low_range}</span> kb and <span class="highlight-term">${map.high_range}</span> kb`
        );
      }
    });

  if (mapParts.length > 0) {
    queryParts.push(mapParts.join(mapParts.length > 1 ? " and " : ""));
  }
  // console.log(params);
  let locusParts = [];
  params.locusList &&
    params.locusList.forEach((loc) => {
      locusParts.push(
        `locus AGI is <span class="highlight-term">${loc}</span>`
      );
    });

  if (locusParts.length > 0) {
    queryParts.push(locusParts.join(locusParts.length > 1 ? " and " : ""));
  }

  queryStatement += queryParts.join(queryParts.length > 1 ? " and " : " ");
  queryStatement += ` resulted in <span style="color: green">${params.matchCount}</span> matches`;
  return queryStatement;
}
