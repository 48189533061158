<template>
  <div class="overview-wrapper">
    <NewsSideBar></NewsSideBar>
    <div class="content-wrapper">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import NewsSideBar from "./NewsSideBar.vue";

export default {
  name: "NewsView",
  components: {
    NewsSideBar,
  },
};
</script>

<style scoped lang="scss">
.overview-wrapper {
  display: flex;
  background-color: #f1efec;
  border: 1px solid #e0e0e0;
  padding: 20px;
  .breadcrumb {
    padding: 10px 0;
    font-weight: bold;
    font-size: 18px;
  }
}
</style>
