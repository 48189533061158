<template>
  <div class="w-full">
    <div class="d-flex justify-content-start">
      <span class="font-weight-bold text-left">{{ title }}</span>
    </div>
    <div class="d-flex justify-content-start" v-if="!data || data.length === 0">
      <span class="text-left">No data available</span>
    </div>
    <div class="d-flex justify-content-start" v-for="(p, l) in data" :key="l">
      <div class="d-flex flex-column">
        <span v-if="p.title && !p.link" class="text-left">{{ p.title }}</span>
        <span v-if="p.title && p.link" class="text-left">
          <link-wrapper :link="p.link" :name="p.title" />
        </span>
        <ul>
          <li
            v-for="(point, j) in p.points"
            :key="j"
            class="text-left"
            v-html="point"
          ></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import LinkWrapper from "../common/LinkWrapper.vue";
export default {
  components: { LinkWrapper },
  mounted() {},
  props: ["data", "title"],
};
</script>
