<template>
  <b-modal
    ref="microarrayModal"
    title="Select Options"
    @hide="resetSelections"
    :hide-footer="true"
  >
    <!-- <b-form-group label="Select Option:">
		<b-form-select
		  v-model="selectedOption"
		  :options="options"
		  :value="options[0].value"
		></b-form-select>
	  </b-form-group> -->

    <b-form-group label="Search Against:">
      <b-form-radio-group
        v-model="selectedFormat"
        :options="formats"
        :value="formats[0].value"
      ></b-form-radio-group>
    </b-form-group>

    <b-button variant="primary" @click="submitSelection">Submit</b-button>
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      formats: [
        {
          value: "AFFY_ATH1",
          text: "Affymetrix ATH1",
        },
        {
          value: "catma",
          text: "CATMA",
        },
        {
          value: "Agilent-G2519F-021169",
          text: "Agilent (G2519F-021169)",
        },
      ],
      selectedFormat: "AFFY_ATH1",
    };
  },
  methods: {
    show() {
      this.$refs.microarrayModal.show();
    },
    hide() {
      this.$refs.microarrayModal.hide();
    },
    resetSelections() {
      //   this.selectedOption = null;
      //   this.selectedFormat = null;
    },
    submitSelection() {
      this.$emit("submit", {
        format: this.selectedFormat,
      });
      this.hide();
    },
  },
};
</script>
