<template>
  <div>
    <div v-if="!error" class="detail-wrapper">
      <div class="side-wrapper">
        <ul class="list-group list-group-flush">
          <a
            v-for="(col, i) in side_cols"
            :key="i"
            href="#"
            class="list-group-item list-group-item-action flex-column align-items-start side-item"
            v-bind:class="{ selected: i == colIdxSelected }"
            @click="sideColClicked(i)"
          >
            {{ col }}
          </a>
        </ul>
      </div>
      <div class="list-wrapper" v-on:scroll.passive="handleScroll">
        <div
          v-if="loading"
          class="d-flex justify-content-center align-items-center"
          style="height: 100%"
        >
          <b-spinner label="Loading..."></b-spinner>
        </div>
        <div
          v-if="json_data"
          class="text-left m-2 font-weight-bold fixed-title"
        >
          <h4>
            Germplasm:
            {{
              json_data.originalName ? json_data.originalName : json_data.name
            }}
          </h4>
        </div>
        <BaseEntry
          v-for="(e, i) in entries"
          :selectedColIdx="colIdxSelected"
          :currIdx="i"
          :key="i"
          :content="e"
          :ref="`entry` + i"
        ></BaseEntry>
      </div>
    </div>
    <div v-else>
      <PageNotFound />
    </div>
  </div>
</template>

<script>
//http://localhost:8082/germplasm?key=3510701934
import BaseEntry from "@/components/detail/BaseEntry";
import PostsService from "@/services/PostsService";
import PageNotFound from "@/components/common/PageNotFound";

export default {
  name: "GermplasmDetail",
  components: {
    BaseEntry,
    PageNotFound,
  },
  data() {
    return {
      base_url: process.env.VUE_APP_S3_PATH + "germplasm/",
      json_data: null,
      side_cols: ["Summary", "Stocks", "Pedigree"],
      loading: false,
      error: false,
      colIdxSelected: 0,
      entries: [],
      entryPosys: [],
      helpTexts: {
        stockInfo: `<b>Stock Information</b>: Links to information for seed stocks associated to a germplasm or polymorphism and DNA stocks linked to clones and other data. For example, a T-DNA insertion polymorphism that is found in a specific germplasm, this links to the ABRC stock record for that germplasm.This can be used to select stocks to order directly or to link to the stock detail page to find more information about the stock.`,
        stockAvailabality: `<b>Availability:</b> Indicates if the stock is available to order from the ABRC.
<ul>
  <li><b>available:</b> The stock is available for ordering.</li>
  <li><b>not available:</b> The stock is not currently available for ordering.</li>
  <li><b>not yet received:</b> The stock information has been submitted to the ABRC but the stock itself has not yet been received at the ABRC.</li>
  <li><b>no longer distributed:</b> The stock record is maintained but the stock itself is no longer distributed by the ABRC.</li>
</ul>
`,
        taxon: `<b>Taxon</b>: The taxonomic classification of organisms at the level of genus and species.`,
        pedigree: `<b>Pedigree</b>: The lineage or record of ancestors of a germplasm.`,
        parentLine: `<b>parent line</b>: The parent of the current germplasm entity. This links to the germplasm details for the parental line.`,
        ploidy: `<b>ploidy</b>: A term referring to the number of chromosome sets per cell.`,
        isAnepluid: `<b>is aneuploid</b>: Used to distinguish between plants that are euploids (containing a complete set of chromosomes) and aneuploids (having additions or deletions of entire chromosomes).`,
        mutagen: `<b>Mutagen:</b> A chemical or physical agent that causes a heritable alteration in the genome.
<ul>
  <li><b>HZE Ne:</b> Radioactive particles of high energy and atomic number.</li>
  <li><b>HZE U:</b> Radioactive particles of high energy and atomic number.</li>
  <li><b>Spontaneous:</b> Mutations that occur in the absence of treatment with a chemical or biological mutagen. Usually refers to mutations in natural populations.</li>
  <li><b>T-DNA insertion:</b> A physical mutagen that causes mutations by insertion of transfer DNA (with or without additional DNA sequences) into the genome. These tend to be large insertions in the kilobase range and thus are expected to knockout the gene it is inserted in.</li>
  <li><b>Transposon insertion:</b> Transposable elements (transposons) include a diverse class of DNA sequences that are capable of inserting, excising, and relocating into chromosomal or extrachromosomal DNA. Autonomous transposons encode a transposase and are capable of transposing on their own. Transposition of non-autonomous elements requires trans-activation from the autonomous element. Insertion of a transposable element into a gene may create a knock-out, loss of function allele.</li>
  <li><b>X-rays:</b> Electromagnetic radiation emitted by atoms during extranuclear loss of energy of incident radiation or by atoms if certain radionucleotides during transformation of electron capture. X rays have wavelengths in the range of 1 picometer to 10 nanometers. X rays can induce many types of mutations such as deletions and chromosomal rearrangements.</li>
</ul>`,
        cloneName: `<b>Name:</b> Clones are often named with the acronym of the vector followed by the plate and row numbers of the isolated clone. For example, CIC (YAC), T (TAMU BAC), F (IGF BAC) are some common vector acronyms.`,
        constructorType: `<b>Construct Type:</b> Types of constructs used in genetic cloning and their applications.

<ul>
  <li><b>Activation Tag:</b> A DNA construct with multiple enhancers to activate gene expression. When inserted into a genome, it activates adjacent genes, creating a dominant mutation.</li>
  <li><b>Antisense:</b> A construct where a gene is fused to a promoter in a reverse orientation. The transcript produced suppresses the expression of the endogenous gene, primarily used in plants.</li>
  <li><b>Cre-lox Recombination:</b> DNA constructs with the Cre recombinase gene or lox sites. Used for directed recombination, creating deletions or chromosomal rearrangements in plants.</li>
  <li><b>Enhancer Trap:</b> A construct with a reporter gene and a minimal promoter. It helps identify regulatory elements controlling gene expression patterns.</li>
  <li><b>Gene Trap:</b> Constructs with a reporter gene downstream of a splice acceptor site. They fuse transcriptionally with a host gene, mirroring its expression pattern.</li>
  <li><b>Over Expression:</b> A construct where a gene is fused to a promoter for high-level expression.</li>
  <li><b>Promoter Fusion:</b> A construct where a promoter from one gene drives expression of another. The promoter and gene can be from different organisms.</li>
  <li><b>Promoter Reporter:</b> A construct where a gene's promoter is fused to a reporter gene, like fluorescent proteins or GUS.</li>
  <li><b>Promoter Trap:</b> A construct with a reporter gene but no promoter. If inserted into a gene, the reporter expression is controlled by the plant's endogenous promoter.</li>
  <li><b>RNAi:</b> RNA interference constructs silence target genes to analyze mutant phenotypes. They contain inverted repeat sequences from the target gene.</li>
  <li><b>Simple Insert:</b> Basic constructs for tagging, containing selectable markers and T-DNA or transposon sequences.</li>
  <li><b>Unknown:</b> Used when specific construct information is unavailable.</li>
</ul>`,
        vectorName: `<b>name</b>: The name of the vector.`,
        phenotype: `phenotype: Any detectable manifestation of the genotype of an organism.`,
        addInfo: `description: A brief description of the germplasm.`,
        publications: `Publication: Publications in TAIR come from a variety of sources including PubMed, Biosis and Agiricola. Publications are selected from these databases based on a search for the term "Arabidopsis". Publications may also be included as they include information about homologous genes in other organisms, or because they contain relevant experimental methods.`,
        growthCond: `Special Growth Conditions: A description of any special requirements for growth and propagation of a strain.`,
        stockNo: `Stock: DNA or seed stocks from the Arabidopsis Biological Resource Center. These include individual lines, sets of lines, pools, pool sets, and a variety of DNA stocks such as clones, libraries, filters, pooled genomic DNA and more.`,
        type: `<b>Type: TAIR's Classification of Germplasm Types</b>
<ul>
  <li><b>Individual Line:</b> A lineage derived from a single ancestral line. Propagation occurs through single seed descent or by bulking seeds from sibling plants.</li>
  <li><b>Individual Pool:</b> A germplasm collection from multiple individuals, combined into a single unit.</li>
  <li><b>Set of Lines:</b> A collection of germplasm originating from individual plants.</li>
  <li><b>Set of Pools:</b> A collection of germplasm pools, each containing genetic material from multiple individuals.</li>
</ul>`,
        attribution: `Attribution: Associations to a member of TAIR's community who supplied information, materials, or a specific piece of data. It can be used to identify the person or organization from which additional information or specific materials can be obtained. Each attribution is linked to a record for that community member, from which you can obtain their contact information.`,
        dateLastModified: `date last modified: The date this record was last updated or changed in any way.`,
        comments: `Comments: Comments displayed include comments contributed by TAIR curators or other members of the community. Comments are associated to particular data on the data detail pages. Registered users can submit comments on any detail page. The comments are then displayed along with the name of the contributor. Comments are reviewed by curators who may perform updates or corrections to data based upon the contributors comments.`,
        polymorphisms: `<b>Associated Polymorphisms</b>: Links to polymorphisms associated to the displayed data. For example, alleles found in germplasms or all polymorphisms detected with a single genetic marker.`,
        polyName: `<b>name</b>: The name of a polymorphism such as an allele name, or an alphanumeric identifying code (e.g. for SALK T-DNA insertions or TILLing lines) or the name of the marker used to detect the polymorphism.`,
        polyLocus: `<b>Locus</b>: In TAIR a locus is a mapped element that corresponds to a transcribed region in Arabidopsis genome, or a genetic locus that segregates as a single genetic locus or quantitative trait. Loci are mapped based upon sequence match, or by recombination frequencies. A locus can have one or more associated gene models such as alternatively spliced variants.`,
        polyMutagen: `<b>mutagen</b>: The physical or chemical agent used to cause a mutation.`,
        polyInheritnce: `<b>Inheritance:</b> Refers to the genotype's impact on phenotypic character for any given allele.

<ul>
  <li><b>Dominant:</b> An allele that expresses the same character in both homozygous and heterozygous states.</li>
  <li><b>Incompletely Dominant:</b> An allele combination in the heterozygous state that produces a distinct phenotype, different from both the dominant and recessive homozygote phenotypes. Also known as semi-dominant.</li>
  <li><b>Recessive:</b> An allele that does not affect the phenotype when paired with a dominant allele of the same gene in a diploid organism.</li>
</ul>`,
      },
    };
  },

  async mounted() {
    this.loading = true;
    let germplasmId = this.$route.query.key; // Adjust the key as per your routing setup
    if (germplasmId) {
      try {
        let response = await PostsService.getGermplasmDetail({
          key: germplasmId,
        });
        this.json_data = response.data;

        this.loading = true;
        // this.side_cols = Object.keys(this.json_data.germplasm);
        this.processEntries();
        this.loading = false;
        document.title = `Germplasm: ${this.json_data.originalName}`;
      } catch (error) {
        console.error("Error fetching germplasm details: ", error);
        this.loading = false;
        this.error = true;
      }
    } else {
      this.loading = false;
      this.error = true;
    }
  },
  methods: {
    sideColClicked(colIdx) {
      this.scrollToElement(colIdx);
      this.colIdxSelected = colIdx;
    },
    processEntries() {
      this.side_cols.forEach((title) => {
        let entry = {
          title: title,
          name: title.replace(/\s/g, ""),
        };
        switch (title) {
          case "Summary":
            entry.type = "bands";
            entry.bands = this.fillSummary();
            break;
          case "Stocks":
            entry.type = "bands";
            entry.bands = this.fillStocks();
            break;
          case "Pedigree":
            entry.type = "bands";
            entry.bands = this.fillPedigree();
            entry.helpTxt = this.helpTexts.pedigree;
            break;
          default:
            entry.type = "bands";
            entry.bands = [];
            break;
        }
        this.entries.push(entry);
      });
    },
    fillSummary() {
      let bands = [];
      bands.push({
        key: "Name",
        text: this.json_data.name,
      });
      bands.push(this.getOtherNames());
      bands.push({
        key: "Taxon",
        text: this.json_data.scientificName,
        helpTxt: this.helpTexts.taxon,
      });
      bands.push({
        key: "Type",
        text: this.json_data.germplasmType,
      });
      bands.push({
        key: "TAIR Accession",
        text: "Germplasm:" + this.json_data.tairObjectId,
      });
      bands.push(this.getAccessions());

      bands.push({
        key: "Date Last Modified",
        text: this.dateToYMD(this.json_data.dateLastModified),
      });
      bands.push({
        key: "Additional Information",
        text: this.json_data.description,
      });
      bands.push({
        key: "Special Growth Conditions",
        text: this.json_data.specialGrowthConditions
          ? this.json_data.specialGrowthConditions
          : "No Special Growth Conditions available",
      });
      bands.push(this.getConstitution());
      bands.push(this.getPolymorphisms());
      bands.push(this.getPhenotypes());
      bands.push(this.getConstructs());
      bands.push({
        key: "Mutagen Applied to Strain",
        text: this.json_data.mutagen ? this.json_data.mutagen : "N/A",
        helpTxt: this.helpTexts.mutagen,
      });
      bands.push(this.getImages());
      bands.push(this.getPublications());
      bands.push(this.getAttributions());
      bands.push(this.getComments());
      return bands;
    },
    fillPedigree() {
      let bands = [];
      bands.push(this.getParentLines());
      bands.push(this.getProgenyLines());
      return bands;
    },
    fillStocks() {
      let bands = [];
      bands.push(this.getStocks());
      bands.push(this.getStockEntries());
      bands.push(this.getMemberStockEntries());
      return bands;
    },
    //Getters
    getAccessions() {
      let entry = {
        key: "Accession",
        type: "table",
        items: [],
        fields: [],
      };
      let accessions = this.json_data.accessions;
      if (accessions == null || accessions.length == 0) {
        entry.type = "";
        entry.text = "No Accessions available";
        return entry;
      }

      entry.fields.push({
        key: "name",
        label: "Name",
        cellType: "name_link",
      });
      entry.fields.push({
        key: "abbrev",
        label: "Abbreviated Name",
        cellType: "name_link",
      });
      entry.fields.push({
        key: "country",
        label: "Country",
        cellType: "name_link",
      });
      entry.fields.push({
        key: "location",
        label: "Location",
        cellType: "name_link",
      });

      accessions.forEach((l) => {
        let item = {};
        item.name = {
          name: l.originalName,
          link: `/ecotype?key=${l.speciesVariantId}`,
        };
        item.country = { name: l.country };
        item.location = {
          name: l.location,
        };
        item.abbrev = {
          name: l.abbrevName,
        };
        entry.items.push(item);
      });

      return entry;
    },
    getOtherNames() {
      let entry = {
        key: "Other Names",
        type: "table",
        items: [],
        fields: [],
      };
      let otherNames = this.json_data.otherNames;
      if (otherNames == null || otherNames.length == 0) {
        entry.type = "";
        entry.text = "No Other Names available";
        return entry;
      }

      entry.fields.push({
        key: "alias",
        label: "Alias",
        cellType: "name_link",
      });
      entry.fields.push({
        key: "note",
        label: "Note",
        cellType: "name_link",
      });

      otherNames.forEach((l) => {
        let item = {};
        item.alias = {
          name: l.originalAlias,
        };
        item.note = { name: l.note };
        entry.items.push(item);
      });

      return entry;
    },
    getConstitution() {
      let entry = {
        key: "Chromosomal Constitution",
        type: "table",
        items: [],
        fields: [],
      };
      entry.fields.push({
        key: "aneuploide",
        label: "Is Aneuploid",
        cellType: "name_link",
        helpTxt: this.helpTexts.isAnepluid,
      });
      entry.fields.push({
        key: "ploidy",
        label: "Ploidy",
        cellType: "name_link",
        helpTxt: this.helpTexts.ploidy,
      });
      let item = {};
      if (this.json_data.aneuploid) {
        item.aneuploide = { name: "Yes" };
      } else {
        item.aneuploide = { name: "No" };
      }
      if (this.json_data.ploidy) {
        item.ploidy = { name: this.json_data.ploidy };
      }
      entry.items.push(item);

      return entry;
    },
    getParentLines() {
      let entry = {
        key: "Parent Lines",
        type: "table",
        items: [],
        fields: [],
      };
      let lines = this.json_data.pedigree
        ? this.json_data.pedigree.parentLines
        : null;
      if (lines == null || lines.length == 0) {
        entry.type = "";
        entry.text = "No Parent Lines available";
        return entry;
      }

      entry.fields.push({
        key: "parent_line",
        label: "Parent Line",
        cellType: "name_link",
        helpTxt: this.helpTexts.parentLine,
      });
      entry.fields.push({
        key: "background",
        label: "Background",
        cellType: "name_link",
      });
      entry.fields.push({
        key: "parent_gender",
        label: "Parent Gender",
        // cellType: "name_link",
      });
      entry.fields.push({
        key: "loci",
        label: "Parent Locus Associations",
        cellType: "name_link",
      });

      lines.forEach((l) => {
        let item = {};
        item.parent_line = {
          name: l.originalName,
          link: "/germplasm?key=" + l.parentTairObjectId,
        };
        item.loci = { name: l.locusName, link: "/locus?key=" + l.locusId };
        item.parent_gender = l.parentGender;
        item.background = {
          name: l.abbrevName,
          link: `/ecotype?key=${l.speciesVariantId}`,
        };
        entry.items.push(item);
      });

      return entry;
    },
    getProgenyLines() {
      let entry = {
        key: "Progeny Lines",
        type: "table",
        items: [],
        fields: [],
      };
      let lines = this.json_data.pedigree
        ? this.json_data.pedigree.progenyLines
        : null;
      if (lines == null || lines.length == 0) {
        entry.type = "";
        entry.text = "No Progeny Lines available";
        return entry;
      }

      entry.fields.push({
        key: "progeny_line",
        label: "Progeny Line(s)",
        cellType: "name_link",
      });
      entry.fields.push({
        key: "background",
        label: "Background",
        cellType: "name_link",
      });
      lines.splice(0, 1000).forEach((l) => {
        let item = {};
        item.progeny_line = {
          name: l.parentName,
          link: "/germplasm?key=" + l.parentTairObjectId,
        };
        item.background = {
          name: l.abbrevName,
          link: `/ecotype?key=${l.speciesVariantId}`,
        };
        entry.items.push(item);
      });
      entry.count = lines.length;
      return entry;
    },
    getPolymorphisms() {
      let entry = {
        key: "Associated Polymorphisms",
        type: "table",
        items: [],
        fields: [],
        helpTxt: this.helpTexts.polymorphisms,
      };
      if (!this.json_data.polymorphisms) {
        entry.type = "";
        entry.text = "No polymorphisms available";
        return entry;
      }
      entry.fields.push({
        key: "name",
        label: "Name",
        cellType: "name_link",
        helpTxt: this.helpTexts.polyName,
      });
      entry.fields.push({
        key: "locus",
        label: "Locus",
        cellType: "name_link",
        helpTxt: this.helpTexts.polyLocus,
      });
      entry.fields.push({
        key: "genes",
        label: "Gene Name(s) and/or Equivalents",
        cellType: "link_list",
      });
      entry.fields.push({
        key: "genotype",
        label: "Genotype",
        cellType: "name_link",
      });
      entry.fields.push({
        key: "mutagen",
        label: "Allele Mutagen",
        cellType: "name_link",
        helpTxt: this.helpTexts.polyMutagen,
      });
      entry.fields.push({
        key: "inheritance",
        label: "Inheritance",
        cellType: "name_link",
        helpTxt: this.helpTexts.polyInheritnce,
      });

      let polyGroupings = {};
      this.json_data.polymorphisms.forEach((d) => {
        if (!polyGroupings[d.polymorphismId]) {
          polyGroupings[d.polymorphismId] = [];
        }
        polyGroupings[d.polymorphismId].push(d);
      });

      Object.keys(polyGroupings).forEach((p) => {
        let item = {};
        item.name = {
          name: polyGroupings[p][0].originalName,
          link: "/polyallele?key=" + polyGroupings[p][0].polymorphismId,
        };
        item.locus = {
          name: polyGroupings[p][0].locusName,
          link: "/locus?key=" + polyGroupings[p][0].locusId,
        };
        let geneGroupings = {};
        polyGroupings[p].forEach((p) => {
          if (!p.geneId) {
            return;
          }
          if (!geneGroupings[p.geneId]) {
            geneGroupings[p.geneId] = [];
          }

          geneGroupings[p.geneId].push({
            geneName: p.geneName,
            fullName: p.fullName,
          });
        });

        let genes = [];
        Object.keys(geneGroupings).forEach((key) => {
          let g = geneGroupings[key];
          let fullNames = g
            .filter((f) => f.fullName != null)
            .map((f) => f.fullName);
          //remove duplicates
          fullNames = [...new Set(fullNames)];
          let geneName = `${g[0].geneName}`;
          if (fullNames.length > 0) {
            geneName += ` (${fullNames.join("/ ")})`;
          }
          genes.push({
            name: geneName,
            link: "/gene?key=" + key,
          });
        });
        item.genes = {
          link_list: genes,
        };

        item.genotype = { name: polyGroupings[p][0].genotype };
        item.mutagen = { name: polyGroupings[p][0].mutagen };
        item.inheritance = { name: polyGroupings[p][0].inheritance };
        entry.items.push(item);
      });

      entry.count = entry.items.length;
      return entry;
    },
    getConstructs() {
      let entry = {
        key: "Associated Constructs",
        type: "table",
        items: [],
        fields: [],
      };
      entry.fields.push({
        key: "name",
        label: "Clone Name",
        cellType: "name_link",
        helpTxt: this.helpTexts.cloneName,
      });
      entry.fields.push({
        key: "type",
        label: "Construct Type",
        cellType: "name_link",
        helpTxt: this.helpTexts.constructorType,
      });
      entry.fields.push({
        key: "vector",
        label: "Vector Name",
        cellType: "name_link",
        helpTxt: this.helpTexts.vectorName,
      });
      if (this.json_data.construct == null) {
        entry.type = "";
        entry.text = "No constructs available";
        return entry;
      }
      this.json_data.construct.forEach((c) => {
        let item = {};
        item.name = {
          name: c.name,
          link: "/clone?key=" + c.cloneId,
        };
        item.type = { name: c.constructType };
        item.vector = {
          name: c.vectorId ? c.originalName : "",
          link: c.vectorId ? "/vector?key=" + c.vectorId : "",
        };
        entry.items.push(item);
      });
      return entry;
    },
    getStocks() {
      let entry = {
        key: "Stock Information",
        type: "table",
        items: [],
        fields: [],
        buttons: [],
        helpTxt: this.helpTexts.stockInfo,
      };
      entry.fields.push({
        key: "availability",
        label: "Availability",
        cellType: "name_link",
        helpTxt: this.helpTexts.stockAvailabality,
      });
      entry.fields.push({
        key: "donors",
        label: "Donors",
        cellType: "link_list",
      });
      entry.fields.push({
        key: "stockNo",
        label: "Donor Stock #",
        cellType: "name_link",
      });
      let stock = this.json_data.stock;
      if (stock == null || stock.length == 0) {
        entry.type = "";
        entry.text =
          "Search at the stock centers or contact an author of publication(s) describing this line.";
        return entry;
      }
      let item = {};
      item.availability = {
        name: stock.stockAvailabilityType,
      };

      let donors = [];
      stock.donors.forEach((d) => {
        donors.push({
          name: d.firstName + " " + d.lastName,
          link:
            d.communityType == "person"
              ? `/person?key=${d.communityId}`
              : `/organization?key=${d.communityId}`,
        });
      });
      item.donors = { link_list: donors };
      item.stockNo = {
        name: stock.donors.length > 0 ? stock.donors[0].donorStockNumber : "",
      };
      entry.items.push(item);

      if (stock.stockAvailabilityType == "available") {
        entry.buttons.push({
          name: "Order from ABRC",
          url: "https://abrc.osu.edu/stocks/number/" + stock.name,
        });
      }

      return entry;
    },
    getStockEntries() {
      let entry = {
        key: "Stocks Included in Set / Pool",
        type: "table",
        items: [],
        fields: [],
      };
      let entries = this.json_data.stockEntries;
      if (entries == null || entries.length == 0) {
        entry.type = "";
        entry.text = "No stock entries available";
        return entry;
      }
      entry.fields.push({
        key: "name",
        label: "Stock #",
        cellType: "name_link",
        helpTxt: this.helpTexts.stockNo,
      });
      entry.fields.push({
        key: "type",
        label: "Type",
        cellType: "name_link",
      });
      entries.forEach((e) => {
        let item = {};
        item.name = {
          name: e.stockName,
          link: `/germplasm?key=${e.tairObjectId}`,
        };
        item.type = { name: e.stockType };
        entry.items.push(item);
      });
      entry.count = entries.length;
      return entry;
    },
    getMemberStockEntries() {
      let entry = {
        key: "Member of Set / Pool",
        type: "table",
        items: [],
        fields: [],
      };
      let entries = this.json_data.memberStockEntries;
      if (entries == null || entries.length == 0) {
        entry.type = "";
        entry.text = "No stock entries available";
        return entry;
      }
      entry.fields.push({
        key: "name",
        label: "Stock #",
        cellType: "name_link",
        helpTxt: this.helpTexts.stockNo,
      });
      entry.fields.push({
        key: "type",
        label: "Stock Type",
        cellType: "name_link",
      });
      entries.forEach((e) => {
        let item = {};
        item.name = {
          name: e.stockName,
          link: `/germplasm?key=${e.tairObjectId}`,
        };
        item.type = { name: e.stockType };
        entry.items.push(item);
      });
      entry.count = entries.length;
      return entry;
    },
    getImages() {
      let entry = {
        key: "Images",
        type: "img_list",
        items: [],
      };
      let images = this.json_data.images;
      if (images == null || images.length == 0) {
        entry.type = "";
        entry.text = "No images available";
        return entry;
      }
      images.forEach((i) => {
        entry.items.push({
          full_url: `${process.env.VUE_APP_OLD_TAIR_URL}/jsp/common/image.jsp?id=${i.imageId}&format=jpg`,
          img_url: `${process.env.VUE_APP_OLD_TAIR_URL}/servlets/images/thumb_${i.imageId}.jpg`,
        });
      });
      return entry;
    },
    getPhenotypes() {
      let entry = {
        key: "Phenotypes",
        type: "multiline",
        items: [],
        fields: [],
        helpTxt: this.helpTexts.phenotype,
      };
      let phenotypes = this.json_data.phenotypes;

      if (phenotypes == null || phenotypes.length == 0) {
        entry.type = "";
        entry.text = "No phenotypes available";
        return entry;
      }
      entry.fields.push({
        key: "phenotypes",
        label: "",
        cellType: "bullet_list",
        width: "100%",
      });
      let pubList = [];
      let authors = {};
      phenotypes.forEach((p) => {
        if (!authors[p.reference_id]) {
          authors[p.reference_id] = [];
        }
        authors[p.reference_id].push(p);
      });
      for (const [key, value] of Object.entries(authors)) {
        let item = {};
        let authors = value[0].authors
          ? value[0].authors.split(",")[0] + ", et al."
          : null;
        let title = authors
          ? `${authors} (${value[0].publicationYear})`
          : "Phenotype curated by ABRC";
        let link = value[0].publicationId
          ? `/publication?key=${value[0].publicationId}`
          : null;
        item.title = title;
        if (link) {
          item.link = link;
        }
        item.points = value.map((v) => v.phenotype);
        pubList.push(item);
      }

      let item = {
        phenotypes: pubList,
      };
      entry.items.push(item);

      return entry;
    },
    getPublications() {
      let entry = {
        key: "Publications",
        type: "table",
        items: [],
        fields: [],
        helpTxt: this.helpTexts.publications,
      };
      let pubs = this.json_data.publications;
      if (pubs == null || pubs.length == 0) {
        entry.type = "";
        entry.text = "No Publications available";
        return entry;
      }
      entry.fields.push({
        key: "title",
        label: "Title",
        cellType: "name_link",
      });
      entry.fields.push({
        key: "source",
        label: "Source",
        cellType: "name_link",
      });
      pubs.forEach((p) => {
        let item = {};
        let pub_link = "/publication?key=" + p.referenceId;
        item.title = {
          name: p.title,
          link: pub_link,
        };
        item.source = { name: p.sourceName };
        entry.items.push(item);
      });
      return entry;
    },
    getAttributions() {
      let entry = {
        key: "Attribution",
        type: "table",
        items: [],
        fields: [],
        helpTxt: this.helpTexts.attribution,
      };
      let attributions = this.json_data.attributions;
      if (!attributions || attributions.length == 0) {
        entry.type = "";
        entry.text = "No attributions available";
        return entry;
      }
      entry.fields = [
        {
          key: "type",
          label: "Type",
          cellType: "name_link",
        },
        {
          key: "name",
          label: "Name",
          cellType: "name_link",
        },
        {
          key: "date",
          label: "Date",
          cellType: "name_link",
        },
      ];
      attributions.forEach((a) => {
        let name = "";
        if (a.communityType == "organization") {
          name = a.organizationName;
        } else {
          name = a.firstName + " " + a.lastName;
        }

        // Assuming the link construction logic is similar to the previous example
        // Adjust the link structure as per your requirement
        let link =
          a.communityType == "organization"
            ? `/organization?key=${a.communityId}`
            : `/person?key=${a.communityId}`;

        let item = {
          type: { name: a.attributionType },
          name: {
            name: name,
            link: link, // Here's where the conditional link is set
          },
          date: {
            name: new Date(a.attributionDate).toISOString().split("T")[0],
          },
        };

        entry.items.push(item);
      });

      return entry;
    },
    getComments() {
      let entry = {
        key: "Community Comments",
        type: "comment_list",
        showComments: true,
        showRecent: true,
        items: [],
        helpTxt: this.helpTexts.comments,
      };
      let items = [];
      if (this.json_data.comments) {
        this.json_data.comments.forEach((c) => {
          let item = {};
          item.profile = {
            name: c.firstName,
            url: "/person?key=" + c.communityId,
          };
          item.comment = {
            text: c.notepadComment,
            url:
              `${process.env.VUE_APP_OLD_TAIR_URL}/servlets/TairObject?type=notepad&id=` +
              1,
          };
          item.date_posted = this.dateToYMD(c.dateEntered);
          items.push(item);
        });
      }
      entry.items = items;
      if (entry.items.length == 0) {
        entry.items.push({ comment: { text: "No comments found" } });
      }
      return entry;
    },
    dateToYMD(dateTs) {
      let date = new Date(dateTs);
      const d = date.getDate();
      const m = date.getMonth() + 1;
      const y = date.getFullYear();
      return `${y}-${m.toString().padStart(2, "0")}-${d
        .toString()
        .padStart(2, "0")}`;
    },
    //
    scrollToElement(colIdx) {
      const entryRef = this.$refs["entry" + colIdx];
      const el = entryRef[0].$el;
      if (el) {
        const top = el.offsetTop - 210;
        el.parentElement.scrollTo(0, top);
      }
    },
    handleScroll(e) {
      const scrollTop = e.target.scrollTop + 215;
      let currTopEntry = 0;
      this.entryPosys.forEach((e, i) => {
        if (scrollTop > e) {
          currTopEntry = i;
        }
      });
      this.colIdxSelected = currTopEntry;
    },
  },
};
</script>

<style scoped lang="scss">
.fixed-title {
  position: sticky;
  top: 0;
  background-color: #f1efec;
  z-index: 10;
  padding-left: 10px;
  padding-top: 10px;
}

.my-custom-scrollbar {
  position: relative;
  height: 200px;
  overflow: auto;
}
.table-wrapper-scroll-y {
  display: block;
}
</style>
