<template>
  <div class="searchWrapper d-flex flex-column">
    <div class="pageTop">
      <div class="d-none d-md-block">
        <div class="font-weight-bold text-left title ml-2">
          Browse Transposon Families
        </div>
      </div>
      <div>
        <div class="pageDesc mt-2">
          <span class="text-left px-4 statement" v-html="queryStatement"></span>
        </div>
      </div>
    </div>
    <div class="pageContent">
      <div class="d-flex align-content-left m-2">
        Displaying {{ localCurrentPage * perPage - perPage + 1 }} -
        {{
          items.length < localCurrentPage * perPage
            ? items.length
            : localCurrentPage * perPage
        }}
        of {{ items.length }} results
      </div>
      <div class="tableWrapper">
        <ResultsTable
          :items="items"
          :fields="fields"
          :loading="loading"
          :error="error"
          :perPageProp="perPage"
          :showSelect="false"
          @update:currentPage="localCurrentPage = $event"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import PostsService from "@/services/PostsService";
import { BPagination } from "bootstrap-vue";
import TopMenu from "@/components/search/TopMenu.vue";
import ResultsTable from "@/components/search/ResultsTable.vue";

export default {
  name: "TransposonFamiliesBrowse",
  data() {
    return {
      searchTerm: "",
      lociMatchCount: 0,
      geneModelCount: 0,
      selected: [],
      items: [],
      fields: [],
      loading: false,
      error: false,
      queryStatement: "",
      localCurrentPage: 1,
      perPage: 350,
      pages: 0,
    };
  },
  mounted() {
    document.title = "Transposon Families";
    this.init();
  },
  components: {
    BPagination,
    ResultsTable,
  },
  methods: {
    init() {
      let search_params = {
        searchTerms: [],
      };
      this.loadTransposonFamiliesResults(search_params);
    },
    async loadTransposonFamiliesResults(search_params) {
      this.fields = [];
      this.fields.push({ key: "row_num", label: " # " });
      this.fields.push({
        key: "family_name",
        label: "Family Name",
        cellType: "name_link",
      });
      this.fields.push({
        key: "super_family",
        label: "Super Family",
        cellType: "name_link",
      });
      this.fields.push({
        key: "num_elements",
        label: "Number of Transposable Elements",
        cellType: "name_link",
      });
      this.loading = true;
      let items = [];
      try {
        let response = await PostsService.fetchTransposonFamiliesSearch(
          search_params
        );
        if (response.data.error) {
          this.error = true;
          return;
        }
        let docs = response.data.docs;

        let totalMatch = response.data.total;

        for (const d of docs) {
          items.push({
            id: d.id,
            selected: false,
            family_name: {
              name: d.family_name ? d.family_name[0] : "",
              link: `/transposonfamily?key=${d.transposonFamilyId}`,
            },
            super_family: {
              name: d.super_family_name ? d.super_family_name[0] : "",
            },
            num_elements: {
              name: d.num_elements,
            },
          });
        }

        this.items = items;
        search_params.matchCount = totalMatch;
        this.loading = false;
      } catch (error) {
        console.log(
          error.response ? error.response.statusText : "Unknwon error"
        );
        this.loading = false;
        this.error = true;
        return;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.title {
  font-size: 24px;
}
.statement {
  font-size: 20px;
}
.two-line-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.custom-gap {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 10px;
}
.keySearchColor {
  color: #ff0000;
}

.ROWheader {
  align-items: center;
  justify-content: center;
  vertical-align: text-top;
}
.tight-checkbox {
  padding: 0px;
  margin: 0px;
}
.text-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.hashtag-icon {
  font-size: 16px;
  padding-bottom: 7px;
  justify-content: center;
  padding-left: 2px;
}
.center-content {
  display: flex;
  align-items: center; // Vertically center aligned
  justify-content: center; // Horizontally center aligned
}
</style>
