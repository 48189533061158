export function formatSize(bytes) {
  const kB = bytes / 1024;
  if (kB < 1024) return kB.toFixed(0) + ' KB';
  const mB = kB / 1024;
  return mB.toFixed(0) + ' MB';
}

export function formatDate(input) {
  let date;
  // Check if the input is a timestamp (integer)
  if (typeof input === 'number') {
    // Input is a timestamp
    date = new Date(input);
  } else if (typeof input === 'string') {
    if (!input.includes('T')) {
      // Input is a date string
      // Assuming the date string is in "YYYY-MM-DD" format
      // Append 'T00:00:00Z' to treat it as UTC to avoid timezone issues
      date = new Date(input + 'T00:00:00Z');
    } else {
      date = new Date(input)
    }
  } else {
    // Unsupported type
    return 'Invalid input';
  }

  // Format the date
  const options = { year: 'numeric', month: 'short', day: 'numeric', timeZone: 'UTC' };
  const formattedDate = date.toLocaleDateString('en-US', options);
  return formattedDate;
}

export function formatEventDates(beginsOn, endsOn) {
  const startDate = new Date(beginsOn);
  const endDate = new Date(endsOn);

  // If the dates are the same, return a single date
  if (beginsOn === endsOn) {
    const month = startDate.toLocaleString('default', { month: 'long' });
    const day = startDate.getDate();
    const year = startDate.getFullYear();
    return `${month} ${day}, ${year}`;
  }

  // Extract the parts of the dates we need
  const startMonth = startDate.toLocaleString('default', { month: 'long' });
  const startDay = startDate.getDate();
  const endDay = endDate.getDate();
  const year = startDate.getFullYear();

  // Check if the event starts and ends in the same month and year
  if (startDate.getMonth() === endDate.getMonth() && startDate.getFullYear() === endDate.getFullYear()) {
    return `${startMonth} ${startDay} - ${endDay}, ${year}`;
  } else {
    // Handle case where event spans different months or years
    const endMonth = endDate.toLocaleString('default', { month: 'long' });
    return `${startMonth} ${startDay}, ${year} - ${endMonth} ${endDay}, ${year}`;
  }
}