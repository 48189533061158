<template>
  <div class="mb-2 text-left">
    <span>
      <tooltip :data="data" :index="index" />
      <span class="font-weight-bold">{{ data.key }}</span>
    </span>
    <span v-if="data.count" class="badge badge-primary ml-2"
      ><span v-if="data.maxShow">{{ data.maxShow }} / </span>{{ data.count }}
      <span>{{ data.entry_name || "entries" }}</span>
    </span>
  </div>
</template>

<script>
import Tooltip from "@/components/common/Tooltip.vue";
export default {
  components: { Tooltip },
  mounted() {},
  props: ["data", "index"],
};
</script>
<style scoped>
.text-left {
  text-align: left;
}
</style>
