<template>
  <div>
    <div v-if="!error" class="detail-wrapper">
      <div class="side-wrapper">
        <ul class="list-group list-group-flush">
          <a
            v-for="(col, i) in side_cols"
            :key="i"
            href="#"
            class="list-group-item list-group-item-action flex-column align-items-start side-item"
            v-bind:class="{ selected: i == colIdxSelected }"
            @click="sideColClicked(i)"
          >
            {{ col }}
          </a>
        </ul>
      </div>
      <div v-if="loading">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <div class="list-wrapper" v-on:scroll.passive="handleScroll">
        <div
          v-if="json_data"
          class="text-left m-2 font-weight-bold fixed-title"
        >
          <h4>Clone: {{ json_data.name }}</h4>
        </div>
        <BaseEntry
          v-for="(e, i) in entries"
          :selectedColIdx="colIdxSelected"
          :currIdx="i"
          :key="i"
          :content="e"
          :ref="`entry` + i"
        ></BaseEntry>
      </div>
    </div>
    <div v-else>
      <PageNotFound />
    </div>
  </div>
</template>

<script>
//http://localhost:8082/clone?key=121407
import BaseEntry from "@/components/detail/BaseEntry";
import PostsService from "@/services/PostsService";
import PageNotFound from "@/components/common/PageNotFound";

export default {
  name: "CloneDetail",
  components: {
    BaseEntry,
    PageNotFound,
  },
  data() {
    return {
      base_url: process.env.VUE_APP_S3_PATH + "clone/",
      json_data: null,
      side_cols: ["Summary"],
      loading: false,
      error: false,
      colIdxSelected: 0,
      entries: [],
      entryPosys: [],
      helpTexts: {},
    };
  },

  async mounted() {
    let cloneId = this.$route.query.key;
    if(this.$route.query.name){
      let response2 = await PostsService.getCloneIdByName(this.$route.query.name.toUpperCase());
      cloneId = response2.data;
      if(cloneId == null){
        this.error = true;
      }
    }
    if (cloneId) {
      try {
        let response = await PostsService.getCloneDetail({ key: cloneId });
        this.json_data = response.data;

        this.loading = true;
        this.processEntries();
        this.loading = false;
        document.title = `Clone Detail`;
      } catch (error) {
        console.error("Error fetching clone details: ", error);
        this.loading = false;
        this.error = true;
      }
    }
  },
  methods: {
    sideColClicked(colIdx) {
      this.scrollToElement(colIdx);
      this.colIdxSelected = colIdx;
    },
    processEntries() {
      this.side_cols.forEach((title) => {
        let entry = {
          title: title,
          name: title.replace(/\s/g, ""),
        };
        switch (title) {
          case "Summary":
            entry.type = "bands";
            entry.bands = this.fillSummary();
            break;
          default:
            entry.type = "bands";
            entry.bands = [];
            break;
        }
        this.entries.push(entry);
      });
    },
    fillSummary() {
      let bands = [];
      bands.push({
        key: "Name",
        text: this.json_data.name,
      });
      bands.push({
        key: "Date Last Modified",
        text: this.dateToYMD(this.json_data.dateLastModified),
      });
      bands.push({
        key: "Aliases",
        text: this.json_data.aliases
          ? this.json_data.aliases.join(", ")
          : "No Alias Available",
      });
      bands.push({
        key: "TAIR Accession",
        text: `Clone:${this.json_data.tairObjectId}`,
      });
      bands.push(this.getClone());
      bands.push(this.getTaxon());
      bands.push({
        key: "Chromosome",
        text: this.json_data.chromosome ? this.json_data.chromosome : "Unknown",
      });
      bands.push({
        key: "Selection",
        text: this.json_data.antibioticSelection
          ? this.json_data.antibioticSelection
          : "Unknown",
      });
      bands.push(this.getStocks());
      bands.push(this.getAssociatedGenes());
      bands.push(this.getSequences());
      bands.push(this.getVectors());
      bands.push(this.getHostStrain());
      bands.push(this.getMapLocations());
      // bands.push(this.getMapLinks());
      bands.push(this.getComments());
      bands.push(this.getAttributions());
      return bands;
    },
    //Getters
    getStocks() {
      let entry = {
        key: "Stock Information",
        type: "multiline",
        items: [],
        fields: [],
        buttons: [],
      };
      entry.fields.push({
        key: "stockNo",
        label: "Stock #",
        cellType: "name_link",
      });
      entry.fields.push({
        key: "availability",
        label: "Availability",
        cellType: "name_link",
      });
      entry.fields.push({
        key: "donors",
        label: "Donors",
        cellType: "link_list",
      });
      entry.fields.push({
        key: "donorStockNo",
        label: "Donor Stock #",
        cellType: "name_link",
      });
      //   entry.fields.push({
      //     key: "description",
      //     label: "Description",
      //     cellType: "name_link",
      //     width: "100%",
      //   });

      let stocks = this.json_data.stockDetails;
      if (stocks == null || stocks.length == 0) {
        entry.type = "";
        entry.text =
          "Search at the stock centers or contact an author of publication(s) describing this line.";
        return entry;
      }
      let stockMap = {};
      stocks.forEach((s) => {
        if (!stockMap[s.stockId]) {
          stockMap[s.stockId] = [];
        }
        stockMap[s.stockId].push(s);
      });

      Object.keys(stockMap).forEach((stockId) => {
        let stocks = stockMap[stockId];
        let donors = [];
        stocks.forEach((s) => {
          if (s.communityType == "person") {
            donors.push({
              name: `${s.firstName} ${s.lastName}`,
              link: `/person?key=${s.communityId}`,
            });
          } else if (s.communityType == "organization") {
            donors.push({
              name: s.organizationName,
              link: `/organization?key=${s.communityId}`,
            });
          }
        });

        let s = stocks[0];
        let item = {};
        item.stockNo = {
          name: s.name,
          link: `https://abrc.osu.edu/stocks/number/${s.name}`,
        };
        item.availability = {
          name: s.stockAvailabilityType,
        };
        item.donors = donors;
        item.donorStockNo = {
          name: s.donorStockNumber,
        };
        item.description = {
          name: s.description,
        };
        entry.items.push(item);
      });

      return entry;
    },
    getClone() {
      let entry = {
        key: "Clone",
        type: "table",
        items: [],
        fields: [],
      };
      let clone = this.json_data.parentClone;
      if (clone == null) {
        entry.type = "";
        entry.text = "No Clone available";
        return entry;
      }
      entry.fields = [
        {
          key: "insertType",
          label: "Insert type",
          cellType: "name_link",
        },
        {
          key: "insertSize",
          label: "Insert Size",
          cellType: "name_link",
        },
      ];
      entry.items = [
        {
          insertType: { name: clone.insertType },
          insertSize: {
            name: clone.insertSize != 0 ? clone.insertSize : "Unknown",
          },
        },
      ];
      return entry;
    },
    getTaxon() {
      let entry = {
        key: "Taxon",
        type: "table",
        items: [],
        fields: [],
      };
      let taxon = this.json_data.taxon;
      if (!taxon) {
        entry.type = "";
        entry.text = "No Taxon available";
        return entry;
      }
      entry.fields = [
        {
          key: "name",
          label: "Taxon",
          cellType: "name_link",
        },
        {
          key: "variant",
          label: "Species Variant",
          cellType: "name_link",
        },
      ];
      entry.items = [
        {
          name: { name: taxon.scientificName },
          variant: {
            name: taxon.originalName,
            link: `/ecotype?key=${taxon.speciesVariantId}`,
          },
        },
      ];
      return entry;
    },
    getAssociatedGenes() {
      let entry = {
        key: "Associated Genes",
        type: "table",
        items: [],
        fields: [],
      };
      entry.fields.push({
        key: "geneModel",
        label: "Gene Model",
        cellType: "name_link",
      });
      entry.fields.push({
        key: "locus",
        label: "Locus",
        cellType: "name_link",
      });
      entry.fields.push({
        key: "associationType",
        label: "Association Type",
        cellType: "name_link",
      });
      let gene = this.json_data.gene;
      if (gene == null) {
        entry.type = "";
        entry.text = "No Associated Genes available";
        return entry;
      }
      entry.items = [
        {
          geneModel: {
            name: gene.geneName,
            link: `/gene?key=${gene.geneId}`,
          },
          locus: {
            name: gene.locusName,
            link: `/locus?key=${gene.locusId}`,
          },
          associationType: { name: gene.relationshipType },
        },
      ];

      return entry;
    },
    getSequences() {
      let entry = {
        key: "Sequences",
        type: "table",
        items: [],
        fields: [],
      };

      // Define table fields
      entry.fields.push({
        key: "bioSrc",
        label: "Bio Source",
        cellType: "name_link",
      });
      entry.fields.push({
        key: "source",
        label: "Source",
        cellType: "name_link",
      });
      entry.fields.push({ key: "date", label: "Date", cellType: "name_link" });
      entry.fields.push({
        key: "genBank",
        label: "GenBank",
        cellType: "name_link",
      });
      entry.fields.push({
        key: "sequence",
        label: "Sequence",
        cellType: "name_link",
      });

      let sequences = this.json_data.sequences; // Adjust the path according to your data structure
      if (!sequences) {
        entry.type = "";
        entry.text = "No Sequences available";
        return entry;
      }

      // Map sequence data to table items
      sequences.map((s) => {
        let item = {
          bioSrc: { name: s.nucleotidesequenceType },
          source: { name: s.organizationName },
          date: { name: this.dateToYMD(s.attributionDate) },
          genBank: {
            name: s.icAccession,
            link: `https://www.ncbi.nlm.nih.gov/nucleotide/${s.icAccession}`,
          },
          sequence: {
            name: s.sequenceClass,
            link: `/sequence?key=${s.nucleotideSeqId}`,
          },
        };
        entry.items.push(item);
      });

      return entry;
    },
    getVectors() {
      let entry = {
        key: "Vector",
        type: "table",
        items: [],
        fields: [],
      };

      // Define table fields
      entry.fields.push({
        key: "vectorName",
        label: "Name",
        cellType: "name_link",
      });
      entry.fields.push({
        key: "vectorType",
        label: "Type",
        cellType: "name_link",
      });
      entry.fields.push({
        key: "vectorDesc",
        label: "Description",
        cellType: "name_link",
      });

      let vectors = this.json_data.vectorDetails; // Adjust the path according to your data structure
      if (!vectors) {
        entry.type = "";
        entry.text = "No Vectors available";
        return entry;
      }

      // Map vector data to table items
      vectors.map((v) => {
        let item = {
          vectorName: {
            name: v.name,
            link: `/vector?key=${v.vectorId}`,
          },
          vectorType: { name: v.vectorType },
          vectorDesc: {
            name: v.description,
          },
        };
        entry.items.push(item);
      });

      return entry;
    },
    getHostStrain() {
      let entry = {
        key: "Host Strain",
        type: "table",
        items: [],
        fields: [],
      };
      let hostStrain = this.json_data.hostStrain;
      if (!hostStrain) {
        entry.type = "";
        entry.text = "No Host Strains available";
        return entry;
      }
      entry.fields = [
        {
          key: "genus",
          label: "Genus",
          cellType: "name_link",
        },
        {
          key: "species",
          label: "Species",
          cellType: "name_link",
        },
        {
          key: "strainName",
          label: "Strain Name",
          cellType: "name_link",
        },
      ];
      let s = hostStrain;
      let item = {
        genus: {
          name: s.genus,
        },
        species: {
          name: s.species,
        },
        strainName: {
          name: s.originalName,
          link: `/hoststrain?key=${s.hostStrainId}`,
        },
      };
      entry.items.push(item);
      return entry;
    },
    getMapLinks() {
      let entry = {
        key: "Map Links",
        type: "link_list",
        items: [],
      };
      entry.items.push({
        text: "Sequence Viewer",
        link: `https://seqviewer.arabidopsis.org/?action=accession&type=clone&id=${this.json_data.sequenceViewerId}&chr=${this.json_data.chrom}`,
      });
      entry.items.push({
        text: "GBrowse",
        link: `${process.env.VUE_APP_OLD_TAIR_URL}/servlets/tools/gbrowse/arabidopsis?name=${this.json_data.gbrowseId}`,
      });
      return entry;
    },
    getMapLocations() {
      let entry = {
        key: "Map Locations",
        type: "table",
        items: [],
        fields: [],
      };

      // Define table fields
      entry.fields.push({
        key: "chrom",
        label: "Chromosome",
        cellType: "name_link",
      });
      entry.fields.push({ key: "map", label: "Map", cellType: "name_link" });
      entry.fields.push({
        key: "mapType",
        label: "Map Type",
        cellType: "name_link",
      });
      entry.fields.push({
        key: "startEnd",
        label: "coordinates",
        cellType: "name_link",
      });
      entry.fields.push({
        key: "orientation",
        label: "Orientation",
        cellType: "name_link",
      });
      entry.fields.push({
        key: "attrib",
        label: "Attrib",
        cellType: "name_link",
      });

      let mapLocations = this.json_data.globalAssignments; // Adjust the path according to your data structure
      if (!mapLocations || mapLocations.length === 0) {
        entry.type = "";
        entry.text = "No Map Locations available";
        return entry;
      }

      // Map map location data to table items
      entry.items = mapLocations.map((loc) => {
        let orientation = "";
        if (loc.orientation == "F") {
          orientation = "forward";
        } else if (loc.orientation == "R") {
          orientation = "reverse";
        } else {
          orientation = "unknwon";
        }
        let mapLinkType = "map";
        if (loc.mapType == "assembly_unit") {
          mapLinkType = "assembly_unit";
        }
        return {
          chrom: { name: loc.chromosome },
          map: {
            name: loc.name,
            link: `${process.env.VUE_APP_OLD_TAIR_URL}/servlets/TairObject?type=${mapLinkType}&id=${loc.mapId}`,
          },
          mapType: { name: loc.mapType },
          startEnd: {
            name: `${loc.startPosition} - ${loc.endPosition} ${loc.units}`,
          },
          orientation: { name: orientation },
          attrib: loc.globalAssignmentId
            ? {
                name: "details",
                link: `${process.env.VUE_APP_OLD_TAIR_URL}/servlets/TairObject?id=${loc.globalAssignmentId}&type=global_assignment`, // Adjust link format as needed
              }
            : { name: "N/A" },
        };
      });

      return entry;
    },
    getAttributions() {
      let entry = {
        key: "Attribution",
        type: "table",
        items: [],
        fields: [],
      };
      let attributions = this.json_data.attributions;
      if (attributions == null || attributions.length == 0) {
        entry.type = "";
        entry.text = "No Attributions available";
        return entry;
      }
      entry.fields = [
        {
          key: "type",
          label: "Type",
          cellType: "name_link",
        },
        {
          key: "name",
          label: "Name",
          cellType: "name_link",
        },
        {
          key: "date",
          label: "Date",
          cellType: "name_link",
        },
      ];
      attributions.forEach((a) => {
        let name = a.organizationName;
        if (a.firstName && a.lastName) {
          name = `${a.firstName} ${a.lastName}`;
        }
        let a_link = "";
        if (a.communityType == "organization") {
          a_link = `/organization?key=${a.communityId}`;
        } else {
          a_link = `/person?key=${a.communityId}`;
        }
        let item = {
          type: { name: a.attributionType },
          name: {
            name: name,
            link: a_link,
          },
          date: { name: this.dateToYMD(a.attributionDate) },
        };
        entry.items.push(item);
      });
      return entry;
    },
    getComments() {
      let entry = {
        key: "Community Comments",
        type: "comment_list",
        showComments: true,
        showRecent: true,
        items: [],
        helpTxt: this.helpTexts.comments,
      };
      let items = [];
      if (this.json_data.comments) {
        this.json_data.comments.forEach((c) => {
          let item = {};
          item.profile = {
            name: c.firstName,
            url: "/person?key=" + c.communityId,
          };
          item.comment = {
            text: c.notepadComment,
            url:
              `${process.env.VUE_APP_OLD_TAIR_URL}/servlets/TairObject?type=notepad&id=` +
              1,
          };
          item.date_posted = this.dateToYMD(c.dateEntered);
          items.push(item);
        });
      }
      entry.items = items;
      if (entry.items.length == 0) {
        entry.items.push({ comment: { text: "No comments found" } });
      }
      return entry;
    },
    dateToYMD(dateTs) {
      let date = new Date(dateTs);
      const d = date.getDate();
      const m = date.getMonth() + 1;
      const y = date.getFullYear();
      return `${y}-${m.toString().padStart(2, "0")}-${d
        .toString()
        .padStart(2, "0")}`;
    },
    //
    scrollToElement(colIdx) {
      const entryRef = this.$refs["entry" + colIdx];
      const el = entryRef[0].$el;
      if (el) {
        const top = el.offsetTop - 210;
        el.parentElement.scrollTo(0, top);
      }
    },
    handleScroll(e) {
      const scrollTop = e.target.scrollTop + 215;
      let currTopEntry = 0;
      this.entryPosys.forEach((e, i) => {
        if (scrollTop > e) {
          currTopEntry = i;
        }
      });
      this.colIdxSelected = currTopEntry;
    },
  },
};
</script>

<style scoped lang="scss">
.fixed-title {
  position: sticky;
  top: 0;
  background-color: #f1efec;
  z-index: 10;
  padding-left: 10px;
  padding-top: 10px;
}

.my-custom-scrollbar {
  position: relative;
  height: 200px;
  overflow: auto;
}
.table-wrapper-scroll-y {
  display: block;
}
</style>
