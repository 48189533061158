<template>
  <div class="searchWrapper">
    <div class="pageTop">
      <div class="d-none d-md-block">
        <div class="d-flex flex-column">
          <b-breadcrumb :items="crumb_links"></b-breadcrumb>
          <div class="text-left ml-2 d-flex">
            <h4 style="color: #930">TAIR Protocol Search</h4>
            <span class="ml-2"
              >[<a :href="helpLink" target="_blank">Help</a>]</span
            >
          </div>
          <div class="text-left m-2">
            Use this form to search for protocols, experimental methods and
            classroom activities in TAIR's database. Protocols have been
            obtained from individual submissions and compilations such as the
            Complete Guide, and EMBO and CSHL Arabidopsis Course manuals. We
            encourage you to share your protocols with the research community
            and welcome your contributions. To submit a protocol to TAIR please
            follow the Guidelines for protocol submission.
          </div>
        </div>
      </div>
      <div class="d-md-none">
        <div class="row">
          <div class="col-4 font-weight-bold pl-2">Search Result</div>
          <div class="col-8">
            <b-dropdown id="dropdown-1" text="Menu" class="m-md-2">
              <b-dropdown-item>New Search</b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>
    </div>
    <div class="pageMain m-2">
      <div class="d-flex flex-column">
        <div class="row mt-2">
          <div class="col-sm d-flex align-items-start ml-4">
            <b-button size="sm" class="mr-2">Reset</b-button>
            <b-button size="sm" @click="onSubmit">Submit Query</b-button>
          </div>
        </div>
        <div>
          <div class="d-flex flex-column mx-4 my-2">
            <form-section
              v-for="(filter, index) in search_form.filters"
              :key="index"
              :filter="filter"
              @on-enter="onSubmit"
            />
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-sm d-flex align-items-start ml-4 mb-4">
            <b-button size="sm" class="mr-2">Reset</b-button>
            <b-button size="sm" @click="onSubmit">Submit Query</b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormSection from "../../components/common/FormSection.vue";
export default {
  name: "ProtocolSearch",
  components: {
    FormSection,
  },
  data() {
    return {
      crumb_links: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "Search",
          href: "/search/overview",
        },
        {
          text: "Protocols",
          active: true,
        },
      ],
      search_form: {
        filters: [
          {
            label: "Protocol Search",

            type: "rows",
            rows: [
              {
                name: "Search Terms",
                value: {
                  type: "mixed",
                  elements: [
                    {
                      type: "select",
                      options: [
                        {
                          text: "description",
                          value: "description",
                        },
                        {
                          text: "title",
                          value: "title",
                        },
                        {
                          text: "authors",
                          value: "authors",
                        },
                      ],

                      value: "title",
                      id: "search_type_by_1",
                      size: "medium",
                    },
                    {
                      type: "select",
                      options: [
                        { text: "contains", value: "contains" },
                        { text: "starts with", value: "starts_with" },
                        { text: "exact", value: "exact" },
                      ],
                      value: "starts_with",
                      id: "search_method_1",
                      size: "medium",
                    },
                    {
                      type: "input",
                      value: "",
                      id: "search_input_1",
                      size: "large",
                    },
                  ],
                },
              },
              {
                name: "",
                value: {
                  type: "mixed",
                  elements: [
                    {
                      type: "select",
                      options: [
                        {
                          text: "description",
                          value: "description",
                        },
                        {
                          text: "title",
                          value: "title",
                        },
                        {
                          text: "authors",
                          value: "authors",
                        },
                      ],

                      value: "description",
                      id: "search_type_by_1",
                      size: "medium",
                    },
                    {
                      type: "select",
                      options: [
                        { text: "contains", value: "contains" },
                        { text: "starts with", value: "starts_with" },
                        { text: "exact", value: "exact" },
                      ],
                      value: "starts_with",
                      id: "search_method_2",
                      size: "medium",
                    },
                    {
                      type: "input",
                      value: "",
                      id: "search_input_2",
                      size: "large",
                    },
                  ],
                },
              },
              {
                name: "Browse Keywords (multiple selection)",
                value: {
                  elements: [
                    {
                      type: "multiSelect",
                      options: [],
                      selected: ["Any"],
                      value: ["any"],
                      width: "large",
                      size: 8,
                      id: "keywords",
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
      keyword_options: [
        "RNA extraction",
        "cDNA synthesis",
        "histology",
        "immunohistochemistry",
        "immunoprecipitation",
        "in planta transformation",
        "in situ hybridization",
        "inverse polymerase chain reaction",
        "mRNA isolation",
        "microarray",
        "microarray slide preparation",
        "molecular mapping",
        "organelle isolation",
        "phage DNA extraction",
        "genomic DNA extraction",
        "biochemical trait assay",
        "plant growth method",
        "plant growth assay",
        "plasmid DNA extraction",
        "plant transformation",
        "polymerase chain reaction",
        "probe labeling",
        "protoplast isolation",
        "reagent preparation",
        "root growth assay",
        "seed sterilization",
        "tissue culture",
        "tissue printing",
        "RNA hybridization",
      ],
      helpLink:
        "https://conf.phoenixbioinformatics.org/display/COM/Protocol+Search+Help",
    };
  },
  watch: {
    "$route.query.key": function (id) {
      this.$forceUpdate();
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      document.title = "Protocol Search";
      this.updateOptionsFromData("keywords", this.keyword_options);
    },
    updateOptionsFromData(id, data) {
      const sortedValues = data.sort((a, b) =>
        a.toLowerCase().localeCompare(b.toLowerCase())
      );
      let optionsList = sortedValues.map((value) => {
        return { text: value, value: value };
      });
      optionsList.unshift({ text: "any", value: "any" });
      this.updateOptionsById(id, optionsList);
    },
    generalizeFieldNames(rowParams) {
      const generalizedParams = {};

      Object.keys(rowParams).forEach((key) => {
        if (key.startsWith("search_type_by_")) {
          generalizedParams["search_type"] = rowParams[key];
        } else if (key.startsWith("search_method_")) {
          generalizedParams["search_method"] = rowParams[key];
        } else if (key.startsWith("search_input_")) {
          generalizedParams["search_input"] = rowParams[key];
        } else {
          // Copy other fields as-is
          generalizedParams[key] = rowParams[key];
        }
      });

      return generalizedParams;
    },
    async onSubmit(event) {
      event.preventDefault();

      let searchParams = {
        searchTerms: [],
      };

      this.search_form.filters.forEach((filter) => {
        if (filter.type === "rows") {
          filter.rows.forEach((row) => {
            let rowParams = {};
            row.value.elements.forEach((element) => {
              let shouldAddParam = true;
              let value = element.value;

              if (element.type === "checkbox") {
                value = element.value;
              }

              if (!value || value === false || value === "any") {
                shouldAddParam = false;
              }

              //check if value is an array
              if (Array.isArray(value)) {
                if (
                  value.length === 0 ||
                  (value.length === 1 && value[0] === "any")
                ) {
                  shouldAddParam = false;
                }
              }

              if (shouldAddParam) {
                if (element.id) {
                  rowParams[element.id] = value;
                }
              }
            });
            if (Object.keys(rowParams).length > 0) {
              if (filter.label === "Protocol Search") {
                const generalizedRowParams =
                  this.generalizeFieldNames(rowParams);
                searchParams.searchTerms.push(generalizedRowParams);
              }
            }
          });
        }
      });

      this.showResults(searchParams);
    },

    onReset(event) {
      event.preventDefault();
    },
    showResults(inp_params) {
      this.$nextTick(() => {
        this.$router.push({
          path: "/results",
          query: {
            mainType: "detail",
            category: "protocol",
            search_params: JSON.stringify(inp_params),
          },
        });
      });
    },
    getValueById(searchId) {
      let result = null;

      // Loop over each filter
      for (let filter of this.search_form.filters) {
        // Loop over each row in the current filter
        for (let row of filter.rows) {
          // Check if row has a value and elements
          if (row.value && row.value.elements) {
            // Find the element with the matching id
            let element = row.value.elements.find((el) => el.id === searchId);
            if (element) {
              result = element.value;
              break;
            }
          }
        }

        if (result !== null) {
          break;
        }
      }

      return result;
    },
    updateOptionsById(searchId, options) {
      for (let filter of this.search_form.filters) {
        for (let row of filter.rows) {
          if (row.value && row.value.elements) {
            let element = row.value.elements.find((el) => el.id === searchId);
            if (element) {
              if (element.options) {
                element.options = options;
                element.value = options[0].value;
              }
              break;
            }
          }
        }
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
