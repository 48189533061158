export function filterResults(docs, params) {
  let filteredDocs = [];
  if (params.searchType == "exact") {
    if (params.searchBy == "Gene Name") {
      filteredDocs = docs.filter((d) => {
        let matched = false;
        let gene_name = d.gene_name[0].toLowerCase();
        let searchText = params.detailSearchText.toLowerCase();
        //check if gene_name starts with searchText
        if (gene_name == searchText) {
          matched = true;
        }
        //check if searchText is in other_names
        if (d.other_names) {
          let other_names = d.other_names.map((o) => o.toLowerCase());
          if (other_names.includes(searchText)) {
            matched = true;
          }
        }
        return matched;
      });
    } else if (params.searchBy == "Description") {
      filteredDocs = docs.filter((d) => {
        let matched = false;
        let description = d.description[0].toLowerCase();
        let searchText = params.detailSearchText.toLowerCase();
        if (description == searchText) {
          matched = true;
        }
        return matched;
      });
    } else if (params.searchBy == "Uniprot ID") {
      filteredDocs = docs.filter((d) => {
        let matched = false;
        let uniprot_id = d.uniprot_ids[0].toLowerCase();
        let searchText = params.detailSearchText.toLowerCase();
        //check if uniprot_id starts with searchText
        if (uniprot_id == searchText) {
          matched = true;
        }
        return matched;
      });
    } else if (params.searchBy == "Phenotype") {
      filteredDocs = docs.filter((d) => {
        let matched = false;
        if (d.phenotypes) {
          let phenotypes = d.phenotypes.map((o) => o.toLowerCase());
          let searchText = params.detailSearchText.toLowerCase();
          if (phenotypes.includes(searchText)) {
            matched = true;
          }
        }
        return matched;
      });
    } else {
      return docs;
    }
  } else if (params.searchType == "starts with") {
    if (params.searchBy == "Gene Name") {
      filteredDocs = docs.filter((d) => {
        let matched = false;
        let gene_name = d.gene_name[0].toLowerCase();
        let searchText = params.detailSearchText.toLowerCase();
        //check if gene_name starts with searchText
        if (gene_name.startsWith(searchText)) {
          matched = true;
        }
        //check if searchText is starts with in other_names
        if (d.other_names) {
          let other_names = d.other_names.map((o) => o.toLowerCase());
          if (other_names.some((o) => o.startsWith(searchText))) {
            matched = true;
          }
        }
        return matched;
      });
    } else if (params.searchBy == "Description") {
      filteredDocs = docs.filter((d) => {
        let matched = false;
        let description = d.description[0].toLowerCase();
        let searchText = params.detailSearchText.toLowerCase();
        //check if description starts with searchText
        if (description.startsWith(searchText)) {
          matched = true;
        }
        return matched;
      });
    } else if (params.searchBy == "Uniprot ID") {
      filteredDocs = docs.filter((d) => {
        let matched = false;
        let uniprot_id = d.uniprot_ids[0].toLowerCase();
        let searchText = params.detailSearchText.toLowerCase();
        //check if uniprot_id starts with searchText
        if (uniprot_id.startsWith(searchText)) {
          matched = true;
        }
        return matched;
      });
    } else if (params.searchBy == "Phenotype") {
      filteredDocs = docs.filter((d) => {
        let matched = false;
        if (d.phenotypes) {
          let phenotypes = d.phenotypes.map((o) => o.toLowerCase());
          let searchText = params.detailSearchText.toLowerCase();
          if (phenotypes.some((o) => o.startsWith(searchText))) {
            matched = true;
          }
        }
        return matched;
      });
    } else {
      return docs;
    }
  } else {
    return docs;
  }
  return filteredDocs;
}
