import Api from "@/services/Api";
import { formatSize, formatDate } from "./ServiceUtils";

export async function listDirContent(dirPath) {
  try {
    // get list content from API
    const response = await Api().get("download-files/list", {
      params: {
        dir: dirPath,
      },
    });

    let items = [];
    Object.entries(response.data).forEach(([key, value]) => {
      let item;
      // when the item is a file
      if (value.hasOwnProperty("type") && value.type == "file") {
        item = {
          name: key,
          type: value.type,
          size: formatSize(value.size),
          lastModified: formatDate(value.lastModified),
          path: value.path,
        };
        // when the item is a directory
      } else {
        item = {
          name: key,
          type: "dir",
          path: window.location.href + "/" + key,
        };
      }
      items.push(item);
    });
    return items;
  } catch (error) {
    return [];
  }
}

export async function getDownloadBlob(filePath) {
  try {
    // get list content from API
    const response = await Api().get("download-files/download", {
      params: {
        filePath: filePath,
      },
      responseType: "blob",
    });

    return response.data;
  } catch (error) {
    return;
  }
}

export function getDownloadLink(filePath) {
  return `${process.env.VUE_APP_API_BASE_URL}/download-files/download?filePath=${filePath}`
}
